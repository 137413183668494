import { TemplateItemFieldType } from '@modules/core/aws/dynamo-db/digital-vault/interfaces';
import { TemplateValuesType } from '@modules/common-ui/components/customer-fields-manager/interfaces';

import { CUSTOMER_OBJECT_FIELD_TYPES } from '@modules/core/constants';

const getInitialValue = (field: TemplateItemFieldType) => {
  switch (field.FieldType) {
    case CUSTOMER_OBJECT_FIELD_TYPES.dropdown:
      return field.VariableOptions?.[0] || '';

    case CUSTOMER_OBJECT_FIELD_TYPES.text:
      return '';

    case CUSTOMER_OBJECT_FIELD_TYPES.password:
      return '';

    case CUSTOMER_OBJECT_FIELD_TYPES.usState:
      return '';

    case CUSTOMER_OBJECT_FIELD_TYPES.number:
      return 0;

    case CUSTOMER_OBJECT_FIELD_TYPES.numberNoFormat:
      return 0;

    case CUSTOMER_OBJECT_FIELD_TYPES.currency:
      return 0;

    default:
      return null;
  }
};

export function createInitialValues(templateFields: TemplateItemFieldType[]): TemplateValuesType {
  return templateFields.reduce((accumulator: TemplateValuesType, value: TemplateItemFieldType) => {
    return { ...accumulator, [value.FieldName]: getInitialValue(value) };
  }, {});
}
