import React, { ReactElement, useState, useEffect } from 'react';

import { isString } from 'lodash';
import { Image } from '@chakra-ui/react';

import { arrayBufferToBase64 } from '@modules/core/lvedHelpers';
import { ConditionSeparator } from '@modules/common-ui/components/condition-separator';
import { UserIcon } from '@modules/common-ui/icons';
import { IAvatarProps } from './interfaces';

import { profileBasicInfoStyles } from './styles';

export function ImgAvatar({ file, style }: IAvatarProps): ReactElement {
  const [imageBase64, setImageBase64] = useState<string>('');

  useEffect(() => {
    if (file) {
      if (isString(file)) setImageBase64(file);
      else {
        arrayBufferToBase64(file).then((base64: string) => {
          setImageBase64(base64);
        });
      }
    }
  }, [file]);

  return (
    <ConditionSeparator
      condition={!!file}
      defaultTarget={<UserIcon />}
      target={<Image src={imageBase64} {...profileBasicInfoStyles.avatar} {...style} />}
    />
  );
}
