import { lambdaGeneralCaller } from '@modules/core/aws/lambdas/lambda-general-caller/lambdaGeneralCaller';
import {
  IFamilyRegisterEventRequest,
  IFamilyRegisterEventResult,
  IUnAuthCredentials,
} from '@modules/core/aws/lambdas/family-action/interfaces';

import { AWS_LAMBDAS, AWS_LAMBDAS_OPERATIONS } from '@modules/core/constants';

export default async function registerEvent(
  request: IFamilyRegisterEventRequest
): Promise<IFamilyRegisterEventResult> {
  const credentials: IUnAuthCredentials = {
    accessKeyId: process.env.REACT_APP_AWS_UNAUTHENTICATED_ACCESS_KEY_ID || 'NONE',
    secretAccessKey: process.env.REACT_APP_AWS_UNAUTHENTICATED_SECRET_ACCESS_KEY || 'NONE',
  };

  return lambdaGeneralCaller<IFamilyRegisterEventResult>(credentials, {
    FunctionName: AWS_LAMBDAS.familyAction,
    Payload: JSON.stringify({
      operation: AWS_LAMBDAS_OPERATIONS.registerEvent,
      sub: request.accountHolderUserName,
      fullName: request.fullName,
      mobile: request.mobile,
      email: request.email,
      accountHolderStatus: request.accountHolderStatus,
      statusDetails: request.statusDetails,
      relationship: request.relationship,
    }),
  });
}
