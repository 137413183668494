import React, { useState, ReactElement } from 'react';

import { Text, Heading, Button, Flex, VStack } from '@chakra-ui/react';
import { isBoolean } from 'lodash';

import { IQuestionBasicProps } from './interfaces';

import { questionStyles } from './styles';

function BooleanQuestion({ question, callback, answers }: IQuestionBasicProps): ReactElement {
  const oldAnswer: boolean | undefined = answers[question.FieldName];
  const [value, setValue] = useState<boolean | null>(oldAnswer ?? null);

  const setAnswer = (answer: boolean): void => {
    setValue(answer);
    callback(question.FieldName, answer);
  };

  return (
    <Flex {...questionStyles.questionContainer}>
      <VStack {...questionStyles.questionTextBox}>
        <Heading>{question.DisplayTitle}</Heading>

        {question.HelperText && <Text>{question.HelperText}</Text>}
      </VStack>

      <Flex {...questionStyles.questionFieldMargin}>
        <Button
          onClick={setAnswer.bind(null, false)}
          borderWidth={`${isBoolean(value) && !value ? '3px' : '1px'}`}
          {...questionStyles.boolButtonDefault}
        >
          No
        </Button>

        <Button
          onClick={setAnswer.bind(null, true)}
          borderWidth={`${value ? '3px' : '1px'}`}
          {...questionStyles.boolButtonDefault}
          {...questionStyles.boolRightButton}
        >
          Yes
        </Button>
      </Flex>
    </Flex>
  );
}

export default BooleanQuestion;
