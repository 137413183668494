import React, { useState, ReactElement } from 'react';

import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Button,
} from '@chakra-ui/react';

import { IPasswordInputProps } from './interfaces';

import { inputStyles } from './styles';

function PasswordInput({
  label,
  id,
  errorMessage,
  style = {},
  variant = 'outline',
  size = 'lg',
  isError = false,
  value,
  ...rest
}: IPasswordInputProps): ReactElement {
  const [show, setShow] = useState<boolean>(false);
  const handleClick = (): void => setShow((prevState) => !prevState);

  return (
    <FormControl {...style} isInvalid={isError}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}

      <InputGroup size={size}>
        <Input
          id={id}
          type={show ? 'text' : 'password'}
          value={value || ''}
          variant={variant}
          {...rest}
        />

        <InputRightElement>
          <Button onClick={handleClick} {...inputStyles.button}>
            {show ? (
              <ViewIcon {...inputStyles.passwordIcon} />
            ) : (
              <ViewOffIcon {...inputStyles.passwordIcon} />
            )}
          </Button>
        </InputRightElement>
      </InputGroup>

      {isError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
}

export default PasswordInput;
