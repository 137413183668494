export enum CONTINUE_WITH {
  noMoreHistory = 'EXHAUSTED',
}

export enum TABLE_COLUMNS {
  plan = 'plan',
  planCost = 'rate',
  creditApplied = 'credit applied',
  amount = 'Charged',
  refunded = 'refunded',
  cardInfo = 'card info',
  paymentDate = 'date of payment',
}

export enum DEFAULT_VALUES {
  paymentMethod = 'Unknown payment method',
}
