import { LOCAL_STORAGE } from '../constants';

export function storageSet(key: LOCAL_STORAGE, value: string): void {
  return localStorage.setItem(key, value);
}

export function storageGet(key: LOCAL_STORAGE): string | null {
  return localStorage.getItem(key);
}

export function storageClear(): void {
  return localStorage.clear();
}
