import { useState, useEffect } from 'react';

import { IUseFileReaderProps, FileContentResType, IFileRes } from './interfaces';

function useFileReader<F extends IUseFileReaderProps>(props: F): IFileRes<F> {
  const [file, setFile] = useState<File | null>(null);
  const [fileData, setFileData] = useState<FileContentResType<F> | null>(null);
  const setFileHandler = (selectedFile: File): void => setFile(selectedFile);

  useEffect(() => {
    let fileReader: FileReader;
    let isCancel: boolean = false;

    if (file) {
      fileReader = new FileReader();
      fileReader.onload = () => {
        if (fileReader.result && !isCancel) {
          setFileData(fileReader.result as FileContentResType<F>);
          setFile(file);
        }
      };

      if (props.format === 'base64') {
        fileReader.readAsDataURL(file);
      } else {
        fileReader.readAsArrayBuffer(file);
      }
    }

    return () => {
      isCancel = true;

      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file]);

  return { setFile: setFileHandler, file, fileContent: fileData };
}

export default useFileReader;
