export const sectionStyles = {
  heading: {
    alignSelf: 'flex-start',
    fontSize: 'xl',
  },

  container: {
    width: '100%',
    height: '100%',
    position: 'relative',
    color: 'inherit',
    whiespace: 'normal',
    padding: '10px',
  },

  contentBox: {
    width: '100%',
    height: '100%',
    minH: '140px',
    backgroundColor: 'white',
    borderRadius: '20px',
    padding: { base: '20px', sm: '50px' },
    spacing: '5',
  },
} as const;
