import React, { ChangeEvent, ReactElement, useRef, useEffect, useState } from 'react';

import {
  Input,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Image,
  Center,
  Flex,
  VStack,
  Heading,
  Text,
} from '@chakra-ui/react';
import { AttachmentIcon } from '@chakra-ui/icons';

import { useFileReader } from '@modules/core/hooks';

import { questionStyles } from './styles';

import { FILE_TYPES } from '@modules/core/constants';
import { IQuestionBasicProps } from './interfaces';
import { resizeFile } from '@app/modules/core/lvedHelpers';

function ThumbnailQuestion({ question, callback, answers }: IQuestionBasicProps): ReactElement {
  const oldAnswer: Buffer | undefined = answers[question.FieldName];
  // const [value, setValue] = useState<Buffer | undefined>(oldAnswer);
  const inputRef = useRef<HTMLInputElement>(null);
  const { file, fileContent, setFile } = useFileReader({ format: 'binary' });
  const [imageData, setImageData] = useState<ArrayBuffer>();
  const [imageString, setImageString] = useState<string | undefined>();

  // const [error, setError] = useState<string>('');
  // const clearError = (): void => setError('');

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files?.length) {
      setFile(event.target.files[0]);
    }
  };

  useEffect(() => {
    if (fileContent) {
      resizeFile(fileContent, 200000).then((buffer: ArrayBuffer) => {
        setImageString(btoa(String.fromCharCode(...new Uint8Array(buffer))));
        return setImageData(buffer);
      });
    }
  }, [fileContent]);

  useEffect(() => {
    if (oldAnswer) setImageString(btoa(String.fromCharCode(...new Uint8Array(oldAnswer!))));
  }, []);

  useEffect(() => {
    if (imageData) {
      callback(question.FieldName, imageData);
    }
  }, [imageData]);

  return (
    <Flex {...questionStyles.questionContainer}>
      <VStack {...questionStyles.questionTextBox}>
        <Heading>{question.DisplayTitle}</Heading>

        {question.HelperText && <Text>{question.HelperText}</Text>}
      </VStack>

      <FormControl isRequired={question.isRequired}>
        {question.DisplayTitle && (
          <FormLabel htmlFor={question.FieldName}>{question.FieldName}</FormLabel>
        )}

        <InputGroup>
          <InputLeftElement {...questionStyles.attachmentIconBox}>
            <AttachmentIcon {...questionStyles.attachmentIcon} />
          </InputLeftElement>

          <Input
            name={question.FieldName}
            onChange={onChangeHandler}
            type='file'
            accept={FILE_TYPES.image}
            ref={inputRef}
            style={{ display: 'none' }}
          />

          <Input
            readOnly
            id={question.FieldName}
            variant='outline'
            size='lg'
            onClick={() => inputRef.current?.click()}
            placeholder='Your file ...'
            value={file?.name || ''}
          />
        </InputGroup>

        {imageString && (
          <Center>
            <Image {...questionStyles.thumbnail} src={`data:image/png;base64,${imageString}`} />
          </Center>
        )}
      </FormControl>
    </Flex>
  );
}

export default ThumbnailQuestion;
