import { DynamoDB, AWSError } from 'aws-sdk';
import { Auth } from 'aws-amplify';

import {
  ICredentials,
  CommonAssetsListType,
  IGetCommonAssets,
} from '@modules/core/aws/dynamo-db/digital-vault/interfaces';

import { DYNAMO_DB_TABLES, HIERARCHY_CATEGORY } from '@modules/core/constants';

const CATEGORY_FILTER = 'HierarchyCatagory = :category';
const SECTION_FILTER = 'HierarchySection = :section';
const TEMPLATE_FILTER = 'TemplateName = :template';

export async function getCommonAssets({
  section,
  templateName = '',
}: IGetCommonAssets): Promise<CommonAssetsListType> {
  const filterOptions = [CATEGORY_FILTER, SECTION_FILTER, ...(templateName && [TEMPLATE_FILTER])];

  const credentials: ICredentials = await Auth.currentCredentials();
  const client: DynamoDB.DocumentClient = new DynamoDB.DocumentClient({
    region: process.env.REACT_APP_AWS_REGION || 'us-east-1',
    credentials: Auth.essentialCredentials(credentials),
  });

  const params = {
    TableName: DYNAMO_DB_TABLES.customerObjects,
    IndexName: 'identityId-CreatedTime-index',
    KeyConditionExpression: 'identityId = :identityId',
    FilterExpression: filterOptions.join(' AND '),
    ExpressionAttributeValues: {
      ':identityId': credentials.identityId,
      ':category': HIERARCHY_CATEGORY.digitalVault,
      ':section': section,
      ...(templateName && { ':template': templateName }),
    },
  };

  return new Promise<CommonAssetsListType>((resolve, reject): void => {
    client.query(params, (err: AWSError, data: DynamoDB.DocumentClient.QueryOutput): void => {
      if (err) {
        return reject(new Error(err.message || JSON.stringify(err)));
      }

      if (!data.Items) {
        return reject(new Error('no data found'));
      }

      return resolve(data.Items as CommonAssetsListType);
    });
  });
}
