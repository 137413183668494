import React, { ReactElement } from 'react';

import { HStack, Button, Box, VStack, Text } from '@chakra-ui/react';
import { useFormik } from 'formik';

import { inviteFamilyMember } from '@modules/core/aws/lambdas/profile';
import { useQueryToast } from '@modules/core/hooks';
import { IFamilyInviteSlotProps, IFormValues } from './interfaces';
import { TextInput } from '../text-field';
import { ConditionSeparator } from '@modules/common-ui/components/condition-separator';

import FamilyInviteSlotDates from './FamilyInviteSlotDates';

import validationSchema from './validation-schema';

import { familyInviteSlotStyles } from './styles';

function FamilyInviteSlot({
  inviteData: { inviteEmailAddress, isLocked, familyMemberSlot, acceptedDate, inviteDate },
}: IFamilyInviteSlotProps): ReactElement {
  const { isLoading, submit: invite, res: inviteRes } = useQueryToast(inviteFamilyMember);
  const initialValues = { email: inviteEmailAddress === 'Open' ? '' : inviteEmailAddress };
  const isEmailChanged = (value: string): boolean => initialValues.email !== value;

  const onSubmitHandler = ({ email }: IFormValues): void => {
    invite({ email, familyMemberSlot });
  };

  const { handleSubmit, handleChange, values, errors } = useFormik({
    onSubmit: onSubmitHandler,
    validateOnMount: true,
    validationSchema,
    initialValues,
  });

  return (
    <Box {...familyInviteSlotStyles.container}>
      <form onSubmit={handleSubmit}>
        <VStack {...familyInviteSlotStyles.contentBox}>
          <ConditionSeparator
            condition={!!(inviteDate || inviteRes?.inviteDate)}
            target={
              <ConditionSeparator
                condition={!!acceptedDate}
                target={<Text {...familyInviteSlotStyles.inUseTitle}>In use</Text>}
                defaultTarget={<Text {...familyInviteSlotStyles.notInUseTitle}>Not in use</Text>}
              />
            }
          />

          <HStack {...familyInviteSlotStyles.inputButtonContainer}>
            <Box {...familyInviteSlotStyles.inputContainer}>
              <TextInput
                id={`${familyMemberSlot} email`}
                name='email'
                type='email'
                placeholder='Email address'
                onChange={handleChange}
                value={values.email}
                disabled={isLocked || isLoading}
              />
            </Box>

            <Box {...familyInviteSlotStyles.buttonContainer}>
              <Button
                type='submit'
                isLoading={isLoading}
                isDisabled={isLocked || !!errors.email || !isEmailChanged(values.email)}
                {...familyInviteSlotStyles.button}
              >
                Send Invite
              </Button>
            </Box>
          </HStack>

          <FamilyInviteSlotDates
            acceptedDate={acceptedDate}
            inviteDate={inviteRes?.inviteDate || inviteDate}
          />
        </VStack>
      </form>
    </Box>
  );
}

export default FamilyInviteSlot;
