export const taskStyles = {
  taskContainer: {
    backgroundColor: 'brand.floralWhite',
    borderRadius: '10px',
    height: '156px',
    width: '95%',
    margin: '20px auto',
    padding: { base: '10px', md: '20px' },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  messageStyle: {
    font: 'normal normal normal 12px/18px Lato',
    fontWeight: '400',
    overflow: 'hidden',
    height: '100px',
  },

  submitButton: {
    width: { base: '100%', md: '150px' },
    height: '35px',
    backgroundColor: 'white',
    border: '1px solid',
    borderColor: 'brand.green.100',
    color: 'brand.green.100',
    borderRadius: '12px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'block',
    _hover: {
      backgroundColor: 'white',
    },
  },
};
