import React, { useState, ChangeEvent, ReactElement } from 'react';

import { Text, Flex, Heading, VStack } from '@chakra-ui/react';

import { IQuestionBasicProps } from './interfaces';
import { TextInput } from '../text-field';

import { questionStyles } from './styles';

function TextQuestion({ question, callback, answers }: IQuestionBasicProps): ReactElement {
  const oldAnswer: string | undefined = answers[question.FieldName];
  const [value, setValue] = useState<string>(oldAnswer || '');
  const [error, setError] = useState<string>('');
  const clearError = (): void => setError('');

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const answer: string = event.target.value;
    const answerLength: number = answer.length;

    if (Number.isNaN(question.MaxLength) || answerLength <= question.MaxLength) {
      setValue(answer);
      callback(question.FieldName, answer);
      clearError();

      if (answerLength === question.MaxLength) {
        setError(`max length is ${question.MaxLength}`);
      }
    }
  };

  const onBlurHandler = (): void => {
    if (error) {
      clearError();
    }
  };

  return (
    <Flex {...questionStyles.questionContainer}>
      <VStack {...questionStyles.questionTextBox}>
        <Heading>{question.DisplayTitle}</Heading>

        {question.HelperText && <Text>{question.HelperText}</Text>}
      </VStack>

      <TextInput
        value={value}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        errorMessage={error}
        isError={Boolean(error)}
        style={{ ...questionStyles.questionFieldMargin }}
      />
    </Flex>
  );
}

export default TextQuestion;
