export const menuLinkStyles = {
  menuItem: {
    display: 'flex',
    padding: '8px',
    alignItems: 'center',
    gap: '8px',
    color: '#FFFFFF',
    fontSize: '16px',
    fontFamily: 'Lato',
    fontWeight: '700',
    fontStyle: 'normal',
    lineHeight: 'normal',
  },
} as const;
