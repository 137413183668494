export const browseStoriesStyles = {
  pageBackground: {
    width: '100%',
    background: 'linear-gradient(180deg, #D0F0E4 13.67%, #F3F7F8 37.59%, #F6F8FA 100%)',
    spacing: '3',
    // margin: '35px',
  },

  textError: {
    color: 'brand.error',
  },

  titleText: {
    fontSize: '24px',
    fontWeight: '700',
    padding: '0 35px 0 35px',
  },

  loadMoreButton: {
    colorScheme: 'whiteAlpha',
    width: 'full',
    backgroundColor: 'brand.green.100',
    height: '56px',
    _hover: {
      backgroundColor: 'brand.green.200',
    },
  },
};
