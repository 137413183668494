import { Auth } from 'aws-amplify';

import { lambdaGeneralCaller } from '@modules/core/aws/lambdas/lambda-general-caller/lambdaGeneralCaller';
import {
  ICredentials,
  IGetAffiliateDetailsLambdaResult,
  IGetAffiliateDetailsRequest,
  IGetAffiliateDetailsResult,
} from '@modules/core/aws/lambdas/dashboard/interfaces';

import { AWS_LAMBDAS } from '@modules/core/constants';

export default async function getAffiliateDetail({
  affiliateId,
}: IGetAffiliateDetailsRequest): Promise<IGetAffiliateDetailsResult> {
  const credentials: ICredentials = await Auth.currentCredentials();

  return lambdaGeneralCaller<IGetAffiliateDetailsLambdaResult>(credentials, {
    FunctionName: AWS_LAMBDAS.getProfileThumbnail,
    Payload: JSON.stringify({
      affiliateId,
    }),
  }).then((res) => {
    const arrayBuffer = new ArrayBuffer(res.thumbnail.data.length);
    // console.log(res.thumbnail);
    const view = new Uint8Array(arrayBuffer);
    for (let i = 0; i < res.thumbnail.data.length; ++i) {
      view[i] = res.thumbnail.data[i];
    }
    return {
      name: res.name,
      thumbnail: arrayBuffer,
    } as IGetAffiliateDetailsResult;
  });
}
