import React, { useState, useEffect, ReactElement } from 'react';

import {
  Box,
  Text,
  Flex,
  VStack,
  Heading,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';

import { IQuestionBasicProps } from './interfaces';

import { questionStyles } from './styles';

function NumberQuestion({ question, callback, answers }: IQuestionBasicProps): ReactElement {
  const oldAnswer: number | undefined = answers[question.FieldName];
  const [value, setValue] = useState<number>(oldAnswer || 0);

  const onChangeHandler = (onChangeValue: string): void => {
    setValue(Number(onChangeValue));
    callback(question.FieldName, Number(onChangeValue));
  };

  useEffect((): void => {
    if (typeof oldAnswer === 'undefined') {
      callback(question.FieldName, value);
    }
  }, []);

  return (
    <Flex {...questionStyles.questionContainer}>
      <VStack {...questionStyles.questionTextBox}>
        <Heading>{question.DisplayTitle}</Heading>

        {question.HelperText && <Text>{question.HelperText}</Text>}
      </VStack>

      <Box {...questionStyles.numberInputBox} {...questionStyles.questionFieldMargin}>
        <NumberInput size='lg' min={0} onChange={onChangeHandler} value={value}>
          <NumberInputField />

          <NumberInputStepper>
            <NumberIncrementStepper />

            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </Box>
    </Flex>
  );
}

export default NumberQuestion;
