import { Auth } from 'aws-amplify';

import { lambdaGeneralCaller } from '@modules/core/aws/lambdas/lambda-general-caller/lambdaGeneralCaller';
import { ICredentials, IDashboardMetricsRes } from '@modules/core/aws/lambdas/dashboard/interfaces';

import { AWS_LAMBDAS } from '@modules/core/constants';

export default async function getDashboardMetrics(): Promise<IDashboardMetricsRes> {
  const credentials: ICredentials = await Auth.currentCredentials();

  return lambdaGeneralCaller<IDashboardMetricsRes>(credentials, {
    FunctionName: AWS_LAMBDAS.dashboard,
  });
}
