import React, { ReactElement, useState } from 'react';

import {
  VStack,
  HStack,
  Text,
  Box,
  Button,
  Wrap,
  WrapItem,
  Stack,
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
} from '@chakra-ui/react';
import UnAuthHeader from '@app/modules/common-ui/components/unAuthHeader/UnAuthHeader';
import UnAuthFooter from '@app/modules/common-ui/components/unAuthFooter/UnAuthFooter';
import { faqStyles } from './styles';
import FAQItem from '@app/modules/common-ui/components/faq-item/FAQItem';
import { FAQType } from '@app/modules/core/faqs/interfaces';
import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import allFAQsData from './faqData.json';
import { TrackGoogleAnalyticsEvent } from '@app/modules/core/google-analytics/google-analytics-tracker';
import GASearchCategory, {
  GACompletedSearch,
  GASearchFailed,
} from '@app/modules/core/google-analytics/events/searchGAEvent';

function FAQs(): ReactElement {
  const [activeTag, setActiveTag] = useState<string | null>(null);
  const tagList: string[] = [];
  const [searchTerm, setSearchTerm] = useState('');
  const allFAQs = allFAQsData as FAQType[];
  allFAQs.forEach((faq) => {
    faq.tags.forEach((tag) => {
      if (tagList.indexOf(tag) === -1) tagList.push(tag);
    });
  });

  function getFilterdFAQs(searchTermStr: string): FAQType[] {
    return allFAQs.filter((faq) => {
      if (activeTag !== null && faq.tags.indexOf(activeTag) === -1) return false;
      const lowerSearchTerm = searchTermStr.toLowerCase();
      return (
        faq.question.toLowerCase().includes(lowerSearchTerm) ||
        faq.answer.toLowerCase().includes(lowerSearchTerm)
      );
    });
  }
  const filteredFAQs = getFilterdFAQs(searchTerm);
  return (
    <VStack {...faqStyles.pageBackground}>
      <UnAuthHeader activeLink='FAQ' />
      <Box {...faqStyles.faqPageContents}>
        <HStack h='80px' />
        <Box {...faqStyles.faqTitleContainer}>
          <Text {...faqStyles.faqTitle}>Frequently asked questions</Text>
          <Text {...faqStyles.faqSubtitle}>Explore by entering a keyword or browse below</Text>
        </Box>
        <Box {...faqStyles.faqMainContainer}>
          <Box {...faqStyles.faqHeader}>
            <Wrap {...faqStyles.filterButtonContainer}>
              <WrapItem>
                <Button
                  {...(activeTag === null
                    ? faqStyles.filterButtonSelected
                    : faqStyles.filterButton)}
                  onClick={() => {
                    setActiveTag(null);
                  }}
                >
                  All
                </Button>
              </WrapItem>
              {tagList.map((tag, index) => {
                return (
                  <WrapItem>
                    <Button
                      {...(activeTag === tag
                        ? faqStyles.filterButtonSelected
                        : faqStyles.filterButton)}
                      key={index}
                      onClick={() => {
                        setActiveTag(activeTag === tag ? null : tag);
                      }}
                    >
                      {tag}
                    </Button>
                  </WrapItem>
                );
              })}
            </Wrap>
            <Stack>
              <InputGroup>
                <InputLeftElement pointerEvents='none'>
                  <SearchIcon color='gray.300' />
                </InputLeftElement>
                <Input
                  {...faqStyles.searchContainer}
                  placeholder='Search FAQs'
                  value={searchTerm}
                  onChange={(newValue) => {
                    setSearchTerm(newValue.currentTarget.value);
                    if (
                      newValue.currentTarget.value.length > 0 &&
                      getFilterdFAQs(newValue.currentTarget.value).length === 0
                    ) {
                      TrackGoogleAnalyticsEvent(
                        GASearchCategory,
                        GASearchFailed,
                        `term: ${newValue.currentTarget.value.toLowerCase()}`
                      );
                    }
                  }}
                  onBlur={() => {
                    if (searchTerm.length > 0 && getFilterdFAQs(searchTerm).length > 0) {
                      TrackGoogleAnalyticsEvent(
                        GASearchCategory,
                        GACompletedSearch,
                        `term: ${searchTerm.toLowerCase()}`
                      );
                    }
                  }}
                />
                <InputRightElement>
                  <CloseIcon
                    color='gray.300'
                    onClick={() => {
                      setSearchTerm('');
                    }}
                  />
                </InputRightElement>
              </InputGroup>
            </Stack>
          </Box>
          <Box {...faqStyles.faqContent}>
            {filteredFAQs.map((faq, index) => {
              return <FAQItem {...faq} key={index} />;
            })}
          </Box>
          {!filteredFAQs.length && (
            <Text {...faqStyles.faqSubtitle}>
              No FAQs match the search term: &quot;{searchTerm}&quot;
            </Text>
          )}
        </Box>
      </Box>
      <UnAuthFooter />
    </VStack>
  );
}

export default FAQs;
