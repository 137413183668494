import React, { ReactElement } from 'react';

import { FormControl, Checkbox, FormErrorMessage } from '@chakra-ui/react';

import { ICheckBoxProps } from './interfaces';

import { checkBoxStyles } from './styles';

function CheckBox({
  children,
  errorMessage,
  isError = false,
  ...rest
}: ICheckBoxProps): ReactElement {
  return (
    <FormControl isInvalid={isError}>
      <Checkbox {...rest} {...checkBoxStyles.checkBox}>
        {children}
      </Checkbox>

      {isError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
}

export default CheckBox;
