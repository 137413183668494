import { useEffect, RefObject, useRef } from 'react';

import { CallbackType, IUseOutsideClickerRes } from './interfaces';

function useOutsideClicker<Elem extends Node>(callback: CallbackType): IUseOutsideClickerRes<Elem> {
  const ref: RefObject<Elem> = useRef<Elem>(null);

  const handleClickOutside = ({ target }: MouseEvent) => {
    const elem: Elem | null = ref.current;

    if (elem && target && !elem.contains(target as Node)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return { ref };
}

export default useOutsideClicker;
