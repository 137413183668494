import React, { ReactElement } from 'react';

import { FormControl, FormLabel, FormErrorMessage, Textarea } from '@chakra-ui/react';

import { ITextAreaInputProps } from './interfaces';

function TextAreaInput({
  label,
  id,
  errorMessage,
  style = {},
  value = '',
  variant = 'outline',
  size = 'lg',
  resize = 'none',
  required = false,
  disabled = false,
  isError = false,
  ...rest
}: ITextAreaInputProps): ReactElement {
  return (
    <FormControl {...style} isInvalid={isError} isRequired={required}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}

      <Textarea
        isDisabled={disabled}
        resize={resize}
        id={id}
        variant={variant}
        size={size}
        value={value}
        {...rest}
      />

      {isError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
}

export default TextAreaInput;
