import { Auth } from 'aws-amplify';
import { Lambda, AWSError } from 'aws-sdk';
import { isString } from 'lodash';

import {
  ICredentials,
  LambdaParamsType,
  LambdaPayloadType,
  BasicPayloadType,
} from '@modules/core/aws/lambdas/lambda-general-caller/interfaces';

import { AWS_LAMBDA_ERRORS } from '@modules/core/constants';

export async function lambdaGeneralCaller<Res extends BasicPayloadType>(
  credentials: ICredentials,
  params: LambdaParamsType
): Promise<Res> {
  const lambda: Lambda = new Lambda({
    credentials: Auth.essentialCredentials(credentials),
    region: process.env.REACT_APP_AWS_REGION || 'us-east-1',
  });

  return new Promise<Res>((resolve, reject): void => {
    lambda.invoke(params, (err: AWSError, data: Lambda.InvocationResponse): void => {
      if (err) {
        return reject(new Error(err?.message || JSON.stringify(err)));
      }

      if (!data?.Payload) {
        return reject(new Error(AWS_LAMBDA_ERRORS.noData));
      }

      if (isString(data.Payload)) {
        const parsedData: LambdaPayloadType<Res> = JSON.parse(data.Payload);

        if (parsedData.error) {
          return reject(new Error(parsedData.error));
        }

        return resolve(parsedData);
      }

      return reject(new Error(AWS_LAMBDA_ERRORS.unknownFormat));
    });
  });
}
