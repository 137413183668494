import { Auth } from 'aws-amplify';

import { lambdaGeneralCaller } from '@modules/core/aws/lambdas/lambda-general-caller/lambdaGeneralCaller';
import {
  ICredentials,
  IPaymentIntentInput,
  IPaymentIntentRes,
} from '@modules/core/aws/lambdas/payments/interfaces';

import { AWS_LAMBDAS, AWS_LAMBDAS_OPERATIONS } from '@modules/core/constants';

async function createPaymentIntent(input: IPaymentIntentInput): Promise<IPaymentIntentRes> {
  const credentials: ICredentials = await Auth.currentCredentials();

  return lambdaGeneralCaller<IPaymentIntentRes>(credentials, {
    FunctionName: AWS_LAMBDAS.payments,
    Payload: JSON.stringify({
      operation: AWS_LAMBDAS_OPERATIONS.createPaymentIntent,
      ...input,
    }),
  });
}

export default createPaymentIntent;
