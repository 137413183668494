export const onboardingStyles = {
  mainContainer: {
    spacing: '14px',
    color: 'brand.text',
    maxWidth: '500px',
    // border: '1px',
    // margin: '30px',
    // margin: { base: '10px', sm: '25px auto', md: '50px auto' },
    alignItems: 'center',
  },
  container: {
    maxWidth: '650px',
    margin: '40px auto',
    alignItems: 'center',
  },
  contentContainer: {
    alignItems: 'end',
    margin: '10px',
    spacing: '25px',
  },

  questionsBox: {
    alignItems: 'center',
    width: 'full',
  },

  questionsContent: {
    spacing: '2',
    width: 'full',
  },

  welcomeTextBox: {
    spacing: '2',
  },

  welcomeAdditionalText: {
    fontSize: 'xl',
  },

  getStartedBox: {
    marginTop: '80px',
    alignItems: 'center',
  },

  getStartedButton: {
    marginTop: '18px',
    width: '221px',
    height: '56px',
    backgroundColor: 'brand.green.100',
    color: 'white',
    _hover: {
      backgroundColor: 'brand.green.200',
    },
  },
  progressBox: {
    alignItems: 'leading',
    padding: '10px',
    border: '1px',
  },
  activeProgressImage: {
    width: '35px',
    height: '35px',
  },
  activeProgressText: {
    fontSize: '18px',
    fontWeight: '600',
    color: 'brand.green.200',
  },
  progressImage: {
    width: '30px',
    height: '30px',
  },
  nextButton: {
    size: 'lg',
    maxWidth: '100px',
    color: 'white',
    backgroundColor: 'brand.green.100',
    _hover: {
      backgroundColor: 'brand.green.200',
    },
  },

  titleText: {
    fontWeight: '600',
    fontSize: '22px',
  },

  descriptionText: {
    fontWeight: '600',
  },

  profileQuestionContainer: {
    justifyContent: 'space-between',
    height: '500px',
    maxWidth: '350px',
  },

  buttonBox: {
    width: 'full',
  },
  continueButtonBox: {
    marginLeft: '20px',
  },
  prevButton: {
    size: 'lg',
    color: 'black',
    borderColor: 'brand.gray.50 !important',
    backgroundColor: 'brand.floralWhite',
    border: '1px solid',
    _hover: {
      backgroundColor: 'brand.floralWhite',
    },
  },
  skipButton: {
    size: 'lg',
    color: 'white',
    backgroundColor: 'gray.400',
    _hover: {
      backgroundColor: 'gray.500',
    },
  },
};
