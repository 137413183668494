import React, { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, Text, Flex, VStack } from '@chakra-ui/react';

import { INavigationState } from './interfaces';
import { getQuestions } from '@modules/core/aws/dynamo-db/my-circle-onboarding';
import { useQuery } from '@modules/core/hooks';
import { QuestionsItemType } from '@modules/core/aws/dynamo-db/my-circle-onboarding/interfaces';

import QuestionsManager from '@modules/common-ui/components/questions-manager';
import Loader from '@modules/common-ui/components/global-loader';

import { onboardingStyles } from './styles';

function OnBoarding(): ReactElement {
  const { state }: INavigationState = useLocation();
  const { isLoading, submit, res: questionsItem } = useQuery<void, QuestionsItemType>(getQuestions);

  useEffect(() => {
    if (!state?.questionsItem) {
      submit();
    }
  }, []);

  return (
    <Box>
      {isLoading ? (
        <Loader />
      ) : (
        (state?.questionsItem || questionsItem) && (
          <Flex flexDirection='column' {...onboardingStyles.container}>
            <Flex flexDirection='column' {...onboardingStyles.questionsBox}>
              <VStack {...onboardingStyles.questionsContent}>
                <Text as='b'>BASIC INFORMATION</Text>

                <QuestionsManager item={state?.questionsItem || questionsItem} />
              </VStack>
            </Flex>
          </Flex>
        )
      )}
    </Box>
  );
}

export default OnBoarding;
