import { lambdaGeneralCaller } from '@modules/core/aws/lambdas/lambda-general-caller/lambdaGeneralCaller';
import {
  IFamilyGetAccountHolderNameResponse,
  IUnAuthCredentials,
} from '@modules/core/aws/lambdas/family-action/interfaces';

import { AWS_LAMBDAS, AWS_LAMBDAS_OPERATIONS } from '@modules/core/constants';

export default async function getAccountHolderName(accountHolderUserName: string): Promise<string> {
  const credentials: IUnAuthCredentials = {
    accessKeyId: process.env.REACT_APP_AWS_UNAUTHENTICATED_ACCESS_KEY_ID || 'NONE',
    secretAccessKey: process.env.REACT_APP_AWS_UNAUTHENTICATED_SECRET_ACCESS_KEY || 'NONE',
  };

  const res = await lambdaGeneralCaller<IFamilyGetAccountHolderNameResponse>(credentials, {
    FunctionName: AWS_LAMBDAS.familyAction,
    Payload: JSON.stringify({
      sub: accountHolderUserName,
      operation: AWS_LAMBDAS_OPERATIONS.getAccountHolderName,
    }),
  });

  return res.accountHolderName;
}
