import CryptoJS from 'crypto-js';

import { PublicKey } from './interfaces';
import {
  getRSAKey,
  getPublicKey,
  decryptByRSAKey,
  encryptByPublicKey,
  createWordArray,
  encryptAES,
} from './crypto';

function convertWordArrayToUint8Array(arr: CryptoJS.lib.WordArray): Uint8Array {
  const arrayOfWords: number[] = Object.prototype.hasOwnProperty.call(arr, 'words')
    ? arr.words
    : [];

  const length: number = Object.prototype.hasOwnProperty.call(arr, 'sigBytes')
    ? arr.sigBytes
    : arrayOfWords.length * 4;

  const uInt8Array: Uint8Array = new Uint8Array(length);
  let index: number = 0;
  let word: number;

  for (let i = 0; i < length; i++) {
    word = arrayOfWords[i];
    uInt8Array[index++] = word >> 24;
    uInt8Array[index++] = (word >> 16) & 0xff;
    uInt8Array[index++] = (word >> 8) & 0xff;
    uInt8Array[index++] = word & 0xff;
  }

  return uInt8Array;
}

export async function generatePublicKey(passphrase: string): Promise<PublicKey> {
  const rsaKey = await getRSAKey(passphrase);
  return getPublicKey(rsaKey);
}

export async function decryptData(
  cipherData: ArrayBuffer,
  passphrase: string
): Promise<ArrayBuffer> {
  const rsaKey = await getRSAKey(passphrase);
  const clearTextDataStr = await decryptByRSAKey(cipherData, rsaKey);
  const encoder = new TextEncoder();
  return encoder.encode(clearTextDataStr);
}

export async function encryptData(data: ArrayBuffer, key: PublicKey): Promise<string> {
  return encryptByPublicKey(data, key);
}

export async function encryptFile(
  fileBuffer: ArrayBuffer,
  encryptionKey: ArrayBuffer
): Promise<string> {
  const wordArray: CryptoJS.lib.WordArray = createWordArray(fileBuffer);
  return encryptAES(wordArray, encryptionKey);
}

export function decryptFile(cipherFile: string, encryptionKey: ArrayBuffer): Uint8Array {
  const textEncoder = new TextDecoder('utf-8');
  const encryptionKeyStr = textEncoder.decode(encryptionKey);
  const decrypted: CryptoJS.lib.WordArray = CryptoJS.AES.decrypt(cipherFile, encryptionKeyStr);
  const typedArray: Uint8Array = convertWordArrayToUint8Array(decrypted);

  return typedArray;
}
