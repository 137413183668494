export const digitalItemStyles = {
  container: {
    backgroundColor: 'inherit',
    padding: '10px',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },

  contentBox: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    spacing: '5',
  },

  text: {
    textAlign: 'center',
  },
} as const;
