import React, { ReactElement } from 'react';
import { useNavigate, NavigateFunction } from 'react-router-dom';

import { Text, Button, Box } from '@chakra-ui/react';

import { ITaskProps } from './interfaces';

import { taskStyles } from './styles';

import { APP_ROUTES } from '../../../core/constants';

function Task({ task }: ITaskProps): ReactElement {
  const navigateTo: NavigateFunction = useNavigate();

  const isButtonDisabled = (): boolean => {
    const routes: string[] = Object.values(APP_ROUTES);

    if (!task.interfaceReference) {
      return true;
    }

    return !routes.includes(`/${task.interfaceReference}`);
  };

  const onClickHandler = (): void => {
    if (task.interfaceReference) {
      navigateTo(`/${task.interfaceReference}`);
    }
  };

  return (
    <Box {...taskStyles.taskContainer} flexDirection='column'>
      <Text {...taskStyles.messageStyle} textAlign='center'>
        {task.message}
      </Text>

      <Button
        whiteSpace='nowrap'
        isDisabled={isButtonDisabled()}
        onClick={onClickHandler}
        {...taskStyles.submitButton}
      >
        {task.action ? task.action : 'Complete'}
      </Button>
    </Box>
  );
}

export default Task;
