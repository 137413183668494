export enum APP_ROUTES {
  signIn = '/signin',
  signUp = '/signup',
  emailVerification = '/email-verification',
  resetPassword = '/resetPassword',
  dashboard = '/dashboard',
  pricing = '/pricing',
  onboarding = '/myCircleOnboarding',
  profileOnBoarding = '/profileOnboarding',
  appDownloadOnboarding = '/profileAppDownload',
  welcome = '/welcome',
  payment = '/payment',
  paymentCompletion = '/payment-completion',
  subscriptionManagement = '/settingsAccountSubscription',
  profile = '/settingsAccountProfile',
  familyNotification = '/family',
  watchStories = '/storyshare',
  finalMessage = '/finalMessages',
  digitalVault = '/myEstateDigitalVault',
  articleNavigation = '/articles',
  faqNavigation = '/faq',

  digitalVaultFamilyDocuments = '/myEstateDigitalVault/familyDocuments',
  digitalVaultPersonalDocuments = '/myEstateDigitalVault/personalLegalDocuments',
  digitalVaultOtherDocuments = '/myEstateDigitalVault/otherDocuments',
  digitalVaultPasswordsCombinations = '/myEstateDigitalVault/passwordsCombinations',
  digitalVaultTaxes = '/myEstateDigitalVault/taxes',

  digitalVaultFamilyDocumentsAssets = '/myEstateDigitalVault/familyDocuments/:templateName',
  digitalVaultOtherDocumentsAssets = '/myEstateDigitalVault/otherDocuments/:templateName',
  digitalVaultPersonalDocumentsAssets = '/myEstateDigitalVault/personalLegalDocuments/:templateName',
  digitalVaultPasswordsCombinationsAssets = '/myEstateDigitalVault/passwordsCombinations/:templateName',
  digitalVaultTaxesAssets = '/myEstateDigitalVault/taxes/:templateName',

  affiliageLandingPageBase = '/partner/',
  affiliateLandingPages = '/partner/:affiliateUserName',
  landingPageNavigationBase = '/landing/',
  landingPageNavigation = '/landing/:landingURL',

  root = '/',
  isNotExist = '*',
  leaveCurrentPage = '#',
}

export const WITHOUT_SIDEBAR_ROUTES = [
  APP_ROUTES.signIn,
  APP_ROUTES.signUp,
  APP_ROUTES.emailVerification,
  APP_ROUTES.payment,
  APP_ROUTES.paymentCompletion,
  APP_ROUTES.pricing,
  APP_ROUTES.resetPassword,
  APP_ROUTES.finalMessage,
  APP_ROUTES.familyNotification,
  APP_ROUTES.watchStories,
  APP_ROUTES.articleNavigation,
  APP_ROUTES.faqNavigation,
  APP_ROUTES.affiliateLandingPages,
  APP_ROUTES.landingPageNavigation,
  APP_ROUTES.profileOnBoarding,
  APP_ROUTES.appDownloadOnboarding,
];

export const WITHOUT_TIMER_ROUTES = [
  APP_ROUTES.signIn,
  APP_ROUTES.signUp,
  APP_ROUTES.emailVerification,
  APP_ROUTES.payment,
  APP_ROUTES.paymentCompletion,
  APP_ROUTES.pricing,
  APP_ROUTES.resetPassword,
  APP_ROUTES.finalMessage,
  APP_ROUTES.familyNotification,
  APP_ROUTES.watchStories,
  APP_ROUTES.articleNavigation,
  APP_ROUTES.landingPageNavigation,
  APP_ROUTES.affiliateLandingPages,
  APP_ROUTES.profileOnBoarding,
  APP_ROUTES.appDownloadOnboarding,
];
