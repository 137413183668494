import React, { ReactElement } from 'react';
import { useLocation, useNavigate, Navigate, NavigateFunction } from 'react-router-dom';

import { Box, HStack, Text, VStack, Button } from '@chakra-ui/react';
import { StripeElementsOptions } from '@stripe/stripe-js';

import { IPaymentPageNavigationState } from './interfaces';
import { IPaymentIntentRes } from '@modules/core/aws/lambdas/payments/interfaces';

import { StripeContainer, PaymentForm } from '@modules/common-ui/components/payments';
// import Background from '@assets/images/bgImage.png';

import { paymentPageStyles } from './styles';

import { APP_ROUTES, LICENSE_STATUSES } from '@app/modules/core/constants';

function Payment(): ReactElement {
  const { state }: IPaymentPageNavigationState = useLocation();
  const navigate: NavigateFunction = useNavigate();

  const paymentIntent: IPaymentIntentRes | undefined = state?.paymentIntent;
  const productName: LICENSE_STATUSES | undefined = state?.productName;
  const isAnnualPlan: boolean | undefined = state?.isAnnualPlan;
  const affiliateName: string | undefined = paymentIntent?.affiliateName;
  const basePrice: number | undefined = paymentIntent?.unDiscountedAmount;
  const creditApplied: number | undefined = paymentIntent?.creditToBeUsed;
  // const remainingCredit: number | undefined = paymentIntent?.creditRemainingAfterTransaction;

  const paymentFormOptions: StripeElementsOptions = {
    clientSecret: paymentIntent?.clientSecret,
    loader: 'auto',
    appearance: {
      theme: 'flat',
    },
  };

  if (!(paymentIntent && productName)) {
    return <Navigate to={APP_ROUTES.pricing} replace />;
  }

  function formatPrice(price: number): string {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(price);
  }
  /*
<VStack {...paymentPageStyles.headingNames}>
            <Heading {...paymentPageStyles.headingTextNames}>Product:</Heading>
            
            <Heading {...paymentPageStyles.headingTextNames}>Price:</Heading>
          </VStack>

          <VStack {...paymentPageStyles.headingValues}>
            <Heading {...paymentPageStyles.headingTextValues}>
              {`${isAnnualPlan ? 'Annual' : 'Monthly'} ${productName}`}
            </Heading>

            <Heading {...paymentPageStyles.headingTextValues}>{displayPrice}</Heading>
          </VStack>
*/
  const isOnPromotion = Math.round(basePrice - paymentIntent.amount - creditApplied) > 0;
  const isCreditApplied = Math.round(creditApplied * 100) > 0;

  return (
    <Box {...paymentPageStyles.backgroundContainer}>
      <VStack {...paymentPageStyles.contentBox}>
        <VStack {...paymentPageStyles.headingBox}>
          <HStack w='100%'>
            <Text {...paymentPageStyles.fieldName}>Subscription: </Text>
            <Text {...paymentPageStyles.chargeAmount}>
              {`${isAnnualPlan ? 'Annual' : 'Monthly'} ${productName}`}
            </Text>
          </HStack>

          <HStack w='100%'>
            <Text {...paymentPageStyles.fieldName}>Price:</Text>
            <Text
              {...(!isOnPromotion && !isCreditApplied
                ? paymentPageStyles.chargeAmount
                : paymentPageStyles.dollarAmount)}
              {...(isOnPromotion ? { textDecoration: 'line-through' } : {})}
            >
              {formatPrice(basePrice)}
            </Text>
          </HStack>

          {isOnPromotion && (
            <VStack spacing='0px' w='100%'>
              <HStack w='100%'>
                <Text {...paymentPageStyles.fieldName}>Promotion:</Text>
                <Text
                  {...(isCreditApplied
                    ? paymentPageStyles.dollarAmount
                    : paymentPageStyles.chargeAmount)}
                >
                  {formatPrice(paymentIntent.amount + creditApplied)}
                </Text>
              </HStack>
              {affiliateName && (
                <Text {...paymentPageStyles.fieldName} w='100%'>
                  ({affiliateName})
                </Text>
              )}
            </VStack>
          )}

          {isCreditApplied && (
            <>
              <HStack w='100%'>
                <Text {...paymentPageStyles.fieldName}>Credit applied:</Text>
                <Text {...paymentPageStyles.dollarAmount}>-{formatPrice(creditApplied)}</Text>
              </HStack>
              <Box border='1px' borderColor='color' w='100%' h='2px' />

              <HStack w='100%'>
                <Text {...paymentPageStyles.fieldName}>Charge amount:</Text>
                <Text {...paymentPageStyles.chargeAmount}>{formatPrice(paymentIntent.amount)}</Text>
              </HStack>
            </>
          )}
        </VStack>

        <Box {...paymentPageStyles.paymentInputBox}>
          <StripeContainer options={paymentFormOptions}>
            <PaymentForm />
          </StripeContainer>
        </Box>

        <Box {...paymentPageStyles.cancelButtonBox}>
          <Button onClick={() => navigate(-1)} {...paymentPageStyles.cancelButton}>
            Go Back
          </Button>
        </Box>
      </VStack>
    </Box>
  );
}

export default Payment;
