import React, { ReactElement } from 'react';

import {
  TextQuestion,
  BooleanQuestion,
  NumberQuestion,
  DropdownQuestion,
  AddressQuestion,
  StateQuestion,
  ThumbnailQuestion,
} from '../question';
import { IQuestionManagerProps } from './interfaces';

import { CUSTOMER_OBJECT_FIELD_TYPES } from '@modules/core/constants';
import DateQuestion from '../question/DateQuestion';

function QuestionManager(props: IQuestionManagerProps): ReactElement {
  switch (props.question.FieldType) {
    case CUSTOMER_OBJECT_FIELD_TYPES.text:
      return <TextQuestion {...props} key={props.question.FieldName} />;

    case CUSTOMER_OBJECT_FIELD_TYPES.boolean:
      return <BooleanQuestion {...props} key={props.question.FieldName} />;

    case CUSTOMER_OBJECT_FIELD_TYPES.dropdown:
      return <DropdownQuestion {...props} key={props.question.FieldName} />;

    case CUSTOMER_OBJECT_FIELD_TYPES.number:
    case CUSTOMER_OBJECT_FIELD_TYPES.numberNoFormat:
      return <NumberQuestion {...props} key={props.question.FieldName} />;

    case CUSTOMER_OBJECT_FIELD_TYPES.address:
      return <AddressQuestion {...props} key={props.question.FieldName} />;

    case CUSTOMER_OBJECT_FIELD_TYPES.usState:
      return <StateQuestion {...props} key={props.question.FieldName} />;

    case CUSTOMER_OBJECT_FIELD_TYPES.thumbnail:
      return <ThumbnailQuestion {...props} key={props.question.FieldName} />;

    case CUSTOMER_OBJECT_FIELD_TYPES.date:
      return <DateQuestion {...props} key={props.question.FieldName} />;

    default:
      return <TextQuestion {...props} key={props.question.FieldName} />;
  }
}

export default QuestionManager;
