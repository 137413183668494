import { lambdaGeneralCaller } from '@modules/core/aws/lambdas/lambda-general-caller/lambdaGeneralCaller';
import {
  IUnAuthCredentials,
  IFinalMessageRequest,
  IGetFinalMessageResponse,
  IFinalMessage,
} from './interfaces';

import { AWS_LAMBDAS, AWS_LAMBDAS_OPERATIONS } from '@modules/core/constants';

export default async function getFinalMessage(
  request: IFinalMessageRequest,
  includeThumbnail: boolean
): Promise<IFinalMessage> {
  const credentials: IUnAuthCredentials = {
    accessKeyId: process.env.REACT_APP_AWS_UNAUTHENTICATED_ACCESS_KEY_ID || 'NONE',
    secretAccessKey: process.env.REACT_APP_AWS_UNAUTHENTICATED_SECRET_ACCESS_KEY || 'NONE',
  };

  const messageResponse = await lambdaGeneralCaller<IGetFinalMessageResponse>(credentials, {
    FunctionName: AWS_LAMBDAS.reviewFinalMessage,
    Payload: JSON.stringify({
      identityId: request.identityId,
      finalMessageId: request.finalMessageId,
      accessKey: request.accessKey,
      operation: AWS_LAMBDAS_OPERATIONS.getFinalMessage,
      includeThumbnail,
    }),
  });

  return {
    senderName: messageResponse.senderName,
    capturedDate: new Date(messageResponse.capturedDate),
    messageURL: messageResponse.messageURL,
    viewCount: messageResponse.viewCount,
    lastViewed: new Date(messageResponse.lastViewed),
    recipient: messageResponse.recipient,
    canBeRejected: messageResponse.canBeRejected,
    base64MessageThumbnail: messageResponse.base64MessageThumbnail,
  } as IFinalMessage;
}
