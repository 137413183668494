import { Flex, HStack, Text, VStack } from '@chakra-ui/react';
import React, { useState, ReactElement } from 'react';
import { getAccountHolderName } from '@modules/core/aws/lambdas/family-action';
import { FamilyRegistration } from './FamilyRegistration';
import { RegistrationKeyEntry } from './RegistrationKeyEntry';
import UnAuthHeader from '@app/modules/common-ui/components/unAuthHeader/UnAuthHeader';
import UnAuthFooter from '@app/modules/common-ui/components/unAuthFooter/UnAuthFooter';
import { familyRegistrationStyles } from './styles';

function FamilyHome(): ReactElement {
  const queryParameters = new URLSearchParams(window.location.search);
  const [sub, setSub] = useState<string | null>();
  const [accountHolderName, setAccountHolderName] = useState<string | null>();
  const [queryError, setQueryError] = useState<string | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const urlSub = queryParameters.get('key');
  if (sub == null && !!urlSub) {
    setSub(urlSub);
  }

  // console.log(`key: ${sub}`);

  function setNotificationKey(arg0: string) {
    // console.log('App got notificaiton key');
    setSub(arg0);
    setQueryError(null);
  }

  function SearchAccountHolderName() {
    setIsLoading(true);
    // console.log('Querying for accountholder name');
    getAccountHolderName(sub!)
      .then((name) => {
        setAccountHolderName(name);
      })
      .catch((err) => {
        // console.log(`Error with query: ${err.message}`);
        setQueryError(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  // We probably want a useEffect here...
  if (!isLoading && sub && !queryError && !accountHolderName) {
    // Not loading and no results available yet.
    SearchAccountHolderName();
  }

  return (
    <VStack {...familyRegistrationStyles.pageBackground}>
      <UnAuthHeader activeLink='None' />
      <HStack h='100px' />
      <Flex w='100%' direction='column' align='center' justify='center'>
        {isLoading ? (
          <Text {...familyRegistrationStyles.titleText}>Searching for account holder...</Text>
        ) : (
          ''
        )}
        {sub && accountHolderName ? (
          <FamilyRegistration sub={sub} accountHolderName={accountHolderName} />
        ) : (
          ''
        )}
        {queryError ? <Text {...familyRegistrationStyles.textError}>{queryError}</Text> : ''}
        {!sub || queryError ? <RegistrationKeyEntry onKeyAvailable={setNotificationKey} /> : ''}
      </Flex>
      <UnAuthFooter />
    </VStack>
  );
}

export default FamilyHome;
