import { Auth } from 'aws-amplify';

import { lambdaGeneralCaller } from '@modules/core/aws/lambdas/lambda-general-caller/lambdaGeneralCaller';
import {
  ICredentials,
  IFamilyMemberInviteRes as InviteRes,
  IFamilyMemberInviteReq as InviteReq,
} from '@modules/core/aws/lambdas/profile/interfaces';

import { AWS_LAMBDAS, AWS_LAMBDAS_OPERATIONS } from '@modules/core/constants';

async function inviteFamilyMember({ email, familyMemberSlot }: InviteReq): Promise<InviteRes> {
  const credentials: ICredentials = await Auth.currentCredentials();

  const res = await lambdaGeneralCaller<InviteRes>(credentials, {
    FunctionName: AWS_LAMBDAS.manageFamilyPlan,
    Payload: JSON.stringify({
      familyMemberSlot,
      operation: AWS_LAMBDAS_OPERATIONS.inviteFamilyMember,
      email,
    }),
  });

  if (res.familyMemberSlot !== familyMemberSlot) {
    throw new Error('The result of the invitation is not successful, please try again later');
  }

  return res;
}

export default inviteFamilyMember;
