import React, { ReactElement } from 'react';

import { ArrowUpDownIcon } from '@chakra-ui/icons';
import { FormControl, FormLabel, FormErrorMessage, Select } from '@chakra-ui/react';

import { ISelectInputProps } from './interfaces';

function SelectInput({
  label,
  id,
  errorMessage,
  options = [],
  style = {},
  variant = 'outline',
  size = 'lg',
  isError = false,
  ...rest
}: ISelectInputProps): ReactElement {
  return (
    <FormControl {...style} isInvalid={isError}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}

      <Select
        iconColor='gray.400'
        icon={<ArrowUpDownIcon />}
        id={id}
        variant={variant}
        size={size}
        {...rest}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Select>

      {isError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
}

export default SelectInput;
