import { Auth } from 'aws-amplify';

import { lambdaGeneralCaller } from '@modules/core/aws/lambdas/lambda-general-caller/lambdaGeneralCaller';
import { ICredentials, ISetFreePlanRes } from '@modules/core/aws/lambdas/payments/interfaces';

import { AWS_LAMBDAS, AWS_LAMBDAS_OPERATIONS } from '@modules/core/constants';

export default async function setFreePlan(): Promise<ISetFreePlanRes> {
  const credentials: ICredentials = await Auth.currentCredentials();

  return lambdaGeneralCaller<ISetFreePlanRes>(credentials, {
    FunctionName: AWS_LAMBDAS.payments,
    Payload: JSON.stringify({
      operation: AWS_LAMBDAS_OPERATIONS.selectFreePlan,
    }),
  });
}
