import { InitialValuesType } from './interfaces';
import {
  UserProfileType,
  UserTemplateFieldType,
} from '@modules/core/aws/dynamo-db/user-profile/interfaces';

import { CUSTOMER_OBJECT_FIELD_TYPES } from '@modules/core/constants';

const getInitialValue = (field: UserTemplateFieldType, user: UserProfileType): any => {
  switch (field.FieldType) {
    case CUSTOMER_OBJECT_FIELD_TYPES.dropdown:
      return user[field.FieldName] || field.VariableOptions?.[0] || '';

    case CUSTOMER_OBJECT_FIELD_TYPES.text:
      return user[field.FieldName] || '';

    case CUSTOMER_OBJECT_FIELD_TYPES.password:
      return user[field.FieldName] || '';

    case CUSTOMER_OBJECT_FIELD_TYPES.usState:
      return user[field.FieldName] || '';

    case CUSTOMER_OBJECT_FIELD_TYPES.number:
      return user[field.FieldName] || 0;

    case CUSTOMER_OBJECT_FIELD_TYPES.numberNoFormat:
      return user[field.FieldName] || 0;

    case CUSTOMER_OBJECT_FIELD_TYPES.currency:
      return user[field.FieldName] || 0;

    case CUSTOMER_OBJECT_FIELD_TYPES.date:
      return user[field.FieldName] || null;

    case CUSTOMER_OBJECT_FIELD_TYPES.address:
      return {
        City: user.address?.City || '',
        Country: user.address?.Country || '',
        State: user.address?.State || '',
        Street1: user.address?.Street1 || '',
        Street2: user.address?.Street2 || '',
        Zip: user.address?.Zip || '',
      };

    default:
      return null;
  }
};

export function createInitialValues(user: UserProfileType | null): InitialValuesType {
  return (
    user?.Template.Fields.filter(({ ReadOnly }) => !ReadOnly).reduce((accumulator, field) => {
      return {
        ...accumulator,
        [field.FieldName]: getInitialValue(field, user),
      };
    }, {}) || {}
  );
}
