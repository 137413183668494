import React, { ReactElement } from 'react';

import { FormControl, FormLabel, FormErrorMessage, Input } from '@chakra-ui/react';

import { ITextInputProps } from './interfaces';

function TextInput({
  label,
  id,
  errorMessage,
  style = {},
  value,
  variant = 'outline',
  size = 'lg',
  required = false,
  isError = false,
  type = 'text',
  ...rest
}: ITextInputProps): ReactElement {
  return (
    <FormControl {...style} isInvalid={isError} isRequired={required}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}

      <Input id={id} type={type} variant={variant} size={size} value={value || ''} {...rest} />

      {isError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
}

export default TextInput;
