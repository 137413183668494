import { DynamoDB, AWSError } from 'aws-sdk';
import { Auth } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';

import {
  ICredentials,
  ISetAnswersInputProps,
  ObjectIdItemType,
  AnswersInputType,
} from './interfaces';
import { getObjectID } from './get-objectId';
import { getAnswers } from './get-answers';

import { DYNAMO_DB_TABLES } from '@modules/core/constants';

export async function setAnswers({ item, answers }: ISetAnswersInputProps): Promise<{}> {
  const credentials: ICredentials = await Auth.currentCredentials();
  const objectIdItems: ObjectIdItemType[] = await getObjectID();
  const answersDataItem: AnswersInputType | null = await getAnswers(
    objectIdItems[0]?.objectId || ''
  );

  const dynamoClient: DynamoDB.DocumentClient = new DynamoDB.DocumentClient({
    region: process.env.REACT_APP_AWS_REGION || 'us-east-1',
    credentials: Auth.essentialCredentials(credentials),
  });

  const params = {
    TableName: DYNAMO_DB_TABLES.customerObjects,
    Item: {
      objectId: objectIdItems[0]?.objectId || uuidv4(),
      identityId: credentials.identityId,

      'Created Timestamp': answersDataItem?.['Created Timestamp'] || new Date().toISOString(),
      'Last Update Timestamp': new Date().toISOString(),

      CanBeAgent: item.CanBeAgent,
      CanBeBequestTarget: item.CanBeBequestTarget,
      HierarchyCatagory: item.HierarchyCatagory,
      HierarchySection: item.HierarchySection,
      InstantiationLimit: item.InstantiationLimit,
      ReleaseTiming: item.ReleaseTiming,
      TemplateName: item.TemplateName,
      Template: item,
      ...answers,
    },
  };

  return new Promise<{}>((resolve, reject): void => {
    dynamoClient.put(params, (err: AWSError, data: DynamoDB.DocumentClient.PutItemOutput): void => {
      if (err) {
        return reject(new Error(err.message || JSON.stringify(err)));
      }

      return resolve(data);
    });
  });
}
