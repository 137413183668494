import React, { ReactElement, useEffect, useState } from 'react';

import { VStack, HStack, Wrap, Text } from '@chakra-ui/react';
import ArticleSummary from '@app/modules/common-ui/components/article-summary/ArticleSummary';
import UnAuthHeader from '@app/modules/common-ui/components/unAuthHeader/UnAuthHeader';
import ArticleCard from '@app/modules/common-ui/components/article-card/ArticleCard';
import ArticleFilter from '@app/modules/common-ui/components/article-filter/ArticleFilter';
// import allArticleData from './articleMetadata.json';

// import logoImg from '@assets/images/lvedLogo.svg';

// import { useQuery } from '@modules/core/hooks';
// import { sortByDateString } from '@modules/core/date-formatter';

// import ArticleCard from '@modules/common-ui/components/article-summary/ArticleCard';

import { articleStyles } from './styles';
import { ArticleType } from '@app/modules/core/articles/interfaces';
import UnAuthFooter from '@app/modules/common-ui/components/unAuthFooter/UnAuthFooter';

function Articles(): ReactElement {
  const [articles, setArticles] = useState<ArticleType[] | null>();
  const [tagFilter, setTagFilter] = useState('');
  const [contentFilter, setContentFilter] = useState('');
  const [errorMsg, setErrorMsg] = useState<string | undefined>();

  useEffect(() => {
    fetch('https://www.lved.com/articles/articlesMetadata.json')
      .then((response) => {
        // console.log(response);
        return response.json();
      })
      .then((jsonData) => {
        const articlesArray = jsonData as ArticleType[];
        articlesArray.sort((a: ArticleType, b: ArticleType) => {
          return b.id - a.id;
        });
        setArticles(articlesArray);
      })
      .catch((error) => {
        // handle your errors here
        // console.error(error);
        setErrorMsg(error.message);
      });
  }, []);

  // const allArticles = allArticleData as ArticleType[];
  const filteredArticles =
    articles?.filter((article) => {
      if (tagFilter.length && !article.tags.includes(tagFilter)) return false;
      if (
        contentFilter.length &&
        !article.teaserContent.toLowerCase().includes(contentFilter.toLowerCase()) &&
        !article.title.toLowerCase().includes(contentFilter.toLowerCase()) &&
        !article.author.toLowerCase().includes(contentFilter.toLowerCase())
      )
        return false;
      return true;
    }) ?? [];
  const sortedArticles = filteredArticles.sort((a, b) => {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  });

  return (
    <VStack {...articleStyles.pageBackground}>
      <VStack padding='0px 24px 0px 24px'>
        <UnAuthHeader activeLink='Blog' />
        <HStack h='120px' />
        {articles && (
          <div>
            <ArticleSummary {...articles[0]} />
            <ArticleFilter
              tagFilter={[tagFilter, setTagFilter]}
              contentFilter={[contentFilter, setContentFilter]}
            />
            <Wrap {...articleStyles.articleCards}>
              {sortedArticles.map((article, index) => {
                return (
                  <a href={`http://www.lved.com/articles/${article.id}.html`}>
                    <ArticleCard {...article} key={index} />
                  </a>
                );
              })}
            </Wrap>
          </div>
        )}
        {!articles && <Text>Loading articles...</Text>}
        {errorMsg && <Text {...articleStyles.errorText}>{errorMsg}</Text>}
      </VStack>
      <UnAuthFooter />
    </VStack>
  );
}

export default Articles;
