import { UI_SIZES } from '@modules/core/constants';

export const appDownloadStyles = {
  heading: {
    size: 'xs',
  },

  text: {
    pt: '3',
  },

  textContainer: {
    width: `100%`,
    bg: 'brand.red.100',
  },

  textStyleWSideBar: {
    padding: '0px 0px 0px 0px',
    // width: `100%`,
    color: 'white',
    bg: 'brand.red.100',
  },

  textStyleWOSideBar: {
    padding: '10px 25px 10px 40px',
    // width: `100%`,
    color: 'white',
    bg: 'brand.red.100',
  },

  appleBadge: {
    mr: '10px',
    mt: '10px',
    mb: '10px',
  },

  contentBoxWSidebar: {
    marginLeft: `${UI_SIZES.sideBar.width}`,
    width: `calc(100% - ${UI_SIZES.sideBar.width})`,
    spacing: '0px',
    align: 'flex-start',
  },

  contentBoxWOSidebar: {
    // marginLeft: `${UI_SIZES.sideBarMinimized.width}`,
    width: '100%', // `calc(100% - ${UI_SIZES.sideBarMinimized.width})`,
    spacing: '0px',
    align: 'flex-start',
  },
};
