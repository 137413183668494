export const UI_SIZES = {
  sideBar: {
    width: '340px',
    widthInt: 340,
  },

  sideBarMinimized: {
    width: '40px',
    widthInt: 340,
  },
};
