import { DynamoDB, AWSError } from 'aws-sdk';
import { Auth } from 'aws-amplify';

import { ICredentials, IGetTemplateNames, TemplateNamesListType } from './interfaces';

import { DYNAMO_DB_TABLES, HIERARCHY_CATEGORY } from '@modules/core/constants';

export async function getTemplateNames({
  section,
}: IGetTemplateNames): Promise<TemplateNamesListType> {
  const credentials: ICredentials = await Auth.currentCredentials();
  const client: DynamoDB.DocumentClient = new DynamoDB.DocumentClient({
    region: process.env.REACT_APP_AWS_REGION || 'us-east-1',
    credentials: Auth.essentialCredentials(credentials),
  });

  const params = {
    TableName: DYNAMO_DB_TABLES.objectTemplates,
    FilterExpression: 'HierarchyCatagory = :category AND HierarchySection = :section',
    ProjectionExpression: 'TemplateName',
    ExpressionAttributeValues: {
      ':category': HIERARCHY_CATEGORY.digitalVault,
      ':section': section,
    },
  };

  return new Promise<TemplateNamesListType>((resolve, reject): void => {
    client.scan(params, (err: AWSError, data: DynamoDB.DocumentClient.ScanOutput): void => {
      if (err) {
        return reject(new Error(err.message || JSON.stringify(err)));
      }

      if (!data.Items) {
        return reject(new Error('no data found'));
      }

      return resolve(data.Items as TemplateNamesListType);
    });
  });
}
