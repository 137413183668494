import { toast, ToastContentProps } from 'react-toastify';

import { IUseQueryResponse, IToastErrorData } from '@modules/core/hooks/interfaces';
import { useQuery } from '@modules/core/hooks';

import { TOAST_ERRORS, TOAST_MESSAGES } from '@modules/core/constants';

function useQueryToast<Req, Res>(
  fetcher: (data: Req) => Promise<Res>,
  withPending: boolean = false
): IUseQueryResponse<Req, Res> {
  return useQuery<Req, Res>(
    (reqData: Req): Promise<Res> =>
      toast.promise(fetcher.bind(null, reqData), {
        ...(withPending && { pending: TOAST_MESSAGES.pending }),
        success: TOAST_MESSAGES.success,
        error: {
          render({ data }: ToastContentProps<IToastErrorData>) {
            return data?.message || TOAST_ERRORS.unknown;
          },
        },
      })
  );
}

export default useQueryToast;
