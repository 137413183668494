import React, { ReactElement } from 'react';

import { VStack, Text, HStack, Heading, Box, Wrap } from '@chakra-ui/react';
import { Doughnut } from 'react-chartjs-2';
import { TooltipItem } from 'chart.js';

import { IStorageUsageProps } from '@modules/common-ui/components/storage-usage/interfaces';

import { storageUsageStyles } from '@modules/common-ui/components/storage-usage/styles';

const CHART_HOVER_COLORS: Array<string> = ['#DB4F62', '#6CAE36'];
const CHART_LABELS: Array<string> = ['Used Memory', 'Available Memory'];
const CHART_COLORS: Array<string> = ['#FF6D74', '#8BCB4B'];

function StorageUsage({ usedMemory, availableMemory, files }: IStorageUsageProps): ReactElement {
  const commonMemory: number = availableMemory + usedMemory;

  const formatBytes = (bytes: number, toFixed: number = 0): string => {
    if (String(bytes).length > 9) {
      return `${(bytes / 10 ** 9).toFixed(toFixed)} GB`;
    }

    return `${(bytes / 10 ** 6).toFixed(toFixed)} MB`;
  };

  const tooltipHandler = (context: TooltipItem<'doughnut'>): string => {
    return `Storage Usage: ${formatBytes(context.parsed, 2)}`;
  };

  return (
    <Wrap {...storageUsageStyles.container}>
      <VStack {...storageUsageStyles.textContainer}>
        <Heading {...storageUsageStyles.title}>{`${formatBytes(usedMemory, 2)} / ${formatBytes(
          commonMemory
        )}`}</Heading>

        <HStack {...storageUsageStyles.subTextContainer}>
          <VStack {...storageUsageStyles.pointsContainer}>
            <HStack {...storageUsageStyles.pointBox}>
              <Box {...storageUsageStyles.availableMemoryPoint} />

              <Text {...storageUsageStyles.text}>Available Storage</Text>
            </HStack>

            <HStack {...storageUsageStyles.pointBox}>
              <Box {...storageUsageStyles.usedMemoryPoint} />

              <Text {...storageUsageStyles.text}>Used Storage</Text>
            </HStack>

            <HStack {...storageUsageStyles.pointBox}>
              <Box {...storageUsageStyles.filesPoint} />

              <Text {...storageUsageStyles.text}>Files</Text>
            </HStack>
          </VStack>

          <VStack {...storageUsageStyles.pointsContainer}>
            <Text {...storageUsageStyles.text}>
              {`${formatBytes(availableMemory, 2)} of ${formatBytes(commonMemory)}`}
            </Text>

            <Text {...storageUsageStyles.text}>{`${formatBytes(usedMemory, 2)}`}</Text>

            <Text {...storageUsageStyles.text}>{files}</Text>
          </VStack>
        </HStack>
      </VStack>
      {window.innerWidth > 886 && (
        <Box {...storageUsageStyles.chartContainer}>
          <Doughnut
            data={{
              labels: CHART_LABELS,
              datasets: [
                {
                  data: [usedMemory, availableMemory],
                  backgroundColor: CHART_COLORS,
                  hoverBackgroundColor: CHART_HOVER_COLORS,
                  hoverOffset: 4,
                },
              ],
            }}
            options={{
              responsive: true,
              maintainAspectRatio: true,
              plugins: {
                tooltip: {
                  callbacks: {
                    label: tooltipHandler,
                  },
                },
              },
            }}
          />
        </Box>
      )}
    </Wrap>
  );
}

export default StorageUsage;
