import React, { ReactElement, useEffect, useState } from 'react';

import { Flex, VStack, Text, Box, Spinner } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

import { getPaymentsHistory } from '@app/modules/core/aws/lambdas/payments';
import { formatDate } from '@modules/core/date-formatter';
import { useQuery } from '@modules/core/hooks';
import {
  IPaymentsHistoryRes,
  PaymentsListType,
  PaymentType,
} from '@app/modules/core/aws/lambdas/payments/interfaces';

import Table from '../table';

import { paymentsHistoryStyles } from './styles';

import { PAYMENTS_HISTORY_TABLE_COLUMNS, DEFAULT_VALUES } from '@modules/core/constants';

function PaymentsHistory(): ReactElement {
  const { submit, res, isLoading } = useQuery<string, IPaymentsHistoryRes>(getPaymentsHistory);

  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
  const [pagesIds, setPagesIds] = useState<Array<string>>(['']);

  const payments: PaymentsListType = res?.payments || [];
  const isNextPageAvaliable: boolean = Boolean(res?.isNextPageAvaliable);
  const nextPageId: string = res?.nextPageId || '';

  const loadNextPayments = (): void => {
    if (isLoading) return;
    if (currentPageIndex + 1 < pagesIds.length) {
      setCurrentPageIndex((oldPageIndex: number) => oldPageIndex + 1);
    }
  };

  const loadPreviousPayments = (): void => {
    if (isLoading) return;
    if (currentPageIndex - 1 >= 0) {
      setCurrentPageIndex((oldPageIndex: number) => oldPageIndex - 1);
    }
  };

  useEffect((): void => {
    submit(pagesIds[currentPageIndex]);
  }, [currentPageIndex]);

  useEffect((): void => {
    if (res && isNextPageAvaliable && nextPageId) {
      if (!pagesIds.includes(nextPageId)) {
        setPagesIds((oldPagesIDs) => [...oldPagesIDs, nextPageId]);
      }
    }
  }, [res]);

  const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
  const hasCreditsApplied =
    payments.map((payment) => payment.metadata.appliedCredit ?? 0).reduce((a, b) => a + b, 0.0) > 0;
  const hasRefunds =
    payments.map((payment) => payment.refunded ?? 0).reduce((a, b) => a + b, 0.0) > 0;
  return (
    <VStack {...paymentsHistoryStyles.tableContainer} position='relative'>
      <Table
        isBodyBlocked={isLoading}
        headers={[
          PAYMENTS_HISTORY_TABLE_COLUMNS.plan,
          PAYMENTS_HISTORY_TABLE_COLUMNS.planCost,
          hasCreditsApplied ? PAYMENTS_HISTORY_TABLE_COLUMNS.creditApplied : '  ',
          PAYMENTS_HISTORY_TABLE_COLUMNS.amount,
          hasRefunds ? PAYMENTS_HISTORY_TABLE_COLUMNS.refunded : ' ',
          PAYMENTS_HISTORY_TABLE_COLUMNS.cardInfo,
          PAYMENTS_HISTORY_TABLE_COLUMNS.paymentDate,
        ]}
        data={payments.map((payment: PaymentType) => ({
          [PAYMENTS_HISTORY_TABLE_COLUMNS.plan]: `${payment.metadata.billingFrequency} ${payment.metadata.product}`,
          [PAYMENTS_HISTORY_TABLE_COLUMNS.planCost]: currencyFormatter.format(
            Math.round(payment.amount * 100) / 100 +
              Math.round(payment.metadata.appliedCredit * 100) / 100
          ),
          [PAYMENTS_HISTORY_TABLE_COLUMNS.creditApplied]:
            payment.metadata.appliedCredit > 0
              ? currencyFormatter.format(payment.metadata.appliedCredit)
              : ' ',
          [PAYMENTS_HISTORY_TABLE_COLUMNS.amount]: currencyFormatter.format(payment.amount),
          [PAYMENTS_HISTORY_TABLE_COLUMNS.refunded]:
            (payment.refunded ?? 0) > 0 ? currencyFormatter.format(payment.refunded!) : ' ',
          [PAYMENTS_HISTORY_TABLE_COLUMNS.cardInfo]: payment.method.last4
            ? `**** ${payment.method.last4}`
            : payment.isAppleInAppPayment
            ? 'Apple In-app Payment'
            : payment.isGoogleInAppPayment
            ? 'Google Pay'
            : DEFAULT_VALUES.paymentMethod,
          [PAYMENTS_HISTORY_TABLE_COLUMNS.paymentDate]: formatDate(payment.timestamp),
        }))}
      />

      {isLoading && (
        <Box position='absolute' {...paymentsHistoryStyles.spinnerBox}>
          <Spinner {...paymentsHistoryStyles.spinner} />
        </Box>
      )}

      <Flex {...paymentsHistoryStyles.paginationBox}>
        <Flex
          onClick={loadPreviousPayments}
          color={!currentPageIndex ? 'gray.200' : 'inherit'}
          {...paymentsHistoryStyles.paginationButton}
        >
          <ChevronLeftIcon />

          <Text>Back</Text>
        </Flex>

        <Flex
          onClick={loadNextPayments}
          color={currentPageIndex + 1 < pagesIds.length ? 'inherit' : 'gray.200'}
          {...paymentsHistoryStyles.paginationButton}
        >
          <Text>Next</Text>

          <ChevronRightIcon />
        </Flex>
      </Flex>
    </VStack>
  );
}

export default PaymentsHistory;
