export const familyInviteSlotStyles = {
  container: {
    width: '100%',
  },

  contentBox: {
    alignItems: 'flex-start',
  },

  inputButtonContainer: {
    spacing: { base: '3', sm: '6', md: '10' },
    maxWidth: '600px',
    width: '100%',
  },

  inputContainer: {
    flex: '3',
  },

  buttonContainer: {
    flex: '1',
  },

  slotDatesContainer: {
    spacing: '10',
  },

  button: {
    size: 'lg',
    width: { base: '110px', sm: '130px', md: '100%' },
    backgroundColor: 'white',
    color: 'brand.green.100',
    border: '2px solid',
    borderColor: 'brand.green.100',

    _hover: {
      backgroundColor: 'brand.green.50',
    },
  },

  inUseTitle: {
    color: 'brand.green.100',
  },

  notInUseTitle: {
    color: 'brand.red',
  },

  dateTitle: {
    color: 'brand.gray.100',
  },
};
