import React, { ReactElement, memo } from 'react';

import { HStack, Text } from '@chakra-ui/react';

import { getAgeDescription } from '@modules/core/lvedHelpers';
import { IFamilyInviteSlotDatesProps } from './interfaces';
import { ConditionSeparator } from '@modules/common-ui/components/condition-separator';

import { familyInviteSlotStyles } from './styles';

function FamilyInviteSlotDates({
  inviteDate = '',
  acceptedDate = '',
}: IFamilyInviteSlotDatesProps): ReactElement {
  return (
    <HStack {...familyInviteSlotStyles.slotDatesContainer}>
      <ConditionSeparator
        condition={!!inviteDate}
        target={
          <Text {...familyInviteSlotStyles.dateTitle}>
            Invited: {getAgeDescription(new Date(inviteDate))}
          </Text>
        }
      />

      <ConditionSeparator
        condition={!!acceptedDate}
        target={
          <Text {...familyInviteSlotStyles.dateTitle}>
            Accepted: {getAgeDescription(new Date(acceptedDate))}
          </Text>
        }
      />
    </HStack>
  );
}

export default memo(FamilyInviteSlotDates);
