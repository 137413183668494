import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router-dom';

import {
  IPaymentIntentInput as PaymentInput,
  IPaymentIntentRes as PaymentRes,
} from '@app/modules/core/aws/lambdas/payments/interfaces';
import { createPaymentIntent as payReq } from '@app/modules/core/aws/lambdas/payments';
import { useQuery } from '@app/modules/core/hooks';
import { IPaidPricingCardProps } from './interfaces';

import PricingCard from './PricingCard';

import { APP_ROUTES } from '@app/modules/core/constants';
import Modal from '../modal/ModalBasic';
import { Button, Text, VStack } from '@chakra-ui/react';
import { TrackGoogleAnalyticsEvent } from '@app/modules/core/google-analytics/google-analytics-tracker';
import {
  UserCategory,
  AcknowledgedIOSLimitation,
  SubscriptionPlanSelected,
} from '@app/modules/core/google-analytics/events/signupGAEvent';
import { pricingCardStyles } from './styles';

function PaidPricingCard({
  lecense,
  isAnnualPlan,
  heading,
  price,
  renewalPrice,
  monthlyPrice,
  features,
  icon,
  isPopular,
  promoCode,
}: IPaidPricingCardProps): ReactElement {
  const navigateTo: NavigateFunction = useNavigate();
  const { submit, isLoading, err: paymentErr, res } = useQuery<PaymentInput, PaymentRes>(payReq);
  const [showingConfirmation, setShowingConfirmation] = useState<boolean>(false);

  const onPayHandler = (): void => {
    submit({
      isAnnualPlan,
      productName: lecense,
      ...(promoCode && { promoCode }),
    });
  };

  useEffect((): void => {
    if (res) {
      navigateTo(APP_ROUTES.payment, {
        state: {
          paymentIntent: res,
          productName: lecense,
          isAnnualPlan,
        },
      });
    }
  }, [res]);

  return (
    <div>
      <Modal
        isOpen={showingConfirmation}
        onClose={() => {
          setShowingConfirmation(false);
        }}
        title='Apple IOS Device Confirmation'
      >
        <VStack {...pricingCardStyles.acknowledgementBox}>
          <Text>
            We are actively building advanced security into our Android and Web apps, but they are
            not yet to the specifications we require to host your sensitive data.
          </Text>
          <Text>
            At this time, you will need to download the LVED App from the IOS App Store to take full
            advantage of your LVED subscription.
          </Text>
          <Text>
            By selecting, &quot;I acknowledge&quot; you indicate you are willing to use an IOS
            device, such as an iPhone, and download the LVED app from the Apple App Store.
          </Text>
          <Button
            {...pricingCardStyles.standaloneButton}
            onClick={() => {
              TrackGoogleAnalyticsEvent(
                UserCategory,
                AcknowledgedIOSLimitation,
                `Plan: ${heading}, term: ${isAnnualPlan ? 'Annual' : 'Monthly'}`
              );
              setShowingConfirmation(false);
              onPayHandler();
            }}
          >
            I acknowledge
          </Button>
        </VStack>
      </Modal>

      <PricingCard
        submitCallback={() => {
          TrackGoogleAnalyticsEvent(
            UserCategory,
            SubscriptionPlanSelected,
            `Plan: ${heading}, term: ${isAnnualPlan ? 'Annual' : 'Monthly'}`
          );
          setShowingConfirmation(true);
        }}
        isActionLoading={isLoading}
        isError={!!paymentErr}
        errorMessage={paymentErr?.message}
        isAnnualPlan={isAnnualPlan}
        heading={heading}
        features={features}
        price={price}
        isPopular={isPopular}
        renewalPrice={renewalPrice}
        monthlyPrice={monthlyPrice}
        icon={icon}
      />
    </div>
  );
}

export default PaidPricingCard;
