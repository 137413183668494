export const storageUsageStyles = {
  availableMemoryPoint: {
    width: '14px',
    height: '14px',
    borderRadius: '100%',
    backgroundColor: 'brand.green.100',
  },

  usedMemoryPoint: {
    width: '14px',
    height: '14px',
    borderRadius: '100%',
    backgroundColor: 'brand.red',
  },

  filesPoint: {
    width: '14px',
    height: '14px',
    borderRadius: '100%',
    backgroundColor: 'brand.gray.50',
  },

  container: {
    height: '100%',
    width: '100%',
    justify: 'space-between',
    spacing: '0',
  },

  textContainer: {
    spacing: '5',
    paddingLeft: { base: '0px', sm: '25px', md: '50px' },
  },

  chartContainer: {
    height: '90%',
    paddingRight: '50px',
  },

  subTextContainer: {
    spacing: '10',
  },

  pointsContainer: {
    alignItems: 'flex-start',
  },

  pointBox: {
    spacing: '5',
  },

  title: {
    as: 'h3',
    size: 'lg',
  },

  text: {
    fontSize: 'xl',
  },
} as const;
