export const faqStyles = {
  pageBackground: {
    width: '100%',
    background: 'linear-gradient(180deg, #D0F0E4 13.67%, #F3F7F8 37.59%, #F6F8FA 100%)',
    spacing: '30px',
  },

  faqPageContents: {
    display: 'inline-flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    gap: '32px',
    padding: { base: '0px 24px 0px 24px', md: '0px 40px 0px 40px' },
    width: '100%',
  },

  faqTitleContainer: {
    flexDirection: 'column' as 'column',
    display: 'inline-flex',
    alignItems: 'center',
    gap: '16px',
  },

  faqTitle: {
    color: 'var(--Typography-Dark, #202122)',
    fontFamily: 'Lato',
    fontSize: { base: '24px', md: '48px' },
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
  },

  faqSubtitle: {
    color: 'var(--Typography-Medium, #4F5156)',
    fontFamily: 'Lato',
    fontSize: { base: '18px', md: '24px' },
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
  },

  faqMainContainer: {
    display: 'inline-flex',
    width: '100%',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    gap: '32px',
  },

  faqContent: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column' as 'column',
    alignItems: 'flex-start',
    gap: '24px',
  },

  faqHeader: {
    display: 'flex',
    width: '100%',
    gap: { base: '24px', md: '12px' },
    flexDirection: { base: 'column', md: 'row' } as const,
    justifyContent: { base: '', md: 'space-between' },
    alignItems: 'center',
  },

  filterButtonContainer: {
    // display: 'flex',
    // alignItems: 'flex-start',
    gap: '16px',
    // alignSelf: 'stretch',
  },

  filterButton: {
    display: 'flex',
    width: '120px',
    height: '48px',
    padding: '8px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '24px',
    border: '1px solid var(--Clickable-Unselected, #BABCBF)',
  },

  filterButtonSelected: {
    display: 'flex',
    width: '120px',
    height: '48px',
    padding: '8px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '24px',
    border: '1px solid var(--Clickable-Default, #080809)',
    background: 'var(--Clickable-Unselected, #BABCBF)',
  },

  searchContainer: {
    display: 'flex',
    padding: '0px 0px 0px 40px',
    width: '342px',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderRadius: '8px',
    border: '1px solid var(--elements-selected, #DDDDDF)',
    background: 'var(--input-default, #FFF)',
    color: 'var(--typography-placeholder, #080809)',
    fontFamily: 'Lato',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
  },
};
