import { DynamoDB, AWSError } from 'aws-sdk';
import { Auth } from 'aws-amplify';

import { ICredentials, AssetInputType } from '@modules/core/aws/dynamo-db/digital-vault/interfaces';

import { DYNAMO_DB_TABLES } from '@modules/core/constants';

export async function getAsset(assetId: string): Promise<AssetInputType> {
  const credentials: ICredentials = await Auth.currentCredentials();

  const client: DynamoDB.DocumentClient = new DynamoDB.DocumentClient({
    region: process.env.REACT_APP_AWS_REGION || 'us-east-1',
    credentials: Auth.essentialCredentials(credentials),
  });

  const params = {
    TableName: DYNAMO_DB_TABLES.customerObjects,
    Key: {
      identityId: credentials.identityId,
      objectId: assetId,
    },
  };

  return new Promise<AssetInputType>((resolve, reject): void => {
    client.get(params, (err: AWSError, data: DynamoDB.DocumentClient.GetItemOutput): void => {
      if (err) {
        return reject(new Error(err.message || JSON.stringify(err)));
      }

      if (!data.Item) {
        return reject(new Error('no data found'));
      }

      return resolve(data.Item as AssetInputType);
    });
  });
}
