import React, { ReactElement } from 'react';

import { Icon } from '@chakra-ui/react';

import { ICommonIconProps } from './interfaces';

function FamilyIcon({ width = '66', height = '66' }: ICommonIconProps): ReactElement {
  return (
    <Icon
      width={width}
      height={height}
      viewBox='0 0 66 66'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='66' height='66' rx='13' fill='currentColor' />
      <g clipPath='url(#clip0_1511_1723)'>
        <path
          d='M42.8203 23.4219C45.6982 23.4219 48.0312 21.0889 48.0312 18.2109C48.0312 15.333 45.6982 13 42.8203 13C39.9424 13 37.6094 15.333 37.6094 18.2109C37.6094 21.0889 39.9424 23.4219 42.8203 23.4219Z'
          fill='white'
        />
        <path
          d='M40.4609 43.2578L38.2656 34.6484C37.875 33.1172 36.4922 32.0391 34.9062 32.0391H31.0937C29.5078 32.0391 28.125 33.1172 27.7344 34.6484L25.5391 43.2578C25.2109 44.5391 26.1797 45.7813 27.5 45.7813H29.1484V51.2656C29.1484 52.2187 29.9219 53 30.8828 53H35.125C36.0781 53 36.8594 52.2266 36.8594 51.2656V45.7813H38.5078C39.8203 45.7813 40.7891 44.5391 40.4609 43.2578Z'
          fill='white'
        />
        <path
          d='M33 31.1328C35.1272 31.1328 36.8516 29.4084 36.8516 27.2813C36.8516 25.1541 35.1272 23.4297 33 23.4297C30.8728 23.4297 29.1484 25.1541 29.1484 27.2813C29.1484 29.4084 30.8728 31.1328 33 31.1328Z'
          fill='white'
        />
        <path
          d='M52.9141 39.8281L49.9453 28.1797C49.4141 26.1016 47.5469 24.6484 45.4062 24.6484H40.25C39.4453 24.6484 38.6875 24.8516 38.0156 25.2187C38.2813 25.8516 38.4219 26.5469 38.4219 27.2812C38.4219 28.6875 37.8828 29.9688 37 30.9375C38.3516 31.5547 39.3984 32.7578 39.7812 34.2656L41.4844 40.9453L41.9766 42.875C42.0078 42.9922 42.0312 43.1172 42.0469 43.2344C42.1953 44.2031 41.9375 45.1719 41.3281 45.9531C40.6484 46.8359 39.6094 47.3359 38.5 47.3359H38.4141V51.2578C38.4141 51.6094 38.3594 51.9453 38.2578 52.2656C38.6797 52.7109 39.2891 52.9922 39.9531 52.9922H45.6953C46.9922 52.9922 48.0391 51.9453 48.0391 50.6484V43.2266H50.2656C52.0469 43.2344 53.3516 41.5547 52.9141 39.8281Z'
          fill='white'
        />
        <path
          d='M24.0234 42.875L24.5156 40.9453L26.2188 34.2656C26.6016 32.75 27.6484 31.5547 29 30.9375C28.1172 29.9766 27.5781 28.6875 27.5781 27.2812C27.5781 26.5547 27.7188 25.8594 27.9844 25.2187C27.3125 24.8516 26.5547 24.6484 25.75 24.6484H20.5938C18.4531 24.6484 16.5781 26.1016 16.0547 28.1797L13.0859 39.8281C12.6484 41.5547 13.9531 43.2344 15.7344 43.2344H17.9609V50.6562C17.9609 51.9531 19.0078 53 20.3047 53H26.0469C26.7109 53 27.3125 52.7188 27.7422 52.2734C27.6406 51.9531 27.5859 51.6172 27.5859 51.2656V47.3438H27.5C26.3828 47.3438 25.3516 46.8359 24.6719 45.9609C24.0625 45.1797 23.8125 44.2031 23.9531 43.2422C23.9688 43.1172 23.9922 42.9922 24.0234 42.875Z'
          fill='white'
        />
        <path
          d='M28.3868 18.2214C28.3925 15.3435 26.0642 13.0058 23.1862 13C20.3083 12.9943 17.9706 15.3226 17.9649 18.2006C17.9591 21.0785 20.2875 23.4162 23.1654 23.4219C26.0433 23.4277 28.381 21.0993 28.3868 18.2214Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_1511_1723'>
          <rect width='40' height='40' fill='white' transform='translate(13 13)' />
        </clipPath>
      </defs>
    </Icon>
  );
}

export default FamilyIcon;
