import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';

import { ListItem, ListIcon, Box } from '@chakra-ui/react';

import { useNavigation } from '@modules/core/hooks';
import { ConditionSeparator } from '@modules/common-ui/components/condition-separator';
import { ISideBarItemProps } from '@modules/common-ui/components/side-bar/interfaces';
import { INavContext } from '@modules/core/contexts/navigation/interfaces';

import { sideBarStyles } from '@modules/common-ui/components/side-bar/styles';

import { APP_ROUTES } from '@modules/core/constants';

export function SideBarItem({ item, toggleNavMenuVisibility }: ISideBarItemProps): ReactElement {
  const { activeRoute }: INavContext = useNavigation();

  const isItemActive = (): boolean => !!activeRoute?.includes(item.path);
  const isItemDisabled = (): boolean => item.path === APP_ROUTES.leaveCurrentPage;

  return (
    <ListItem
      as={NavLink}
      to={item.path}
      {...sideBarStyles.listItem}
      {...sideBarStyles.listItemLink}
      {...(isItemActive() && sideBarStyles.listItemActive)}
      {...(isItemDisabled() && sideBarStyles.listItemDisabled)}
      onClick={() => {
        toggleNavMenuVisibility(false);
      }}
    >
      <ConditionSeparator
        condition={!!item.icon}
        target={
          <Box {...sideBarStyles.listItemIconBox}>
            <ListIcon as={item.icon} />
          </Box>
        }
      />

      {item.title}
    </ListItem>
  );
}
