import React, { ReactElement } from 'react';
import { VStack, Spacer, Text, Button, Flex } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { keyValidationSchema } from './validation-schema';
import { familyRegistrationStyles } from './styles';
import NotificationKeyInput from '../../modules/common-ui/components/text-field/NotificaitonKeyInput';

export function RegistrationKeyEntry(props: {
  onKeyAvailable: (arg0: string) => void;
}): ReactElement {
  function formSubmitted(formProps: { notificationKey: string }): void {
    // console.log('For submission triggered');
    // console.log(formProps.notificationKey);
    props.onKeyAvailable(formProps.notificationKey);
  }

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    onSubmit: (newValues) => {
      formSubmitted(newValues);
    },
    validationSchema: keyValidationSchema,
    initialValues: {
      notificationKey: '',
    },
  });

  return (
    <Flex w='100%' alignItems='center' justifyContent='center' direction='column'>
      <header>
        <Text {...familyRegistrationStyles.titleText}>Family&apos;s Life Event Registration</Text>
      </header>

      <Spacer />

      <VStack margin='50px'>
        <Text>
          We&apos;re sorry to hear that someone close to you may have been incapacitated or passed
          away. Please provide the notification key included in the family letter to get started.
        </Text>
      </VStack>

      <form onSubmit={handleSubmit}>
        <VStack {...familyRegistrationStyles.contentBox} spacing='5'>
          <Spacer />
          <NotificationKeyInput
            id='notificationKey'
            name='notificationKey'
            label='Notification key'
            onChange={handleChange}
            value={values.notificationKey}
            isError={!!errors.notificationKey && touched.notificationKey}
            errorMessage={errors.notificationKey}
          />

          <Button type='submit' {...familyRegistrationStyles.submitButton}>
            Search
          </Button>
        </VStack>
      </form>
    </Flex>
  );
}
