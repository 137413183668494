import React, { ReactElement } from 'react';

import { HStack, Button, Spacer } from '@chakra-ui/react';

import { useAuth, useQuery } from '../../../core/hooks';
import { IAuthContext } from '../../../core/contexts/auth/interfaces';
import { LogoIcon } from '../../icons';

import { headerStyles } from './styles';

function Header(): ReactElement {
  const { signOut }: IAuthContext = useAuth();
  const { submit, isLoading } = useQuery<void, void>(signOut);

  return (
    <HStack {...headerStyles.headerContainer} position='fixed'>
      <LogoIcon {...headerStyles.icon} />
      <Spacer />
      <Button isLoading={isLoading} onClick={() => submit()} {...headerStyles.signOutButton}>
        Sign Out
      </Button>
    </HStack>
  );
}

export default Header;
