import React, { ReactElement } from 'react';

import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';

import { IRadioTextInputProps } from './interfaces';

function RadioTextInput({
  label,
  id,
  options,
  errorMessage,
  isError = false,
  variant = 'outline',
  ...rest
}: IRadioTextInputProps): ReactElement {
  return (
    <FormControl id={id} isInvalid={isError}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}

      <RadioGroup id={id} variant={variant}>
        <Stack spacing={4} direction='row'>
          {options.map((option) => (
            <Radio
              key={option}
              id={id}
              name={id}
              value={option}
              onChange={(e) => {
                if (e.target.checked) {
                  e.target.value = option;
                  rest.onChange(e);
                }
              }}
            >
              {option}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
      {isError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
}

export default RadioTextInput;
