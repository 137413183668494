import { lambdaGeneralCaller } from '@modules/core/aws/lambdas/lambda-general-caller/lambdaGeneralCaller';
import {
  ILoadStoriesRequest,
  IUnAuthCredentials,
  ILoadStoriesResponse,
} from '@modules/core/aws/lambdas/explore-stories-with-link/interfaces';

import { AWS_LAMBDAS, AWS_LAMBDAS_OPERATIONS } from '@modules/core/constants';

async function getNextStories(request: ILoadStoriesRequest): Promise<ILoadStoriesResponse> {
  const credentials: IUnAuthCredentials = {
    accessKeyId: process.env.REACT_APP_AWS_UNAUTHENTICATED_ACCESS_KEY_ID || 'NONE',
    secretAccessKey: process.env.REACT_APP_AWS_UNAUTHENTICATED_SECRET_ACCESS_KEY || 'NONE',
  };

  const res = await lambdaGeneralCaller<ILoadStoriesResponse>(credentials, {
    FunctionName: AWS_LAMBDAS.exploreStoriesWithLink,
    Payload: JSON.stringify({
      operation: AWS_LAMBDAS_OPERATIONS.getStoryList,
      identityId: request.identityId,
      storyTellingId: request.storyShareId,
      includeThumbnails: request.includeThumbnails || false,
      continueWithKey: request.exclusiveStartKey,
    }),
  });

  if (res.stories !== undefined) {
    res.stories = res.stories.map((story) => {
      const transform = story;

      transform.CreatedTimestamp = new Date(story['Created Timestamp']);
      transform.LastUpdateTimestamp = new Date(story['Last Update Timestamp']);

      return transform;
    });
  }

  return res;
}

export default getNextStories;
