import '@stripe/stripe-js';

import React, { ReactElement, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { Amplify } from 'aws-amplify';
import { ToastContainer } from 'react-toastify';
import { Chart, DoughnutController, ArcElement, Title, Tooltip } from 'chart.js';

import AuthContextProvider from '@modules/core/contexts/auth';
import NavContextProvider from '@modules/core/contexts/navigation';
import EncryptionContextProvider from '@modules/core/contexts/encryption';
import SideBar from '@modules/common-ui/components/side-bar';
// import EncryptionKeyTimer from '@modules/common-ui/components/encryption-key-timer';
import Router from '@modules/core/routing';
import routes from '@app/routes';
import amplifyConfig from '@modules/core/aws/amplify';

import 'react-toastify/dist/ReactToastify.css';
import InitializeGoogleAnalytics from './modules/core/google-analytics/google-analytics-tracker';
import { UI_SIZES } from './modules/core/constants';
import AppEntryParamsContextProvider from './modules/core/contexts/appEntryParams/AppEntryParamsContextProvider';

Amplify.configure(amplifyConfig);
Chart.register(DoughnutController, ArcElement, Title, Tooltip);

// <EncryptionKeyTimer /> remove after <SideBar/>
function App(): ReactElement {
  const [navExpanded, setNavExpanded] = useState(window.innerWidth > UI_SIZES.sideBar.widthInt * 2);
  function toggleNavMenuIfNeeded(forceToggle: boolean) {
    if (forceToggle) setNavExpanded(!navExpanded);
    else if (window.innerWidth < UI_SIZES.sideBar.widthInt * 2) setNavExpanded(!navExpanded);
  }
  useEffect(() => {
    InitializeGoogleAnalytics();
  });

  return (
    <AuthContextProvider>
      <ToastContainer />
      <NavContextProvider>
        <EncryptionContextProvider>
          <AppEntryParamsContextProvider entryPointURL={window.location.search}>
            <BrowserRouter>
              <SideBar isExpanded={navExpanded} toggleNavMenuVisibility={toggleNavMenuIfNeeded} />
              <Router routes={routes} isSideBarExpanded={navExpanded} />
            </BrowserRouter>
          </AppEntryParamsContextProvider>
        </EncryptionContextProvider>
      </NavContextProvider>
    </AuthContextProvider>
  );
}

export default App;
