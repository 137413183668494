export const subscriptionStyles = {
  mainContainer: {
    width: { base: '400px', lg: '1160px' },
    margin: { base: '0px', lg: '40px' },
    spacing: '14',
  },

  pageHeading: {
    color: 'blackAlpha.600',
    alignSelf: 'flex-start',
  },

  spinnerBox: {
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },

  spinner: {
    thickness: '4px',
    speed: '0.65s',
    emptyColor: 'gray.200',
    color: 'brand.green.100',
    size: 'xl',
  },

  currentPlanBox: {
    width: '100%',
    backgroundColor: 'white',
    borderRadius: '20px',
    justifyContent: 'center',
    padding: { base: '15px 30px', lg: '40px 80px' },
    minH: '100px',
  },

  currentPlanContentBox: {
    spacing: { base: '30px', lg: '200px' },
  },

  currentPlanContentChild: {
    alignItems: 'flex-start',
  },

  currentPlanTextHeader: {
    fontSize: 'md',
    color: 'brand.gray.100',
  },

  currentPlanText: {
    fontSize: 'lg',
    color: 'brand.green.100',
  },

  upgradePlanButton: {
    size: 'lg',
    width: { base: '300px', lg: '100%' },
    backgroundColor: 'brand.green.100',
    color: 'white',

    _hover: {
      backgroundColor: 'brand.green.200',
    },
  },

  additionalActionButton: {
    size: 'lg',
    width: '100%',
    backgroundColor: 'white',
    color: 'brand.green.100',
    border: '2px solid',
    borderColor: 'brand.green.100',

    _hover: {
      backgroundColor: 'brand.green.50',
    },
  },

  contactUsButton: {
    flex: '1',
  },

  cancelSubscriptionButton: {
    flex: '2',
    size: 'lg',
    width: '100%',
    backgroundColor: '#FF7E70',
    color: 'white',

    _hover: {
      backgroundColor: '#FF7E70',
    },
  },

  buttonsBox: {
    spacing: '5',
    width: '100%',
  },

  buttonsChildBox: {
    width: '100%',
    spacing: '20',
  },

  affiliateLogo: {
    width: '100px',
    height: '100px',
    borderRadius: '3px',
    // backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
};
