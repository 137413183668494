import React, { ReactElement } from 'react';

import { VStack } from '@chakra-ui/react';

import { IAuthContext } from '@modules/core/contexts/auth/interfaces';
import { useAuth } from '@modules/core/hooks';
import ProgressMenu from './ProgressMenu';
import Header from '@app/modules/common-ui/components/header';

// import { NavigateFunction, useNavigate } from 'react-router';

const subscriptionCompletedText =
  "At this time of rapid innovation, Lved's complete feature set can only be accessed through our IOS app, which you'll need to download to get the full benefits of your subscription.";
const IOSAppLocation = 'https://apps.apple.com/us/app/lved/id6448995985';

export function PostSubscriptionGuide(): ReactElement {
  const { user }: IAuthContext = useAuth();
  // const navigateTo: NavigateFunction = useNavigate();
  const navigateToIOSApp: () => void = () => {
    window.location.href = IOSAppLocation;
  };

  return (
    <VStack paddingTop='100px' paddingBottom='130px' margin='10px'>
      {user && (
        <>
          <Header />
          <ProgressMenu
            stage={3}
            description={subscriptionCompletedText}
            onNext={() => {
              navigateToIOSApp();
            }}
          />
        </>
      )}
    </VStack>
  );
}
