export const TOTPMFAStyles = {
  container: {
    spacing: '20px',
    gap: '20px',
    // width: '653px',
    // background: '#FFFFFF 0% 0% no-repeat padding-box',
    // boxShadow: '0px 0px 20px #00000029',
    // borderRadius: '15px',
  },
};

export const mobileDeviceMFAStyles = {
  container: {
    gap: '24px',
    // width: '653px',
    // background: '#FFFFFF 0% 0% no-repeat padding-box',
    // boxShadow: '0px 0px 20px #00000029',
    // borderRadius: '15px',
  },
};

export const MFASharedStyles = {
  spinnerBox: {
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },

  spinner: {
    thickness: '4px',
    speed: '0.65s',
    emptyColor: 'gray.200',
    color: 'brand.green.100',
    size: 'xl',
  },

  textError: {
    color: 'brand.error',
  },

  textErrorContainer: {
    display: 'flex',
    justifyContent: 'left',
    width: 'full',
  },

  informationText: {
    width: '100%',
    justifyContent: 'left',
    fontStyle: 'normal',
    fontVariant: 'normal',
    fontWeight: 'light',
    fontSize: '16px',
    lineHeight: '24px',
  },

  divider: {
    width: '100%',
    height: '2px',
    justifyContent: 'stetch',
    alignContent: 'center',
    border: '1px solid #707070',
  },

  heading: {
    // background: '#F7F8FA 0% 0% no-repeat padding-box',
    borderRadius: '15px 15px 0px 0px',
    font: 'normal normal bold 24px/54px Roboto',
    fontWeight: '700',
    letterSpacing: '0px',
    color: '#000000',
    justifyContent: 'left',
    width: '100%',
    // height: '62px',
    padding: '5px 0px 0px 10px',
  },

  footer: {
    width: '100%',
    alignContent: 'bottom',
    // height: '62px',
    // background: '#F7F8FA 0% 0% no-repeat padding-box',
    borderRadius: '0px 0px 15px 15px',
  },

  nextButton: {
    width: '131px',
    height: '44px',
    background: '#2E5D5F 0% 0% no-repeat padding-box',
    borderRadius: '22px',
    font: 'normal normal medium 16px/54px Roboto',
    color: '#FFFFFF',
  },

  cancelButton: {
    width: '131px',
    height: '44px',
    border: '1px solid #C7C7C7',
    borderRadius: '22px',
    font: 'normal normal medium 16px/54px Roboto',
    color: '#000000',
  },

  authCodeInput: {
    width: '100%',
    size: 'md',
  },

  errorIcon: {
    width: '19px',
    height: '19px',
  },

  successIcon: {
    width: '55px',
    height: '55px',
  },

  successTitle: {
    font: 'normal normal bold 28px/32px Roboto',
    fontWeight: '700',
    letterSpacing: '0px',
    color: '#2E5D5F',
  },
};

export const manageMFAStyles = {
  container: {
    width: '100%',
    bg: 'white',
    padding: '30px',
    borderRadius: '20px',
    gap: '30px',
  },

  title: {
    font: 'normal normal bold 32px/54px Roboto',
    fontWeight: '700',
    alignSelf: 'flex-start',
    fontSize: 'xl',
  },

  secitonContainer: {
    width: '100%',
    padding: '20px',
    gap: '10px',
  },

  sectionLink: {
    color: 'brand.green.100',
  },

  userText: {
    width: 'full',
    justifyContent: 'left',
  },

  button: {
    colorScheme: 'whiteAlpha',
    width: 'full',
    backgroundColor: 'brand.green.100',

    height: {
      base: '46px',
      md: '56px',
    },

    _hover: {
      backgroundColor: 'brand.green.200',
    },
  },

  mfaOptionsContainer: {
    /* Layout Properties */
    width: 'full',
    height: '200px',
    /* UI Properties */
    background: '#F7F8FA 0% 0% no-repeat padding-box',
    borderRadius: '15px',
  },

  mfaOptionContainer: {
    /* Layout Properties */
    width: '100%',
    height: '50%',
    /* UI Properties */
    opacity: '1',
  },

  authOptionsTitle: {
    width: 'full',
    justifyContent: 'left',
    fontWeight: '700',
  },

  mfaOptionTitle: {
    padding: '5px 0px 0px 10px',
    width: '100%',
    justifyContent: 'left',
    font: 'normal normal medium 22px/54px Roboto',
    fontWeight: '700',
    color: '#000000',
  },

  mfaOptionSubTitle: {
    padding: '0px 0px 0px 10px',
    justifyContent: 'left',
    font: 'normal normal normal 22px/28px Roboto',
    letterSpacing: '0px',
    color: '#000000',
  },

  rightCarretImg: {
    width: '32px',
    height: '32px',
  },

  toggleImage: {
    width: '46px',
    height: '25px',
  },
};
