export const questionsManagerStyles = {
  mainContainer: {
    justifyContent: 'space-between',
    height: '500px',
    width: 'full',
  },

  buttonBox: {
    width: 'full',
  },

  continueButtonBox: {
    marginLeft: '20px',
  },

  prevButton: {
    size: 'lg',
    color: 'black',
    borderColor: 'brand.gray.50 !important',
    backgroundColor: 'brand.floralWhite',
    border: '1px solid',
    _hover: {
      backgroundColor: 'brand.floralWhite',
    },
  },

  nextButton: {
    size: 'lg',
    color: 'white',
    backgroundColor: 'brand.green.100',
    _hover: {
      backgroundColor: 'brand.green.200',
    },
  },

  skipButton: {
    size: 'lg',
    color: 'white',
    backgroundColor: 'gray.400',
    _hover: {
      backgroundColor: 'gray.500',
    },
  },
};
