import React, { ReactElement } from 'react';
import { Routes } from 'react-router-dom';

import { IRouterProps } from './interfaces';
import { createRoutes } from './create-routes';
import { IosAppDownload } from '../../common-ui/components/ios-app-download';

function Router({ routes, isSideBarExpanded }: IRouterProps): ReactElement {
  return (
    <div>
      <IosAppDownload isSideBarExpanded={isSideBarExpanded} />
      <Routes>{createRoutes(routes, isSideBarExpanded)}</Routes>
    </div>
  );
}

export default Router;
