import React from 'react';

import { Box, Spinner } from '@chakra-ui/react';

import { loaderStyles } from './styles';

function GlobalLoader() {
  return (
    <Box {...loaderStyles.loaderContainer} position='absolute'>
      <Spinner {...loaderStyles.spinner} />
    </Box>
  );
}

export default GlobalLoader;
