import * as Yup from 'yup';

import {
  PASSWORD_VALIDATION_ERRORS,
  EMAIL_VALIDATION_ERRORS,
  MFA_VALIDATION_ERRORS,
} from '@modules/core/constants';

const validationSchema = Yup.object().shape({
  password: Yup.string().required(PASSWORD_VALIDATION_ERRORS.required),
  email: Yup.string()
    .trim()
    .required(EMAIL_VALIDATION_ERRORS.required)
    .email(EMAIL_VALIDATION_ERRORS.invalid),
});

export const mfaValidationSchema = Yup.object().shape({
  code: Yup.string().required(MFA_VALIDATION_ERRORS.required),
});

export default validationSchema;
