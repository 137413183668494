import * as Yup from 'yup';

import { EMAIL_VALIDATION_ERRORS } from '@modules/core/constants';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required(EMAIL_VALIDATION_ERRORS.required)
    .email(EMAIL_VALIDATION_ERRORS.invalid),
});

export default validationSchema;
