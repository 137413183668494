import React, { ReactElement, useState } from 'react';

import { Text, Flex } from '@chakra-ui/react';
import { IToggleProps } from './interfaces';
import { toggleStyles } from './styles';

export default function Toggle({
  option1,
  option2,
  initialSelection,
  onSelection,
}: IToggleProps): ReactElement {
  const [activeSelection, setActiveSelection] = useState(initialSelection);
  const onClickHandler = (): void => {
    const newSelection = activeSelection === option1 ? option2 : option1;
    setActiveSelection(newSelection);
    onSelection(newSelection);
  };

  return (
    <Flex {...toggleStyles.container} flexDirection='row' onClick={onClickHandler}>
      <Text
        {...(activeSelection === option1 ? toggleStyles.activeText : toggleStyles.inactiveText)}
      >
        {option1}
      </Text>
      <Text
        {...(activeSelection === option2 ? toggleStyles.activeText : toggleStyles.inactiveText)}
      >
        {option2}
      </Text>
    </Flex>
  );
}
