export const faqItemStyles = {
  faqContainer: {
    display: 'flex',
    width: '100%',
    padding: { base: '12px', md: '32px' },
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: { base: '14px', md: '24px' },
    borderRadius: '8px',
    background: 'var(--Background-White, #FFF)',
    boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.05)',
  },

  faqTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // gap: '8px',
    alignSelf: 'stretch',
  },

  faqQuestion: {
    display: 'flex',
    height: '28px',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: '1 0 0',
    color: 'var(--Typography-Medium, #4F5156)',
    fontFamily: 'Lato',
    fontSize: { base: '14px', md: '24px' },
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: { base: '20px', md: '40px' },
  },

  faqExpandIcon: {
    width: '32px',
    height: '32px',
  },

  faqAnswer: {
    alignSelf: 'stretch',
    color: 'var(--Typography-Light, #7F8289)',
    fontFamily: 'Lato',
    fontSize: { base: '14px', md: '24px' },
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: { base: '16px', md: '32px' },
  },
} as const;
