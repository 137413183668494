import React, { useState, ReactElement, ChangeEvent } from 'react';

import { Text, Flex, VStack, Heading } from '@chakra-ui/react';

import { IQuestionBasicProps } from './interfaces';
import { SelectInput } from '../text-field';

import { questionStyles } from './styles';

function StateQuestion({ question, callback, answers }: IQuestionBasicProps): ReactElement {
  const oldAnswer: string | undefined = answers[question.FieldName];
  const [value, setValue] = useState<string>(oldAnswer || '');

  const onChangeHandler = (event: ChangeEvent<HTMLSelectElement>): void => {
    event.preventDefault();
    setValue(event.target.value);
    callback(question.FieldName, event.target.value);
  };

  return (
    <Flex {...questionStyles.questionContainer}>
      <VStack {...questionStyles.questionTextBox}>
        <Heading>{question.DisplayTitle}</Heading>

        {question.HelperText && <Text>{question.HelperText}</Text>}
      </VStack>

      <SelectInput
        placeholder='Select state'
        value={value}
        onChange={onChangeHandler}
        options={[
          'Alabama',
          'Alaska',
          'Arizona',
          'Arkansas',
          'California',
          'Colorado',
          'Connecticut',
          'Delaware',
          'Florida',
          'Georgia',
          'Hawaii',
          'Idaho',
          'Illinois',
          'Indiana',
          'Iowa',
          'Kansas',
          'Kentucky',
          'Louisiana',
          'Maine',
          'Maryland',
          'Massachusetts',
          'Michigan',
          'Minnesota',
          'Mississippi',
          'Missouri',
          'Montana',
          'Nebraska',
          'Nevada',
          'New Hampshire',
          'New Jersey',
          'New Mexico',
          'New York',
          'North Carolina',
          'North Dakota',
          'Ohio',
          'Oklahoma',
          'Oregon',
          'Pennsylvania',
          'Rhode Island',
          'South Carolina',
          'South Dakota',
          'Tennessee',
          'Texas',
          'Utah',
          'Vermont',
          'Virginia',
          'Washington',
          'West Virginia',
          'Wisconsin',
          'Wyoming',
        ]}
        style={{ ...questionStyles.questionFieldMargin }}
      />
    </Flex>
  );
}

export default StateQuestion;
