import React, { ReactElement } from 'react';

import { VStack } from '@chakra-ui/react';

import { ConditionSeparator } from '@modules/common-ui/components/condition-separator';
import { IAuthContext } from '@modules/core/contexts/auth/interfaces';
import { useAuth } from '@modules/core/hooks';

import ProfileBasicInfo from '@modules/common-ui/components/profile-basic-info';
// import ProfileEncryptionKeys from '@modules/common-ui/components/profile-encryption-keys';
import ProfileFamilyManagement from '@modules/common-ui/components/profile-family-management';
import ProfileStorageUsage from '@modules/common-ui/components/profile-storage-usage';

import { profileStyles } from '@pages/profile/styles';

import { LICENSE_STATUSES } from '@modules/core/constants';
import ManageMFAModal from '@app/modules/common-ui/components/mfa-management/ManageMFAModal';

function Profile(): ReactElement {
  const { user }: IAuthContext = useAuth();

  // <ProfileEncryptionKeys />  Remove this from below...
  return (
    <VStack {...profileStyles.mainContainer}>
      <ProfileBasicInfo />
      <ManageMFAModal />
      <ProfileStorageUsage />
      <ConditionSeparator
        condition={user?.licenseStatus === LICENSE_STATUSES.family}
        target={<ProfileFamilyManagement />}
      />
    </VStack>
  );
}

export default Profile;
