import React, { ReactElement } from 'react';

import { Button, Flex, Image, Text } from '@chakra-ui/react';

// import { IArticleProps } from './interfaces';
import { ArticleType } from '@app/modules/core/articles/interfaces';

import { articleStyles } from './styles';
// import { useNavigate } from 'react-router';

export default function ArticleSummary({
  id,
  title,
  author,
  date,
  teaserContent,
  thumbnailURL,
}: ArticleType): ReactElement {
  // const navigate = useNavigate();
  return (
    <Flex {...articleStyles.articleSummaryContainer}>
      <Image {...articleStyles.articleThumbnail} src={thumbnailURL} />
      <Flex {...articleStyles.articleContentPreview}>
        <Flex {...articleStyles.articleTextContainer}>
          <Flex gap='16px' flexDir='column'>
            <Text {...articleStyles.title}>{title}</Text>
            <Text {...articleStyles.teaserContent}>{teaserContent}</Text>
          </Flex>
          <Text {...articleStyles.subHeading}>{`${author}, on ${date}`}</Text>
        </Flex>
        <Button
          {...articleStyles.readMoreButton}
          onClick={() => {
            window.location.href = `https://www.lved.com/articles/${id}.html`;
          }}
        >
          Read more
        </Button>
      </Flex>
    </Flex>
  );
}
