import React, { ReactElement, useEffect } from 'react';
import { useLocation, useNavigate, NavigateFunction } from 'react-router-dom';

import { Box, Skeleton, Flex, Text } from '@chakra-ui/react';

import { getCommonAssets as getAssets } from '@modules/core/aws/dynamo-db/digital-vault';
import { useQuery } from '@modules/core/hooks';
import { groupArraytoObejct as groupArray } from '@modules/core/lvedHelpers';
import { ConditionSeparator } from '@modules/common-ui/components/condition-separator';
import { DigitalItem } from '@modules/common-ui/components/digital-item';
import { GroupObjetcType } from '@modules/core/lvedHelpers/interfaces';
import {
  CommonAssetsListType,
  IGetCommonAssets,
} from '@modules/core/aws/dynamo-db/digital-vault/interfaces';
import {
  DigitalVaultLocationType,
  IDigitalVaultSectionProps,
} from '@pages/digital-vault/interfaces';

import { digitalVaultStyles as styles } from '@pages/digital-vault/styles';

export default function DigitalVaultSection({ section }: IDigitalVaultSectionProps): ReactElement {
  const navigateTo: NavigateFunction = useNavigate();
  const { pathname } = useLocation<DigitalVaultLocationType>();
  const { submit, res, isLoading } = useQuery<IGetCommonAssets, CommonAssetsListType>(getAssets);
  const assetsObject: GroupObjetcType<CommonAssetsListType> = groupArray(res || [], 'TemplateName');

  const openAssests = (templateName: string): void => {
    return navigateTo(`${pathname}/${templateName}`);
  };

  useEffect(() => {
    if (section) {
      submit({ section });
    }
  }, []);

  return (
    <ConditionSeparator
      condition={isLoading}
      target={<Skeleton isLoaded={!isLoading} {...styles.skeleton} />}
      defaultTarget={
        <ConditionSeparator
          condition={Boolean(res && !res.length)}
          target={<Text>No data</Text>}
          defaultTarget={
            <Flex {...styles.assetsContainer}>
              {Object.entries(assetsObject).map(([name, assetsArray]) => (
                <Box key={name} onClick={openAssests.bind(null, name)} {...styles.assetBox}>
                  <DigitalItem title={name} childCount={assetsArray.length} />
                </Box>
              ))}
            </Flex>
          }
        />
      }
    />
  );
}
