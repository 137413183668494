export const articleStyles = {
  articleSummaryContainer: {
    display: 'flex',
    width: { base: '342px', md: '100%' },
    // height: { base: '', md: '499px' },
    // padding: { base: '0px', md: '0px' },
    paddingBottom: { base: '40px', md: '0px' },
    flexDirection: { base: 'column', md: 'row' },
    alignItems: 'center',
    gap: '48px',
    borderRadius: '16px',
    background: 'var(--elements-active, #202122)',
  },

  articleContentPreview: {
    display: 'flex',
    width: { base: '294px', md: '50%' },
    padding: { base: '0px', md: '32px' },
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '40px',
  },

  articleThumbnail: {
    width: { base: '100%', md: '50%' },
    // height: { base: '182px', md: '200px' },
    padding: '0px',
    borderRadius: { base: '8px 8px 0px 0px', md: '16px 0px 0px 16px' },
    bg: 'lightgray 50% / cover no-repeat',
  },

  articleTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    alignSelf: 'stretch',
  },

  title: {
    // display: 'flex',
    height: '80px',
    // flexDirection: 'column',
    // justifyContent: 'center',
    // alignSelf: 'stretch',
    color: 'var(--typography-dark, #FFF)',
    fontFamily: 'Lato',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
  },

  teaserContent: {
    // inilneSize: '150px',
    height: '61px',
    alignSelf: 'stretch',
    overflow: 'hidden',
    color: 'var(--typography-placeholder, #BABCBF)',
    // overflowWrap: 'break-word',
    // hyphens: 'manual',
    // textOverflow: 'ellipsis',
    fontFamily: 'Lato',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
  },

  subHeading: {
    // padding: '0px 16px 0px 16px',
    alignSelf: 'stretch',
    color: 'var(--typography-medium, #FFF)',
    fontFamily: 'Lato',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
  },

  readMoreButton: {
    display: 'flex',
    width: '131px',
    padding: '16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '1000px',
    background: 'var(--clickable-white, #FFF)',
    color: 'var(--clickable-default, #080809)',
    textAlign: 'center',
    fontFamily: 'Lato',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    position: 'static',
  },
} as const;
