import React, { useEffect, ReactElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Button, VStack, Heading, Text } from '@chakra-ui/react';
import { useFormik } from 'formik';

import { emailValidationSchema } from './validation-schema';
import { useQuery } from '@modules/core/hooks';
import { TextInput } from '@modules/common-ui/components/text-field';

import { forgotPasswordStyles } from './styles';

import { APP_ROUTES } from '@modules/core/constants';
import { IForgotPasswordRequest } from './interfaces';
import { getUserFromEmail } from './cognitoUser';

function requestCode(data: IForgotPasswordRequest): Promise<boolean> {
  // console.log('Requesting code...');
  return new Promise((resolve, reject) => {
    const user = getUserFromEmail(data.email);
    // console.log('Requesting code...');
    user.forgotPassword({
      onSuccess: () => {
        // console.log('onSuccess: ', resData);
        resolve(true);
      },
      onFailure: (err) => {
        // console.log('error: ', err);
        reject(err);
      },
      inputVerificationCode: () => {
        // console.log('Input code: ', resData);
        resolve(true);
      },
    });
  });
}

function RequestPasswordResetCode(props: {
  setEmail: React.Dispatch<React.SetStateAction<string | undefined>>;
}): ReactElement {
  const { submit, res, isLoading, err } = useQuery<IForgotPasswordRequest, boolean>(requestCode);
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    onSubmit: submit,
    validationSchema: emailValidationSchema,
    initialValues: {
      email: '',
    },
  });

  useEffect((): void => {
    if (res === true) {
      props.setEmail(values.email);
    }
  }, [res]);

  return (
    <VStack {...forgotPasswordStyles.resetPasswordBox}>
      <Box {...forgotPasswordStyles.container}>
        <form onSubmit={handleSubmit}>
          <VStack spacing='20%'>
            <Heading>Forgot your password</Heading>

            <TextInput
              id='email'
              name='email'
              label='Please provide the email address associated with your LVED account'
              onChange={handleChange}
              value={values.email}
              isError={!!errors.email && touched.email}
              errorMessage={errors.email}
            />

            <Button isLoading={isLoading} type='submit' {...forgotPasswordStyles.button}>
              Begin password reset
            </Button>

            {err && (
              <Box {...forgotPasswordStyles.textErrorContainer}>
                <Text {...forgotPasswordStyles.textError}>{err?.message || 'unknown error'}</Text>
              </Box>
            )}
          </VStack>
        </form>
      </Box>

      <Text {...forgotPasswordStyles.textContainer}>
        Cancel, signin instead? &nbsp;
        <Text {...forgotPasswordStyles.textGreen} as='span'>
          <RouterLink to={APP_ROUTES.signIn}>Login</RouterLink>
        </Text>
      </Text>
    </VStack>
  );
}

export default RequestPasswordResetCode;
