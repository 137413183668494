import React, { useState, ReactElement } from 'react';

import { navContext as NavContext } from './nav-context';
import { INavContextProviderProps } from './interfaces';

import { APP_ROUTES } from '@modules/core/constants';

function NavContextProvider(props: INavContextProviderProps): ReactElement {
  const [activeRoute, setActiveRoute] = useState<APP_ROUTES | null>(null);
  const setRoute = (route: APP_ROUTES) => setActiveRoute(route);

  return (
    <NavContext.Provider
      value={{
        activeRoute,
        setRoute,
      }}
    >
      {props.children}
    </NavContext.Provider>
  );
}

export default NavContextProvider;
