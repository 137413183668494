import Resizer from 'react-image-file-resizer';
import { groupBy, isString } from 'lodash';

import { ArrayObjectType, GroupObjetcType } from './interfaces';

export function getAgeDescription(date: Date) {
  let diff = (Date.now() - date.getTime()) / 1000;
  if (diff < 60) {
    return `${Math.floor(diff)} second${Math.floor(diff) >= 2 ? 's' : ''} ago`;
  }
  diff /= 60;
  if (diff < 60) {
    return `${Math.floor(diff)} minute${Math.floor(diff) >= 2 ? 's' : ''} ago`;
  }
  diff /= 60;
  if (diff < 24) {
    return `${Math.floor(diff)} hour${Math.floor(diff) >= 2 ? 's' : ''} ago`;
  }
  diff /= 24;
  if (diff < 30) {
    return `${Math.floor(diff)} day${Math.floor(diff) >= 2 ? 's' : ''} ago`;
  }
  diff /= 30;
  if (diff < 12) {
    return `${Math.floor(diff)} month${Math.floor(diff) >= 2 ? 's' : ''} ago`;
  }
  diff /= 12;
  return `${Math.floor(diff)} years ago`;
}

export function formatUSPhoneNumber(number: string): string {
  if (number.length !== 12) return number;
  if (number.substring(0, 2) !== '+1') return number;
  return `+1 (${number.substring(2, 5)}) ${number.substring(5, 8)}-${number.substring(8)}`;
}

export function buffersEqual(buf1?: ArrayBuffer, buf2?: ArrayBuffer): boolean {
  if (buf1 === buf2 && buf1 === undefined) return true;
  if (buf1 === undefined || buf2 === undefined) return false;
  if (buf1.byteLength !== buf2.byteLength) return false;
  const dv1 = new Int8Array(buf1);
  const dv2 = new Int8Array(buf2);
  for (let i = 0; i !== buf1.byteLength; i++) {
    if (dv1[i] !== dv2[i]) return false;
  }
  return true;
}

export function formatPrice(price?: number, removeCentsOver1K?: boolean): string {
  if (price === undefined) return '$-';
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  if (removeCentsOver1K) return USDollar.format(price).split('.')[0];
  return USDollar.format(price);
}

export function formatCommaSeperatedNumber(number?: number): string {
  if (number === undefined) return 'NaN';
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function groupArraytoObejct<I extends ArrayObjectType, B extends keyof I>(
  array: I[],
  key: B
): GroupObjetcType<I[]> {
  return groupBy(array, (item: I) => item[key]);
}

export function resizeFile(buffer: ArrayBuffer, maxLength: number): Promise<ArrayBuffer> {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    const fileBlob: Blob = new Blob([buffer]);
    const fileLength: number = buffer.byteLength;
    const quality: number = Math.floor(100 / (fileLength / maxLength));

    if (fileLength <= maxLength) return resolve(buffer);

    return Resizer.imageFileResizer(
      fileBlob,
      152,
      152,
      'JPEG',
      quality,
      0,
      (blob) => {
        const fileReader = new FileReader();

        fileReader.readAsArrayBuffer(blob as Blob);
        fileReader.onload = (event) => {
          if (!event.target?.result) return reject(new Error('No result was obtained'));
          return resolve(event.target.result as ArrayBuffer);
        };
      },
      'blob'
    );
  });
}

export function arrayBufferToBase64(buffer: ArrayBuffer): Promise<string> {
  return new Promise((resolve, reject) => {
    const blob: Blob = new Blob([buffer]);
    const reader: FileReader = new FileReader();

    reader.readAsDataURL(blob);
    reader.onerror = () => reject(new Error('Converting error'));
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const dataUrl = event.target?.result;

      if (!dataUrl) {
        return reject(new Error('No result was obtained'));
      }

      if (!isString(dataUrl)) {
        return reject(new Error('The result is not in a suitable format'));
      }

      return resolve(`data:image/png;base64,${dataUrl.split(',')[1]}`);
    };
  });
}
