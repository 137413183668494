import { Auth } from 'aws-amplify';

import { ICredentials, IFetchedUserData } from '@modules/core/aws/lambdas/link-user/interfaces';
import { lambdaGeneralCaller } from '@modules/core/aws/lambdas/lambda-general-caller/lambdaGeneralCaller';

import { AWS_LAMBDAS } from '@modules/core/constants';

export default async function linkUser(): Promise<{}> {
  const { attributes }: IFetchedUserData = await Auth.currentAuthenticatedUser();
  const credentials: ICredentials = await Auth.currentCredentials();

  return lambdaGeneralCaller<{}>(credentials, {
    FunctionName: AWS_LAMBDAS.linkUser,
    Payload: JSON.stringify({ userName: attributes?.sub }),
  });
}
