import React, { useEffect, ReactElement, useState } from 'react';
import { useNavigate, NavigateFunction, Navigate } from 'react-router-dom';

import { Box, Button, Flex, VStack, Heading, Text } from '@chakra-ui/react';
import { useFormik } from 'formik';

import validationSchema from './validation-schema';
// import { LvedFeatures } from '@modules/common-ui/components/lved-features';
import { TextInput } from '@modules/common-ui/components/text-field';
import { useQuery, useAuth } from '@modules/core/hooks';
import { storageGet, storageClear, storageSet } from '@modules/core/local-storage';
import { IEmailVerificationData, IAuthContext } from '@modules/core/contexts/auth/interfaces';

import { emailVerificationStyles } from './styles';

import { APP_ROUTES, LOCAL_STORAGE } from '@modules/core/constants';
import Modal from '@app/modules/common-ui/components/modal';
import { TrackGoogleAnalyticsEvent } from '@app/modules/core/google-analytics/google-analytics-tracker';
import UserCategory, {
  ValidatedEmail,
} from '@app/modules/core/google-analytics/events/signupGAEvent';

function EmailVerification(): ReactElement {
  const email: string | null = storageGet(LOCAL_STORAGE.unverifiedEmail);

  const navigateTo: NavigateFunction = useNavigate();
  const { verifyEmail, resendCode }: IAuthContext = useAuth();
  const [showingCongratuations, setShowCongratulations] = useState<boolean>(false);

  const {
    submit: resend,
    isLoading: isResendLoad,
    err: resendErr,
  } = useQuery<string, void>(resendCode);
  const {
    submit: verify,
    res: verificationRes,
    isLoading: isVerifyLoad,
    err: verifyErr,
  } = useQuery<IEmailVerificationData, string>(verifyEmail);

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: { verificationCode: '' },
    validationSchema,
    onSubmit: () => {
      if (email) {
        verify({ email, verificationCode: values.verificationCode });
      }
    },
  });

  const resendVerificationCode = () => {
    if (email) {
      resend(email);
    }
  };

  /* const obscureEmail = (emailAddress: string): string => {
    const [name, domain] = emailAddress.split('@');
    return `${name[0]}${new Array(name.length).join('*')}@${domain}`;
  }; */

  useEffect((): void => {
    if (verificationRes) {
      setShowCongratulations(true);
      TrackGoogleAnalyticsEvent(UserCategory, ValidatedEmail, `email: ${email ?? 'Unknown'}`);
    }
  }, [verificationRes]);

  const returnToSignin = () => {
    storageClear();
    storageSet(LOCAL_STORAGE.lastUsedEmail, email ?? '');
    navigateTo(APP_ROUTES.signIn);
  };

  if (!email) return <Navigate replace to={APP_ROUTES.signUp} />;

  return (
    <Flex {...emailVerificationStyles.container}>
      <Modal
        isOpen={showingCongratuations}
        onClose={returnToSignin}
        onSubmit={returnToSignin}
        text="Your email has been verified.  We'll now redirect you to log in for the first time"
        submitButtonText='Go to Login'
      />

      <Box {...emailVerificationStyles.verificationBox}>
        <form onSubmit={handleSubmit}>
          <VStack {...emailVerificationStyles.contentBox}>
            <Heading>Email Verification</Heading>

            <Text {...emailVerificationStyles.textWrapper}>
              We have sent an email verification code to&nbsp;
              <Text as='b'>{email ?? ''}&nbsp;</Text>
              (if this email is incorrect, please recreate your account using your browser&apos;s
              back button).
            </Text>
            <Text {...emailVerificationStyles.textWrapper}>
              Check your junk mail folder if you haven&apos;t received it within 15 seconds.
            </Text>

            <TextInput
              id='verificationCode'
              name='verificationCode'
              label='Verification Code'
              onChange={handleChange}
              value={values.verificationCode}
              isError={!!errors.verificationCode && touched.verificationCode}
              errorMessage={errors.verificationCode}
              style={{ ...emailVerificationStyles.verificationCodeInput }}
            />

            <Button
              isLoading={isVerifyLoad}
              type='submit'
              {...emailVerificationStyles.verifyButton}
            >
              Verify email, return to Login
            </Button>

            <Button
              isLoading={isResendLoad}
              onClick={resendVerificationCode}
              {...emailVerificationStyles.resendButton}
            >
              click here to resend the code
            </Button>

            {verifyErr && (
              <Box {...emailVerificationStyles.textContainer}>
                <Text {...emailVerificationStyles.textError}>
                  {verifyErr?.message || 'unknown error'}
                </Text>
              </Box>
            )}

            {resendErr && (
              <Box {...emailVerificationStyles.textContainer}>
                <Text {...emailVerificationStyles.textError}>
                  {resendErr?.message || 'unknown error'}
                </Text>
              </Box>
            )}
          </VStack>
        </form>
      </Box>
    </Flex>
  );
}

export default EmailVerification;
