export const articleStyles = {
  articleContainer: {
    display: 'flex',
    width: { base: '342px', md: '376px' },
    padding: '0px 0px 24px 0px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
    flexShrink: '0',
    borderRadius: '8px',
    bg: 'var(--clickable-white, #FFF)',
    boxShadow: '0px 0px 24px 0px rgba(0, 0, 0, 0.08)',
  },

  articleThumbnail: {
    width: { base: '342px', md: '376px' },
    height: { base: '182px', md: '200px' },
    borderRadius: '8px 8px 0px 0px',
    bg: 'lightgray 50% / cover no-repeat',
  },

  tagsContainer: {
    padding: '0px 16px 0px 16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'leading',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch',
  },

  tagItem: {
    display: 'flex',
    padding: '8px',
    alignItems: 'flex-start',
    gap: '8px',
    borderRadius: '2px',
    background: 'var(--auxiliar-blue-light-blue, #D8E6EE)',
    color: 'var(--auxiliar-blue-dark-blue, #478FB8)',
    fontFamily: 'Lato',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
  },

  title: {
    padding: '0px 16px 0px 16px',
    display: 'flex',
    height: '58px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'stretch',
    color: 'var(--typography-dark, #202122)',
    fontFamily: 'Lato',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
  },

  subHeading: {
    padding: '0px 16px 0px 16px',
    alignSelf: 'stretch',
    color: 'var(--typography-medium, #4F5156)',
    fontFamily: 'Lato',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
  },
} as const;
