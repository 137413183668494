import React, { ReactElement, useEffect, RefObject } from 'react';

import { VStack, Text, Box } from '@chakra-ui/react';

import { getFamilyMemberSlotStatus as getStatus } from '@modules/core/aws/lambdas/profile';
import { useQuery, useAnchorScroll } from '@modules/core/hooks';
import { Section } from '@modules/common-ui/components/section';
import {
  IFamilyMembersSlotsStatus,
  FamilyMemberSlotType,
} from '@modules/core/aws/lambdas/profile/interfaces';

import FamilyInviteSlot from '@modules/common-ui/components/family-invite-slot';

import { profileFamilyManagementStyles } from '@modules/common-ui/components/profile-family-management/styles';

import { ANCHORS } from '@modules/core/constants';

function ProfileFamilyManagement(): ReactElement {
  const ref: RefObject<HTMLDivElement> = useAnchorScroll(ANCHORS.familyManagement);
  const { submit, isLoading, res } = useQuery<void, IFamilyMembersSlotsStatus>(getStatus);

  useEffect((): void => {
    submit();
  }, []);

  return (
    <Box ref={ref} {...profileFamilyManagementStyles.container}>
      <Section title='Family Plan Management' isLoading={isLoading}>
        <Box {...profileFamilyManagementStyles.textBox}>
          <Text>
            You have a family plan, which entitles you to invite family members to use your
            subscription. You have up to
            <Text {...profileFamilyManagementStyles.subText}>
              &nbsp; {res?.familyMemberSlots.length} family members &nbsp;
            </Text>
            you may add
          </Text>
        </Box>

        <VStack {...profileFamilyManagementStyles.invitesContainer}>
          {res?.familyMemberSlots.map((slotData: FamilyMemberSlotType) => (
            <FamilyInviteSlot inviteData={slotData} key={slotData.familyMemberSlot} />
          ))}
        </VStack>
      </Section>
    </Box>
  );
}

export default ProfileFamilyManagement;
