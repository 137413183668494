import React, { ReactElement, useEffect } from 'react';
import { useNavigate, NavigateFunction } from 'react-router-dom';

import { FreeIcon } from '@modules/common-ui/icons';

import { setFreePlan } from '@app/modules/core/aws/lambdas/payments';
import { useQuery, useAuth } from '@app/modules/core/hooks';
import { ISetFreePlanRes } from '@app/modules/core/aws/lambdas/payments/interfaces';
import { IAuthContext } from '@modules/core/contexts/auth/interfaces';
import { IFreePricingCardProps } from './interfaces';
import PricingCard from './PricingCard';

import { APP_ROUTES, LICENSE_STATUSES } from '@app/modules/core/constants';
import { TrackGoogleAnalyticsEvent } from '@app/modules/core/google-analytics/google-analytics-tracker';
import UserCategory, {
  SubscriptionPlanSelected,
} from '@app/modules/core/google-analytics/events/signupGAEvent';

function FreePricingCard({ planName, features }: IFreePricingCardProps): ReactElement {
  const navigateTo: NavigateFunction = useNavigate();
  const { user }: IAuthContext = useAuth();
  const { submit, res, isLoading, err } = useQuery<void, ISetFreePlanRes>(setFreePlan);

  const isCardDisabled = (): boolean => {
    return (
      user?.licenseStatus === LICENSE_STATUSES.individual ||
      user?.licenseStatus === LICENSE_STATUSES.family
    );
  };

  useEffect((): void => {
    if (res) {
      navigateTo(APP_ROUTES.appDownloadOnboarding);
    }
  }, [res]);

  return (
    <PricingCard
      submitCallback={() => {
        TrackGoogleAnalyticsEvent(UserCategory, SubscriptionPlanSelected, `Plan: Free`);
        submit();
      }}
      isDisabled={isCardDisabled()}
      isError={!!err}
      errorMessage={err?.message}
      heading={planName ?? 'Account Agent'}
      features={features}
      icon={FreeIcon}
      isActionLoading={isLoading}
    />
  );
}

export default FreePricingCard;
