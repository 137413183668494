export const paymentFormStyles = {
  button: {
    width: '100%',
    size: 'lg',
    color: 'white',
    backgroundColor: '#005455',

    _hover: {
      backgroundColor: '#308485',
    },
  },

  mainContainer: {
    spacing: '10',
  },

  paymentElementsBox: {
    width: '100%',
  },
};

export const paymentStatusStyles = {
  heading: {
    color: 'blackAlpha.700',
  },

  errorButton: {
    size: 'lg',
    color: 'white',
    backgroundColor: '#FF6D74',
    width: '240px',

    _hover: {
      backgroundColor: '#DB4F62',
    },
  },

  button: {
    size: 'lg',
    color: 'white',
    backgroundColor: 'brand.green.100',

    _hover: {
      backgroundColor: 'brand.green.200',
    },
  },
};
