import React, { useState, ReactElement, ChangeEvent } from 'react';

import { Text, Flex, VStack, Heading } from '@chakra-ui/react';

import { IQuestionBasicProps } from './interfaces';
import { SelectInput } from '../text-field';

import { questionStyles } from './styles';

function DropdownQuestion({ question, callback, answers }: IQuestionBasicProps): ReactElement {
  const oldAnswer: string | undefined = answers[question.FieldName];
  const [value, setValue] = useState<string>(oldAnswer || '');

  const onChangeHandler = (event: ChangeEvent<HTMLSelectElement>): void => {
    event.preventDefault();
    setValue(event.target.value);
    callback(question.FieldName, event.target.value);
  };

  return (
    <Flex {...questionStyles.questionContainer}>
      <VStack {...questionStyles.questionTextBox}>
        <Heading>{question.DisplayTitle}</Heading>

        {question.HelperText && <Text>{question.HelperText}</Text>}
      </VStack>

      <SelectInput
        placeholder='Select option'
        value={value}
        onChange={onChangeHandler}
        options={question.VariableOptions || []}
        style={{ ...questionStyles.questionFieldMargin }}
      />
    </Flex>
  );
}

export default DropdownQuestion;
