import React, { ChangeEvent, ReactElement, useRef, useEffect } from 'react';

import {
  Input,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  FormErrorMessage,
} from '@chakra-ui/react';
import { AttachmentIcon } from '@chakra-ui/icons';

import { encryptFile } from '@modules/core/crypto';
import { useFileReader } from '@modules/core/hooks';
import { resizeFile } from '@modules/core/lvedHelpers';
import { IThumbnailUploadInputProps } from '@modules/common-ui/components/text-field/interfaces';

import { inputStyles } from '@modules/common-ui/components/text-field/styles';

import { FILE_TYPES } from '@modules/core/constants';

function ThumbnailUploadInput({
  label,
  id,
  name,
  errorMessage,
  encryptionKey,
  variant = 'outline',
  size = 'lg',
  isError = false,
  required = false,
  isStorageEncrypted = false,
  style = {},
  setValue,
}: IThumbnailUploadInputProps): ReactElement {
  const inputRef = useRef<HTMLInputElement>(null);
  const { file, fileContent, setFile } = useFileReader({ format: 'binary' });

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files?.length) {
      setFile(event.target.files[0]);
    }
  };

  useEffect(() => {
    if (fileContent && setValue) {
      resizeFile(fileContent, 350000).then((buffer: ArrayBuffer) => {
        if (isStorageEncrypted && encryptionKey) {
          return encryptFile(buffer, encryptionKey).then((fileData: string) => setValue(fileData));
        }

        return setValue(buffer);
      });
    }
  }, [fileContent]);

  return (
    <FormControl {...style} isInvalid={isError} isRequired={required}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}

      <InputGroup>
        <InputLeftElement {...inputStyles.attachmentIconBox}>
          <AttachmentIcon {...inputStyles.attachmentIcon} />
        </InputLeftElement>

        <Input
          name={name}
          onChange={onChangeHandler}
          type='file'
          accept={FILE_TYPES.image}
          ref={inputRef}
          style={{ display: 'none' }}
        />

        <Input
          readOnly
          id={id}
          variant={variant}
          size={size}
          onClick={() => inputRef.current?.click()}
          placeholder='The max size is 350 Kb. A larger image will be compressed'
          value={file?.name || ''}
        />
      </InputGroup>

      {isError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
}

export default ThumbnailUploadInput;
