export const digitalVaultStyles = {
  container: {
    padding: '80px',
    color: 'brand.text',
  },

  assetBox: {
    width: '212px',
    height: '212px',
    backgroundColor: 'white',
    margin: '5px',
  },

  skeleton: {
    width: '212px',
    height: '212px',
  },

  assetsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  sectionLayoutContentBox: {
    spacing: '10',
    alignItems: 'flex-start',
  },
} as const;
