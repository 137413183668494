import React, { ReactElement } from 'react';

import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputLeftElement,
  InputGroup,
  NumberInput as Input,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
} from '@chakra-ui/react';

import { ConditionSeparator } from '@modules/common-ui/components/condition-separator';
import { INumberInputProps } from './interfaces';

import { inputStyles } from './styles';

function NumberInput({
  label,
  id,
  errorMessage,
  style = {},
  variant = 'outline',
  size = 'lg',
  isError = false,
  required = false,
  isCurrency = false,
  value,
  setValue,
  ...rest
}: INumberInputProps): ReactElement {
  return (
    <FormControl {...style} isInvalid={isError} isRequired={required}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}

      <InputGroup size={size}>
        <ConditionSeparator
          condition={isCurrency}
          target={
            <InputLeftElement>
              <Text>$</Text>
            </InputLeftElement>
          }
        />

        <Input
          min={0}
          w='100%'
          variant={variant}
          size={size}
          id={id}
          onChange={(_, val) => setValue(val)}
          value={value}
          {...rest}
        >
          <NumberInputField {...(isCurrency && inputStyles.currencyInput)} />

          <NumberInputStepper>
            <NumberIncrementStepper />

            <NumberDecrementStepper />
          </NumberInputStepper>
        </Input>
      </InputGroup>

      {isError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
}

export default NumberInput;
