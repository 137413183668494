export const digitalVaultSlideStyles = {
  slide: {
    zIndex: 10,
    width: '30%',
    height: '100%',
  },

  mainContainer: {
    height: '100%',
    backgroundColor: 'white',
    padding: '40px',
    boxShadow: '0px -1px 60px rgba(81, 81, 81, 0.1)',
    color: 'brand.text',
    position: 'relative',
  },

  templateNamesFlexBox: {
    flexWrap: 'wrap',
  },

  iconBox: {
    position: 'absolute',
    top: '20px',
    left: '20px',
    cursor: 'pointer',
  },

  backIcon: {
    boxSize: '6',
  },

  templateNameHeader: {
    size: 'md',
    as: 'h4',
  },

  slideChildContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },

  assetForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    paddingTop: '30px',
  },

  templateDetailsContentBox: {
    width: 'inherit',
    spacing: '5',
  },

  chooseTemplateContainer: {
    spacing: '5',
  },

  slideChildContentBox: {
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    paddingTop: '30px',
  },

  assetInputsContainer: {
    width: '100%',
    spacing: '5',
  },

  noTemplatesBox: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'gray.300',
    spacing: '10',
  },

  noTemplatesIcon: {
    width: '75%',
  },

  templateBox: {
    width: '130px',
    height: '130px',
    backgroundColor: 'brand.floralWhite',
    margin: '10px',
  },

  activeTemplateBox: {
    backgroundColor: 'brand.gray.50',
  },

  button: {
    backgroundColor: 'brand.green.100',
    color: 'white',
    width: 'full',
    size: 'md',

    _hover: {
      backgroundColor: 'brand.green.200',
    },
  },

  modalContainer: {
    spacing: '5',
  },
} as const;
