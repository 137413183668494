import React, { useState, useEffect, ReactElement, FC, createElement } from 'react';
import { Location, useLocation, useNavigate, NavigateFunction } from 'react-router-dom';

import { Heading, VStack, Box, Button } from '@chakra-ui/react';
import { useStripe } from '@stripe/react-stripe-js';
import { Stripe, PaymentIntentResult } from '@stripe/stripe-js';

import { useAuth } from '@modules/core/hooks';
import { IAuthContext } from '@modules/core/contexts/auth/interfaces';
import { ConditionSeparator } from '@modules/common-ui/components/condition-separator';
import {
  PaymentErrorIcon,
  PaymentProcessingIcon,
  PaymentStatusLoadingIcon,
  PaymentSuccessIcon,
} from '@modules/common-ui/icons';

import { paymentStatusStyles } from './styles';

import {
  REDIRECT_SEARCH_PARAMS,
  PAYMENT_STATUS,
  PAYMENT_STATUS_MESSAGE,
  APP_ROUTES,
} from '@modules/core/constants';
import { TrackGoogleAnalyticsEvent } from '@app/modules/core/google-analytics/google-analytics-tracker';
import UserCategory, {
  SubscribedCompletedAction,
} from '@app/modules/core/google-analytics/events/signupGAEvent';

const iconsGenerator = (message: PAYMENT_STATUS_MESSAGE): FC => {
  return (
    {
      [PAYMENT_STATUS_MESSAGE.loading]: PaymentStatusLoadingIcon,
      [PAYMENT_STATUS_MESSAGE.success]: PaymentSuccessIcon,
      [PAYMENT_STATUS_MESSAGE.processing]: PaymentProcessingIcon,
      [PAYMENT_STATUS_MESSAGE.unknown]: PaymentErrorIcon,
      [PAYMENT_STATUS_MESSAGE.requiresPaymentMethod]: PaymentErrorIcon,
    }[message] || PaymentErrorIcon
  );
};

function PaymentStatus(): ReactElement {
  const stripe: Stripe | null = useStripe();

  const { reloadUser, isAuthenticating }: IAuthContext = useAuth();
  const { search }: Location = useLocation();
  const secret: string | null = new URLSearchParams(search).get(REDIRECT_SEARCH_PARAMS.secret);

  const [message, setMessage] = useState<PAYMENT_STATUS_MESSAGE>(
    (): PAYMENT_STATUS_MESSAGE =>
      secret ? PAYMENT_STATUS_MESSAGE.loading : PAYMENT_STATUS_MESSAGE.unknown
  );

  const navigateTo: NavigateFunction = useNavigate();
  const openPaymentPage = (): void => navigateTo(-1);
  const continueOnboardingPage = (): void => {
    reloadUser().then(() => navigateTo(APP_ROUTES.appDownloadOnboarding, { replace: true }));
  };

  const isErrorOccurred = (): boolean => {
    return (
      message === PAYMENT_STATUS_MESSAGE.unknown ||
      message === PAYMENT_STATUS_MESSAGE.requiresPaymentMethod
    );
  };

  const isButtonDisabled = (): boolean => {
    return message === PAYMENT_STATUS_MESSAGE.loading;
  };

  const getPaymentIntentRes = async (): Promise<PaymentIntentResult | null> => {
    if (!(stripe && secret)) return null;

    try {
      return await stripe.retrievePaymentIntent(secret);
    } catch {
      return null;
    }
  };

  useEffect(() => {
    if (stripe) {
      getPaymentIntentRes().then((res: PaymentIntentResult | null) => {
        switch (res?.paymentIntent?.status) {
          case PAYMENT_STATUS.success:
            setMessage(PAYMENT_STATUS_MESSAGE.success);
            TrackGoogleAnalyticsEvent(
              UserCategory,
              SubscribedCompletedAction,
              `Amt (cents): ${res.paymentIntent.amount}`
            );
            break;

          case PAYMENT_STATUS.processing:
            setMessage(PAYMENT_STATUS_MESSAGE.processing);
            break;

          case PAYMENT_STATUS.requiresPaymentMethod:
            setMessage(PAYMENT_STATUS_MESSAGE.requiresPaymentMethod);
            break;

          default:
            setMessage(PAYMENT_STATUS_MESSAGE.unknown);
            break;
        }
      });
    }
  }, [stripe]);

  return (
    <VStack>
      <Heading {...paymentStatusStyles.heading}>{message}</Heading>

      <Box>{createElement(iconsGenerator(message))}</Box>

      <Box>
        <ConditionSeparator
          condition={isErrorOccurred()}
          target={
            <Button onClick={openPaymentPage} {...paymentStatusStyles.errorButton}>
              Go Back
            </Button>
          }
          defaultTarget={
            <Button
              isLoading={isAuthenticating}
              isDisabled={isButtonDisabled()}
              onClick={continueOnboardingPage}
              {...paymentStatusStyles.button}
            >
              Back to the application
            </Button>
          }
        />
      </Box>
    </VStack>
  );
}

export default PaymentStatus;
