import React, { ReactElement } from 'react';

import { Image } from '@chakra-ui/react';

import { ICommonIconProps } from './interfaces';
import lvedLogo from '@assets/images/lvedLogoWhite.svg';

/* <Icon
      width={width}
      height={height}
      viewBox='0 0 170 100'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M40.3431 24.8848V54.9178H59.0485V61.2567H33.4844V24.8848H40.3431Z' fill='#8ABB2A' />
      <path
        d='M52.0791 20.5449H53.8927C53.7298 20.914 53.5885 21.2835 53.4583 21.6637L53.0349 22.8583L52.0791 20.5449Z'
        fill='#5A5B5E'
      />
      <path
        d='M80.821 61.413H73.7024L58.582 24.8848H66.2719L77.236 53.2034L88.251 24.8848H95.9414L80.821 61.413Z'
        fill='#8ABB2A'
      />
      <rect x='102.644' y='24.8887' width='27.2265' height='6.64648' fill='#005589' />
      <path
        d='M109.453 46.295H129.142V39.8984H102.644V61.2603H129.87V54.5052H109.453V46.295Z'
        fill='#8ABB2A'
      />
      <path
        d='M169.776 42.7596C169.931 51.9561 164.32 61.2567 151.59 61.2567H137.301V24.8848H151.59C164.06 24.8848 169.62 33.7698 169.776 42.7596ZM144.107 54.6579H151.59C159.851 54.6579 163.125 48.6303 162.969 42.707C162.813 37.0435 159.488 31.38 151.59 31.38H144.107V54.6579Z'
        fill='#8ABB2A'
      />
      <path
        d='M80.1741 65.7559C77.3072 68.6987 74.4508 71.4465 71.8116 73.9444C69.4877 76.1492 67.0984 78.3534 64.7855 80.4712C61.9291 83.0993 58.9752 85.8255 56.141 88.5511C56.1188 88.5728 56.0867 88.6055 56.0645 88.6271C55.9781 86.629 55.6196 84.6092 54.9785 82.6433C54.718 81.7528 54.4033 80.8951 54.0664 80.0584C55.9343 78.2991 57.8022 76.5725 59.6379 74.8786C61.9618 72.7387 64.2857 70.5993 66.5775 68.4272C67.5007 67.5478 68.4343 66.6574 69.3791 65.7559H80.1741Z'
        fill='#005589'
      />
      <path
        d='M98.5825 20.5461H90.4809C90.1662 19.8075 89.8077 19.0695 89.406 18.3524C87.5159 15.0184 84.6163 12.2274 81.2279 10.4786C77.818 8.73042 73.8218 8.00288 69.9879 8.42622C66.2084 8.83903 62.5164 10.4027 59.5841 12.8463C57.0862 14.8987 55.1424 17.5379 53.8934 20.5461C53.7305 20.9152 53.5892 21.2848 53.459 21.6649L53.0351 22.8595L50.0053 31.5473L46.3127 21.7519C45.9764 20.8609 45.6179 19.9377 45.1835 19.0911C43.0551 14.9314 39.3953 11.5541 35.1159 9.79486C32.7488 8.81742 30.1639 8.31762 27.5685 8.31762C26.2109 8.31762 24.8423 8.44783 23.5069 8.73042C19.9773 9.44743 16.5889 11.2283 13.9392 13.7368C11.3975 16.1261 9.48641 19.2972 8.38928 22.9244C7.90056 24.4886 7.65121 26.0091 7.64013 27.4641V27.616C7.55313 29.3536 7.61852 31.037 7.81412 32.6334L5.76116 40.659C5.1317 43.1026 5.10954 45.4703 5.15331 47.6202C4.36039 46.1541 3.63285 44.6663 3.01391 43.1674C2.8294 42.7009 2.67702 42.3313 2.52519 41.9734C2.2426 41.3323 1.92787 40.6047 1.66689 39.8118C0.396325 36.0433 -0.157225 31.8404 0.0383742 27.3339C0.0599842 25.1618 0.417936 22.9244 1.11334 20.6874C2.59002 15.8218 5.21814 11.4998 8.71509 8.22009C12.4187 4.70153 17.0122 2.30115 21.9754 1.28049C27.3297 0.172837 33.0308 0.693696 38.0156 2.76826C42.7183 4.70153 46.8884 8.0461 49.864 12.2163C51.2542 10.2725 52.8833 8.51321 54.7296 6.99275C58.7912 3.61549 63.9172 1.4434 69.1623 0.867684C74.4512 0.281442 79.979 1.29102 84.7033 3.72409C89.406 6.14553 93.4238 10.0121 96.0303 14.6167L96.0414 14.6494C97.1163 16.5716 97.9635 18.548 98.5825 20.5461Z'
        fill='#005589'
      />
      <path
        d='M50.8242 83.9306C49.004 77.6027 44.2093 72.7271 39.5643 68.3065C35.7459 64.8733 32.1021 61.3586 28.7781 57.512C28.6678 59.7135 29.1482 61.9482 30.2121 63.8908C31.7625 66.9334 34.6261 69.1426 36.1233 72.1775C36.7311 73.187 36.3388 74.3728 36.2352 75.4395C20.2216 61.1962 13.5701 49.7163 11.1609 44.6119C10.8057 43.6339 10.4139 42.6731 9.96733 41.7422C9.32568 44.2434 9.48637 46.8089 9.54899 49.3068C9.74458 50.9043 9.88754 52.494 10.1973 54.0544C12.9794 65.9338 20.5507 76.5217 30.6315 83.3172C37.2315 87.3245 43.9882 91.7335 48.2509 98.3694C48.3413 98.4913 48.5662 98.795 48.702 98.9773C52.0305 94.765 52.4705 88.8804 50.8242 83.9306Z'
        fill='#8ABB2A'
      />
      <path
        d='M38.7917 91.3967C34.248 87.7479 28.8893 85.2228 24.5435 81.333C19.4296 76.7888 14.9863 71.3702 12.0684 65.1504C12.1387 66.1339 12.2701 67.0731 12.6818 67.9453C14.5275 72.2762 16.8209 76.5101 20.1705 79.8197C26.0994 86.1615 34.2226 89.7454 41.0203 94.9723C42.7314 96.1996 44.2369 97.7206 45.9402 99.0012C44.2995 95.8372 41.4885 93.6352 38.7917 91.3967Z'
        fill='#005589'
      />
    </Icon>
    */
function LogoIcon({ width = '170', height = '100' }: ICommonIconProps): ReactElement {
  return <Image width={width} height={height} src={lvedLogo} />;
}

export default LogoIcon;
