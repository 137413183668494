import React, { useState, useEffect, useRef } from 'react';
import './index.css';
import { VStack, Text, Box, Button, Input, Flex, HStack } from '@chakra-ui/react';
import {
  getFinalMessage,
  rejectFinalMessage,
  recordMessagePlayed,
} from '@app/modules/core/aws/lambdas/final-messages';
import {
  IFinalMessageRequest,
  IFinalMessage,
} from '@app/modules/core/aws/lambdas/final-messages/interfaces';
import { reviewFinalMessageStyles } from './styles';
import { getAgeDescription } from '@app/modules/core/lvedHelpers/commontools';
import UnAuthHeader from '@app/modules/common-ui/components/unAuthHeader/UnAuthHeader';
import UnAuthFooter from '@app/modules/common-ui/components/unAuthFooter/UnAuthFooter';

function isMobileDevice(): boolean {
  return navigator.maxTouchPoints > 0;
}

function FinalMessage() {
  const [finalMessage, setFinalMessage] = useState<IFinalMessage>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const videoPlayer = useRef<HTMLVideoElement>(null);
  const [errorString, setErrorString] = useState<string | null>(null);

  const queryParameters = new URLSearchParams(window.location.search);
  const identityId = queryParameters.get('id');
  const messageId = queryParameters.get('messageId');
  const accessKey = queryParameters.get('accessKey');
  const isMobileAccessKey = accessKey?.charAt(0) === 'm';
  const [shouldReject, setShouldReject] = useState<boolean>(
    queryParameters.get('reject') === 'true'
  );

  const messageParams = {
    identityId: identityId!,
    finalMessageId: messageId!,
    accessKey: accessKey!,
  } as IFinalMessageRequest;

  useEffect(() => {
    if (!(!!identityId && !!messageId && !!accessKey)) {
      // console.log('Nothing to do, requies an identityId, messageId, and accessKey');
      return;
    }
    // console.log('Attempting to retrieve message');
    setIsLoading(true);
    getFinalMessage(messageParams, isMobileDevice())
      .then((finalMessageResponse: IFinalMessage) => {
        setFinalMessage(finalMessageResponse);
        // console.log('Success, result: ');
        // console.log(finalMessage);
      })
      .catch((err) => {
        // console.log(`Unable to retrieve message: ${err.message}`);
        setErrorString(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []); // , [refreshes]

  function videoPlayed() {
    // console.log(`Video played at: ${videoPlayer.current?.currentTime}`);
    if (videoPlayer.current && videoPlayer.current?.currentTime < 0.1) {
      recordMessagePlayed(messageParams).then((updatedViewCount: number) => {
        finalMessage!.viewCount = updatedViewCount;
      });
    }
  }

  const [rejectDetailInput, setRejectDetailInput] = useState<string>('');
  const handleReject = () => {
    // console.log(event);
    // console.log(rejectDetailInput);
    setIsLoading(true);
    rejectFinalMessage(messageParams, rejectDetailInput)
      .then((wasSuccess) => {
        if (wasSuccess) {
          setErrorString('Thank you for your feedback.');
        } else {
          setErrorString('Unable to reject this message, please try again later');
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (errorString) {
    return (
      <VStack {...reviewFinalMessageStyles.pageBackground}>
        <UnAuthHeader activeLink='None' />
        <HStack h='100px' />
        <Text {...reviewFinalMessageStyles.textError}>{errorString}</Text>
        <UnAuthFooter />
      </VStack>
    );
  }

  if (shouldReject && finalMessage) {
    return (
      <VStack {...reviewFinalMessageStyles.pageBackground}>
        <UnAuthHeader activeLink='None' />
        <HStack h='100px' />
        <Text {...reviewFinalMessageStyles.titleText}>
          This message from {finalMessage!.senderName} was prepared for:
        </Text>
        <Box border='1px' margin='5px'>
          <Text fontSize='24px' margin='5px'>
            {`${finalMessage!.recipient['First Name']} ${finalMessage!.recipient['Last Name']}`}
          </Text>
          {!isMobileAccessKey ? (
            <Text fontSize='24px' margin='5px'>
              Email: {finalMessage!.recipient.Email}
            </Text>
          ) : (
            <Text fontSize='24px' margin='5px'>
              Mobile number: {finalMessage!.recipient['Mobile Number']}
            </Text>
          )}
        </Box>
        <Text {...reviewFinalMessageStyles.detailText}>
          If this is not you, please do our community a favor by confirming below and, optionally,
          providing any context you may have to help us contact{' '}
          {finalMessage!.recipient['First Name']}.
        </Text>
        <Input
          id='rejectDetail'
          type='text'
          placeholder='Helpful details'
          {...reviewFinalMessageStyles.detailsInput}
          onChange={(event) => {
            setRejectDetailInput(event.target.value);
          }}
        />
        <Flex justify='space-between' wrap='wrap' gap='20px'>
          <Button
            w='160px'
            flexGrow='1'
            onClick={handleReject}
            isLoading={isLoading}
            {...reviewFinalMessageStyles.rejectMessageButton}
          >
            Yes, reject message
          </Button>
          <Button
            w='160px'
            flexGrow='1'
            onClick={() => {
              setShouldReject(false);
            }}
            {...reviewFinalMessageStyles.cancelButton}
          >
            Cancel
          </Button>
        </Flex>
        <UnAuthFooter />
      </VStack>
    );
  }

  if (finalMessage) {
    return (
      <VStack {...reviewFinalMessageStyles.pageBackground}>
        <UnAuthHeader activeLink='None' />
        <HStack h='100px' />
        <Text {...reviewFinalMessageStyles.titleText}>
          A final message from {finalMessage!.senderName}
        </Text>
        <Text fontSize='24px'>
          Prepared for:
          {` ${finalMessage!.recipient['First Name']} ${finalMessage!.recipient['Last Name']}`}
        </Text>

        <Box h='10px' />

        <video
          className='finalMessageVideo'
          src={finalMessage.messageURL}
          ref={videoPlayer}
          onPlay={videoPlayed}
          poster={
            finalMessage.base64MessageThumbnail
              ? `data:image/jpeg;base64,${finalMessage.base64MessageThumbnail!}`
              : ``
          }
          controls
        >
          <track src='' kind='captions' />
        </video>

        <Text fontSize='14px'>
          {`Captured ${getAgeDescription(finalMessage!.capturedDate)} on
          ${finalMessage!.capturedDate.toDateString()}`}
        </Text>
        {finalMessage && <Text>{finalMessage!.viewCount || 0} views</Text>}

        {finalMessage.canBeRejected && (
          <Button
            w='300px'
            onClick={() => {
              setShouldReject(true);
            }}
            {...reviewFinalMessageStyles.rejectMessageButton}
          >
            This message isn&apos;t for me
          </Button>
        )}
        <UnAuthFooter />
      </VStack>
    );
  }

  return (
    <VStack {...reviewFinalMessageStyles.pageBackground}>
      <UnAuthHeader activeLink='None' />
      <HStack h='100px' />
      <Text>
        {isLoading && 'Loading...'}
        {!isLoading && 'Error with request, please try again later'}
      </Text>
      <UnAuthFooter />
    </VStack>
  );
}

export default FinalMessage;
