import * as Yup from 'yup';

import {
  PASSWORD_VALIDATION_ERRORS,
  EMAIL_VALIDATION_ERRORS,
  // PHONE_VALIDATION_ERRORS,
  ACCEPT_TERMS_VALIDATION_ERRORS,
  // ACCEPT_SMS_VALIDATION_ERRORS,
  LAST_NAME_VALIDATION_ERRORS,
  FIRST_NAME_VALIDATION_ERRORS,
} from '@modules/core/constants';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required(EMAIL_VALIDATION_ERRORS.required)
    .email(EMAIL_VALIDATION_ERRORS.invalid),

  /* phoneNumber: Yup.string()
    .trim()
    .required(PHONE_VALIDATION_ERRORS.required)
    .matches(/^(\+1\s[0-9]{3}-[0-9]{3}-[0-9]{4})$/m, PHONE_VALIDATION_ERRORS.invalid),
  */
  givenName: Yup.string()
    .trim()
    .required(FIRST_NAME_VALIDATION_ERRORS.required)
    .matches(/^[A-Za-z ,.'-]+$/, FIRST_NAME_VALIDATION_ERRORS.invalid),

  familyName: Yup.string()
    .trim()
    .required(LAST_NAME_VALIDATION_ERRORS.required)
    .matches(/^[A-Za-z ,.'-]+$/, LAST_NAME_VALIDATION_ERRORS.invalid),

  password: Yup.string()
    .required(PASSWORD_VALIDATION_ERRORS.required)
    .min(7, PASSWORD_VALIDATION_ERRORS.min)
    .matches(/[0-9]/, PASSWORD_VALIDATION_ERRORS.oneNumber)
    .matches(/[a-z]/, PASSWORD_VALIDATION_ERRORS.oneLowerCase)
    .matches(/[A-Z]/, PASSWORD_VALIDATION_ERRORS.oneUpperCase)
    .matches(/[^\w]/, PASSWORD_VALIDATION_ERRORS.oneSymbol),

  passwordCheck: Yup.string()
    .required(PASSWORD_VALIDATION_ERRORS.checkRequired)
    .oneOf([Yup.ref('password')], PASSWORD_VALIDATION_ERRORS.matching),

  acceptedTerms: Yup.boolean()
    .required(ACCEPT_TERMS_VALIDATION_ERRORS.required)
    .oneOf([true], ACCEPT_TERMS_VALIDATION_ERRORS.invalid),

  /* acceptedSMS: Yup.boolean()
    .required(ACCEPT_SMS_VALIDATION_ERRORS.required)
    .oneOf([true], ACCEPT_SMS_VALIDATION_ERRORS.invalid),
  */
});

export default validationSchema;
