import React, { ReactElement } from 'react';

import { Elements } from '@stripe/react-stripe-js';

import { getStripe } from '@modules/core/stripe';

import { IStripeContainerProps } from './interfaces';

function StripeContainer({ children, ...rest }: IStripeContainerProps): ReactElement {
  return (
    <Elements stripe={getStripe()} {...rest}>
      {children}
    </Elements>
  );
}

export default StripeContainer;
