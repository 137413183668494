export enum LICENSE_STATUSES {
  family = 'Family Plan',
  individual = 'Individual Plan',
  free = 'Free Plan',
  notSelected = 'None',
}

export enum LICENSE_RENEWAL {
  monthly = 'Monthly',
  annual = 'Annual',
  never = 'Never',
}

export enum PRODUCTBILLING {
  annual = 'Annual billing',
  monthly = 'Monthly billing',
}
