export const questionStyles = {
  thumbnail: {
    boxSize: '150px',
    objectFit: 'contain',
    margin: '15px',
  },

  attachmentIcon: {
    boxSize: '4',
    color: 'gray.500',
  },

  attachmentIconBox: {
    height: '100%',
  },

  questionContainer: {
    alignItems: 'center',
    flexDirection: 'column',
  },

  questionTextBox: {
    spacing: '2',
    textAlign: 'center',
  },

  questionFieldMargin: {
    marginTop: '64px',
  },

  numberInputBox: {
    width: '50%',
  },

  boolButtonDefault: {
    width: '164px',
    height: '58px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'brand.green.100',
    borderColor: 'brand.green.100',
    borderStyle: 'solid',
    borderRadius: '29px',
    backgroundColor: 'brand.floralWhite',
    _hover: {
      backgroundColor: 'brand.floralWhite',
    },
  },

  boolRightButton: {
    marginLeft: '24px',
  },

  errorText: {
    color: 'brand.error',
  },
} as const;
