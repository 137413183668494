export const paymentsHistoryStyles = {
  spinnerBox: {
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },

  spinner: {
    thickness: '4px',
    speed: '0.65s',
    emptyColor: 'gray.200',
    color: 'brand.green.100',
    size: 'xl',
  },

  tableContainer: {
    justifyContent: 'space-between',
    alignItems: 'normal',
    width: '100%',
    backgroundColor: 'white',
    borderRadius: '20px',
    padding: '20px',
    minH: '400px',
    color: 'blackAlpha.700',
  },

  paginationButton: {
    alignItems: 'center',
    cursor: 'pointer',
  },

  paginationBox: {
    justifyContent: 'space-between',
  },
};
