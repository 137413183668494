import React, { ReactElement } from 'react';

import { Flex, Text, Input, InputGroup, InputLeftElement, Stack } from '@chakra-ui/react';

import { IFilterProps } from './interfaces';

import { filterNavStyles } from './styles';
import { SearchIcon } from '@chakra-ui/icons';

export default function ArticleFilter({ tagFilter, contentFilter }: IFilterProps): ReactElement {
  const [tag, setTag] = tagFilter;
  const [content, setContent] = contentFilter;
  return (
    <Flex {...filterNavStyles.filterContainer}>
      <Flex {...filterNavStyles.filterTagContainer}>
        {tag.length === 0 && <Text {...filterNavStyles.selectedTag}>All articles</Text>}
        {tag.length !== 0 && (
          <Text
            {...filterNavStyles.unSelectedTag}
            onClick={() => {
              setTag('');
            }}
          >
            All articles
          </Text>
        )}
        {tag === 'Guides' && <Text {...filterNavStyles.selectedTag}>Guides</Text>}
        {tag !== 'Guides' && (
          <Text
            {...filterNavStyles.unSelectedTag}
            onClick={() => {
              setTag('Guides');
            }}
          >
            Guides
          </Text>
        )}
        {tag === 'News' && <Text {...filterNavStyles.selectedTag}>News</Text>}
        {tag !== 'News' && (
          <Text
            {...filterNavStyles.unSelectedTag}
            onClick={() => {
              setTag('News');
            }}
          >
            News
          </Text>
        )}
        {tag === 'Tutorials' && <Text {...filterNavStyles.selectedTag}>Tutorials</Text>}
        {tag !== 'Tutorials' && (
          <Text
            {...filterNavStyles.unSelectedTag}
            onClick={() => {
              setTag('Tutorials');
            }}
          >
            Tutorials
          </Text>
        )}
      </Flex>
      <Stack>
        <InputGroup>
          <InputLeftElement pointerEvents='none'>
            <SearchIcon color='gray.300' />
          </InputLeftElement>
          <Input
            {...filterNavStyles.searchContainer}
            placeholder='Search articles'
            value={content}
            onChange={(newValue) => {
              setContent(newValue.currentTarget.value);
            }}
          />
        </InputGroup>
      </Stack>
    </Flex>
  );
}
