import { lambdaGeneralCaller } from '@modules/core/aws/lambdas/lambda-general-caller/lambdaGeneralCaller';

import { AWS_LAMBDAS } from '@modules/core/constants';
import {
  IGetAffiliateLambdaResponse,
  IGetAffiliateResponse,
  IUnAuthCredentials,
} from './interfaces';

async function getAffiliateProfile(userName: String): Promise<IGetAffiliateResponse> {
  const credentials: IUnAuthCredentials = {
    accessKeyId: process.env.REACT_APP_AWS_UNAUTHENTICATED_ACCESS_KEY_ID || 'NONE',
    secretAccessKey: process.env.REACT_APP_AWS_UNAUTHENTICATED_SECRET_ACCESS_KEY || 'NONE',
  };

  const res = await lambdaGeneralCaller<IGetAffiliateLambdaResponse>(credentials, {
    FunctionName: AWS_LAMBDAS.getAffiliateProfile,
    Payload: JSON.stringify({
      userName,
    }),
  });

  if (res.logo) {
    const arrayBuffer = new ArrayBuffer(res.logo.data.length);
    const view = new Uint8Array(arrayBuffer);
    for (let i = 0; i < res.logo.data.length; ++i) {
      view[i] = res.logo.data[i];
    }
    return {
      ...res,
      affiliateName: res.entityName,
      logo: arrayBuffer,
    } as IGetAffiliateResponse;
  }

  return {
    ...res,
    affiliateName: res.entityName,
  } as IGetAffiliateResponse;
}

export default getAffiliateProfile;
