import React, { ReactElement, useEffect } from 'react';

import { Box, Flex, Heading, VStack, Button } from '@chakra-ui/react';
import { isEqual } from 'lodash';

import { useQuery } from '@modules/core/hooks';
import { getTemplateNames } from '@modules/core/aws/dynamo-db/digital-vault';
import { ITemplateNameProps } from '@modules/common-ui/components/slide/digital-vault/interfaces';
import { ConditionSeparator } from '@modules/common-ui/components/condition-separator';
import { Loader } from '@modules/common-ui/components/loader';
import { NoDataIcon } from '@modules/common-ui/icons';
import { DigitalItem } from '@modules/common-ui/components/digital-item';
import {
  IGetTemplateNames as ITemplateReq,
  TemplateNamesListType as TemplateListType,
  TemplateNameType,
} from '@modules/core/aws/dynamo-db/digital-vault/interfaces';

import { digitalVaultSlideStyles } from '@modules/common-ui/components/slide/digital-vault/styles';

export function TemplateName({
  section,
  activeTemplate,
  onSubmitCallback,
  saveTemplate,
}: ITemplateNameProps): ReactElement {
  const { submit, res, isLoading } = useQuery<ITemplateReq, TemplateListType>(getTemplateNames);

  useEffect(() => {
    submit({ section });
  }, []);

  return (
    <Box {...digitalVaultSlideStyles.slideChildContainer}>
      <ConditionSeparator condition={isLoading} target={<Loader />} />

      <ConditionSeparator
        condition={Boolean(res)}
        target={
          <ConditionSeparator
            condition={!res?.length}
            target={
              <VStack {...digitalVaultSlideStyles.noTemplatesBox}>
                <Heading>No templates</Heading>

                <NoDataIcon {...digitalVaultSlideStyles.noTemplatesIcon} />
              </VStack>
            }
            defaultTarget={
              <VStack {...digitalVaultSlideStyles.slideChildContentBox}>
                <VStack {...digitalVaultSlideStyles.chooseTemplateContainer}>
                  <Heading {...digitalVaultSlideStyles.templateNameHeader}>
                    please select a template
                  </Heading>

                  <Flex {...digitalVaultSlideStyles.templateNamesFlexBox}>
                    {res?.map((template: TemplateNameType) => (
                      <Box
                        key={template.TemplateName}
                        onClick={saveTemplate.bind(null, template)}
                        {...digitalVaultSlideStyles.templateBox}
                        {...(isEqual(template, activeTemplate) &&
                          digitalVaultSlideStyles.activeTemplateBox)}
                      >
                        <DigitalItem title={template.TemplateName} />
                      </Box>
                    ))}
                  </Flex>
                </VStack>

                <Button
                  onClick={onSubmitCallback}
                  isDisabled={!activeTemplate}
                  {...digitalVaultSlideStyles.button}
                >
                  Continue
                </Button>
              </VStack>
            }
          />
        }
      />
    </Box>
  );
}
