import { useRef, useEffect } from 'react';
import { useLocation, Location } from 'react-router';

import { AnchorType, RefType } from './interfaces';

function useAnchorScroll(anchor: AnchorType): RefType {
  const location: Location = useLocation();
  const ref: RefType = useRef<HTMLDivElement>(null);

  useEffect((): void => {
    const { hash } = location;

    if (!ref.current) return;
    if (hash !== anchor) return;

    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, [ref.current, location]);

  return ref;
}

export default useAnchorScroll;
