import React, { ReactElement, useState } from 'react';

import { Text, Box, Image } from '@chakra-ui/react';
import { FAQType } from '@app/modules/core/faqs/interfaces';

import { faqItemStyles } from './styles';
import expandIcon from '@assets/images/chevron-up.svg';
import collapseIcon from '@assets/images/chevron-down.svg';

export default function FAQItem({ question, answer }: FAQType): ReactElement {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Box {...faqItemStyles.faqContainer}>
      <Box
        {...faqItemStyles.faqTitleContainer}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        <Text {...faqItemStyles.faqQuestion}>{question}</Text>
        <Image {...faqItemStyles.faqExpandIcon} src={isExpanded ? collapseIcon : expandIcon} />
      </Box>
      {isExpanded && <Text {...faqItemStyles.faqAnswer}>{answer}</Text>}
    </Box>
  );
}
