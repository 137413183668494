export const loaderStyles = {
  loaderContainer: {
    top: '0',
    left: '0',
    width: '100%',
    height: '100vh',
    position: 'absolute',
    backgroundColor: 'black',
    zIndex: '5',
    opacity: '15%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  spinner: {
    thickness: '4px',
    speed: '0.65s',
    emptyColor: 'gray.200',
    color: 'blue.500',
    size: 'xl',
  },
};
