import React, { ReactElement } from 'react';

import { Text, VStack, Image, HStack } from '@chakra-ui/react';

import { articleStyles } from './styles';
import { ArticleType } from '@app/modules/core/articles/interfaces';

export default function ArticleCard({
  title,
  author,
  date,
  readLengthMin,
  tags,
  thumbnailURL,
}: ArticleType): ReactElement {
  if (tags.length > 0) {
    // console.log('We have tags');
  }
  return (
    <VStack {...articleStyles.articleContainer}>
      <Image {...articleStyles.articleThumbnail} src={thumbnailURL} />
      <HStack {...articleStyles.tagsContainer}>
        {tags.map((tag, index) => (
          <Text {...articleStyles.tagItem} key={index}>
            {tag}
          </Text>
        ))}
      </HStack>
      <Text {...articleStyles.title}>{title}</Text>
      <Text {...articleStyles.subHeading}>
        By: {author} on {date} • {readLengthMin} min read
      </Text>
    </VStack>
  );
}
