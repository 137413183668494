import * as Yup from 'yup';

import { PHONE_VALIDATION_ERRORS, ACCEPT_SMS_VALIDATION_ERRORS } from '@modules/core/constants';

const validationSchema = Yup.object().shape({
  mobileNo: Yup.string()
    .trim()
    .required(PHONE_VALIDATION_ERRORS.required)
    .matches(/^(\+1\s[0-9]{3}-[0-9]{3}-[0-9]{4})$/m, PHONE_VALIDATION_ERRORS.invalid),

  acceptedSMS: Yup.boolean()
    .required(ACCEPT_SMS_VALIDATION_ERRORS.required)
    .oneOf([true], ACCEPT_SMS_VALIDATION_ERRORS.invalid),
});

export default validationSchema;
