import { Auth } from 'aws-amplify';

import { lambdaGeneralCaller } from '@modules/core/aws/lambdas/lambda-general-caller/lambdaGeneralCaller';
import {
  ICredentials,
  IJoinFamilyPlanInput,
  IJoinFamilyPlanRes,
} from '@modules/core/aws/lambdas/payments/interfaces';

import { AWS_LAMBDAS, AWS_LAMBDAS_OPERATIONS } from '@modules/core/constants';

export default async function joinFamilyPlan({
  accountHolderId,
}: IJoinFamilyPlanInput): Promise<IJoinFamilyPlanRes> {
  const credentials: ICredentials = await Auth.currentCredentials();
  const res: IJoinFamilyPlanRes = await lambdaGeneralCaller<IJoinFamilyPlanRes>(credentials, {
    FunctionName: AWS_LAMBDAS.payments,
    Payload: JSON.stringify({
      operation: AWS_LAMBDAS_OPERATIONS.joinFamilyPlan,
      accountHolderId,
    }),
  });

  if (!res.success) {
    throw new Error('Result of the join was not successful, please try again later');
  }

  return res;
}
