import { Auth } from 'aws-amplify';

import { lambdaGeneralCaller } from '@modules/core/aws/lambdas/lambda-general-caller/lambdaGeneralCaller';
import {
  ICredentials,
  IEmailChallengeReq,
  ILicenseBlockApplicaitonRes,
} from '@modules/core/aws/lambdas/payments/interfaces';

import { AWS_LAMBDAS, AWS_LAMBDAS_OPERATIONS } from '@modules/core/constants';

async function startEmailDomainChallenge({
  promoCode,
  email,
}: IEmailChallengeReq): Promise<ILicenseBlockApplicaitonRes> {
  const credentials: ICredentials = await Auth.currentCredentials();

  return lambdaGeneralCaller<ILicenseBlockApplicaitonRes>(credentials, {
    FunctionName: AWS_LAMBDAS.payments,
    Payload: JSON.stringify({
      operation: AWS_LAMBDAS_OPERATIONS.beginEmailDomainChallenge,
      promoCode,
      email,
    }),
  });
}

export default startEmailDomainChallenge;
