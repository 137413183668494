export const inputStyles = {
  baseIcon: {
    color: 'gray.400',
  },

  passwordIcon: {
    boxSize: '5',
    color: 'gray.400',
  },

  attachmentIcon: {
    boxSize: '4',
    color: 'gray.500',
  },

  attachmentIconBox: {
    height: '100%',
  },

  button: {
    colorScheme: 'whiteAlpha',
  },

  currencyInput: {
    paddingLeft: '40px',
  },
};
