import React, { useEffect, ReactElement, useState } from 'react';
import { Link as RouterLink, useNavigate, NavigateFunction } from 'react-router-dom';

import { ISignUpResult } from 'amazon-cognito-identity-js';
import {
  Box,
  Button,
  Flex,
  VStack,
  Heading,
  InputGroup,
  Text,
  Link,
  Image,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import validationSchema from './validation-schema';
import { useQuery, useAuth, useAppEntryParams } from '@modules/core/hooks';
import { storageSet } from '@modules/core/local-storage';
// import { LvedFeatures } from '@modules/common-ui/components/lved-features';
import logoImg from '@assets/images/lvedLogo.svg';
import { CheckBox } from '@modules/common-ui/components/check-box';
// import { PhoneInput, PasswordInput, TextInput } from '@modules/common-ui/components/text-field';
import { PasswordInput, TextInput } from '@modules/common-ui/components/text-field';
import {
  IUserRegistrationData as IUserData,
  IAuthContext,
} from '@modules/core/contexts/auth/interfaces';

import { signUpStyles } from './styles';

import { LVED_LINKS, APP_ROUTES, LOCAL_STORAGE } from '@modules/core/constants';
import { TrackGoogleAnalyticsEvent } from '@app/modules/core/google-analytics/google-analytics-tracker';
import UserCategory, {
  SignUpCompletedAction,
} from '@app/modules/core/google-analytics/events/signupGAEvent';
import Modal from '@app/modules/common-ui/components/modal/ModalBasic';

export function SignUpForm({
  linkColor = undefined,
  transparentBackground = false,
  showLogo = true,
}: {
  linkColor?: string;
  transparentBackground?: boolean;
  showLogo?: boolean;
}): ReactElement {
  const navigateTo: NavigateFunction = useNavigate();
  const appEntryParams = useAppEntryParams();
  const { signUp }: IAuthContext = useAuth();
  const { submit, res, isLoading, err } = useQuery<IUserData, ISignUpResult>(signUp);
  const [showingMarketResearchQs, setShowingMarketResearchQs] = useState<boolean>(false);
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    onSubmit: () => {
      setShowingMarketResearchQs(true);
      // submit({ ...values, affiliate: appEntryParams.affiliateCode });
    },
    validationSchema,
    initialValues: {
      givenName: '',
      familyName: '',
      // phoneNumber: '',
      email: '',
      password: '',
      passwordCheck: '',
      acceptedTerms: false,
      // acceptedSMS: false,
    },
  });

  useEffect((): void => {
    if (res?.user) {
      TrackGoogleAnalyticsEvent(UserCategory, SignUpCompletedAction, `User: ${values.givenName}`);
      storageSet(LOCAL_STORAGE.unverifiedEmail, values.email);
      navigateTo(APP_ROUTES.emailVerification);
    }
  }, [res]);

  /*
  <PhoneInput
    isError={!!errors.phoneNumber && touched.phoneNumber}
    errorMessage={errors.phoneNumber}
    label='Mobile Number'
    id='phoneNumber'
    name='phoneNumber'
    value={values.phoneNumber}
    onChange={handleChange}
  />
  <CheckBox
    id='acceptedSMS'
    name='acceptedSMS'
    onChange={handleChange}
    checked={values.acceptedSMS}
    isError={!!errors.acceptedSMS && touched.acceptedSMS}
    errorMessage={errors.acceptedSMS}
  >
    I accept receiving SMS messages from LVED to secure my account using Multi-factor
    Authentication
  </CheckBox>
*/
  return (
    <Box {...(transparentBackground ? signUpStyles.signUpBoxTransparent : signUpStyles.signUpBox)}>
      <form onSubmit={handleSubmit}>
        <VStack {...signUpStyles.contentBox}>
          {showLogo && (
            <Box display='flex' flexDirection='row' width='100%' justifyContent='center'>
              <Image {...signUpStyles.logo} src={logoImg} />
            </Box>
          )}
          <Heading>Create an account</Heading>
          <Text {...signUpStyles.textContainer}>
            Already have an account? &nbsp;
            <Text {...(linkColor ? { color: linkColor } : signUpStyles.textGreen)} as='span'>
              <RouterLink to={APP_ROUTES.signIn}>Login</RouterLink>
            </Text>
          </Text>

          <InputGroup {...signUpStyles.inputGroup}>
            <TextInput
              id='givenName'
              name='givenName'
              label='First Name'
              onChange={handleChange}
              value={values.givenName}
              isError={!!errors.givenName && touched.givenName}
              errorMessage={errors.givenName}
              style={{ ...signUpStyles.firstNameinput }}
            />
            <TextInput
              id='familyName'
              name='familyName'
              label='Last Name'
              onChange={handleChange}
              value={values.familyName}
              isError={!!errors.familyName && touched.familyName}
              errorMessage={errors.familyName}
            />
          </InputGroup>

          <TextInput
            id='email'
            name='email'
            type='email'
            label='Email Address'
            onChange={handleChange}
            value={values.email}
            isError={!!errors.email && touched.email}
            errorMessage={errors.email}
          />

          <PasswordInput
            id='password'
            name='password'
            label='Password'
            onChange={handleChange}
            value={values.password}
            isError={!!errors.password && touched.password}
            errorMessage={errors.password}
          />

          <PasswordInput
            id='passwordCheck'
            name='passwordCheck'
            label='Re-enter Password to verify'
            onChange={handleChange}
            value={values.passwordCheck}
            isError={!!errors.passwordCheck && touched.passwordCheck}
            errorMessage={errors.passwordCheck}
          />

          <CheckBox
            id='acceptedTerms'
            name='acceptedTerms'
            onChange={handleChange}
            checked={values.acceptedTerms}
            isError={!!errors.acceptedTerms && touched.acceptedTerms}
            errorMessage={errors.acceptedTerms}
          >
            I agree to LVED&apos;s &nbsp;
            <Link
              {...(linkColor ? { color: linkColor } : signUpStyles.textGreen)}
              href={LVED_LINKS.termsOfService}
              isExternal
            >
              Terms of Service &nbsp;
            </Link>
            and
            <Link
              {...(linkColor ? { color: linkColor } : signUpStyles.textGreen)}
              href={LVED_LINKS.pricacyPolicy}
              isExternal
            >
              &nbsp; Privacy Policy
            </Link>
          </CheckBox>

          <Button isLoading={isLoading} type='submit' {...signUpStyles.button}>
            Create account
          </Button>

          {err && (
            <Box {...signUpStyles.textErrorContainer}>
              <Text {...signUpStyles.textError}>{err?.message || 'unknown error'}</Text>
            </Box>
          )}
        </VStack>

        <Modal
          isOpen={showingMarketResearchQs}
          onClose={() => {
            setShowingMarketResearchQs(false);
          }}
        >
          <VStack gap='20px'>
            <Text {...signUpStyles.textHeader}>Market Research</Text>
            <Text>
              We&apos;re working on building the best possible experience for our customers. So
              we&apos;re conducting customer research and looking for volunteers willing to meet
              with our team.
            </Text>
            <Text fontWeight='700'>
              Are you willing to talk with us on the phone for 10 minutes in return for a virtually
              free ($1) estate plan and 1st year subscription?
            </Text>
            <Button
              isLoading={isLoading}
              {...signUpStyles.button}
              onClick={() => {
                setShowingMarketResearchQs(false);
                submit({
                  ...values,
                  affiliate: appEntryParams.affiliateCode,
                  marketResearchOk: true,
                });
              }}
            >
              Yes, you may interview me
            </Button>
            <Button
              isLoading={isLoading}
              {...signUpStyles.button}
              onClick={() => {
                setShowingMarketResearchQs(false);
                submit({
                  ...values,
                  affiliate: appEntryParams.affiliateCode,
                });
              }}
            >
              No thanks
            </Button>
          </VStack>
        </Modal>
      </form>
    </Box>
  );
}

function SignUp(): ReactElement {
  return (
    <Flex {...signUpStyles.container}>
      <SignUpForm />
    </Flex>
  );
}

export default SignUp;
