import React, { ReactElement } from 'react';

import { Slide, Button, HStack, Box, Text } from '@chakra-ui/react';

import { useOutsideClicker } from '@app/modules/core/hooks';
import { TextInput } from '@app/modules/common-ui/components/text-field';
import { IPromoCodeSlideProps } from './interfaces';

// import PromoCodeBackground from '@assets/images/promocodeBg.png';

import { promoCodeSlideStyles } from './styles';

const DIRECTION = 'bottom';

function PromoCodeSlide({
  isOpen,
  isSubmitLoading,
  value,
  isPromoCodeApplied,
  onSubmit,
  onClose,
  onValueChange,
  onResetRequest,
}: IPromoCodeSlideProps): ReactElement {
  const { ref } = useOutsideClicker<HTMLDivElement>(onClose);

  return (
    <Slide ref={ref} direction={DIRECTION} in={isOpen} style={promoCodeSlideStyles.slide}>
      <Box {...promoCodeSlideStyles.mainContainer}>
        <HStack {...promoCodeSlideStyles.contentBox}>
          <TextInput value={value} onChange={onValueChange} placeholder='Promocode' />
          <Button
            isDisabled={!value}
            isLoading={isSubmitLoading}
            onClick={onSubmit}
            {...promoCodeSlideStyles.submitButton}
          >
            Apply
          </Button>
        </HStack>
        {isPromoCodeApplied && (
          <Text {...promoCodeSlideStyles.removePromocode} align='center' onClick={onResetRequest}>
            Remove promocode
          </Text>
        )}
      </Box>
    </Slide>
  );
}

export default PromoCodeSlide;
