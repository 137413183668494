import React, { ReactElement, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useDisclosure } from '@chakra-ui/react';

import { DigitalVaultLocationType } from '@pages/digital-vault/interfaces';

import DigitalVaultSlide from '@modules/common-ui/components/slide/digital-vault';

import { HIERARCHY_SECTION } from '@app/modules/core/constants';

function DigitalVaultLayout(): ReactElement {
  const { pathname } = useLocation<DigitalVaultLocationType>();
  const { isOpen: isSlideOpen, onClose: slideOnClose, onOpen: slideOnOpen } = useDisclosure();
  const [slideSection, setSlideSection] = useState<HIERARCHY_SECTION | null>(null);

  useEffect(() => {
    slideOnClose();
  }, [pathname]);

  return (
    <>
      {slideSection && (
        <DigitalVaultSlide
          key={slideSection}
          section={slideSection}
          isOpen={isSlideOpen}
          onClose={slideOnClose}
        />
      )}

      <Outlet context={{ slideOnOpen, setSlideSection }} />
    </>
  );
}

export default DigitalVaultLayout;
