import { DynamoDB, AWSError } from 'aws-sdk';
import { Auth } from 'aws-amplify';

import { ICredentials, ILatestUpdates } from './interfaces';

import { DYNAMO_DB_TABLES } from '@modules/core/constants';

export async function getLatestUpdates(): Promise<ILatestUpdates> {
  const credentials: ICredentials = await Auth.currentCredentials();

  const clientDB: DynamoDB.DocumentClient = new DynamoDB.DocumentClient({
    region: process.env.REACT_APP_AWS_REGION || 'us-east-1',
    credentials: Auth.essentialCredentials(credentials),
  });

  const params: DynamoDB.DocumentClient.QueryInput = {
    TableName: DYNAMO_DB_TABLES.latestUpdates,
    KeyConditionExpression: 'identityId = :identityId',
    ExpressionAttributeValues: {
      ':identityId': credentials.identityId,
    },
  };

  return new Promise<ILatestUpdates>((resolve, reject) => {
    clientDB.query(params, (err: AWSError, data: DynamoDB.DocumentClient.QueryOutput) => {
      if (err) {
        return reject(new Error(err.message || JSON.stringify(err)));
      }

      return resolve(data as ILatestUpdates);
    });
  });
}
