import React, { ReactElement } from 'react';

import { IConditionSeparatorProps } from './interfaces';

function ConditionSeparator({
  target,
  defaultTarget,
  condition,
}: IConditionSeparatorProps): ReactElement {
  if (!condition) return defaultTarget || <></>;
  return target || <></>;
}

export default ConditionSeparator;
