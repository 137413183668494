import React, { ReactElement } from 'react';

import { Text, Link } from '@chakra-ui/react';

import { IMenuLinkProps } from './interfaces';
import { menuLinkStyles } from './styles';
import { useNavigate } from 'react-router';

export default function MenuLink({
  title,
  url,
  isUrlLocal,
  isActiveLink,
  closeMenu,
}: IMenuLinkProps): ReactElement {
  const navigate = useNavigate();
  return (
    <Link
      {...menuLinkStyles.menuItem}
      onClick={() => {
        if (isActiveLink) return;
        if (isUrlLocal) {
          navigate(url);
        } else {
          window.location.href = url;
        }
        if (closeMenu) closeMenu();
      }}
    >
      <Text borderBottom={isActiveLink ? '2px solid var(--clickable-white, #FFF)' : '0px'}>
        {title}
      </Text>
    </Link>
  );
}
