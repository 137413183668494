export const pricingCardStyles = {
  card: {
    width: { base: '100%', sm: '390px' },
    height: { base: 'auto', sm: '500px' },
    background: '#045F60 0% 0% no-repeat padding-box;',
    // border: '4px solid',
    // borderColor: 'brand.green.100',
    borderRadius: '30px',
    padding: { base: '20px 20px', sm: '42px 43px' },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    position: 'relative',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    // fontSpacing: '25px',
    textAlign: 'left',
    font: 'normal normal bold 24px/29px Lato',
    letterSpacing: '0.5px',
    color: '#FFFFFF',
  },

  notPopular: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #DBD3D3',
    color: '#000000',
  },

  separator: {
    width: '100%',
    height: '2px',
    border: '1px',
    borderColor: '#FFFFFF',
    opacity: '0.6',
  },

  nonPopularSeparator: {
    width: '100%',
    height: '2px',
    border: '1px',
    borderColor: '#000000',
    opacity: '0.6',
  },

  featuresList: {
    margin: { base: '15px 25px 0px 25px', sm: '0px' },
  },

  productName: {
    width: '100%',
    fontWeight: 'bold',
    fontSize: '24px',
    // fontSpacing: '29px',
  },

  price: {
    width: '100%',
    fontWeight: 'normal',
    fontSize: '40px',
    // fontSpacing: '19px',
    margin: '0px',
    padding: '0px',
  },

  moneyBack: {
    fontWeight: 'normal',
    fontSize: '16px',
    margin: '0px',
    marginTop: '10px',
    padding: '0px',
  },

  priceFrequency: {
    fontWeight: 'normal',
    fontSize: '14px',
    // fontSpacing: '19px',
    width: '100%',
    height: '100%',
    textAlign: 'left',
    padding: '28px 0px 0px 0px',
    margin: '0px',
  },

  popularStamp: {
    background: '#3EA3BA 0% 0% no-repeat padding-box',
    borderRadius: '50px',
    padding: '4px',
    width: '104px',
    height: '32px',
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: '500',
    // fontSpacing: '17px',
    textAlign: 'center',
  },

  button: {
    backgroundColor: '#FFFFFF',
    width: 'full',
    color: '#000000',
    fontSize: '14px',
    // fontSpacing: '17px',
    fontWeight: '600',
    borderRadius: '27px',
    height: '54px',
    marginTop: { base: '20px', sm: '10px' },
    _hover: {
      backgroundColor: '#D1D1D1',
    },
  },

  notPopularButton: {
    backgroundColor: '#000000',
    color: '#FFFFFF',
    _hover: {
      backgroundColor: '#515151',
    },
  },

  standaloneButton: {
    backgroundColor: 'brand.green.100',
    width: '250px',
    color: 'white',
    fontSize: '20px',
    // fontSpacing: '17px',
    fontWeight: '500',
    borderRadius: '27px',
    height: '54px',
    _hover: {
      backgroundColor: 'brand.green.200',
    },
  },

  acknowledgementBox: {
    background: 'white',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    textAlign: 'left',
    spacing: '15px',
  },

  cardDisabled: {
    borderColor: 'brand.gray.100',
    color: 'brand.gray.100',
  },

  cardContentBox: {
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
  },

  licenseStatusBox: {
    margin: '14px 0px 0px 0px',
    padding: '0px',
    spacing: '1',
  },

  licenseStatus: {
    fontSize: '2xl',
  },

  text: {
    fontSize: '2xl',
  },

  canceled: {
    fontSize: '2x1',
    textDecoration: 'line-through',
  },

  nonCancelled: {
    fontSize: '2xl',
  },

  textError: {
    color: 'brand.error',
    textAlign: 'center',
  },

  buttonDisabled: {
    backgroundColor: 'brand.gray.100',
    _hover: {
      backgroundColor: 'brand.gray.100',
    },
  },

  cardIconBox: {
    position: 'absolute',
    top: '-35px',
    color: 'brand.green.100',
  },

  cardIconBoxDisabled: {
    color: 'brand.gray.100',
  },
} as const;
