export const familyRegistrationStyles = {
  pageBackground: {
    width: '100%',
    background: 'linear-gradient(180deg, #D0F0E4 13.67%, #F3F7F8 37.59%, #F6F8FA 100%)',
    spacing: '3',
    // margin: '35px',
  },

  gridContainer: {
    templateAreas: `"latest score"
      "tasks score"`,
    gridTemplateRows: '0.5fr 1fr',
    gridTemplateColumns: '60% 40%',
    h: '820px',
    gap: '50px',
    color: 'blackAlpha.700',
    fontWeight: 'bold',
    width: '60%',
    margin: 'auto 80px',
  },

  titleText: {
    fontSize: '24px',
    fontWeight: '700',
    padding: '0 35px 0 35px',
  },

  contentBox: {
    spacing: '4',
    align: 'flex-start',
    width: { base: '100%', sm: '450px' },
  },

  detailsInput: {
    width: '80%',
    background: 'white',
    size: '16px',
    padding: '0 35px 0 35px',
  },

  textError: {
    color: 'brand.error',
    fontSize: '16px',
  },

  submitButton: {
    colorScheme: 'whiteAlpha',
    width: 'full',
    backgroundColor: 'brand.green.100',
    height: '56px',
    _hover: {
      backgroundColor: 'brand.green.200',
    },
  },

  gridItemBox: {
    backgroundColor: '#FEFEFE',
    borderRadius: '20px',
    padding: '45px',
    width: '100%',
    height: '100%',
  },

  spinnerBox: {
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },

  spinner: {
    thickness: '4px',
    speed: '0.65s',
    emptyColor: 'gray.200',
    color: 'brand.green.100',
    size: 'xl',
  },

  widgetHeader: {
    size: 'md',
  },
};
