export const modalBasicStyles = {
  button: {
    size: 'lg',
    color: 'white',
    backgroundColor: 'brand.green.100',
    _hover: {
      backgroundColor: 'brand.green.200',
    },
  },
};

export const editUserProfileModalStyles = {
  contentBox: {
    spacing: '10',
  },

  button: {
    ...modalBasicStyles.button,
    size: 'md',
    width: '100%',
  },
};

export const decryptEncryptionKeyModalStyles = {
  contentBox: {
    spacing: '10',
  },

  button: {
    ...modalBasicStyles.button,
    size: 'md',
    width: '100%',
  },
};

export const joinFamilyMemberModalStyles = {
  contentBox: {
    spacing: '10',
  },

  memberBox: {
    width: '100%',
    transition: 'all .2s ease',
    justifyContent: 'space-between',
    padding: '20px 40px',
    borderRadius: '15px',
    boxShadow: 'none',
    cursor: 'pointer',
    _hover: {
      boxShadow: '0px 5px 15px rgba(139, 201, 74, 0.15)',
    },
  },

  invitationIcon: {
    boxSize: '6',
    color: 'brand.green.100',
  },

  membersBox: {
    width: '100%',
  },

  memberName: {
    size: 'sm',
  },

  memberBoxActive: {
    boxShadow: '0px 8px 24px rgba(139, 201, 74, 0.25) !important',
  },

  button: {
    ...modalBasicStyles.button,
    size: 'md',
    width: '100%',
  },

  textError: {
    color: 'brand.error',
  },

  textSuccess: {
    color: 'brand.green.100',
  },
};
