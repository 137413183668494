import React, { ReactElement, useEffect } from 'react';
import { Outlet, Navigate, useOutletContext } from 'react-router-dom';

import { VStack, Button, Heading, Box } from '@chakra-ui/react';

import { ConditionSeparator } from '@modules/common-ui/components/condition-separator';
import {
  DigitalVaultContextType,
  IDigitalVaultSectionLayoutProps,
} from '@pages/digital-vault/interfaces';

import { digitalVaultStyles } from '@pages/digital-vault/styles';

import { APP_ROUTES } from '@modules/core/constants';

function DigitalVaultSectionLayout({ section }: IDigitalVaultSectionLayoutProps): ReactElement {
  const { slideOnOpen, setSlideSection } = useOutletContext<DigitalVaultContextType>();

  useEffect(() => {
    if (section) {
      setSlideSection(section);
    }
  });

  return (
    <>
      <ConditionSeparator
        condition={!!section}
        defaultTarget={<Navigate to={APP_ROUTES.digitalVault} replace />}
        target={
          <Box {...digitalVaultStyles.container}>
            <VStack {...digitalVaultStyles.sectionLayoutContentBox}>
              <Heading>{section}</Heading>

              <Button onClick={slideOnOpen}>Add {section}</Button>

              <Outlet />
            </VStack>
          </Box>
        }
      />
    </>
  );
}

export default DigitalVaultSectionLayout;
