import React from 'react';

import { Box, Spinner } from '@chakra-ui/react';

import { loaderStyles } from '@modules/common-ui/components/loader/styles';

function Loader() {
  return (
    <Box {...loaderStyles.spinnerBox}>
      <Spinner {...loaderStyles.spinner} />
    </Box>
  );
}

export default Loader;
