import React, { useState, useRef, useEffect } from 'react';
import { VStack, SimpleGrid, Text, Button, Box, HStack } from '@chakra-ui/react';
import { getNextStories } from '@app/modules/core/aws/lambdas/explore-stories-with-link';
import {
  ILoadStoriesRequest,
  ILoadStoriesResponse,
  LambdaStory,
} from '@modules/core/aws/lambdas/explore-stories-with-link/interfaces';
// import { useQuery } from '@modules/core/hooks';
import StoryItem from './StoryItem';
import { browseStoriesStyles } from './styles';
import UnAuthHeader from '@app/modules/common-ui/components/unAuthHeader/UnAuthHeader';
import UnAuthFooter from '@app/modules/common-ui/components/unAuthFooter/UnAuthFooter';

function isMobileDevice(): boolean {
  // return true;
  return navigator.maxTouchPoints > 0;
}

function BrowseStories() {
  const continueWithKey = useRef<string>();
  const [storyData, setStoryData] = useState<LambdaStory[]>([]);
  const [storyTellerName, setStoryTellerName] = useState<string | null>();
  const queryParameters = new URLSearchParams(window.location.search);
  const identityId = queryParameters.get('id');
  const storyShareId = queryParameters.get('storiesShareId');
  const [refreshes, setRefreshes] = useState<number>(0);
  const [errorString, setErrorString] = useState<string | null>(null);
  // const storySummary = `User has ${storyData ? storyData.length : 0} stories${!!continueWithKey.current ? ", and may have more." : "."}`;
  // var storyItems: JSX.Element[];

  // console.log(`IdentityId: ${identityId}`);
  // console.log(`StorytellingId: ${storyShareId}`);

  /* const {
    submit: loadMore,
    res: storyLoadResponse,
    isLoading,
  } = useQuery<ILoadStoriesRequest, ILoadStoriesResponse>(getNextStories); */
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!(!!identityId && !!storyShareId)) {
      // console.log('Nothing to do, requires an identityId and Story Share id');
      return;
    }
    // console.log('Attempting to retrieve stories');
    setIsLoading(true);
    getNextStories({
      identityId: identityId!,
      storyShareId: storyShareId!,
      exclusiveStartKey: continueWithKey.current,
      includeThumbnails: isMobileDevice(),
    } as ILoadStoriesRequest)
      .then((loadStoriesResponse: ILoadStoriesResponse) => {
        // setErrorString(loadStoriesResponse?.error || null);
        const newStories = loadStoriesResponse?.stories;
        continueWithKey.current = loadStoriesResponse?.lastEvaluatedKey;
        if (loadStoriesResponse?.accountHolderName) {
          setStoryTellerName(loadStoriesResponse!.accountHolderName!);
        }
        if (newStories) {
          setStoryData([...storyData, ...newStories!]);
        }
        // console.log('Success, result: ');
        // console.log(loadStoriesResponse);
      })
      .catch((error) => {
        // console.log('Error: ', error);
        setStoryTellerName('Unknown');
        setStoryData([]);
        setErrorString(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [refreshes]);

  return (
    <VStack {...browseStoriesStyles.pageBackground}>
      <UnAuthHeader activeLink='None' />
      <HStack h='100px' />
      {errorString && <Text {...browseStoriesStyles.textError}>{errorString}</Text>}
      {!errorString && storyData.length === 0 && !isLoading && (
        <Text>No stories have been shared</Text>
      )}

      {storyData.length > 0 && (
        <div>
          <Text {...browseStoriesStyles.titleText}>{storyTellerName} Stories</Text>

          <Box h='25px' />

          <SimpleGrid
            minChildWidth='250px'
            spacingX='40px'
            spacingY='20px'
            w='100%'
            alignItems='bottom'
          >
            {storyData.map((story) => (
              <StoryItem story={story} key={story.objectId} />
            ))}
          </SimpleGrid>

          <Box h='25px' />

          <Button
            isDisabled={continueWithKey.current === undefined}
            isLoading={isLoading}
            {...browseStoriesStyles.loadMoreButton}
            onClick={() => {
              setRefreshes(refreshes + 1);
            }}
          >
            Load more
          </Button>
        </div>
      )}
      <UnAuthFooter />
    </VStack>
  );
}

export default BrowseStories;
