export const paymentCompletionStyles = {
  backgroundContainer: {
    height: '100vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },

  mainContainer: {
    spacing: '10',
    height: '100%',
    justifyContent: 'center',
  },
};
