import React from 'react';
import './index.css';
import { VStack, Text, Spacer } from '@chakra-ui/react';
import { getAgeDescription } from '@app/modules/core/lvedHelpers/commontools';
import { LambdaStory } from '@app/modules/core/aws/lambdas/explore-stories-with-link/interfaces';

function StoryItem(props: { story: LambdaStory }) {
  const createdTimeStr = props.story.CreatedTimestamp;
  const createdTime = new Date(createdTimeStr);
  const video = (
    <video
      className='storyVideo'
      src={props.story.storyUrl}
      poster={
        props.story.base64StoryThumbnail
          ? `data:image/jpeg;base64,${props.story.base64StoryThumbnail!}`
          : ``
      }
      controls
    >
      <track src='' kind='captions' />
    </video>
  );

  return (
    <VStack w='100%' spacing={0} align='left'>
      <Spacer />
      {video}
      <Text px='10px' fontSize='20px' fontWeight='bold'>
        {props.story.StoryTitle}
      </Text>
      <Text px='10px' fontSize='14px'>
        {getAgeDescription(createdTime)}
      </Text>
    </VStack>
  );
}

export default StoryItem;
