export const profileFamilyManagementStyles = {
  container: {
    minH: '530px',
    width: '100%',
  },

  textBox: {
    width: { base: '95%', sm: '80%', md: '60%' },
    alignSelf: 'flex-start',
  },

  subText: {
    as: 'span',
    color: 'brand.green.100',
  },

  invitesContainer: {
    width: '100%',
    spacing: '10',
  },
} as const;
