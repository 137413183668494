export enum CUSTOMER_OBJECT_FIELD_TYPES {
  dropdown = 'Dropdown',
  text = 'Text',
  number = 'Number',
  numberNoFormat = 'Number_NoFormat',
  boolean = 'Boolean',
  password = 'Password',
  image = 'Image',
  video = 'Video',
  currency = 'Currency',
  percent = 'Percent',
  date = 'Date',
  time = 'Time',
  dateTime = 'Date-time',
  contact = 'Contact',
  address = 'Address',
  document = 'Document',
  attachment = 'Attachment',
  thumbnail = 'Thumbnail',
  object = 'Object',
  objectArray = 'ObjectArray',
  usState = 'USState',
}

export const DOCUMENT_FIELD_TYPES = [
  CUSTOMER_OBJECT_FIELD_TYPES.image,
  CUSTOMER_OBJECT_FIELD_TYPES.video,
  CUSTOMER_OBJECT_FIELD_TYPES.document,
  CUSTOMER_OBJECT_FIELD_TYPES.attachment,
  CUSTOMER_OBJECT_FIELD_TYPES.thumbnail,
];
