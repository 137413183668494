import React, { ReactElement, useEffect } from 'react';

import { Box } from '@chakra-ui/react';

import { useNavigation } from '@modules/core/hooks';
import { IRouteWrapperProps } from './interfaces';

import { routeWrapperStyles } from './styles';

import { WITHOUT_SIDEBAR_ROUTES } from '@modules/core/constants';

export default function RouteWrapper(props: IRouteWrapperProps): ReactElement {
  const { setRoute } = useNavigation();
  const isRouteWithSideBar = (): boolean => {
    return !WITHOUT_SIDEBAR_ROUTES.includes(props.route) && props.isSideBarExpanded;
  };

  useEffect(() => {
    setRoute(props.route);
  }, [props]);

  return <Box {...(isRouteWithSideBar() && routeWrapperStyles.container)}>{props.children}</Box>;
}
