import { Auth } from 'aws-amplify';

import { lambdaGeneralCaller } from '@modules/core/aws/lambdas/lambda-general-caller/lambdaGeneralCaller';
import { ICredentials, IStorageUsage } from '@modules/core/aws/lambdas/profile/interfaces';

import { AWS_LAMBDAS } from '@modules/core/constants';

export default async function getStorageUsage(): Promise<IStorageUsage> {
  const credentials: ICredentials = await Auth.currentCredentials();

  return lambdaGeneralCaller<IStorageUsage>(credentials, {
    FunctionName: AWS_LAMBDAS.storageUsage,
  });
}
