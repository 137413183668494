import React, { ReactElement } from 'react';

import { Box, VStack, Text } from '@chakra-ui/react';

import { IDigitalItemProps } from '@modules/common-ui/components/digital-item/interfaces';
import { ConditionSeparator } from '@modules/common-ui/components/condition-separator';

import { digitalItemStyles } from '@modules/common-ui/components/digital-item/styles';

function DigitalItem({ title, childCount, icon }: IDigitalItemProps): ReactElement {
  return (
    <Box {...digitalItemStyles.container}>
      <VStack {...digitalItemStyles.contentBox}>
        <ConditionSeparator condition={Boolean(icon)} target={icon} />

        <Text {...digitalItemStyles.text}>{title}</Text>

        <ConditionSeparator
          condition={!Number.isNaN(childCount)}
          target={<Text>{childCount}</Text>}
        />
      </VStack>
    </Box>
  );
}

export default DigitalItem;
