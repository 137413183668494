import { DateTime } from 'luxon';
import { isString } from 'lodash';

import { IFormatOptions, OrderType, SortItemType } from './interfaces';

export function formatDate(date: string | Date, options?: IFormatOptions): string {
  const luxonDate: DateTime = isString(date) ? DateTime.fromISO(date) : DateTime.fromJSDate(date);

  return luxonDate
    .setLocale(options?.locale || 'en')
    .toLocaleString({ ...(options?.format && options.format) });
}

export function isExpired(date: string | Date): boolean {
  const expiresDate: DateTime = isString(date) ? DateTime.fromISO(date) : DateTime.fromJSDate(date);
  const today: DateTime = DateTime.now();

  return today > expiresDate;
}

export function sortByDateString<T extends SortItemType>(list: T[], order: OrderType = 'desc') {
  return [...list].sort((a: SortItemType, b: SortItemType) => {
    const firstDateMillis: number = DateTime.fromISO(a.timestamp).toMillis();
    const secondDateMillis: number = DateTime.fromISO(b.timestamp).toMillis();

    if (order === 'asc') {
      return firstDateMillis - secondDateMillis;
    }

    return secondDateMillis - firstDateMillis;
  });
}
