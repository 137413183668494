import { Auth } from 'aws-amplify';

import { lambdaGeneralCaller } from '@modules/core/aws/lambdas/lambda-general-caller/lambdaGeneralCaller';
import { ICredentials } from '@modules/core/aws/lambdas/profile/interfaces';

import { AWS_LAMBDAS } from '@modules/core/constants';

export default async function syncProfile(): Promise<{}> {
  const credentials: ICredentials = await Auth.currentCredentials();

  return lambdaGeneralCaller<{}>(credentials, {
    FunctionName: AWS_LAMBDAS.syncProfile,
  });
}
