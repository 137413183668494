export const pricingStyles = {
  backgroundContainer: {
    height: '100vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },

  contentBox: {
    fontFamily: 'Lato',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'blackAlpha.700',
    spacing: '10',
  },

  discountComment: {
    textAlign: 'center',
    fontStyle: 'italic',
    fontSize: '14px',
    letterSpacing: '0px',
    color: '#000000',
    opacity: '0.6',
  },

  joinMemberText: {
    margin: '5px',
    fontSize: '2xl',
    color: 'brand.green.200',
  },

  joinMemberBox: {
    cursor: 'pointer',
  },

  cardsBox: {
    width: '100%',
    justifyContent: 'space-evenly',
    marginTop: '100px',
    padding: { base: '0px 20px 0px 20px', sm: '0px' },
    gap: { base: '20px', lg: 'auto' },
    flexWrap: 'wrap',
  },

  cardIconBox: {
    top: '-35px',
  },

  heading: {
    margin: '5px',
    padding: '0px 10px 0px 10px',
    fontFamily: 'Lato',
    fontSize: '42px',
    fontWeight: '900',
    letterSpacing: '0px',
    lineHeight: '54px',
    color: 'black',
  },

  lecenseStatusBox: {
    marginTop: '25px',
  },

  licenseStatus: {
    fontSize: '2xl',
  },

  text: {
    color: 'black',
    fontFamily: 'Lato',
    fontStyle: 'regular',
    fontSize: '18px',
  },

  link: {
    color: '#045F60',
    fontSize: '18px',
    fontWeight: '600',
    opacity: '50%',
  },

  discountContentBox: {
    spacing: '3px',
    width: '100%',
    height: '100%',
    alignItems: 'middle',
    padding: '8px',
  },

  button: {
    color: 'white',
    width: 'full',
    backgroundColor: 'brand.green.100',
    borderRadius: '12px',
    height: '48px',
    _hover: {
      backgroundColor: 'brand.green.200',
    },
  },

  discountButtonContents: {
    width: '100%',
    height: '100%',
    // justifyContent: 'space-between',
    alignItems: 'center',
    // flexDirection: 'column',
    // wordWrap: 'break-word',
    // padding: '0px',
  },

  activePromotionText: {
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    // wordWrap: 'break-word',
    w: '100%',
    h: '100%',
    padding: '5px',
    textAlign: 'center',
    fontSize: '20px',
  },

  chooseBillingButton: {
    size: 'lg',
    margin: '0 15px',
    width: '160px',
    backgroundColor: '#F5F5F5',
    border: '1px solid',
    borderColor: '#CFCFCF',
    color: '#CFCFCF',
    borderRadius: '12px',

    _hover: {
      backgroundColor: '#F5F5F5',
    },
  },

  discountButton: {
    size: 'md',
    color: 'white',
    backgroundColor: '#005F60',
    // top: '130px',
    // right: '30px',

    _hover: {
      backgroundColor: '#307475',
    },
  },

  chooseBillingButtonActive: {
    backgroundColor: 'brand.green.50',
    borderColor: 'brand.green.100',
    color: 'brand.green.100',
    _hover: {
      backgroundColor: 'brand.green.50',
    },
  },

  affiliateLogo: {
    width: '100px',
    height: 'auto',
    borderRadius: '3px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },

  errorText: {
    color: 'brand.red.100',
  },

  affiliateTitle: {},
} as const;

export const socialProofStyles = {
  backgroundContainer: {
    w: '100%',
    bg: 'white',
  },

  text: {
    color: 'brand.green.100',
    marginLeft: '5px',
    fontFamily: 'Lato',
    fontStyle: 'regular',
    fontWeight: '700',
    fontSize: '18px',
  },

  link: {
    color: '#045F60',
    fontSize: '18px',
    fontWeight: '600',
    opacity: '50%',
  },
} as const;
