export enum NAVIGATION_TITLES {
  wishes = 'My Wishes',
  circle = 'My Circle',
  estate = 'My Estate',
  legacy = 'My Legacy',
  commitments = 'My Commitments',
  digitalVault = 'Digital Vault',
  settings = 'Settings',
  dashboard = 'Dashboard',
  unauthenticated = 'Unauthenticated',
  onboarding = 'Onboarding',

  wishesBequests = 'Bequests',
  wishesBequestsCash = 'Cash Bequests',
  wishesBequestsAsset = 'Asset Bequests',

  wishesRemainderDistribution = 'Remainder Distribution',
  wishesRemainderDistributionPrincipal = 'Principal, Alternate remainder Distribution, and Disinheritance',
  wishesRemainderDistributionDisinherit = 'Disinherit children or spouse',

  wishesTrusts = 'Trusts',
  wishesTrustSpousal = 'Spousal trust',
  wishesTrustsChildren = 'Children trust',
  wishesTrustsPets = 'Pets Trusts',

  wishesForMe = 'For Me',
  wishesForMeFuneralPlans = 'Funeral Plans',
  wishesForMeBodyHandlingInstructions = 'Body Handling Instructions',
  wishesForMeLifeSupportDirectives = 'Life Support Directives',

  wishesLegalDocuments = 'Legal Documents',
  wishesLegalDocumentsWill = 'Will, Financial POA, Living Will & HC POA, HIPPA Release Generation',
  wishesLegalDocumentsScan = 'Document Scanning and Upload',

  circleBeneficiaries = 'Beneficiaries',

  circleTrustees = 'Trustees',
  circleTrusteesPet = 'Pet Trustee(s)',
  circleTrusteesDependents = `Dependents' Trust Trustee(s)`,

  circleGuardians = 'Guardians',
  circleGuardiansChildren = `Children's Guardian(s)`,

  circleExecutors = 'Executors and Agents',
  circleExecutorsAccount = 'LVED Account Agents',
  circleExecutorsWill = 'Will Executors',

  circleAgents = 'Other Agents',
  circleAgentsFinancial = 'Financial Power of Attorney',
  circleAgentsHealthcarePower = 'Healthcare Power of Attorney',
  circleAgentsHealthcareSupport = 'Healthcare Support',
  circleAgentsOthers = 'Other Service Providers',

  estateAssets = 'Assets',
  estateAssetsBequestable = 'Bequestable asset',
  estateAssetsOther = 'Other assets',

  estateLiabilities = 'Liabilities',
  estateSolicitations = 'Interest Solicitations',

  legacyStories = 'Stories',
  legacyFinalMessages = 'Final Messages',

  commitmentsExecutor = 'As an Account Agent',
  commitmentsExecutorOther = 'Authenticate, Repudiate role, Register Death or Incapacitation',
  commitmentsExecutorBrowseDocuments = `Browse customer's documents`,
  commitmentsExecutorReleaseMessages = `Release customer's final messages`,
  commitmentsExecutorShareStories = `Share customer's stories`,
  commitmentsOther = 'Other and Pending Responsibilties',

  settingsProfile = 'Profile',

  settingsSubscription = 'Subscription',
  settingsSubscriptionManage = 'Manage Subscription',
  settingsSubscriptionPaymentInfo = 'Payment Information',

  settingsSecurity = 'Security',

  unauthenticatedStories = 'Watch Stories',
  unauthenticatedMessage = 'Watch Personal Message',
  unauthenticatedFeedback = 'Provide Feedback',
}
