import React, { ReactElement, FormEvent, useState } from 'react';

import { useElements, useStripe, PaymentElement } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { Button, VStack, Box } from '@chakra-ui/react';

import * as notifications from '@modules/core/notifications';

import { paymentFormStyles } from './styles';

import { APP_ROUTES } from '@modules/core/constants';

function PaymentForm(): ReactElement {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const stripe: Stripe | null = useStripe();
  const elements: StripeElements | null = useElements();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!(stripe && elements)) return;

    setIsProcessing(true);

    try {
      const { error: stripeError } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.origin + APP_ROUTES.paymentCompletion,
        },
      });

      if (stripeError) {
        notifications.showErrorMessage(stripeError.message || 'Unknown error');
      }
    } catch (err: any) {
      notifications.showErrorMessage(err.message || JSON.stringify(err));
    }

    setIsProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <VStack {...paymentFormStyles.mainContainer}>
        <Box {...paymentFormStyles.paymentElementsBox}>
          <PaymentElement />
        </Box>

        <Button
          isDisabled={!stripe}
          isLoading={isProcessing}
          type='submit'
          {...paymentFormStyles.button}
        >
          Submit
        </Button>
      </VStack>
    </form>
  );
}

export default PaymentForm;
