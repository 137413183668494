import React, { ReactElement } from 'react';

import { Box, Button, VStack, Text, Link } from '@chakra-ui/react';

import { pricingStyles } from './styles';

import { Avatar } from '@app/modules/common-ui/components/profile-basic-info/Avatar';
import {
  ILicenseBlockApplicaitonRes,
  IProductsListRes,
} from '@app/modules/core/aws/lambdas/payments/interfaces';
import { useQuery } from '@app/modules/core/hooks';

import acceptCorporatePlan from '@app/modules/core/aws/lambdas/payments/accept-corporate-plan';
import Header from '@app/modules/common-ui/components/header';

export function AcceptCorporateSubscription({
  affiliateLogo,
  affiliateName,
  promoCode,
}: IProductsListRes): ReactElement {
  const { submit, res, isLoading, err } = useQuery<
    { promoCode: string },
    ILicenseBlockApplicaitonRes
  >(acceptCorporatePlan);

  return (
    <Box {...pricingStyles.backgroundContainer}>
      <VStack paddingTop='100px' paddingBottom='130px'>
        <Header />

        <VStack spacing='15px'>
          <Text {...pricingStyles.heading}>Employer Sponsored Plan</Text>
          <VStack>
            {affiliateName && <Avatar file={affiliateLogo} style={pricingStyles.affiliateLogo} />}
            <Box h={affiliateName ? '100px' : 'auto'}>
              <Text>{affiliateName}</Text>
            </Box>
          </VStack>
          {!res && (
            <Button
              {...pricingStyles.button}
              isLoading={isLoading}
              type='submit'
              onClick={() => {
                if (promoCode) submit({ promoCode });
              }}
            >
              Accept Sponsored Subscription
            </Button>
          )}
          {err && <Text>{err.message}</Text>}
          {err && <Link href={err.message}>Test</Link>}
          {res && (
            <Text>
              Your request has been submitted. We will email you when your subscription has been
              approved.
            </Text>
          )}
        </VStack>
      </VStack>
    </Box>
  );
}
