import React, { ReactElement, useEffect } from 'react';

import { Box } from '@chakra-ui/react';

import { getStorageUsage } from '@modules/core/aws/lambdas/profile';
import { useQuery } from '@modules/core/hooks';
import { Section } from '@modules/common-ui/components/section';
import { ConditionSeparator } from '@modules/common-ui/components/condition-separator';
import { IStorageUsage } from '@modules/core/aws/lambdas/profile/interfaces';

import StorageUsage from '@modules/common-ui/components/storage-usage';

import { profileStorageUsageStyles } from '@modules/common-ui/components/profile-storage-usage/styles';

function ProfileStorageUsage(): ReactElement {
  const { submit, isLoading, res: storageData } = useQuery<void, IStorageUsage>(getStorageUsage);

  useEffect((): void => {
    submit();
  }, []);

  return (
    <Box {...profileStorageUsageStyles.container}>
      <Section isLoading={isLoading} title='Storage Usage'>
        <ConditionSeparator
          condition={Boolean(storageData)}
          target={
            <StorageUsage
              files={storageData?.files || 0}
              usedMemory={storageData?.size || 0}
              availableMemory={storageData?.available || 0}
            />
          }
        />
      </Section>
    </Box>
  );
}

export default ProfileStorageUsage;
