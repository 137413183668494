export enum EVENTS_CHANNELS {
  auth = 'auth',
}

export enum EVENTS {
  signIn = 'signIn',
  signOut = 'signOut',
  signUp = 'signUp',
  signInFailure = 'signIn_failure',
  signUpFailure = 'signUp_failure',
  forgotPassword = 'forgotPassword',
  forgotPasswordSubmit = 'forgotPasswordSubmit',
  forgotPasswordSubmitFailure = 'forgotPasswordSubmit_failure',
  forgotPasswordFailure = 'forgotPassword_failure',
}
