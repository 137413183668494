import React, { ReactElement, useEffect, useState, useMemo } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';

import { Button, VStack, Text } from '@chakra-ui/react';

import { showSuccessMessage as showSuccessToast } from '@modules/core/notifications';
import { getDecryptWorker } from '@modules/core/workers';
import { useWorker, useAuth } from '@modules/core/hooks';

import Modal from '@modules/common-ui/components/modal';
import { ConditionSeparator } from '../condition-separator';
import { TextAreaInput } from '@modules/common-ui/components/text-field';
import { IAuthContext } from '@modules/core/contexts/auth/interfaces';
import { DecryptEncryptionKeyModalPropsType } from './interfaces';
import {
  IDecryptWorkerRes as IWorkerRes,
  IDecryptWorkerProps as IWorkerProps,
} from '@modules/core/workers/interfaces';

import { decryptEncryptionKeyModalStyles } from './styles';

import { APP_ROUTES, ANCHORS } from '@modules/core/constants';

function DecryptEncryptionKeyModal({
  isOpen,
  setKey,
  onClose,
}: DecryptEncryptionKeyModalPropsType): ReactElement {
  const worker: Worker = useMemo<Worker>(getDecryptWorker, []);
  const navigateTo: NavigateFunction = useNavigate();

  const { user }: IAuthContext = useAuth();
  const { isLoading, res, error, startWorker } = useWorker<IWorkerProps, IWorkerRes>(worker);
  const [passphrase, setPassphrase] = useState<string>('');

  const decryptEncryptionKey = (): void => {
    const encryptedKey: ArrayBuffer = user?.['Encrypted Encryption Key'] || new ArrayBuffer(0);

    if (!(encryptedKey && passphrase)) return;

    startWorker({ passphrase, encryptedKey });
  };

  const openProfilePage = (): void => {
    return navigateTo(`${APP_ROUTES.profile}${ANCHORS.keysGeneration}`);
  };

  const closeModal = (): void => {
    if (passphrase) setPassphrase('');
    onClose();
  };

  useEffect(() => {
    if (res?.encryptionKey) {
      setKey(res.encryptionKey);
      showSuccessToast('Encryption key was decrypted successfully');
    }
  }, [res]);

  return (
    <Modal isOpen={isOpen} onClose={closeModal} title='Decryption of the Encryption Key'>
      <VStack {...decryptEncryptionKeyModalStyles.contentBox} alignItems='flex-start'>
        <ConditionSeparator
          condition={!!user?.['Encrypted Encryption Key']}
          defaultTarget={<Text>You need to generate an encryption key firstly</Text>}
          target={
            <TextAreaInput
              disabled={isLoading}
              value={passphrase}
              onChange={(e) => setPassphrase(e.target.value)}
              label='Please provide a passphrase'
              id='passphrase'
              isError={!!error}
              errorMessage={error?.message}
            />
          }
        />

        <ConditionSeparator
          condition={!!user?.['Encrypted Encryption Key']}
          defaultTarget={
            <Button onClick={openProfilePage} {...decryptEncryptionKeyModalStyles.button}>
              Generate the key
            </Button>
          }
          target={
            <Button
              isLoading={isLoading}
              isDisabled={!passphrase}
              onClick={decryptEncryptionKey}
              {...decryptEncryptionKeyModalStyles.button}
            >
              Decrypt the Encryption Key
            </Button>
          }
        />
      </VStack>
    </Modal>
  );
}

export default DecryptEncryptionKeyModal;
