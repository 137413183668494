import {
  CircleIcon,
  CommitmentsIcon,
  DashboardIcon,
  DigitalVaultIcon,
  EstateIcon,
  LegacyIcon,
  SettingsIcon,
  //  UnAuthIcon,
  WishesIcon,
} from '@modules/common-ui/icons';

import { SideBarItemsListType } from '@modules/common-ui/components/side-bar/interfaces';

import { APP_ROUTES, NAVIGATION_TITLES } from '@modules/core/constants';

export const SIDE_BAR_ITEMS: SideBarItemsListType = [
  {
    title: NAVIGATION_TITLES.dashboard,
    icon: DashboardIcon,
    path: APP_ROUTES.dashboard,
  },
  {
    title: NAVIGATION_TITLES.circle,
    icon: CircleIcon,
    children: [
      {
        title: NAVIGATION_TITLES.onboarding,
        path: APP_ROUTES.onboarding,
      },
      {
        title: NAVIGATION_TITLES.circleBeneficiaries,
        path: APP_ROUTES.leaveCurrentPage,
      },
      {
        title: NAVIGATION_TITLES.circleTrustees,
        children: [
          {
            title: NAVIGATION_TITLES.circleTrusteesDependents,
            path: APP_ROUTES.leaveCurrentPage,
          },
          {
            title: NAVIGATION_TITLES.circleTrusteesPet,
            path: APP_ROUTES.leaveCurrentPage,
          },
        ],
      },
      {
        title: NAVIGATION_TITLES.circleGuardians,
        children: [
          {
            title: NAVIGATION_TITLES.circleGuardiansChildren,
            path: APP_ROUTES.leaveCurrentPage,
          },
        ],
      },
      {
        title: NAVIGATION_TITLES.circleExecutors,
        children: [
          {
            title: NAVIGATION_TITLES.circleExecutorsAccount,
            path: APP_ROUTES.leaveCurrentPage,
          },
          {
            title: NAVIGATION_TITLES.circleExecutorsWill,
            path: APP_ROUTES.leaveCurrentPage,
          },
        ],
      },
      {
        title: NAVIGATION_TITLES.circleAgents,
        children: [
          {
            title: NAVIGATION_TITLES.circleAgentsFinancial,
            path: APP_ROUTES.leaveCurrentPage,
          },
          {
            title: NAVIGATION_TITLES.circleAgentsHealthcarePower,
            path: APP_ROUTES.leaveCurrentPage,
          },
          {
            title: NAVIGATION_TITLES.circleAgentsHealthcareSupport,
            path: APP_ROUTES.leaveCurrentPage,
          },
          {
            title: NAVIGATION_TITLES.circleAgentsOthers,
            path: APP_ROUTES.leaveCurrentPage,
          },
        ],
      },
    ],
  },
  {
    title: NAVIGATION_TITLES.estate,
    icon: EstateIcon,
    children: [
      {
        title: NAVIGATION_TITLES.onboarding,
        path: APP_ROUTES.leaveCurrentPage,
      },
      {
        title: NAVIGATION_TITLES.estateAssets,
        children: [
          {
            title: NAVIGATION_TITLES.estateAssetsBequestable,
            path: APP_ROUTES.leaveCurrentPage,
          },
          {
            title: NAVIGATION_TITLES.estateAssetsOther,
            path: APP_ROUTES.leaveCurrentPage,
          },
        ],
      },
      {
        title: NAVIGATION_TITLES.estateLiabilities,
        path: APP_ROUTES.leaveCurrentPage,
      },
      {
        title: NAVIGATION_TITLES.estateSolicitations,
        path: APP_ROUTES.leaveCurrentPage,
      },
    ],
  },
  {
    title: NAVIGATION_TITLES.wishes,
    icon: WishesIcon,
    children: [
      {
        title: NAVIGATION_TITLES.onboarding,
        path: APP_ROUTES.leaveCurrentPage,
      },
      {
        title: NAVIGATION_TITLES.wishesBequests,
        children: [
          {
            title: NAVIGATION_TITLES.wishesBequestsCash,
            path: APP_ROUTES.leaveCurrentPage,
          },
          {
            title: NAVIGATION_TITLES.wishesBequestsAsset,
            path: APP_ROUTES.leaveCurrentPage,
          },
        ],
      },
      {
        title: NAVIGATION_TITLES.wishesRemainderDistribution,
        children: [
          {
            title: NAVIGATION_TITLES.wishesRemainderDistributionPrincipal,
            path: APP_ROUTES.leaveCurrentPage,
          },
          {
            title: NAVIGATION_TITLES.wishesRemainderDistributionDisinherit,
            path: APP_ROUTES.leaveCurrentPage,
          },
        ],
      },
      {
        title: NAVIGATION_TITLES.wishesTrusts,
        children: [
          {
            title: NAVIGATION_TITLES.wishesTrustSpousal,
            path: APP_ROUTES.leaveCurrentPage,
          },
          {
            title: NAVIGATION_TITLES.wishesTrustsChildren,
            path: APP_ROUTES.leaveCurrentPage,
          },
          {
            title: NAVIGATION_TITLES.wishesTrustsPets,
            path: APP_ROUTES.leaveCurrentPage,
          },
        ],
      },
      {
        title: NAVIGATION_TITLES.wishesForMe,
        children: [
          {
            title: NAVIGATION_TITLES.wishesForMeFuneralPlans,
            path: APP_ROUTES.leaveCurrentPage,
          },
          {
            title: NAVIGATION_TITLES.wishesForMeBodyHandlingInstructions,
            path: APP_ROUTES.leaveCurrentPage,
          },
          {
            title: NAVIGATION_TITLES.wishesForMeLifeSupportDirectives,
            path: APP_ROUTES.leaveCurrentPage,
          },
        ],
      },
      {
        title: NAVIGATION_TITLES.wishesLegalDocuments,
        children: [
          {
            title: NAVIGATION_TITLES.wishesLegalDocumentsWill,
            path: APP_ROUTES.leaveCurrentPage,
          },
          {
            title: NAVIGATION_TITLES.wishesLegalDocumentsScan,
            path: APP_ROUTES.leaveCurrentPage,
          },
        ],
      },
    ],
  },
  {
    title: NAVIGATION_TITLES.legacy,
    icon: LegacyIcon,
    children: [
      {
        title: NAVIGATION_TITLES.onboarding,
        path: APP_ROUTES.leaveCurrentPage,
      },
      {
        title: NAVIGATION_TITLES.legacyStories,
        path: APP_ROUTES.leaveCurrentPage,
      },
      {
        title: NAVIGATION_TITLES.legacyFinalMessages,
        path: APP_ROUTES.leaveCurrentPage,
      },
    ],
  },
  {
    title: NAVIGATION_TITLES.digitalVault,
    icon: DigitalVaultIcon,
    children: [
      {
        title: NAVIGATION_TITLES.onboarding,
        path: APP_ROUTES.leaveCurrentPage,
      },
      {
        title: NAVIGATION_TITLES.digitalVault,
        path: APP_ROUTES.digitalVault,
      },
    ],
  },
  {
    title: NAVIGATION_TITLES.commitments,
    icon: CommitmentsIcon,
    children: [
      {
        title: NAVIGATION_TITLES.commitmentsExecutor,
        children: [
          {
            title: NAVIGATION_TITLES.commitmentsExecutorOther,
            path: APP_ROUTES.leaveCurrentPage,
          },
          {
            title: NAVIGATION_TITLES.commitmentsExecutorBrowseDocuments,
            path: APP_ROUTES.leaveCurrentPage,
          },
          {
            title: NAVIGATION_TITLES.commitmentsExecutorReleaseMessages,
            path: APP_ROUTES.leaveCurrentPage,
          },
          {
            title: NAVIGATION_TITLES.commitmentsExecutorShareStories,
            path: APP_ROUTES.leaveCurrentPage,
          },
        ],
      },
      {
        title: NAVIGATION_TITLES.commitmentsOther,
        path: APP_ROUTES.leaveCurrentPage,
      },
    ],
  },
  {
    title: NAVIGATION_TITLES.settings,
    icon: SettingsIcon,
    children: [
      {
        title: NAVIGATION_TITLES.settingsProfile,
        path: APP_ROUTES.profile,
      },
      {
        title: NAVIGATION_TITLES.settingsSubscription,
        children: [
          {
            title: NAVIGATION_TITLES.settingsSubscriptionManage,
            path: APP_ROUTES.subscriptionManagement,
          },
          {
            title: NAVIGATION_TITLES.settingsSubscriptionPaymentInfo,
            path: APP_ROUTES.leaveCurrentPage,
          },
        ],
      },
      {
        title: NAVIGATION_TITLES.settingsSecurity,
        path: APP_ROUTES.leaveCurrentPage,
      },
    ],
  },
  /* {
    title: NAVIGATION_TITLES.unauthenticated,
    icon: UnAuthIcon,
    children: [
      {
        title: NAVIGATION_TITLES.unauthenticatedStories,
        path: APP_ROUTES.leaveCurrentPage,
      },
      {
        title: NAVIGATION_TITLES.unauthenticatedMessage,
        path: APP_ROUTES.leaveCurrentPage,
      },
      {
        title: NAVIGATION_TITLES.unauthenticatedFeedback,
        path: APP_ROUTES.leaveCurrentPage,
      },
    ],
  },
  */
];
