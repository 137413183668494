import { loadStripe } from '@stripe/stripe-js';

import { StripePromiseType } from './interfaces';

let stripePromise: StripePromiseType;

export const getStripe = (): StripePromiseType => {
  if (!process.env.REACT_APP_STRIPE_KEY) throw new Error('Stripe key is not provided!');
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  }

  return stripePromise;
};
