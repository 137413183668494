import React, { ReactElement } from 'react';

import {
  // Popover,
  // PopoverContent,
  // PopoverHeader,
  // PopoverArrow,
  // PopoverBody,
  // PopoverCloseButton,
  Button,
  Box,
  Text,
  // PopoverFooter,
  // useDisclosure,
} from '@chakra-ui/react';
import { useFormik } from 'formik';

import { mfaValidationSchema } from './validation-schema';
import { TextInput } from '@modules/common-ui/components/text-field';
import { useQuery, useAuth } from '@modules/core/hooks';
import { IAuthContext, IMFAChallengeData } from '@modules/core/contexts/auth/interfaces';

import { mfaEntryStyles } from './styles';

export interface mfaCodeEntryProps {
  isAuthChallenge: boolean;
}

function MfaCodeEntry(props: mfaCodeEntryProps): ReactElement {
  const { submitMFACode, codeDestination }: IAuthContext = useAuth();
  const { submit, isLoading, err } = useQuery<IMFAChallengeData, void>(submitMFACode);

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    onSubmit: props.isAuthChallenge ? submit : submit,
    validationSchema: mfaValidationSchema,
    initialValues: {
      code: '',
      source: codeDestination === 'SOFTWARE_TOKEN_MFA' ? 'SOFTWARE_TOKEN_MFA' : 'MFA_SMS',
    },
  });

  return (
    <Box {...mfaEntryStyles.mfaContainer}>
      <hr />
      <Box {...mfaEntryStyles.mfaHeader}>
        <Text {...mfaEntryStyles.headerText}>Multi-factor Authentication Required</Text>
        <Text {...mfaEntryStyles.textGreen}>
          {codeDestination === 'SOFTWARE_TOKEN_MFA' &&
            'Enter the code provided by your authenticator app'}
          {codeDestination !== 'SOFTWARE_TOKEN_MFA' &&
            `Enter the code sent to you at ${codeDestination}`}
        </Text>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box {...mfaEntryStyles.formBox}>
          <TextInput
            {...mfaEntryStyles.mfaInput}
            id='code'
            name='code'
            label='MFA Code'
            onChange={handleChange}
            value={values.code}
            isError={!!errors.code && touched.code}
            errorMessage={errors.code}
          />

          <Button isLoading={isLoading} type='submit' {...mfaEntryStyles.submitButton}>
            Submit code
          </Button>
        </Box>
      </form>

      {err && (
        <Box {...mfaEntryStyles.mfaHeader}>
          <Text {...mfaEntryStyles.textError}>{err?.message || 'unknown error'}</Text>
        </Box>
      )}
    </Box>
  );

  // const { isOpen, onToggle, onClose } = useDisclosure();
  // const [isOpen, setOpen] = useState(true);
  // <Button onClick={onToggle}>MFA Code Required</Button>
  /* return (
    <>
      <Popover
        isOpen={isOpen}
        onClose={() => {
          setOpen(false);
        }}
      >
        <PopoverContent>
          <Box w='100vw' h='100vh' alignContent='center' alignItems='center'>
            <Box w='300px' h='200px'>
              <PopoverHeader>Multi-factor Authentication Challenge</PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <Box {...mfaEntryStyles.textContainer}>
                  <Text {...mfaEntryStyles.textGreen}>
                    Enter the code sent to you at {codeDestination}
                  </Text>
                </Box>
                <form onSubmit={handleSubmit}>
                  <TextInput
                    {...mfaEntryStyles.mfaInput}
                    id='code'
                    name='code'
                    label='MFA Code'
                    onChange={handleChange}
                    value={values.code}
                    isError={!!errors.code && touched.code}
                    errorMessage={errors.code}
                  />

                  <Button isLoading={isLoading} type='submit' {...mfaEntryStyles.submitButton}>
                    Submit code
                  </Button>
                </form>
              </PopoverBody>

              {err && (
                <PopoverFooter>
                  <Box {...mfaEntryStyles.textContainer}>
                    <Text {...mfaEntryStyles.textError}>{err?.message || 'unknown error'}</Text>
                  </Box>
                </PopoverFooter>
              )}
            </Box>
          </Box>
        </PopoverContent>
      </Popover>
    </>
  ); */
}

export default MfaCodeEntry;
