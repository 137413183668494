import React, { ReactElement } from 'react';

import { List } from '@chakra-ui/react';

import { SideBarCollapseItem } from '@modules/common-ui/components/side-bar/SideBarCollapseItem';
import { SideBarItem } from '@modules/common-ui/components/side-bar/SideBarItem';
import {
  ISideBarListProps,
  SideBarItemType,
} from '@modules/common-ui/components/side-bar/interfaces';

import { sideBarStyles } from '@modules/common-ui/components/side-bar/styles';

export function SideBarList({
  items,
  isNested = false,
  toggleNavMenuVisibility,
}: ISideBarListProps): ReactElement {
  return (
    <List {...sideBarStyles.commonItemsList} {...(isNested && sideBarStyles.nestedItemsList)}>
      {items.map((item: SideBarItemType) =>
        item.children ? (
          <SideBarCollapseItem
            item={item}
            key={item.title}
            toggleNavMenuVisibility={toggleNavMenuVisibility}
          />
        ) : (
          <SideBarItem
            item={item}
            key={item.title}
            toggleNavMenuVisibility={toggleNavMenuVisibility}
          />
        )
      )}
    </List>
  );
}
