import { Auth } from 'aws-amplify';

import { lambdaGeneralCaller } from '@modules/core/aws/lambdas/lambda-general-caller/lambdaGeneralCaller';
import {
  ICredentials,
  IPaymentsHistoryRes,
  IPaymentsHistoryLambdaRes,
} from '@modules/core/aws/lambdas/payments/interfaces';

import { AWS_LAMBDAS, AWS_LAMBDAS_OPERATIONS, CONTINUE_WITH } from '@modules/core/constants';

async function getPaymentsHistory(continueWith: string = ''): Promise<IPaymentsHistoryRes> {
  const credentials: ICredentials = await Auth.currentCredentials();
  const res: IPaymentsHistoryLambdaRes = await lambdaGeneralCaller<IPaymentsHistoryLambdaRes>(
    credentials,
    {
      FunctionName: AWS_LAMBDAS.payments,
      Payload: JSON.stringify({
        operation: AWS_LAMBDAS_OPERATIONS.getPayments,
        ...(continueWith && { continueWith }),
      }),
    }
  );

  return {
    payments: res?.payments || [],
    isNextPageAvaliable: res?.continueWith !== CONTINUE_WITH.noMoreHistory,
    nextPageId: res?.continueWith,
  };
}

export default getPaymentsHistory;
