import { useState } from 'react';

import { IUseQueryResponse } from './interfaces';

function useQuery<Req, Res>(fetcher: (data: Req) => Promise<Res>): IUseQueryResponse<Req, Res> {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<Res | null>(null);

  const submit = async (reqData: Req): Promise<void> => {
    try {
      setIsLoading(true);
      const res: Res = await fetcher(reqData);
      setError(null);
      setData(res);
    } catch (err) {
      setData(null);
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  return { submit, res: data, isLoading, err: error };
}

export default useQuery;
