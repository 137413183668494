import React, { ReactElement, useEffect, useRef, useState } from 'react';

import {
  Wrap,
  HStack,
  Image,
  Text,
  VStack,
  UnorderedList,
  ListItem,
  Link,
  Box,
  Center,
  Show,
  Button,
} from '@chakra-ui/react';

import Loader from '@modules/common-ui/components/global-loader';
import { landingPageStyles } from './styles';
import { ConditionSeparator } from '@app/modules/common-ui/components/condition-separator';
import UnAuthHeader from '@app/modules/common-ui/components/unAuthHeader';
import UnAuthFooter from '@app/modules/common-ui/components/unAuthFooter';
import { SignUpForm } from '../sign-up/SignUp';
// import { useAppEntryParams } from '@modules/core/hooks';
import { LandingPageDataType } from '@app/modules/core/landingPages/interfaces';
import { useParams } from 'react-router';
import { useAppEntryParams } from '@app/modules/core/hooks';
import testPage from './test.json';
import { APP_ROUTES } from '@app/modules/core/constants';
import { LogPageView } from '@app/modules/core/google-analytics/google-analytics-tracker';

// const familyCooking = require('@assets/images/familyMealPrep.jpg');
// const familySunset = require('@assets/images/familySunset.jpg');

function LandingPage(): ReactElement {
  const { landingURL } = useParams();
  const [pageContent, setPageContent] = useState<LandingPageDataType | null>();
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [err, setErr] = useState<string | undefined>();
  const appEntryParams = useAppEntryParams();
  const signupRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (landingURL?.toLowerCase() === 'localtest') {
      setPageContent(testPage as LandingPageDataType);
    } else
      fetch(`https://www.lved.com/dynamicLandingPages/${landingURL}.json`)
        .then((response) => {
          return response.json();
        })
        .then((jsonData) => {
          const targetLandingPage = jsonData as unknown as LandingPageDataType;
          // const targetLandingPage = pagesArray.find((page) => page.url === landingURL);
          setIsLoading(false);
          if (!targetLandingPage) {
            setErr('Unable to find the page you are looking for');
          } else {
            setPageContent(targetLandingPage);
            if (targetLandingPage.promoCode)
              appEntryParams.setPromoCodeManually(targetLandingPage.promoCode!);
            if (targetLandingPage.affiliateCode)
              appEntryParams.setAffiliateManually(targetLandingPage.affiliateCode!);
            setErr(undefined);

            const pageURL = `${APP_ROUTES.landingPageNavigationBase}${landingURL}`;
            LogPageView(pageURL, targetLandingPage?.tagLine);
          }
        })
        .catch((error) => {
          setErr(error.message);
        });
  }, []);

  const scrollToSignup = () => {
    if (window.innerWidth <= 767) {
      signupRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <VStack
      bg={pageContent?.pageBackgroundColor ?? 'white'}
      color={pageContent?.pageTextColor ?? 'black'}
    >
      <UnAuthHeader activeLink='' />
      <HStack h={{ base: '65px', lg: '100px' }} />
      {isLoading ? (
        <Loader />
      ) : (
        <ConditionSeparator
          condition={!err}
          target={
            <VStack w='100%'>
              <Wrap {...landingPageStyles.wrapContainer}>
                <VStack {...landingPageStyles.promoTextContainer}>
                  <ConditionSeparator
                    condition={(pageContent?.imageURL ?? '').endsWith('.mp4')}
                    target={
                      <Box
                        as='video'
                        muted
                        autoPlay
                        loop
                        borderRadius={pageContent?.imageBorderRadius ?? 0}
                        src={`https://${pageContent?.imageURL ?? ''}`}
                        poster=''
                        objectFit='contain'
                      />
                    }
                    defaultTarget={
                      <Image
                        {...landingPageStyles.welcomeImage}
                        borderRadius={pageContent?.imageBorderRadius ?? 0}
                        src={`https://${pageContent?.imageURL ?? ''}`}
                      />
                    }
                  />
                  <Text {...landingPageStyles.promoTagLine}>
                    {pageContent?.tagLine ?? '<tagLine>'}
                  </Text>
                  {pageContent?.subTagline && <Text>{pageContent!.subTagline!}</Text>}
                  {pageContent?.preFeatureContent &&
                    pageContent!.preFeatureContent!.map((content) => {
                      return <Text key={content}>{content}</Text>;
                    })}
                  <Show breakpoint='(max-width: 767px)'>
                    <VStack w='100%' justifyItems='center'>
                      <Text
                        color={pageContent?.signupLinkColor ?? 'brand.green.100'}
                        onClick={scrollToSignup}
                      >
                        Signup up now
                      </Text>
                    </VStack>
                  </Show>
                  <Text {...landingPageStyles.promoTextHighlight}>
                    {pageContent?.featureHeading ?? '<featureHeading>'}
                  </Text>
                  {pageContent?.features && (
                    <UnorderedList {...landingPageStyles.benefitsList}>
                      {pageContent?.features?.map((feature) => {
                        if (pageContent?.boldFeatureTitles) {
                          const featureTitle = feature.split(':')[0];
                          const featureDescription = feature.substring(featureTitle.length);
                          return (
                            <ListItem key={feature}>
                              <b>{featureTitle}</b>
                              {featureDescription}
                            </ListItem>
                          );
                        }
                        return <ListItem key={feature}>{feature}</ListItem>;
                      })}
                    </UnorderedList>
                  )}
                  {pageContent?.postFeatureContent &&
                    pageContent!.postFeatureContent!.map((content) => {
                      return <Text key={content}>{content}</Text>;
                    })}
                  <Text>{pageContent?.summary ?? '<summary>'}</Text>
                  {!pageContent?.actionButton && <section ref={signupRef} />}
                  <Text>{pageContent?.callToAction ?? '<callToAction>'}</Text>
                  {pageContent?.actionButton && (
                    <VStack w='100%' justifyItems='center'>
                      <section ref={signupRef} />
                      <Button {...landingPageStyles.getStartedButton} onClick={scrollToSignup}>
                        {pageContent.actionButton!}
                      </Button>
                    </VStack>
                  )}
                </VStack>
                <SignUpForm
                  linkColor={pageContent?.signupLinkColor}
                  transparentBackground
                  showLogo={false}
                />
              </Wrap>
              {pageContent?.explainerVideoURL && (
                <VStack {...landingPageStyles.explainerVideoContainer}>
                  <Text {...landingPageStyles.faqTitle}>
                    Not sure if Lved is right for you, here&apos;s a quick explanation of our
                    services:
                  </Text>
                  <Box
                    as='iframe'
                    {...landingPageStyles.explainerVideo}
                    title='YouTube video player'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen'
                    src={`https://${pageContent!.explainerVideoURL!}`}
                  />
                </VStack>
              )}
              {pageContent?.faqs && (
                <VStack {...landingPageStyles.faqContainer}>
                  <Text {...landingPageStyles.faqTitle}>FREQUENTLY ASKED QUESTIONS</Text>
                  <VStack {...landingPageStyles.faqListContainer}>
                    {pageContent!.faqs!.map((faq) => {
                      return (
                        <div key={faq.question}>
                          <Text {...landingPageStyles.faqQuestion} key={faq.question}>
                            {faq.question}
                          </Text>
                          <Text {...landingPageStyles.faqAnswer} key={faq.answer}>
                            {faq.answer}
                          </Text>
                        </div>
                      );
                    })}
                  </VStack>
                  <Center>
                    <Link href='https://app.lved.com/faq'>
                      <Text color={pageContent?.signupLinkColor ?? 'brand.green.100'}>
                        Visit our FAQ page for more
                      </Text>
                    </Link>
                  </Center>
                </VStack>
              )}
              {!pageContent?.faqs && (
                <Link href='https://app.lved.com/faq'>
                  <Text color={pageContent?.signupLinkColor ?? 'brand.green.100'}>
                    Visit our FAQ page for more information
                  </Text>
                </Link>
              )}
            </VStack>
          }
          defaultTarget={
            <VStack h='100vh'>
              <Text {...landingPageStyles.errorText}>
                There was an error retrieving this landing page. Please check the URL and try again.
              </Text>
            </VStack>
          }
        />
      )}
      <UnAuthFooter />
    </VStack>
  );
}

export default LandingPage;
