import { getUserProfile } from '@app/modules/core/aws/dynamo-db/user-profile';
import { IAuthContext, IMFAPreferences } from '@app/modules/core/contexts/auth/interfaces';
import { useAuth } from '@app/modules/core/hooks';
import { Box, Flex, HStack, Image, Spacer, Text, VStack } from '@chakra-ui/react';
import Modal from '@modules/common-ui/components/modal/ModalBasic';
import React, { ReactElement, useEffect, useState } from 'react';
import { ConditionSeparator } from '../condition-separator';
import { SetActiveMFAMethodType } from './interfaces';
import { manageMFAStyles, MFASharedStyles } from './styles';
import chevronRight from '@assets/images/chevron-right.svg';
import toggleOff from '@assets/images/toggle_off.svg';
import toggleOn from '@assets/images/toggle_on.svg';
import errorIcon from '@assets/images/error_filled.svg';
import MobileDeviceMFA from './MobileDeviceMFA';
import TOTPMFASetup from './TOTPMFASetup';
import { Section } from '../section';

function ManageMFAModal(): ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  const [userIdentifier, setUserIdentifier] = useState<string | null>();
  const [currentMFAPreference, setCurrentMFAPreference] = useState<IMFAPreferences | null>();
  const { getMFAPreference, setMFAPreference }: IAuthContext = useAuth();
  const [mfaEnabledToggle, setMFAEnabledToggle] = useState(false);
  const [showingPhoneValidation, setShowingPhoneValidation] = useState(false);
  const [showingTOTPValidation, setShowingTOTPValidation] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>();

  function modalClosed() {
    if (currentMFAPreference) {
      setMFAEnabledToggle(
        currentMFAPreference.preferredMFASource &&
          currentMFAPreference.preferredMFASource !== 'NONE'
      );
    }
    setIsOpen(false);
  }

  const setActiveMFAMethod: SetActiveMFAMethodType = async (
    MFAType: 'SMS' | 'TOTP' | 'NONE',
    updatedMobileNo?: string | null
  ): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject): void => {
      if (MFAType === 'SMS' && currentMFAPreference?.preferredMFASource === 'SMS_MFA') {
        if (updatedMobileNo)
          setCurrentMFAPreference({
            ...currentMFAPreference,
            phoneNumber: updatedMobileNo,
            isPhoneVerified: true,
          });
        resolve(true);
        return;
      }
      if (MFAType === 'TOTP' && currentMFAPreference?.preferredMFASource === 'SOFTWARE_TOKEN_MFA') {
        resolve(true);
        return;
      }
      if (MFAType === 'NONE' && currentMFAPreference?.preferredMFASource === 'NONE') {
        resolve(true);
        return;
      }
      switch (MFAType) {
        case 'SMS':
          /* if (!currentMFAPreference?.isPhoneVerified) {
            setShowingPhoneValidation(true);
            resolve(false);
            return;
          } */
          setMFAPreference(true, false, true).then((success) => {
            if (success) {
              setCurrentMFAPreference({
                isSMSEnabled: true,
                isTOTPEnabled: false,
                preferredMFASource: 'SMS_MFA',
                phoneNumber: updatedMobileNo ?? currentMFAPreference?.phoneNumber,
                isPhoneVerified: updatedMobileNo ? true : currentMFAPreference?.isPhoneVerified,
              });
              resolve(true);
            } else reject(new Error('Unable to turn on SMS authentication'));
          });
          return;

        case 'TOTP':
          /* if (!currentMFAPreference?.isTOTPEnabled) {
            setShowingTOTPValidation(true);
            resolve(false);
            return;
          } */
          setMFAPreference(false, true, false).then((success) => {
            if (success) {
              setCurrentMFAPreference({
                ...currentMFAPreference!,
                isSMSEnabled: false,
                isTOTPEnabled: true,
                preferredMFASource: 'SOFTWARE_TOKEN_MFA',
              });
              resolve(true);
            } else reject(new Error('Unable to set MFA preference'));
          });
          return;

        default:
          setMFAPreference(false, false, false).then((success) => {
            if (success) {
              setCurrentMFAPreference({
                ...currentMFAPreference!,
                isSMSEnabled: false,
                isTOTPEnabled: false,
                preferredMFASource: 'NONE',
              });
              resolve(true);
            } else reject(new Error('Unable to set MFA preference'));
          });
      }
    });
  };

  function descriptionForStatus(): string {
    if (
      !currentMFAPreference!.isSMSEnabled &&
      !currentMFAPreference!.isTOTPEnabled &&
      !currentMFAPreference!.isPhoneVerified
    )
      return 'You need to set up a multi-factor authentication device below to enable MFA.';

    switch (currentMFAPreference!.preferredMFASource) {
      case 'SOFTWARE_TOKEN_MFA':
        return `We'll ask for the authentication code presented in your authentication app when you attempt to log in`;
      case 'SMS_MFA':
        return `We'll ask for an authentication code when you attempt to log in;  Codes will be sent to: ${currentMFAPreference?.phoneNumber}`;
      default:
        return 'Please choose an option below to activate MFA for your account';
    }
  }

  function setMFAEnabled(enabled: boolean) {
    if (
      (enabled && currentMFAPreference?.preferredMFASource !== 'NONE') ||
      (!enabled && currentMFAPreference?.preferredMFASource === 'NONE')
    ) {
      setMFAEnabledToggle(!mfaEnabledToggle);
      return;
    }
    if (!enabled) {
      setErrorMessage('Disabling MFA...');
      setActiveMFAMethod('NONE').then((success) => {
        if (success) {
          setMFAEnabledToggle(false);
          setErrorMessage(null);
        } else setErrorMessage('Error disabling MFA - might want a password check to disable this');
      });
      return;
    }

    // If here, then user is trying to enable MFA...
    setMFAEnabledToggle(true);

    if (currentMFAPreference?.isPhoneVerified) {
      setErrorMessage('Enabling SMS authentication codes...');
      setActiveMFAMethod('SMS').then((success) => {
        if (success) setErrorMessage(null);
        else setErrorMessage('Unable to turn on MFA due to an unknown reason');
      });
    }
  }

  useEffect(() => {
    getMFAPreference().then((preferences) => {
      setCurrentMFAPreference(preferences);
      setMFAEnabledToggle(
        preferences.preferredMFASource && preferences.preferredMFASource !== 'NONE'
      );
      // console.log('Preferred MFA source...');
      // console.log(preferences.preferredMFASource);
    });
    getUserProfile().then((profile) => {
      setUserIdentifier(`${profile.fullName} (${profile.contactInfo.Email})`);
    });
  }, []);

  return (
    <Flex width='100%'>
      <Section title='Account Security' isLoading={false}>
        <Text
          {...(mfaEnabledToggle ? manageMFAStyles.sectionLink : MFASharedStyles.textError)}
          width='100%'
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          Multi-factor Authentication (MFA) {!mfaEnabledToggle ? '- needs attention' : ''}
        </Text>
      </Section>
      <Modal isOpen={isOpen} onClose={modalClosed} title=''>
        <ConditionSeparator
          condition={!showingPhoneValidation && !showingTOTPValidation}
          target={
            <VStack width='full' gap='10px'>
              <Text {...manageMFAStyles.userText}>{userIdentifier}</Text>
              <HStack width='full'>
                <Text {...manageMFAStyles.title}>
                  Two-factor authentication is
                  {mfaEnabledToggle ? ' enabled' : ' disabled'}
                </Text>
                <Spacer />
                <Image
                  {...manageMFAStyles.toggleImage}
                  src={mfaEnabledToggle ? toggleOn : toggleOff}
                  onClick={() => {
                    setMFAEnabled(!mfaEnabledToggle);
                  }}
                />
              </HStack>
              {mfaEnabledToggle && (
                <VStack gap='24px'>
                  <Text>{descriptionForStatus()}</Text>
                  <Text {...manageMFAStyles.authOptionsTitle}>How you get login codes</Text>
                  <Box {...manageMFAStyles.mfaOptionsContainer}>
                    <HStack
                      {...manageMFAStyles.mfaOptionContainer}
                      onClick={() => {
                        setShowingTOTPValidation(true);
                      }}
                    >
                      <VStack>
                        <Text {...manageMFAStyles.mfaOptionTitle}>Authentication app</Text>
                        <Text {...manageMFAStyles.mfaOptionSubTitle}>
                          You will get a login code from your Authentication App
                        </Text>
                      </VStack>
                      <Spacer />
                      <Image {...manageMFAStyles.rightCarretImg} src={chevronRight} />
                    </HStack>

                    <Box {...MFASharedStyles.divider} />

                    <HStack
                      {...manageMFAStyles.mfaOptionContainer}
                      onClick={() => {
                        setShowingPhoneValidation(true);
                      }}
                    >
                      <VStack gap='0px'>
                        <Text {...manageMFAStyles.mfaOptionTitle}>
                          Text message (SMS) - US mobile numbers only
                        </Text>
                        <Text {...manageMFAStyles.mfaOptionSubTitle}>
                          We will send a code to the mobile device you choose.
                        </Text>
                      </VStack>
                      <Spacer />
                      <Image {...manageMFAStyles.rightCarretImg} src={chevronRight} />
                    </HStack>
                  </Box>
                </VStack>
              )}
              {errorMessage && (
                <HStack {...MFASharedStyles.textErrorContainer}>
                  <Image {...MFASharedStyles.errorIcon} src={errorIcon} />
                  <Text color='red'>{errorMessage}</Text>
                </HStack>
              )}
            </VStack>
          }
          defaultTarget={
            <ConditionSeparator
              condition={showingPhoneValidation}
              target={
                <MobileDeviceMFA
                  currentMFAPreferences={currentMFAPreference!}
                  onClose={() => {
                    setShowingPhoneValidation(false);
                  }}
                  setMFAPreference={setActiveMFAMethod}
                />
              }
              defaultTarget={
                <TOTPMFASetup
                  currentMFAPreferences={currentMFAPreference!}
                  onClose={() => {
                    setShowingTOTPValidation(false);
                  }}
                  setMFAPreference={setActiveMFAMethod}
                />
              }
            />
          }
        />
      </Modal>
    </Flex>
  );
}

export default ManageMFAModal;
