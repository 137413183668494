class UserProfileisNotExistError extends Error {
  public readonly name: string;
  public readonly message: string;

  constructor() {
    super();

    this.name = this.constructor.name;
    this.message = 'User profile does not exist';

    if (Error.captureStackTrace) Error.captureStackTrace(this, this.constructor); // Made optional due to lack of support in Safari using below instead
    Object.setPrototypeOf(this, UserProfileisNotExistError.prototype);
  }
}

export default UserProfileisNotExistError;
