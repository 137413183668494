import { DynamoDB, AWSError } from 'aws-sdk';
import { Auth } from 'aws-amplify';

import { ICredentials, ObjectIdItemType } from './interfaces';

import { DYNAMO_DB_TABLES } from '@modules/core/constants';

export async function getObjectID(): Promise<ObjectIdItemType[]> {
  const credentials: ICredentials = await Auth.currentCredentials();

  const client: DynamoDB.DocumentClient = new DynamoDB.DocumentClient({
    region: process.env.REACT_APP_AWS_REGION || 'us-east-1',
    credentials: Auth.essentialCredentials(credentials),
  });

  const params = {
    TableName: DYNAMO_DB_TABLES.customerObjects,
    IndexName: 'identityId-TemplateName-index',
    KeyConditionExpression: 'identityId = :identityId AND TemplateName = :myCircleOnboarding',
    ExpressionAttributeValues: {
      ':identityId': credentials.identityId,
      ':myCircleOnboarding': 'myCircle Onboarding',
    },
  };

  return new Promise<ObjectIdItemType[]>((resolve, reject): void => {
    client.query(params, (err: AWSError, data: DynamoDB.DocumentClient.QueryOutput): void => {
      if (err) {
        return reject(new Error(err.message || JSON.stringify(err)));
      }

      return resolve(data.Items ?? []);
    });
  });
}
