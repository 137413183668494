import * as Yup from 'yup';

import {
  LIFE_EVENT_KEY_ERRORS,
  EMAIL_VALIDATION_ERRORS,
  PHONE_VALIDATION_ERRORS,
  RELATION_TO_ACCOUNT_HOLDER_ERRORS,
  NAME_VALIDATION_ERRORS,
  LIFE_EVENT_ERRORS,
} from '../../modules/core/constants/validation';

const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .trim()
    .required(NAME_VALIDATION_ERRORS.required)
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
      NAME_VALIDATION_ERRORS.invalid
    )
    .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, NAME_VALIDATION_ERRORS.invalid),

  email: Yup.string()
    .trim()
    .lowercase()
    .required(EMAIL_VALIDATION_ERRORS.required)
    .email(EMAIL_VALIDATION_ERRORS.invalid),

  phoneNumber: Yup.string()
    .trim()
    .required(PHONE_VALIDATION_ERRORS.required)
    .matches(/^(\+1\s[0-9]{3}-[0-9]{3}-[0-9]{4})$/m, PHONE_VALIDATION_ERRORS.invalid),

  relation: Yup.string()
    .trim()
    .required(RELATION_TO_ACCOUNT_HOLDER_ERRORS.required)
    .matches(/^[A-Za-z ,.'-]+$/, RELATION_TO_ACCOUNT_HOLDER_ERRORS.invalid),

  lifeEvent: Yup.string()
    .required(LIFE_EVENT_ERRORS.required)
    .matches(/^(Incapacitated|Passed away)$/, LIFE_EVENT_ERRORS.invalid),
});

export const keyValidationSchema = Yup.object().shape({
  notificationKey: Yup.string()
    .trim()
    .required(LIFE_EVENT_KEY_ERRORS.required)
    .matches(
      /^[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}$/m,
      LIFE_EVENT_KEY_ERRORS.invalid
    ),
});

export default validationSchema;
// export default keyValidationSchema;
