export const signInStyles = {
  topContainer: {
    wrap: 'wrap',
    alignItems: 'center',
    justify: 'center',
    h: '100vh',

    gap: {
      base: '5%',
      xl: '10%',
    },

    margin: {
      xl: '0',
      sm: '30px',
      md: '40px',
      base: '15px',
    },
  },

  headerBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    w: '100%',
  },

  logo: {
    w: '162px',
  },

  signInBox: {
    bg: 'white',
    rounded: 'md',
    w: '574px',
    padding: '40px 20px',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.160784)',
  },

  submitButton: {
    colorScheme: 'whiteAlpha',
    width: 'full',
    backgroundColor: 'brand.green.100',

    height: {
      base: '46px',
      md: '56px',
    },

    _hover: {
      backgroundColor: 'brand.green.200',
    },
  },

  contentBox: {
    spacing: '4',
    align: 'flex-start',
  },

  textGreen: {
    color: 'brand.green.100',
  },

  textError: {
    color: 'brand.error',
  },

  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: 'full',
  },

  textWrapper: {
    display: 'flex',
    pb: '16px',
  },

  passwordInput: {
    marginBottom: '25px !important',
  },
} as const;

export const mfaEntryStyles = {
  topContainer: {
    wrap: 'wrap',
    alignItems: 'center',
    justify: 'center',
    h: '100vh',

    gap: {
      base: '5%',
      xl: '10%',
    },

    margin: {
      xl: '0',
      base: '40px',
    },
  },

  mfaInput: {
    w: '100%',
  },

  mfaContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'leading',
    justifyContent: 'space-between',
    height: '230px',
  },

  formBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '155px',
  },

  headerText: {
    fontWeight: 'bold',
  },

  signInBox: {
    bg: 'white',
    rounded: 'md',
    w: '574px',
    padding: '40px 20px',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.160784)',
  },

  submitButton: {
    colorScheme: 'whiteAlpha',
    width: 'full',
    backgroundColor: 'brand.green.100',

    height: {
      base: '46px',
      md: '56px',
    },

    _hover: {
      backgroundColor: 'brand.green.200',
    },
  },

  contentBox: {
    spacing: '4',
    align: 'flex-start',
  },

  textGreen: {
    color: 'brand.green.100',
  },

  textError: {
    color: 'brand.error',
  },

  mfaHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 'full',
  },

  textWrapper: {
    display: 'flex',
    pb: '16px',
  },

  passwordInput: {
    marginBottom: '25px !important',
  },
} as const;
