// these styles are connected globally in the file app/style/theme.ts
export const sliderStyles = {
  slickSlider: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  slickDots: {
    position: 'static',
  },

  slickDotsElem: {
    width: '10px',
    height: '10px',
    margin: '0 10px',
  },
};
