import React, { useState, ReactElement } from 'react';

import { Box } from '@chakra-ui/react';
import SlickSlider from 'react-slick';

import { ISliderProps, ISliderSettings, IDefaultSliderSettings } from './interfaces';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ROWS: number = 2;
const SLIDES_TO_SHOW: number = 2;
const SLIDES_TO_SCROLL: number = 2;
const SPEED: number = 500;

const defaultSliderSettings: IDefaultSliderSettings = {
  speed: SPEED,
  rows: ROWS,
  slidesToShow: SLIDES_TO_SHOW,
  slidesToScroll: SLIDES_TO_SCROLL,

  dots: true,
  arrows: false,
  infinite: true,
  adaptiveHeight: true,
};

function Slider({ children }: ISliderProps): ReactElement {
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);

  const sliderSettings: ISliderSettings = {
    ...defaultSliderSettings,

    beforeChange: (oldIndex: number, newIndex: number) => {
      setActiveSlideIndex(newIndex);
    },

    customPaging: (slideIndex: number) => (
      <Box
        width='100%'
        height='100%'
        borderRadius='100%'
        backgroundColor={
          slideIndex * SLIDES_TO_SCROLL === activeSlideIndex ? 'brand.green.100' : 'brand.gray.50'
        }
      />
    ),
  };

  return (
    <Box width='100%' height='100%'>
      <SlickSlider {...sliderSettings}>{children}</SlickSlider>
    </Box>
  );
}

export default Slider;
