export const forgotPasswordStyles = {
  container: {
    align: 'center',
    justify: 'space-between',
    h: '100vh',
    maxWidth: '1320px',
    margin: '0 auto',
  },

  resetPasswordBox: {
    bg: 'white',
    rounded: 'md',
    w: '90%',
    padding: '40px 30px',
    h: '100vh',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.160784)',
  },

  button: {
    colorScheme: 'whiteAlpha',
    width: 'full',
    backgroundColor: 'brand.green.100',
    height: '56px',
    _hover: {
      backgroundColor: 'brand.green.200',
    },
  },

  textGreen: {
    color: 'brand.green.100',
  },

  textError: {
    color: 'brand.error',
  },

  textContainer: {
    display: 'flex',
    pb: '16px',
  },

  textErrorContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: 'full',
  },

  inputGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};
