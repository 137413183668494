import React, { ReactElement, useState, useEffect } from 'react';

import { Flex, Box, Heading, Button, VStack, Text, HStack } from '@chakra-ui/react';
import { EmailIcon } from '@chakra-ui/icons';

import { useQuery } from '@app/modules/core/hooks';
import { joinFamilyPlan } from '@app/modules/core/aws/lambdas/payments';
import { formatDate } from '@app/modules/core/date-formatter';
import { JoinFamilyMemberModalPropsType } from './interfaces';
import {
  FamilyMemberType,
  FamilyMembersListType,
  IJoinFamilyPlanRes as IJoinRes,
  IJoinFamilyPlanInput as IJoinInput,
} from '@app/modules/core/aws/lambdas/payments/interfaces';

import Modal from './ModalBasic';

import { joinFamilyMemberModalStyles } from './styles';

function JoinFamilyMemberModal({
  familyMembers: members,
  onClose,
  ...rest
}: JoinFamilyMemberModalPropsType): ReactElement {
  const { submit, res, isLoading, err } = useQuery<IJoinInput, IJoinRes>(joinFamilyPlan);
  const [familyMember, setFamilyMember] = useState<FamilyMemberType | null>(null);
  const [familyMembers, setFamilyMembers] = useState<FamilyMembersListType>([]);
  const clearMember = () => setFamilyMember(null);

  const joinAsFamilyMember = (): void => {
    if (familyMember?.accountHolderIdentityId) {
      submit({ accountHolderId: familyMember.accountHolderIdentityId });
    }
  };

  const isMemberActive = (member: FamilyMemberType): boolean => {
    return member.accountHolderIdentityId === familyMember?.accountHolderIdentityId;
  };

  const formatInviteDate = (date: string): string => {
    return formatDate(date, {
      format: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      },
    });
  };

  const closeModal = (): void => {
    clearMember();
    onClose();
  };

  useEffect((): void => {
    if (members && members.length) {
      setFamilyMembers([...members]);
    }
  }, [members]);

  useEffect((): void => {
    if (res && res.success && familyMember) {
      const { accountHolderIdentityId: currentID } = familyMember;

      setFamilyMember(null);
      setFamilyMembers((oldMembers) =>
        oldMembers.filter(({ accountHolderIdentityId }) => accountHolderIdentityId !== currentID)
      );
    }
  }, [res]);

  return (
    <Modal {...rest} onClose={closeModal} title='Accept your free subscription'>
      <VStack {...joinFamilyMemberModalStyles.contentBox}>
        <Box {...joinFamilyMemberModalStyles.membersBox}>
          {!familyMembers.length && <Text>No invitations</Text>}

          {familyMembers.map((member) => (
            <Flex
              onClick={setFamilyMember.bind(null, member)}
              key={member.accountHolderIdentityId}
              {...joinFamilyMemberModalStyles.memberBox}
              {...(isMemberActive(member) && joinFamilyMemberModalStyles.memberBoxActive)}
            >
              <HStack spacing='20px'>
                <Box>
                  <EmailIcon {...joinFamilyMemberModalStyles.invitationIcon} />
                </Box>

                <Box>
                  <Heading as='h5' {...joinFamilyMemberModalStyles.memberName}>
                    {member.accountHolderName}
                  </Heading>
                </Box>
              </HStack>

              <Text>Invited on {formatInviteDate(member.inviteSent)}</Text>
            </Flex>
          ))}
        </Box>

        <Button
          isDisabled={!familyMember}
          isLoading={isLoading}
          onClick={joinAsFamilyMember}
          {...joinFamilyMemberModalStyles.button}
        >
          Accept
        </Button>

        {(err || res) && (
          <VStack>
            {err && <Text {...joinFamilyMemberModalStyles.textError}>{err.message}</Text>}

            {res && res.success && (
              <Text {...joinFamilyMemberModalStyles.textSuccess}>Success</Text>
            )}
          </VStack>
        )}
      </VStack>
    </Modal>
  );
}

export default JoinFamilyMemberModal;
