import { Auth } from 'aws-amplify';

import { lambdaGeneralCaller } from '@modules/core/aws/lambdas/lambda-general-caller/lambdaGeneralCaller';
import {
  ICredentials,
  IGetProductsInput,
  IProductsListLamdaRes,
  IProductsListRes,
} from '@modules/core/aws/lambdas/payments/interfaces';

import { AWS_LAMBDAS, AWS_LAMBDAS_OPERATIONS } from '@modules/core/constants';

async function getProducts({ promoCode = '' }: IGetProductsInput = {}): Promise<IProductsListRes> {
  const credentials: ICredentials = await Auth.currentCredentials();

  return lambdaGeneralCaller<IProductsListLamdaRes>(credentials, {
    FunctionName: AWS_LAMBDAS.payments,
    Payload: JSON.stringify({
      operation: AWS_LAMBDAS_OPERATIONS.getProductList,
      ...(promoCode && { promoCode }),
    }),
  }).then((res) => {
    // console.log(res);
    if (res.affiliateLogo) {
      const arrayBuffer = new ArrayBuffer(res.affiliateLogo.data.length);
      const view = new Uint8Array(arrayBuffer);
      for (let i = 0; i < res.affiliateLogo.data.length; ++i) {
        view[i] = res.affiliateLogo.data[i];
      }
      return {
        ...res,
        affiliateLogo: arrayBuffer,
      } as IProductsListRes;
    }
    return res as IProductsListRes;
  });
}

export default getProducts;
