import React, { ReactElement } from 'react';
import { Wrap, Image, VStack, Text, Link, HStack, Spacer } from '@chakra-ui/react';
import { socialProofStyles } from './styles';

const laWeeklyImg = require('@assets/images/laweekly.jpg');
const vcPostImg = require('@assets/images/vcpost.jpg');
const usaTodayImg = require('@assets/images/usatoday.jpg');
const msnImg = require('@assets/images/msn.jpg');

export function SocialProof(): ReactElement {
  return (
    <VStack {...socialProofStyles.backgroundContainer}>
      <HStack w='100%'>
        <Text {...socialProofStyles.text}>Read about us in the news</Text>
        <Spacer />
      </HStack>
      <Wrap spacing='20px'>
        <Link href='https://www.laweekly.com/the-future-of-estate-planning-is-a-lved-story/'>
          <Image src={laWeeklyImg} />
        </Link>
        <Link href='https://www.vcpost.com/articles/125336/20240328/modern-simple-and-accessible-estate-planning-lveds-innovative-approach.htm'>
          <Image src={vcPostImg} />
        </Link>
        <Link href='https://www.usatoday.com/story/special/contributor-content/2024/04/11/frontrunners-of-the-future-the-best-startups-shaping-the-next-era-of-innovation/73292433007/'>
          <Image src={usaTodayImg} />
        </Link>
        <Link href='https://www.msn.com/en-us/news/other/from-complexity-to-clarity-lveds-modern-accessible-affordable-estate-planning-/ar-BB1m5ido?disableErrorRedirect=true&infiniteContentCount=0'>
          <Image src={msnImg} />
        </Link>
      </Wrap>
    </VStack>
  );
}
