export const signUpStyles = {
  container: {
    wrap: 'wrap',
    alignItems: 'center',
    justify: 'center',
    h: '100vh',

    gap: {
      base: '5%',
      xl: '10%',
    },

    margin: {
      xl: '0',
      sm: '30px',
      md: '40px',
      base: '15px',
    },
  },

  signUpBox: {
    bg: 'white',
    rounded: 'md',
    w: { base: '98%', md: '574px' },
    padding: { base: '20px 15px', sm: '40px 20px' },
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.160784)',
  },

  signUpBoxTransparent: {
    bg: 'clear',
    rounded: 'md',
    w: { base: '98%', md: '574px' },
    padding: { base: '20px 15px', sm: '40px 20px' },
  },

  button: {
    colorScheme: 'whiteAlpha',
    width: 'full',
    backgroundColor: 'brand.green.100',

    height: {
      base: '46px',
      md: '56px',
    },

    _hover: {
      backgroundColor: 'brand.green.200',
    },
  },

  contentBox: {
    spacing: '4',
    align: 'flex-start',
  },

  textGreen: {
    color: 'brand.green.100',
  },

  textHeader: {
    fontWeight: '700',
    color: 'brand.green.100',
  },

  textError: {
    color: 'brand.error',
  },

  textContainer: {
    display: 'flex',
    pb: '16px',
  },

  textErrorContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: 'full',
  },

  firstNameinput: {
    marginRight: '20px',
  },

  inputGroup: {
    display: 'flex',
    flexDirection: { base: 'column', md: 'row' },
    gap: { base: '16px', xl: '10%' },
    margin: { base: '40px', xl: '0' },
    justifyContent: 'space-between',
  },

  logo: {
    w: '162px',
  },
} as const;
