import { FormikErrors } from 'formik';

import {
  TemplateValuesType,
  TemplateValueType,
  FieldType,
} from '@modules/common-ui/components/customer-fields-manager/interfaces';

import { CUSTOMER_OBJECT_FIELD_TYPES } from '@modules/core/constants';

export function validate(formValues: TemplateValuesType, templateFields: FieldType[]) {
  const formErrors: FormikErrors<TemplateValuesType> = {};

  templateFields.forEach((templateField: FieldType) => {
    const formValue: TemplateValueType = formValues[templateField.FieldName];

    if (templateField.isRequired) {
      if (!formValue) {
        formErrors[templateField.FieldName] = 'Required';
      }
    }

    if (templateField.MaxLength && templateField.FieldType === CUSTOMER_OBJECT_FIELD_TYPES.text) {
      if (formValue?.length > templateField.MaxLength) {
        formErrors[templateField.FieldName] = `Max length is ${templateField.MaxLength}`;
      }
    }
  });

  return formErrors;
}
