import { useEffect, useState, useRef } from 'react';

import { IUseWorkerRes, WorkerResType } from '@modules/core/hooks/interfaces';

import { WORKER_STATUSES } from '@modules/core/constants';

function useWorker<Req, Res>(workerRef: Worker): IUseWorkerRes<Req, Res> {
  const { current: worker } = useRef<Worker>(workerRef);

  const [res, setRes] = useState<Res | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [status, setStatus] = useState<WORKER_STATUSES>(WORKER_STATUSES.onHold);

  const startWorker = (workerProps: Req): void => {
    setStatus(WORKER_STATUSES.pending);
    setError(null);
    worker.postMessage(JSON.stringify(workerProps));
  };

  const onMessageHandler = (event: MessageEvent<string>, isCancel: boolean): void => {
    const { errorMessage, data }: WorkerResType<Record<string, any>> = JSON.parse(event.data);

    if (isCancel) return;

    if (errorMessage) {
      setStatus(WORKER_STATUSES.error);
      setError(new Error(errorMessage));
      setRes(null);
    } else {
      setRes(data as Res);
      setStatus(WORKER_STATUSES.success);
      setError(null);
    }
  };

  const onErrorHandler = (err: ErrorEvent, isCancel: boolean): void => {
    if (!isCancel) {
      setStatus(WORKER_STATUSES.error);
      setError(new Error(err.message || JSON.stringify(err)));
      setRes(null);
    }
  };

  useEffect(() => {
    let isCancel: boolean = false;

    if (worker && window.Worker) {
      worker.onmessage = (event: MessageEvent) => onMessageHandler(event, isCancel);
      worker.onerror = (err: ErrorEvent) => onErrorHandler(err, isCancel);
    }

    return () => {
      isCancel = true;

      if (worker && window.Worker) {
        worker.terminate();
      }
    };
  }, []);

  return { startWorker, res, error, status, isLoading: status === WORKER_STATUSES.pending };
}

export default useWorker;
