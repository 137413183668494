export const landingPageStyles = {
  sponsorBox: {
    border: '1px',
    marginTop: '10px',
    padding: '10px',
    width: '90%',
    borderStyle: 'dotted',
    spacing: '30px',
  },

  container: {
    maxWidth: { base: '350px', lg: '650px' },
    margin: { base: '10px auto', lg: '40px auto' },
    alignItems: 'center',
  },

  questionsBox: {
    alignItems: 'center',
    width: 'full',
  },

  questionsContent: {
    spacing: '2',
    width: 'full',
  },

  welcomeTextBox: {
    spacing: '2',
  },

  wrapContainer: {
    padding: '0px 30px 0px 30px',
  },

  welcomeImage: {
    // border: '1px',
    rounded: 'md',
    w: { base: '100%', sm: '420px' },
    h: 'auto',
  },

  welcomeAdditionalText: {
    fontSize: 'xl',
  },

  promoTextContainer: {
    width: { base: '100%', md: '45%' },
    justifyContent: 'leading',
    alignItems: 'leading',
    fontWeight: '400',
  },

  promoTextHighlight: {
    fontWeight: '700',
  },

  benefitsList: {
    padding: '0 0 0 25px',
  },

  promoImage: {
    width: { base: '350px', sm: '45%' },
    height: 'auto',
  },

  lowerPromoTextContainer: {
    width: { base: '100%', sm: '50%' },
    alignItems: 'center',
  },

  getStartedBox: {
    marginTop: '80px',
    alignItems: 'center',
  },

  getStartedButton: {
    marginTop: '18px',
    width: '221px',
    height: '56px',
    backgroundColor: 'brand.green.100',
    color: 'white',
    _hover: {
      backgroundColor: 'brand.green.200',
    },
  },

  footnote: {
    fontSize: '10px',
  },

  errorText: {
    color: 'red',
  },
};
