import React, { ReactElement, useEffect } from 'react';

import { Box, Button, VStack, useDisclosure } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { omitBy, isNil } from 'lodash';

import { createInitialValues } from '@modules/common-ui/components/slide/digital-vault/formik-initial-values';
import { validate as formValidate } from '@modules/common-ui/components/slide/digital-vault/template-details-validation';
import { useQuery, useQueryToast, useEncryption } from '@modules/core/hooks';
import { getTemplateDetails, saveAsset } from '@modules/core/aws/dynamo-db/digital-vault';

import { Loader } from '@modules/common-ui/components/loader';
import { ConditionSeparator } from '@modules/common-ui/components/condition-separator';
import { CustomerFieldsManager } from '@modules/common-ui/components/customer-fields-manager';
import { DecryptEncryptionKeyModal } from '@modules/common-ui/components/modal';

import { ITemplateDetailsProps } from '@modules/common-ui/components/slide/digital-vault/interfaces';
import { TemplateValuesType } from '@modules/common-ui/components/customer-fields-manager/interfaces';
import {
  TemplateNameType,
  TemplateItemFieldType,
  ObjectsTemplateItemType,
  ISaveAssetInputProps,
} from '@modules/core/aws/dynamo-db/digital-vault/interfaces';

import { digitalVaultSlideStyles } from '@modules/common-ui/components/slide/digital-vault/styles';

export function TemplateDetails({ template, closeSlide }: ITemplateDetailsProps): ReactElement {
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  const { encryptionKey, setEncryptionKey, isKeyExist } = useEncryption();

  const closeModal = (): void => {
    if (!encryptionKey) closeSlide();

    onModalClose();
  };

  const {
    submit: getTemplateDetailsSubmit,
    res: templateDetails,
    isLoading: isTemplateLoad,
  } = useQuery<TemplateNameType, ObjectsTemplateItemType>(getTemplateDetails);

  const {
    submit: saveAssetSubmit,
    isLoading: isSaveAssetLoad,
    res: saveAssetRes,
  } = useQueryToast<ISaveAssetInputProps, {}>(saveAsset);

  const formOnSubmitHandler = (data: TemplateValuesType) => {
    if (templateDetails) {
      saveAssetSubmit({ templateItem: templateDetails, assetData: omitBy(data, isNil) });
    }
  };

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue, resetForm } =
    useFormik({
      enableReinitialize: true,
      initialValues: createInitialValues(templateDetails?.Fields || []),
      onSubmit: formOnSubmitHandler,
      validate: (formValues: TemplateValuesType) =>
        formValidate(formValues, templateDetails?.Fields || []),
    });

  useEffect(() => {
    if (template) {
      getTemplateDetailsSubmit(template);
    }
  }, []);

  useEffect(() => {
    if (templateDetails?.SecureStorage && !isKeyExist) {
      onModalOpen();
    }
  }, [templateDetails]);

  useEffect(() => {
    if (saveAssetRes) {
      resetForm();
    }
  }, [saveAssetRes]);

  return (
    <>
      <DecryptEncryptionKeyModal
        isOpen={isModalOpen}
        onClose={closeModal}
        setKey={setEncryptionKey}
      />

      <Box {...digitalVaultSlideStyles.slideChildContainer}>
        <ConditionSeparator condition={isTemplateLoad} target={<Loader />} />

        <ConditionSeparator
          condition={Boolean(templateDetails?.Fields)}
          target={
            <form onSubmit={handleSubmit} style={digitalVaultSlideStyles.assetForm}>
              <VStack {...digitalVaultSlideStyles.assetInputsContainer}>
                {templateDetails?.Fields.map((field: TemplateItemFieldType) => (
                  <CustomerFieldsManager
                    key={field.FieldName}
                    field={field}
                    values={values}
                    errors={errors}
                    touched={touched}
                    encryptionKey={encryptionKey}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </VStack>

              <Button type='submit' isLoading={isSaveAssetLoad} {...digitalVaultSlideStyles.button}>
                Save
              </Button>
            </form>
          }
        />
      </Box>
    </>
  );
}
