import React, { ReactElement, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { CognitoUserSession } from 'amazon-cognito-identity-js';

import { useAuth } from '../hooks';
import { IPrivateRouteProps } from './interfaces';
import { IAuthContext } from '../contexts/auth/interfaces';

import { APP_ROUTES, LICENSE_STATUSES } from '../constants';
import { UserProfileType } from '../aws/dynamo-db/user-profile/interfaces';

function PrivateRoute({
  isAuthDisallowed,
  isAuthProtected,
  isDisallowedNoneLicensedUser,
  children,
}: IPrivateRouteProps): ReactElement {
  const navigateTo = useNavigate();
  const { user, getSession }: IAuthContext = useAuth();
  const session: CognitoUserSession | null = getSession();

  function reRouteIfNeeded(activeUser: UserProfileType) {
    if (isDisallowedNoneLicensedUser && activeUser.licenseStatus === LICENSE_STATUSES.notSelected) {
      if (activeUser.birthdate) navigateTo(APP_ROUTES.pricing);
      else navigateTo(APP_ROUTES.profileOnBoarding);
    }
  }

  useEffect(() => {
    if (user) {
      reRouteIfNeeded(user);
    }
  }, [user]);

  if (session && isAuthDisallowed) {
    return <Navigate to={APP_ROUTES.root} />;
  }

  if (!session && isAuthProtected) {
    return <Navigate to={APP_ROUTES.signIn} />;
  }

  return children;
}

export default PrivateRoute;
