export const welcomeStyles = {
  container: {
    maxWidth: '650px',
    margin: '130px auto',
    alignItems: 'center',
  },

  welcomeTextBox: {
    spacing: '2',
  },

  welcomeAdditionalText: {
    fontSize: 'xl',
  },

  getStartedBox: {
    marginTop: '80px',
    alignItems: 'center',
  },

  getStartedButton: {
    marginTop: '18px',
    width: '221px',
    height: '56px',
    backgroundColor: 'brand.green.100',
    color: 'white',
    _hover: {
      backgroundColor: 'brand.green.200',
    },
  },
};
