import React, { ReactElement } from 'react';

import InputMask from 'react-input-mask';
import { PhoneIcon } from '@chakra-ui/icons';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';

import { IPhoneInputProps } from './interfaces';

import { inputStyles } from './styles';

function PhoneInput({
  label,
  id,
  errorMessage,
  mask,
  variant = 'outline',
  size = 'lg',
  isError = false,
  ...rest
}: IPhoneInputProps): ReactElement {
  return (
    <FormControl isInvalid={isError}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}

      <InputGroup size={size}>
        <InputLeftElement pointerEvents='none'>
          <PhoneIcon {...inputStyles.baseIcon} />
        </InputLeftElement>

        <Input
          id={id}
          as={InputMask}
          maskChar={null}
          mask={mask || '+1\\ 999-999-9999'}
          variant={variant}
          {...rest}
        />
      </InputGroup>

      {isError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
}

export default PhoneInput;
