import { CognitoUserPool, ICognitoUserPoolData, CognitoUser } from 'amazon-cognito-identity-js';

function userPool(): CognitoUserPool {
  return new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_AWS_USER_POOL_ID || '',
    ClientId: process.env.REACT_APP_AWS_CLIENT_ID || '',
  } as ICognitoUserPoolData);
}

export function getUserFromEmail(email: string): CognitoUser {
  return new CognitoUser({
    Username: email.toLowerCase(),
    Pool: userPool(),
  });
}
