import React, { ReactElement } from 'react';

import {
  NumberInput,
  TextInput,
  PasswordInput,
  FileUploadInput,
  SelectInput,
  AddressInput,
  DateInput,
  ThumbnailUploadInput,
} from '@modules/common-ui/components/text-field';
import { ICustomerFieldsManagerProps } from './interfaces';

import { CUSTOMER_OBJECT_FIELD_TYPES, FILE_TYPES } from '@modules/core/constants';

export default function CustomerFieldsManager({
  field,
  values,
  errors,
  touched,
  encryptionKey,
  handleChange,
  setFieldValue,
}: ICustomerFieldsManagerProps): ReactElement {
  const defaultProps = {
    id: field.FieldName,
    name: field.FieldName || field.DisplayTitle,
    label: field.HelperText || field.DisplayTitle,
    value: values[field.FieldName],
    isError: !!errors[field.FieldName] && !!touched[field.FieldName],
    errorMessage: String(errors[field.FieldName]),
    readOnly: field.ReadOnly,
  };

  switch (field.FieldType) {
    case CUSTOMER_OBJECT_FIELD_TYPES.text:
      return <TextInput {...defaultProps} key={field.FieldName} onChange={handleChange} />;

    case CUSTOMER_OBJECT_FIELD_TYPES.password:
      return <PasswordInput {...defaultProps} key={field.FieldName} onChange={handleChange} />;

    case CUSTOMER_OBJECT_FIELD_TYPES.date:
      return <DateInput {...defaultProps} key={field.FieldName} onChange={handleChange} />;

    case CUSTOMER_OBJECT_FIELD_TYPES.dropdown:
      return (
        <SelectInput
          {...defaultProps}
          key={field.FieldName}
          options={field.VariableOptions}
          onChange={handleChange}
        />
      );

    case CUSTOMER_OBJECT_FIELD_TYPES.number:
      return (
        <NumberInput
          {...defaultProps}
          key={field.FieldName}
          setValue={setFieldValue.bind(null, field.FieldName)}
        />
      );

    case CUSTOMER_OBJECT_FIELD_TYPES.numberNoFormat:
      return (
        <NumberInput
          {...defaultProps}
          key={field.FieldName}
          setValue={setFieldValue.bind(null, field.FieldName)}
        />
      );

    case CUSTOMER_OBJECT_FIELD_TYPES.currency:
      return (
        <NumberInput
          {...defaultProps}
          key={field.FieldName}
          setValue={setFieldValue.bind(null, field.FieldName)}
          isCurrency
        />
      );

    case CUSTOMER_OBJECT_FIELD_TYPES.document:
      return (
        <FileUploadInput
          {...defaultProps}
          key={field.FieldName}
          setValue={setFieldValue.bind(null, field.FieldName)}
          acceptedFileTypes={FILE_TYPES.pdf}
          isStorageEncrypted={field.StoreEncrypted}
          encryptionKey={encryptionKey}
        />
      );

    case CUSTOMER_OBJECT_FIELD_TYPES.image:
      return (
        <FileUploadInput
          {...defaultProps}
          key={field.FieldName}
          setValue={setFieldValue.bind(null, field.FieldName)}
          acceptedFileTypes={FILE_TYPES.image}
          isStorageEncrypted={field.StoreEncrypted}
          encryptionKey={encryptionKey}
        />
      );

    case CUSTOMER_OBJECT_FIELD_TYPES.video:
      return (
        <FileUploadInput
          {...defaultProps}
          key={field.FieldName}
          setValue={setFieldValue.bind(null, field.FieldName)}
          acceptedFileTypes={FILE_TYPES.video}
          isStorageEncrypted={field.StoreEncrypted}
          encryptionKey={encryptionKey}
        />
      );

    case CUSTOMER_OBJECT_FIELD_TYPES.thumbnail:
      return (
        <ThumbnailUploadInput
          {...defaultProps}
          key={field.FieldName}
          setValue={setFieldValue.bind(null, field.FieldName)}
          isStorageEncrypted={field.StoreEncrypted}
          encryptionKey={encryptionKey}
        />
      );

    case CUSTOMER_OBJECT_FIELD_TYPES.address:
      return (
        <AddressInput
          values={values[field.FieldName]}
          helperText={field.HelperText}
          setValue={setFieldValue}
        />
      );

    default:
      return <TextInput {...defaultProps} key={field.FieldName} onChange={handleChange} />;
  }
}
