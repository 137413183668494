import React, { ReactElement } from 'react';

import { Box, Flex, Button, Text, UnorderedList, ListItem, VStack, HStack } from '@chakra-ui/react';

import { IPricingCardProps } from './interfaces';

import { pricingCardStyles } from './styles';
import { formatPrice } from '@app/modules/core/lvedHelpers';

export default function PricingCard({
  isError = false,
  isDisabled = false,
  heading,
  price,
  monthlyPrice,
  renewalPrice,
  isAnnualPlan,
  features,
  isPopular,
  submitCallback,
  isActionLoading,
  errorMessage,
}: IPricingCardProps): ReactElement {
  let displayPrice = formatPrice(price ?? 0);
  if (isAnnualPlan)
    displayPrice = `(subscription renews at ${formatPrice(renewalPrice ?? 0)}/year)`;
  else displayPrice = `${displayPrice.concat(`/mo`)}`;
  if (price === undefined) {
    displayPrice = `-`;
  }

  // let monthlyPriceCompareOld = formatPrice(monthlyPrice ?? 0);
  // monthlyPriceCompareOld = `${monthlyPriceCompareOld.concat(`/mo`)}`;
  // let monthlyEquivalent = formatPrice((price ?? 0) / 12);
  // monthlyEquivalent = `${monthlyEquivalent}`;

  return (
    <Box
      {...pricingCardStyles.card}
      {...(isDisabled && pricingCardStyles.cardDisabled)}
      {...(!isPopular && pricingCardStyles.notPopular)}
    >
      <Flex {...pricingCardStyles.cardContentBox}>
        <HStack width='100%'>
          <Text {...pricingCardStyles.productName} as='b'>
            {heading}
          </Text>
          {isPopular && <Text {...pricingCardStyles.popularStamp}>Popular</Text>}
        </HStack>

        {monthlyPrice && isAnnualPlan && (
          <VStack spacing='-10px'>
            <HStack width='100%' spacing='5px' padding='0px' margin='0px'>
              <Text as='b' {...pricingCardStyles.price}>
                {formatPrice(price)}
              </Text>
              <Text {...pricingCardStyles.priceFrequency}> (one time)</Text>
            </HStack>
            <Text width='100%' textAlign='left'>
              Includes free 1-year trial subscription for continued updates, digital vault storage,
              and legacy management
            </Text>
            <Text as='b' paddingTop='15px' {...(isAnnualPlan ? null : pricingCardStyles.text)}>
              {displayPrice}
            </Text>
          </VStack>
        )}
        {!isAnnualPlan && (
          <Text as='b' {...pricingCardStyles.price}>
            {displayPrice}
          </Text>
        )}

        <VStack
          {...(isPopular ? pricingCardStyles.separator : pricingCardStyles.nonPopularSeparator)}
        >
          {' '}
        </VStack>

        {features && (
          <UnorderedList {...pricingCardStyles.featuresList}>
            {features.map((feature) => (
              <ListItem key={feature}>{feature}</ListItem>
            ))}
          </UnorderedList>
        )}

        {isError && errorMessage && <Text {...pricingCardStyles.textError}>{errorMessage}</Text>}

        {price && (
          <Text {...pricingCardStyles.moneyBack}>
            30-day money back guarantee and keep your legal documents if not satisfied
          </Text>
        )}

        <Button
          onClick={submitCallback}
          isLoading={isActionLoading}
          isDisabled={isDisabled}
          {...pricingCardStyles.button}
          {...(isDisabled && pricingCardStyles.buttonDisabled)}
          {...(!isPopular && pricingCardStyles.notPopularButton)}
        >
          {price ? 'Subscribe' : 'Get Started'}
        </Button>
      </Flex>
    </Box>
  );
}
