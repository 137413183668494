import React, { ReactElement } from 'react';

import { Table as ChakraTable, Thead, Tbody, Tr, Th, Td, TableContainer } from '@chakra-ui/react';

import { ITableProps, IBodyItemType } from './interfaces';

function Table({
  data,
  variant = 'simple',
  isBodyBlocked = false,
  headers,
}: ITableProps): ReactElement {
  const validHeaderCount = headers.reduce((a, b) => a + (b.trimEnd().length ? 1 : 0), 0);
  // console.log(`Header count: ${validHeaderCount}`);
  const cellWidth: string = String(Math.floor(100 / validHeaderCount));
  return (
    <TableContainer>
      <ChakraTable variant={variant}>
        <Thead>
          <Tr>
            {headers.map((header) => (
              <Th key={header} width={`${header.trimEnd().length ? cellWidth : 0}%`}>
                {header}
              </Th>
            ))}
          </Tr>
        </Thead>

        {!isBodyBlocked && Boolean(data.length) && (
          <Tbody>
            {data.map((datum: IBodyItemType, index: number) => (
              <Tr key={index}>
                {headers.map((header: string) => (
                  <Td key={header + index}>{datum[header]}</Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        )}
      </ChakraTable>
    </TableContainer>
  );
}

export default Table;
