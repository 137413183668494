import React, { ReactElement } from 'react';

import { VStack, Heading, Box } from '@chakra-ui/react';

import { ISectionProps } from '@modules/common-ui/components/section/interfaces';
import { ConditionSeparator } from '@modules/common-ui/components/condition-separator';
import { Loader } from '@modules/common-ui/components/loader';

import { sectionStyles } from '@modules/common-ui/components/section/styles';

function Section({ children, title, isLoading = false }: ISectionProps): ReactElement {
  return (
    <Box {...sectionStyles.container}>
      <ConditionSeparator condition={isLoading} target={<Loader />} />

      <VStack {...sectionStyles.contentBox}>
        <ConditionSeparator
          condition={!!title}
          target={<Heading {...sectionStyles.heading}>{title}</Heading>}
        />

        {/* <ConditionSeparator
          condition={!isLoading}
          target={<>{children}</>}
          target={<Loader />}
        /> */}

        <ConditionSeparator condition={!isLoading} target={<>{children}</>} />
      </VStack>
    </Box>
  );
}

export default Section;
