import { Auth } from 'aws-amplify';

import { lambdaGeneralCaller } from '@modules/core/aws/lambdas/lambda-general-caller/lambdaGeneralCaller';
import {
  ICredentials,
  IFamilyMembersSlotsStatus,
} from '@modules/core/aws/lambdas/profile/interfaces';

import { AWS_LAMBDAS, AWS_LAMBDAS_OPERATIONS } from '@modules/core/constants';

export default async function getFamilyMemberSlotStatus(): Promise<IFamilyMembersSlotsStatus> {
  const credentials: ICredentials = await Auth.currentCredentials();

  return lambdaGeneralCaller<IFamilyMembersSlotsStatus>(credentials, {
    FunctionName: AWS_LAMBDAS.manageFamilyPlan,
    Payload: JSON.stringify({
      operation: AWS_LAMBDAS_OPERATIONS.getFamilyMemberSlotStatus,
    }),
  });
}
