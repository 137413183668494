import CryptoJS from 'crypto-js';
import {
  generateRSAKey,
  publicKeyString,
  decrypt as cryptoDecrypt,
  encrypt as cryptoEncrypt,
} from 'cryptico';

import { RSAKey, PublicKey } from './interfaces';

export async function getRSAKey(passphrase: string, bitlength: number = 2048): Promise<RSAKey> {
  return generateRSAKey(passphrase, bitlength);
}

export async function getPublicKey(rsaKey: RSAKey): Promise<PublicKey> {
  return publicKeyString(rsaKey);
}

export async function encryptByPublicKey(
  clearTextData: ArrayBuffer,
  key: PublicKey
): Promise<string> {
  const textDecoder = new TextDecoder('utf-8');
  const clearTextStr = textDecoder.decode(clearTextData);

  const encryptionResult = cryptoEncrypt(clearTextStr, key);

  if (encryptionResult.status === 'failure') throw new Error('Encryption error');

  return encryptionResult.cipher;
}

export async function decryptByRSAKey(cipherText: ArrayBuffer, key: RSAKey): Promise<string> {
  const textDecoder = new TextDecoder('utf-8');
  const cipherTextStr = textDecoder.decode(cipherText);
  const decryptionResult = cryptoDecrypt(cipherTextStr, key);

  if (decryptionResult.status === 'failure') throw new Error('Decryption error');

  return decryptionResult.plaintext;
}

export async function encryptAES(
  data: string | CryptoJS.lib.WordArray,
  encryptionKey: ArrayBuffer
): Promise<string> {
  const textEncoder = new TextDecoder('utf-8');
  const encryptionKeyStr = textEncoder.decode(encryptionKey);
  return CryptoJS.AES.encrypt(data, encryptionKeyStr).toString();
}

export async function decryptAES(cipherText: string, encryptionKey: ArrayBuffer): Promise<string> {
  const textEncoder = new TextDecoder('utf-8');
  const encryptionKeyStr = textEncoder.decode(encryptionKey);
  const bytes: CryptoJS.lib.WordArray = CryptoJS.AES.decrypt(cipherText, encryptionKeyStr);
  const data: string = bytes.toString(CryptoJS.enc.Utf8);

  return data;
}

export function createWordArray(fileBuffer: ArrayBuffer): CryptoJS.lib.WordArray {
  return CryptoJS.lib.WordArray.create(fileBuffer as any);
}
