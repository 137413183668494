export const dashboardStyles = {
  gridContainer: {
    templateAreas: `"score"
      "latest"
      "tasks"`,
    gridTemplateRows: '15% 30% 55%',
    gridTemplateColumns: '100%',
    height: '800px',
    gap: { base: '15px', sm: '30px', md: '50px' },
    color: 'blackAlpha.700',
    fontWeight: 'bold',
    width: { base: '95%', sm: '90%', md: '80%' },
    margin: '80px auto',
  },

  gridItemBox: {
    backgroundColor: '#FEFEFE',
    borderRadius: '20px',
    padding: { base: '10px', md: '20px' },
    width: '100%',
    height: '100%',
  },

  spinnerBox: {
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },

  spinner: {
    thickness: '4px',
    speed: '0.65s',
    emptyColor: 'gray.200',
    color: 'brand.green.100',
    size: 'xl',
  },

  latestText: {
    font: 'normal normal normal 12px/18px Lato',
    fontWeight: '400',
  },

  updatesContentBox: {
    spacing: '2',
    overflow: 'auto',
    width: '100%',
    height: '100%',
    alignItems: 'normal',
  },

  tasksContentBox: {
    height: '100%',
  },

  scoreContentBox: {
    spacing: '2',
  },

  widgetHeader: {
    size: 'md',
  },

  score: {
    size: '3xl',
    color: '#FDE000',
  },
};
