// import { IPaymentPageStyles } from './interfaces';

export const paymentPageStyles = {
  // }: IPaymentPageStyles = {
  backgroundContainer: {
    height: '100vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },

  headingTextNames: {
    size: 'md',
  },

  headingTextValues: {
    size: 'md',
    color: 'brand.blue',
  },

  headingBox: {
    color: 'blackAlpha.700',
    justifyContent: 'space-between',
    width: '100%',
  },

  headingNames: {
    alignItems: 'flex-start',
    spacing: '3',
  },

  headingValues: {
    alignItems: 'flex-end',
    spacing: '3',
  },

  paymentInputBox: {
    width: '100%',
  },

  contentBox: {
    spacing: '30px',
    backgroundColor: '#FEFEFE',
    borderRadius: '20px',
    padding: '45px',
    width: '400px',
    position: 'absolute',
    top: '60%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  cancelButtonBox: {
    width: '100%',
  },

  cancelButton: {
    size: 'lg',
    width: '100%',
    backgroundColor: 'white',
    color: '#005455',
    border: '2px solid',
    borderColor: '#005455',

    _hover: {
      backgroundColor: '#308485',
      color: 'white',
    },
  },

  dollarAmount: {
    width: '60%',
    color: 'gray',
    justifyContent: 'end',
    textAlign: 'end',
  },

  chargeAmount: {
    width: '60%',
    color: 'black',
    fontWeight: '700',
    justifyContent: 'end',
    textAlign: 'end',
  },

  fieldName: {
    fontSize: 'md',
    width: '40%',
  },
} as const;
