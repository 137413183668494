export const reviewFinalMessageStyles = {
  pageBackground: {
    width: '100%',
    background: 'linear-gradient(180deg, #D0F0E4 13.67%, #F3F7F8 37.59%, #F6F8FA 100%)',
    spacing: '3',
    // margin: '35px',
  },

  textError: {
    color: 'brand.error',
  },

  titleText: {
    fontSize: '24px',
    fontWeight: '700',
    padding: '0 35px 0 35px',
  },

  detailText: {
    fontSize: '16px',
    fontWeight: '400',
    padding: '0 35px 0 35px',
  },

  detailsInput: {
    width: '80%',
    background: 'white',
    size: '16px',
    padding: '0 35px 0 35px',
  },

  rejectMessageButton: {
    colorScheme: 'whiteAlpha',
    backgroundColor: 'brand.green.100',
    height: '50px',
    _hover: {
      backgroundColor: 'brand.green.200',
    },
  },

  cancelButton: {
    colorScheme: 'whiteAlpha',
    backgroundColor: 'brand.gray.100',
    height: '50px',
    _hover: {
      backgroundColor: 'brand.green.200',
    },
  },

  rejectMessageButton2: {
    display: 'flex',
    width: '120px',
    height: '48px',
    padding: '8px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '24px',
    border: '1px solid var(--Clickable-Unselected, #BABCBF)',
  },

  cancelButton2: {
    display: 'flex',
    width: '120px',
    height: '48px',
    padding: '8px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '24px',
    border: '1px solid var(--Clickable-Default, #080809)',
    background: 'var(--Clickable-Unselected, #BABCBF)',
  },
};
