import React, { ReactElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Button, Flex, VStack, Heading, Text, Image } from '@chakra-ui/react';
import { useFormik } from 'formik';

import validationSchema from './validation-schema';
// import { LvedFeatures } from '@modules/common-ui/components/lved-features';
import { PasswordInput, TextInput } from '@modules/common-ui/components/text-field';
import { useQuery, useAuth } from '@modules/core/hooks';
import { storageGet } from '@modules/core/local-storage';
import { IUserLoginData, IAuthContext } from '@modules/core/contexts/auth/interfaces';
import logoImg from '@assets/images/lvedLogo.svg';

import { signInStyles } from './styles';

import { APP_ROUTES, LOCAL_STORAGE } from '@modules/core/constants';
import MfaCodeEntry from './MfaCodeEntry';

function SignIn(): ReactElement {
  const email: string | null =
    storageGet(LOCAL_STORAGE.lastUsedEmail) ?? storageGet(LOCAL_STORAGE.unverifiedEmail);

  const { signIn, needsMFACode }: IAuthContext = useAuth();
  const { submit, isLoading, err } = useQuery<IUserLoginData, void>(signIn);

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    onSubmit: submit,
    validationSchema,
    initialValues: {
      email: email ?? '',
      password: '',
    },
  });

  return (
    <Flex {...signInStyles.topContainer}>
      <Box {...signInStyles.signInBox}>
        <form onSubmit={handleSubmit}>
          <VStack {...signInStyles.contentBox}>
            <Box {...signInStyles.headerBox}>
              <Heading>Log in</Heading>
              <Image {...signInStyles.logo} src={logoImg} />
            </Box>
            <Text {...signInStyles.textWrapper}>
              New to LVED? &nbsp;
              <Text {...signInStyles.textGreen} as='span'>
                <RouterLink to={APP_ROUTES.signUp}>Create an account</RouterLink>
              </Text>
              {email && (
                <>
                  <Text as='span'>&nbsp; or &nbsp;</Text>
                  <Text {...signInStyles.textGreen} as='span'>
                    <RouterLink to={APP_ROUTES.emailVerification}>Confirm an account</RouterLink>
                  </Text>
                </>
              )}
            </Text>

            <TextInput
              id='email'
              name='email'
              type='email'
              label='Email Address'
              onChange={handleChange}
              value={values.email}
              isError={!!errors.email && touched.email}
              errorMessage={errors.email}
            />

            <PasswordInput
              id='password'
              name='password'
              label='Password'
              onChange={handleChange}
              value={values.password}
              isError={!!errors.password && touched.password}
              errorMessage={errors.password}
              style={{ ...signInStyles.passwordInput }}
            />

            {!needsMFACode && (
              <VStack w='100%'>
                <Button isLoading={isLoading} type='submit' {...signInStyles.submitButton}>
                  Log in
                </Button>

                <Box {...signInStyles.textContainer}>
                  <Text {...signInStyles.textGreen} as='span'>
                    <RouterLink to={APP_ROUTES.resetPassword}>Forgot your password</RouterLink>
                  </Text>
                </Box>
              </VStack>
            )}

            {err && (
              <Box {...signInStyles.textContainer}>
                <Text {...signInStyles.textError}>{err?.message || 'unknown error'}</Text>
              </Box>
            )}
          </VStack>
        </form>

        {needsMFACode && <MfaCodeEntry isAuthChallenge={false} />}
      </Box>
    </Flex>
  );
}

export default SignIn;
