import { UI_SIZES } from '@modules/core/constants';

export const sideBarStyles = {
  sideBarContainer: {
    width: `${UI_SIZES.sideBar.width}`,
    position: 'fixed',
    overflow: 'auto',
    top: '0',
    height: '100vh',
    zIndex: 10,
    backgroundColor: 'white',
    padding: '30px',
    boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.160784)',
    color: 'blackAlpha.700',
    fontWeight: '500',
  },

  sideBarContainerCollapsed: {
    width: `${UI_SIZES.sideBarMinimized.width}`,
    position: 'fixed',
    overflow: 'auto',
    top: '0',
    height: '40px',
    zIndex: 10,
    // backgroundColor: 'black',
    // padding: '30px',
    // boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.160784)',
    // color: 'blackAlpha.700',
    // fontWeight: '500',
  },

  hamburgerMenuImage: {
    boxSize: '24px',
    margin: '5px',
    // bgColor: 'white',
  },

  closeMenuImage: {
    boxSize: '24px',
    margin: `-20px 0px 0px 290px`,
  },

  logoListContainer: {
    spacing: '10',
    width: '100%',
  },

  contentBox: {
    justifyContent: 'space-between',
    height: '100%',
  },

  logoIcon: {
    width: '100px',
    height: '58px',
  },

  settingsIcon: {
    color: 'gray.500',
    boxSize: '5',
  },

  iconsFlexBox: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },

  signOutButton: {
    backgroundColor: 'brand.green.100',
    color: 'white',
    width: 'full',
    padding: '12px',
    marginTop: '24px !important',
    marginBottom: '53px !important',
    _hover: {
      backgroundColor: 'brand.green.200',
    },
  },

  listItemIconBox: {
    marginRight: '16px',
  },

  listItem: {
    paddingY: '8px',
    paddingX: '8px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '5px',
    _hover: {
      backgroundColor: 'brand.green.50',
      color: 'brand.green.100',
    },
  },

  listItemLink: {
    justifyContent: 'flex-start',
  },

  listItemActive: {
    color: 'brand.green.100',
  },

  listItemDisabled: {
    color: 'brand.gray.100',
    cursor: 'not-allowed',

    _hover: {
      backgroundColor: 'brand.gray.50',
    },
  },

  nestedItemsList: {
    paddingLeft: '16px',
  },

  commonItemsList: {
    width: '100%',
  },
} as const;
