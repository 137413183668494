import React, { ReactElement } from 'react';

import { Button, HStack, Image, Text, VStack } from '@chakra-ui/react';

import { onboardingStyles } from './styles';
import { IProcessStep, IProgressMenu } from './interfaces';

import accountIcon from '@assets/images/accountIcon.svg';
import downloadApp from '@assets/images/downloadApp.svg';
import circleEsateWishesIcon from '@assets/images/circleEstateWishesIcon.svg';
import profileIcon from '@assets/images/profileIcon.svg';
import selectPlanIcon from '@assets/images/selectPlanIcon.svg';
// import captureMessagesIcon from '@assets/images/captureMessagesIcon.svg';
// import digitalVaultIcon from '@assets/images/digitalVaultIcon.svg';
// import legalDocumentsIcon from '@assets/images/legalDocumentsIcon2.svg';
// import montiorUpdatesIcon from '@assets/images/monitorUpdatesIcon.svg';

const steps = [
  { step: 0, iconSource: accountIcon, title: 'Create Account' },
  { step: 1, iconSource: profileIcon, title: 'Setup your Basic Account Profile' },
  { step: 2, iconSource: selectPlanIcon, title: 'Select a Plan' },
  { step: 3, iconSource: downloadApp, title: 'Install the Lved App' },
  {
    step: 4,
    iconSource: circleEsateWishesIcon,
    title: 'Prepare and Manage your Comprehensive Estate Plan',
  },
  // { step: 5, iconSource: legalDocumentsIcon, title: 'Generate Legal Documents' },
  // { step: 6, iconSource: digitalVaultIcon, title: 'Populate your Digital Vault' },
  // { step: 7, iconSource: captureMessagesIcon, title: 'Capture Final Messages' },
  // { step: 8, iconSource: montiorUpdatesIcon, title: 'Monitor and Maintain Updates' },
];

function ProgressMenu({ stage, description, onNext }: IProgressMenu): ReactElement {
  function ProcessStep({ iconSource, title, isActive }: IProcessStep): ReactElement {
    return (
      <HStack>
        <Image
          src={iconSource}
          {...onboardingStyles.progressImage}
          {...(isActive ? onboardingStyles.activeProgressImage : {})}
        />
        <Text {...(isActive ? onboardingStyles.activeProgressText : {})}>{title}</Text>
      </HStack>
    );
  }

  function ShowProgress({ activeStep }: { activeStep: number }): ReactElement {
    return (
      <VStack {...onboardingStyles.progressBox}>
        {steps.map(({ step, iconSource, title }) => {
          return (
            <ProcessStep
              key={step}
              iconSource={iconSource}
              title={title}
              isActive={step === activeStep}
            />
          );
        })}
      </VStack>
    );
  }

  return (
    <VStack {...onboardingStyles.mainContainer}>
      <VStack {...onboardingStyles.contentContainer}>
        <Text w='100%' {...onboardingStyles.titleText}>
          Getting started with Lved
        </Text>
        <ShowProgress activeStep={stage} />
        <Text {...onboardingStyles.descriptionText}>{description}</Text>
        <Button {...onboardingStyles.nextButton} onClick={onNext}>
          {stage === 3 ? 'Download' : 'Continue'}
        </Button>
      </VStack>
    </VStack>
  );
}

export default ProgressMenu;
