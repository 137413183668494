import React, { useEffect, useState, ReactElement } from 'react';
import { Link as RouterLink, useNavigate, NavigateFunction } from 'react-router-dom';

import { Box, Button, VStack, Heading, Text } from '@chakra-ui/react';
import { useFormik } from 'formik';

import { resetPasswordValidationSchema } from './validation-schema';
import { useQuery } from '@modules/core/hooks';
import { PasswordInput, TextInput } from '@modules/common-ui/components/text-field';

import { forgotPasswordStyles } from './styles';

import { APP_ROUTES } from '@modules/core/constants';
import { IResetPasswordRequest } from './interfaces';
import RequestPasswordResetCode from './RequestPasswordResetCode';
import { getUserFromEmail } from './cognitoUser';

function ForgotPassword(): ReactElement {
  const [email, setEmail] = useState<string | undefined>();

  function resetPassword(data: IResetPasswordRequest): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const user = getUserFromEmail(email!);
      user.confirmPassword(data.emailedCode, data.newPassword, {
        onSuccess: () => {
          // console.log('Success: ', resData);
          resolve(true);
        },
        onFailure: (err) => {
          // console.log('Failure: ', err);
          reject(err);
        },
      });
    });
  }

  const navigateTo: NavigateFunction = useNavigate();
  const { submit, res, isLoading, err } = useQuery<IResetPasswordRequest, boolean | null>(
    resetPassword
  );
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    onSubmit: submit,
    validationSchema: resetPasswordValidationSchema,
    initialValues: {
      emailedCode: '',
      newPassword: '',
      confirmPassword: '',
    },
  });

  useEffect((): void => {
    if (res === true) {
      navigateTo(APP_ROUTES.signIn);
    }
  }, [res]);

  return (
    <div>
      {!email && <RequestPasswordResetCode setEmail={setEmail} />}
      {email && (
        <VStack {...forgotPasswordStyles.resetPasswordBox}>
          <Box {...forgotPasswordStyles.container}>
            <form onSubmit={handleSubmit}>
              <VStack>
                <Heading>Reset your password</Heading>

                <TextInput
                  id='emailedCode'
                  name='emailedCode'
                  label={`Please provide the password reset code you received at your ${email} email account`}
                  onChange={handleChange}
                  value={values.emailedCode}
                  isError={!!errors.emailedCode && touched.emailedCode}
                  errorMessage={errors.emailedCode}
                />

                <PasswordInput
                  id='newPassword'
                  name='newPassword'
                  label='New password'
                  onChange={handleChange}
                  value={values.newPassword}
                  isError={!!errors.newPassword && touched.newPassword}
                  errorMessage={errors.newPassword}
                />

                <PasswordInput
                  id='confirmPassword'
                  name='confirmPassword'
                  label='Confirm password'
                  onChange={handleChange}
                  value={values.confirmPassword}
                  isError={!!errors.confirmPassword && touched.confirmPassword}
                  errorMessage={errors.confirmPassword}
                />

                <Button isLoading={isLoading} type='submit' {...forgotPasswordStyles.button}>
                  Reset password
                </Button>

                {err && (
                  <Box {...forgotPasswordStyles.textErrorContainer}>
                    <Text {...forgotPasswordStyles.textError}>
                      {err?.message || 'unknown error'}
                    </Text>
                  </Box>
                )}
              </VStack>
            </form>
          </Box>

          <Text {...forgotPasswordStyles.textContainer}>
            Cancel, signin instead? &nbsp;
            <Text {...forgotPasswordStyles.textGreen} as='span'>
              <RouterLink to={APP_ROUTES.signIn}>Login</RouterLink>
            </Text>
          </Text>
        </VStack>
      )}
    </div>
  );
}

export default ForgotPassword;
