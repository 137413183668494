import React, { ReactElement, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Flex, Skeleton, Text, Box, useDisclosure } from '@chakra-ui/react';

import { useQuery, useEncryption } from '@modules/core/hooks';
import { decryptFile } from '@app/modules/core/crypto';
import { getAssets } from '@modules/core/aws/dynamo-db/digital-vault';
import { DecryptEncryptionKeyModal } from '@modules/common-ui/components/modal';
import { DigitalItem } from '@modules/common-ui/components/digital-item';
import { ConditionSeparator } from '@modules/common-ui/components/condition-separator';
import { IDigitalVaultAsestsProps, DigitalAssetParamsType } from './interfaces';
import { IGetAssets, AssetsListType } from '@modules/core/aws/dynamo-db/digital-vault/interfaces';

import { digitalVaultStyles as styles } from '@pages/digital-vault/styles';

function DigitalVaultAsests({ section }: IDigitalVaultAsestsProps): ReactElement {
  const { encryptionKey, setEncryptionKey } = useEncryption();
  const { submit, res, isLoading } = useQuery<IGetAssets, AssetsListType>(getAssets);
  const { templateName } = useParams<DigitalAssetParamsType>();
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();

  const getUrl = (fileData?: string): string => {
    let data: string | Uint8Array | undefined = fileData;
    const type: string = 'application/pdf;charset=utf-8';

    if (!data) return '';
    if (encryptionKey) {
      data = decryptFile(data, encryptionKey);
    }

    return window.URL.createObjectURL(new Blob([data], { type }));
  };

  useEffect(() => {
    if (section && templateName) {
      submit({ section, templateName });
    }
  }, []);

  return (
    <>
      <DecryptEncryptionKeyModal
        isOpen={isModalOpen}
        onClose={onModalClose}
        setKey={setEncryptionKey}
      />

      <Button hidden onClick={onModalOpen}>
        Decrypt
      </Button>

      <ConditionSeparator
        condition={isLoading}
        target={<Skeleton isLoaded={!isLoading} {...styles.skeleton} />}
        defaultTarget={
          <ConditionSeparator
            condition={Boolean(res && !res.length)}
            target={<Text>No data</Text>}
            defaultTarget={
              <Flex {...styles.assetsContainer}>
                {res?.map(({ Nickname, objectId, Document }) => (
                  <Box {...styles.assetBox} key={objectId}>
                    <ConditionSeparator
                      condition={!!Document}
                      defaultTarget={<DigitalItem title={Nickname || objectId} />}
                      target={
                        <a href={getUrl(Document)} download>
                          Click to download
                        </a>
                      }
                    />
                  </Box>
                ))}
              </Flex>
            }
          />
        }
      />
    </>
  );
}

export default DigitalVaultAsests;
