import React, { ReactElement, useEffect } from 'react';

import { Button, VStack } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { omitBy, isNil } from 'lodash';

import { createInitialValues } from '@modules/common-ui/components/modal/formik-initial-values';
import { useQueryToast, useAuth } from '@app/modules/core/hooks';
import { updateUserProfile } from '@app/modules/core/aws/dynamo-db/user-profile';

import { CustomerFieldsManager } from '@modules/common-ui/components/customer-fields-manager';

import { EditUserProfileModalPropsType } from '@modules/common-ui/components/modal/interfaces';
import { UserTemplateFieldType } from '@modules/core/aws/dynamo-db/user-profile/interfaces';
import { IAuthContext } from '@modules/core/contexts/auth/interfaces';

import Modal from '@modules/common-ui/components/modal/ModalBasic';

import { editUserProfileModalStyles } from '@modules/common-ui/components/modal/styles';

function EditUserProfileModal({ onClose, isOpen }: EditUserProfileModalPropsType): ReactElement {
  const { user, reloadUser }: IAuthContext = useAuth();
  const { submit, isLoading, res } = useQueryToast(updateUserProfile);
  const fields: UserTemplateFieldType[] =
    user?.Template.Fields.filter(({ ReadOnly }) => !ReadOnly) || [];

  useEffect(() => {
    if (res) {
      reloadUser();
    }
  }, [res]);

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: createInitialValues(user) || {},
    onSubmit: (data) => submit(omitBy(data, isNil)),
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} title='Edit User Profile' size='3xl'>
      <form onSubmit={handleSubmit}>
        <VStack {...editUserProfileModalStyles.contentBox}>
          {fields.map((field) => (
            <CustomerFieldsManager
              key={field.FieldName}
              field={field}
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />
          ))}

          <Button isLoading={isLoading} type='submit' {...editUserProfileModalStyles.button}>
            Save User Details
          </Button>
        </VStack>
      </form>
    </Modal>
  );
}

export default EditUserProfileModal;
