export const filterNavStyles = {
  filterContainer: {
    display: 'flex',
    padding: '12px 0px 12px 0px',
    width: { base: '342px', md: '100%' },
    flexDirection: { base: 'column', md: 'row' },
    gap: { base: '16px', md: '0px' },
    alignItems: { base: 'flex-start', md: 'center' },
    justifyContent: { base: 'center', md: 'space-between' },
  },

  filterTagContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: { base: 'space-between', md: '' },
    gap: '8px',
    alignSelf: 'stretch',
  },

  selectedTag: {
    display: 'flex',
    padding: '8px',
    alignItems: 'center',
    gap: '8px',
    color: 'var(--clickable-default, #080809)',
    fontFamily: 'Lato',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    borderBottom: '2px solid var(--clickable-default, #080809)',
  },

  unSelectedTag: {
    display: 'flex',
    padding: '8px',
    alignItems: 'center',
    gap: '8px',
    color: 'var(--clickable-unselected, #BABCBF)',
    fontFamily: 'Lato',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
  },

  searchContainer: {
    display: 'flex',
    padding: '0px 0px 0px 40px',
    width: '342px',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderRadius: '8px',
    border: '1px solid var(--elements-selected, #DDDDDF)',
    background: 'var(--input-default, #FFF)',
    color: 'var(--typography-placeholder, #080809)',
    fontFamily: 'Lato',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
  },
} as const;
