import React, { createElement, ReactNode } from 'react';
import { Route, Navigate } from 'react-router-dom';

import { RouteType, PropsType } from './interfaces';
import { ConditionSeparator } from '@modules/common-ui/components/condition-separator';

import PrivateRoute from './PrivateRoute';
import RouteWrapper from './RouteWrapper';

export function createRoutes(
  routes: RouteType[],
  isSideBarExpanded: boolean,
  parentProps: PropsType = {}
): ReactNode {
  return routes.map(
    ({
      redirect,
      path,
      parentPath,
      children,
      component,
      props = {},
      isNeedToBeWrapped = true,
      index = false,
      isAuthProtected = false,
      isAuthDisallowed = false,
      isDisallowedNoneLicensedUser = false,
    }) => {
      if (redirect) {
        return <Route key={path} path={path} element={<Navigate to={redirect} replace />} />;
      }

      if (children) {
        return (
          <Route
            key={path}
            path={path}
            {...(component && {
              element: (
                <ConditionSeparator
                  condition={isNeedToBeWrapped}
                  defaultTarget={createElement(component, props)}
                  target={
                    <RouteWrapper isSideBarExpanded={isSideBarExpanded} route={path}>
                      {createElement(component, props)}
                    </RouteWrapper>
                  }
                />
              ),
            })}
          >
            {createRoutes(children, isSideBarExpanded, props)}
          </Route>
        );
      }

      return (
        <Route
          index={index}
          key={path || parentPath}
          {...(path && { path })}
          {...(component && {
            element: (
              <ConditionSeparator
                condition={isNeedToBeWrapped}
                target={
                  <RouteWrapper isSideBarExpanded={isSideBarExpanded} route={parentPath || path}>
                    <ConditionSeparator
                      condition={isAuthProtected || isAuthDisallowed}
                      defaultTarget={createElement(component, { ...props, ...parentProps })}
                      target={
                        <PrivateRoute
                          isAuthProtected={isAuthProtected}
                          isAuthDisallowed={isAuthDisallowed}
                          isDisallowedNoneLicensedUser={isDisallowedNoneLicensedUser}
                        >
                          {createElement(component, { ...props, ...parentProps })}
                        </PrivateRoute>
                      }
                    />
                  </RouteWrapper>
                }
                defaultTarget={
                  <ConditionSeparator
                    condition={isAuthProtected || isAuthDisallowed}
                    defaultTarget={createElement(component, { ...props, ...parentProps })}
                    target={
                      <PrivateRoute
                        isAuthProtected={isAuthProtected}
                        isAuthDisallowed={isAuthDisallowed}
                        isDisallowedNoneLicensedUser={isDisallowedNoneLicensedUser}
                      >
                        {createElement(component, { ...props, ...parentProps })}
                      </PrivateRoute>
                    }
                  />
                }
              />
            ),
          })}
        />
      );
    }
  );
}
