import React, { ReactElement, useEffect, useState } from 'react';
import { MobileDeviceMFAProps } from './interfaces';
import { Button, HStack, Image, Input, Spacer, Text, VStack } from '@chakra-ui/react';
import { mobileDeviceMFAStyles, MFASharedStyles } from './styles';
import { PhoneInput } from '@modules/common-ui/components/text-field';
import validationSchema from './validation-schema';
import { IAuthContext, ISetMobileNoData } from '@app/modules/core/contexts/auth/interfaces';
import { useAuth, useQuery } from '@app/modules/core/hooks';
import { useFormik } from 'formik';
import { CheckBox } from '../check-box';
import errorIcon from '@assets/images/error_filled.svg';
import successIcon from '@assets/images/check_circle.svg';

function MobileDeviceMFA({ onClose, setMFAPreference }: MobileDeviceMFAProps): ReactElement {
  const [verificationStage, setVerificationStage] = useState<0 | 1 | 2>(0);
  const [mobileAuthCode, setMobileAuthCode] = useState('');
  const [isValidatingCode, setIsValidatingCode] = useState(false);
  const { setMobileNo, verifyPhoneNoWithCode }: IAuthContext = useAuth();
  const { submit, res, isLoading, err } = useQuery<ISetMobileNoData, boolean>(setMobileNo);
  const [errorStr, setErrorStr] = useState<string | null>();
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    onSubmit: submit,
    validationSchema,
    initialValues: {
      mobileNo: '',
      acceptedSMS: false,
    },
  });

  useEffect(() => {
    if (res) setVerificationStage(1);
    else setVerificationStage(0);
  }, [res]);

  function submitMobileCode() {
    if (mobileAuthCode.length !== 6) {
      setErrorStr('Code must be 6 digits');
    } else {
      setIsValidatingCode(true);
      verifyPhoneNoWithCode(mobileAuthCode)
        .then(
          (success: boolean) => {
            if (success) {
              setErrorStr(null);
              setMFAPreference('SMS', values.mobileNo.replace(/[^0-9+]/g, '')).then(
                (turnOnMFASuccess) => {
                  if (turnOnMFASuccess) setVerificationStage(2);
                  else
                    setErrorStr(
                      'Your code was correct, but there was an unknown error turning on MFA for this device'
                    );
                }
              );
            }
          },
          (reason: any) => {
            setMobileAuthCode('');
            setErrorStr(`Code was invalid: ${reason}`);
          }
        )
        .finally(() => {
          setIsValidatingCode(false);
        });
    }
  }

  return (
    <VStack {...mobileDeviceMFAStyles.container}>
      <Text {...MFASharedStyles.heading}>Modile Device Second Factor (SMS)</Text>
      <form onSubmit={handleSubmit}>
        <VStack {...mobileDeviceMFAStyles.container}>
          {verificationStage === 0 && (
            <VStack width='100%'>
              <PhoneInput
                isError={!!errors.mobileNo && touched.mobileNo}
                errorMessage={errors.mobileNo}
                label='Mobile Number (US only)'
                id='mobileNo'
                name='mobileNo'
                size='md'
                value={values.mobileNo}
                onChange={handleChange}
              />
              <CheckBox
                id='acceptedSMS'
                name='acceptedSMS'
                onChange={handleChange}
                checked={values.acceptedSMS}
                isError={!!errors.acceptedSMS && touched.acceptedSMS}
                errorMessage={errors.acceptedSMS}
              >
                I accept receiving SMS messages from LVED for time-sensitive notifications and
                authentication as needed
              </CheckBox>
              <Text {...MFASharedStyles.informationText}>
                (If you don&apos;t have a US-based mobile number, select cancel and utilize an
                Authenticator app instead.)
              </Text>
            </VStack>
          )}
          {verificationStage === 1 && (
            <VStack width='100%'>
              <Text>
                We have sent a verification code to <b>{values.mobileNo}</b>, enter it below.
              </Text>
              <Input
                {...MFASharedStyles.authCodeInput}
                placeholder='- - - - - -'
                value={mobileAuthCode}
                onChange={(e) => setMobileAuthCode(e.target.value)}
              />
            </VStack>
          )}
          {verificationStage === 2 && (
            <VStack width='100%' gap='25px'>
              <VStack gap='10px'>
                <Image src={successIcon} {...MFASharedStyles.successIcon} />
                <Text {...MFASharedStyles.successTitle}>Successfully enabled</Text>
              </VStack>
              <Text>
                Your mobile number is set to <b>{values.mobileNo}</b>. Authentication codes will be
                texted to this number in the future
              </Text>
            </VStack>
          )}
          {(err || errorStr) && (
            <HStack {...MFASharedStyles.textErrorContainer}>
              <Image {...MFASharedStyles.errorIcon} src={errorIcon} />
              <Text {...MFASharedStyles.textError}>{err ? err.message : errorStr}</Text>
            </HStack>
          )}
          <HStack {...MFASharedStyles.footer}>
            <Spacer />
            <Button
              {...MFASharedStyles.cancelButton}
              onClick={() => {
                onClose();
              }}
            >
              {verificationStage === 2 ? 'Continue' : 'Cancel'}
            </Button>

            {verificationStage === 0 && (
              <Button isLoading={isLoading} type='submit' {...MFASharedStyles.nextButton}>
                Continue
              </Button>
            )}

            {verificationStage === 1 && (
              <Button
                {...MFASharedStyles.nextButton}
                isLoading={isValidatingCode}
                onClick={submitMobileCode}
              >
                Verify code
              </Button>
            )}
          </HStack>
        </VStack>
      </form>
    </VStack>
  );
}

export default MobileDeviceMFA;
