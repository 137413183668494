import React, { ReactElement } from 'react';
import { useNavigate, NavigateFunction } from 'react-router-dom';

import { Box, Heading, Flex, VStack } from '@chakra-ui/react';

import { DokumentIcon } from '@modules/common-ui/icons';
import { DigitalItem } from '@modules/common-ui/components/digital-item';

import { digitalVaultStyles } from '@pages/digital-vault/styles';

import { APP_ROUTES, HIERARCHY_SECTION, HIERARCHY_CATEGORY } from '@modules/core/constants';

function DigitalVault(): ReactElement {
  const navigateTo: NavigateFunction = useNavigate();
  const openPage = (route: APP_ROUTES) => navigateTo(route);

  return (
    <>
      <Box {...digitalVaultStyles.container}>
        <VStack {...digitalVaultStyles.sectionLayoutContentBox}>
          <Heading>{HIERARCHY_CATEGORY.digitalVault}</Heading>

          <Flex>
            <Box
              onClick={openPage.bind(null, APP_ROUTES.digitalVaultFamilyDocuments)}
              {...digitalVaultStyles.assetBox}
            >
              <DigitalItem icon={<DokumentIcon />} title={HIERARCHY_SECTION.familyDocuments} />
            </Box>

            <Box
              onClick={openPage.bind(null, APP_ROUTES.digitalVaultPersonalDocuments)}
              {...digitalVaultStyles.assetBox}
            >
              <DigitalItem icon={<DokumentIcon />} title={HIERARCHY_SECTION.personalDocuments} />
            </Box>

            <Box
              onClick={openPage.bind(null, APP_ROUTES.digitalVaultOtherDocuments)}
              {...digitalVaultStyles.assetBox}
            >
              <DigitalItem icon={<DokumentIcon />} title={HIERARCHY_SECTION.otherDocuments} />
            </Box>

            <Box
              onClick={openPage.bind(null, APP_ROUTES.digitalVaultPasswordsCombinations)}
              {...digitalVaultStyles.assetBox}
            >
              <DigitalItem icon={<DokumentIcon />} title={HIERARCHY_SECTION.passwords} />
            </Box>

            <Box
              onClick={openPage.bind(null, APP_ROUTES.digitalVaultTaxes)}
              {...digitalVaultStyles.assetBox}
            >
              <DigitalItem icon={<DokumentIcon />} title={HIERARCHY_SECTION.taxes} />
            </Box>
          </Flex>
        </VStack>
      </Box>
    </>
  );
}

export default DigitalVault;
