import { getCommonAssets, getAsset } from '@modules/core/aws/dynamo-db/digital-vault';
import {
  IGetAssets,
  AssetsListType,
  CommonAssetsListType,
} from '@modules/core/aws/dynamo-db/digital-vault/interfaces';

export async function getAssets(req: IGetAssets): Promise<AssetsListType> {
  const commonAssets: CommonAssetsListType = await getCommonAssets(req);
  return Promise.all(commonAssets.map(({ objectId }) => getAsset(objectId)));
}
