export const toggleStyles = {
  container: {
    fontFamily: 'Lato',
    height: '50px',
    width: '227px',
    background: '#E3E3E3 0% 0% no-repeat padding-box',
    // border: '1px solid #474747',
    borderRadius: '25px',
    userSelect: 'none',
  },

  inactiveText: {
    margin: '5px',
    width: '50%',
    hgith: '40px',
    textAlign: 'center',
    padding: '6px',
    opacity: '0.5',
    color: '#2E5D5F',
    transform: 'uppercase',
  },

  activeText: {
    margin: '5px',
    width: '50%',
    height: '40px',
    background: '#2E5D5F 0% 0% no-repeat padding-box',
    borderRadius: '20px',
    fontSize: '16px',
    color: '#FFFFFF',
    padding: '6px',
    textAlign: 'center',
    transform: 'uppercase',
    opacity: '1',
  },
} as const;
