import React, { ReactElement, useEffect, useState, ChangeEvent } from 'react';

import {
  Box,
  Flex,
  Button,
  VStack,
  Text,
  useDisclosure,
  HStack,
  Wrap,
  Link,
} from '@chakra-ui/react';

import { PaidPricingCard, FreePricingCard } from '@modules/common-ui/components/pricing-card';
import { JoinFamilyMemberModal } from '@modules/common-ui/components/modal';
import { IndividualIcon, FamilyIcon, DiscountIcon } from '@modules/common-ui/icons';
import { useQuery, useQueryToast, useAppEntryParams } from '@modules/core/hooks';
import { getProductsList as getProducts } from '@modules/core/aws/lambdas/payments';
import {
  ProductType,
  IProductsListRes,
  IGetProductsInput,
} from '@modules/core/aws/lambdas/payments/interfaces';

// import Background from '@assets/images/bgImage.png';
import Header from '@modules/common-ui/components/header';
import Loader from '@modules/common-ui/components/global-loader';
import PromoCodeSlide from '@modules/common-ui/components/slide/promo-code';

import { pricingStyles } from './styles';

import { PRODUCTBILLING, LICENSE_STATUSES } from '@modules/core/constants';
import { Avatar } from '@app/modules/common-ui/components/profile-basic-info/Avatar';
import { EmailDomainChallenge } from './EmailDomainChallenge';
import { AcceptCorporateSubscription } from './AcceptCorporateSubscription';
import Toggle from '@app/modules/common-ui/components/toggle';
import { SocialProof } from './SocialProof';

const agentTierFeatures = [
  `Assist a subscriber as an Account Agent`,
  `50MB private Digital Vault`,
  `Expert Guided Templates`,
  `Life Stories Capture`,
  `Final Message Preparation`,
  'Customer Support for Account Agents',
];

/* 
const freeTierFeatures = [
  `50MB private Digital Vault`,
  `Expert Guided Templates`,
  `Life Stories Capture`,
  `Final Message Preparation`,
  `Assist a subscriber as an Account Agent`,
];
*/

const individualTierFeatures = [
  `Legal Documents with Unlimited Updates`,
  `25GB of Controlled Release Storage`,
  `Life Stories Publication`,
  `Final Messages Delivery`,
  'Customer Support',
  `Cancel anytime, keep your legal documents`,
];

const familyTierFeatures = [
  `4 individual licenses, each with 25GB of dedicated digital vault storage`,
  `Cancel anytime, everyone keeps their legal documents`,
  `Designed for spouses and adult children helping parents get organized`,
];

function Pricing(): ReactElement {
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  const { isOpen: isSlideOpen, onOpen: onSlideOpen, onClose: onSlideClose } = useDisclosure();

  const appEntryParams = useAppEntryParams();
  const [activeProduct, setActiveProduct] = useState<ProductType | null>(null);
  const [activeProductType, setActiveProductType] = useState<PRODUCTBILLING | null>(null);
  const [familyInvitesExist, setFamilyInvitesExist] = useState(false);
  const [products, setProducts] = useState<IProductsListRes | null>(null);
  const [promoCode, setPromoCode] = useState<string>('');

  const {
    submit: submitProducts,
    res: originalProducts,
    isLoading: isProductsLoading,
    err: originalProductsErr,
  } = useQuery<IGetProductsInput, IProductsListRes>(getProducts);
  const {
    submit: updateProducts,
    res: updatedProducts,
    isLoading: isProductsUpdating,
    err: updateProductsErr,
  } = useQueryToast<IGetProductsInput, IProductsListRes>(getProducts);

  const getDiscount = (price: number, discount: number = 0): number => {
    if (!discount) return price;
    return Math.round(price * (1 - discount));
  };

  const product = updatedProducts ?? originalProducts;
  let yearlySavingsPts = 60;
  if (product) {
    const monthlyForYear = product['Monthly billing']['Individual Plan'] * 12;
    const annualCost = product['Annual billing']['Individual Plan'];
    yearlySavingsPts = Math.round(10 - (annualCost * 10) / monthlyForYear) * 10;
  }

  const changePromoCodeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    return setPromoCode(e.target.value);
  };

  const getProductsWithPromoCode = (): void => {
    updateProducts({ promoCode });
  };

  const changeBillingHandler = (productType: PRODUCTBILLING) => {
    if (products && products[productType]) {
      setActiveProduct(products[productType]);
      if (activeProductType !== productType) {
        setActiveProductType(productType);
      }
    }
  };

  useEffect(() => {
    // (originalProducts);
    // console.log(updatedProducts);
    if (originalProducts || updatedProducts) {
      setProducts(updatedProducts || originalProducts);
      setFamilyInvitesExist(
        (updatedProducts?.['Family memberships'].length ?? 0) > 0 ||
          (originalProducts?.['Family memberships'].length ?? 0) > 0
      );
    }
  }, [originalProducts, updatedProducts]);

  useEffect(() => {
    if (updateProductsErr && promoCode) {
      setPromoCode('');
    }
  }, [updateProductsErr]);

  useEffect(() => {
    if (appEntryParams.promoCode || appEntryParams.affiliateCode) {
      // console.log('Promo or Affiliate code found, using it');
      // submitProducts({}).then(() => {
      setPromoCode(appEntryParams.promoCode ?? '');
      updateProducts({ promoCode: appEntryParams.promoCode ?? '' });
      // });
    } else {
      // console.log('No promo or affiliate codes found');
      submitProducts({});
    }
  }, [appEntryParams.promoCode, appEntryParams.affiliateCode]);

  function getMonthlyCostCompare(licenseType: LICENSE_STATUSES): number | undefined {
    if (activeProductType === PRODUCTBILLING.monthly) return undefined;
    const liveProducts = updatedProducts || originalProducts;
    if (!liveProducts) return undefined;
    if (licenseType === LICENSE_STATUSES.family)
      return liveProducts[PRODUCTBILLING.monthly]['Family Plan'];
    if (licenseType === LICENSE_STATUSES.individual)
      return liveProducts[PRODUCTBILLING.monthly]['Individual Plan'];
    return undefined;
  }

  function getAnnualRenewalPrice(licenseType: LICENSE_STATUSES): number | undefined {
    if (activeProductType === PRODUCTBILLING.monthly) return undefined;
    const liveProducts = updatedProducts || originalProducts;
    if (!liveProducts) return undefined;
    if (licenseType === LICENSE_STATUSES.family)
      return liveProducts[PRODUCTBILLING.annual]['Family Plan Renewal'];
    if (licenseType === LICENSE_STATUSES.individual)
      return liveProducts[PRODUCTBILLING.annual]['Individual Plan Renewal'];
    return undefined;
  }

  function isShowingBasePricing(): boolean {
    if (
      (updatedProducts?.promoCode ?? '').length > 0 &&
      updatedProducts?.promoCode !== 'BASE PRICE'
    )
      return false;
    if (!updatedProducts && (originalProducts?.affiliateName ?? '').length > 0) return false;
    return true;
  }

  function freeTierAvailable(): boolean {
    return updatedProducts?.isFreeTierAvailable ?? originalProducts?.isFreeTierAvailable ?? false;
  }

  function isAnnualOnly(): boolean {
    return (updatedProducts?.selectionOptions ?? '*').toLowerCase().includes('annual');
  }

  function isMonthlyOnly(): boolean {
    return (updatedProducts?.selectionOptions ?? '*').toLowerCase().includes('monthly');
  }

  function isIndividualPlanAvailable(): boolean {
    return (updatedProducts?.selectionOptions ?? 'individual').toLowerCase().includes('individual');
  }

  function isFamilyPlanAvailable(): boolean {
    return (updatedProducts?.selectionOptions ?? 'family').toLowerCase().includes('family');
  }

  function showTermSelection(): boolean {
    if (!updatedProducts) return false;
    return !(isAnnualOnly() || isMonthlyOnly());
  }

  function multiplePlansAvailable(): boolean {
    let planCount = 0;
    if (freeTierAvailable()) planCount++;
    if (isIndividualPlanAvailable()) planCount++;
    if (isFamilyPlanAvailable()) planCount++;
    return planCount > 1;
  }

  useEffect(() => {
    if (isAnnualOnly()) changeBillingHandler(PRODUCTBILLING.annual);
    else if (isMonthlyOnly()) changeBillingHandler(PRODUCTBILLING.monthly);
    else changeBillingHandler(PRODUCTBILLING.annual);
    // console.log(products);
  }, [products]);

  return (
    <>
      {updatedProducts?.subscriberValidationModel === 'email domain' && (
        <EmailDomainChallenge {...updatedProducts!} />
      )}
      {updatedProducts?.subscriberValidationModel === 'manual approval' && (
        <AcceptCorporateSubscription {...updatedProducts} />
      )}
      {updatedProducts?.subscriberValidationModel === undefined && (
        <Box {...pricingStyles.backgroundContainer}>
          <VStack paddingTop='100px' paddingBottom={isSlideOpen ? '130px' : '0'}>
            <Header />

            {isProductsLoading && <Loader />}
            {originalProductsErr && (
              <Text>{`Unable to load products. ${originalProductsErr}`}</Text>
            )}

            <JoinFamilyMemberModal
              isOpen={isModalOpen}
              onClose={onModalClose}
              familyMembers={products?.['Family memberships'] || []}
            />

            <PromoCodeSlide
              isOpen={isSlideOpen}
              onClose={onSlideClose}
              onSubmit={getProductsWithPromoCode}
              isSubmitLoading={isProductsUpdating}
              value={promoCode}
              onValueChange={changePromoCodeHandler}
              isPromoCodeApplied={!isShowingBasePricing()}
              onResetRequest={() => {
                setPromoCode('');
                updateProducts({ promoCode: 'BASE PRICE' });
                onSlideClose();
              }}
            />

            {products && activeProduct && (
              <VStack {...pricingStyles.contentBox}>
                <VStack width='100%'>
                  <Text {...pricingStyles.heading}>Choose your plan</Text>

                  {familyInvitesExist && (
                    <VStack width='100%'>
                      <Text fontSize='lg'>or</Text>
                      <Box {...pricingStyles.joinMemberBox} onClick={onModalOpen}>
                        <Text as='u' {...pricingStyles.joinMemberText}>
                          You&apos;ve been gifted a free subscription
                        </Text>
                      </Box>
                    </VStack>
                  )}
                </VStack>
                <Wrap alignItems='middle'>
                  <Box h={products.affiliateName || products.promoDescription ? 'auto' : 'auto'}>
                    <Button
                      {...pricingStyles.discountButton}
                      {...(products.affiliateName || products.promoDescription
                        ? { h: 'auto', maxW: '300px' }
                        : {})}
                      {...(products.affiliateName || products.promoDescription
                        ? { margin: '0px' }
                        : {})}
                      onClick={onSlideOpen}
                    >
                      <HStack {...pricingStyles.discountContentBox}>
                        {isShowingBasePricing() && <DiscountIcon />}
                        <VStack {...pricingStyles.discountButtonContents}>
                          <Text margin='4px'>
                            {isShowingBasePricing() ? 'Have Promocode?' : 'Promotion Activated'}
                          </Text>
                          {products?.promoDescription && (
                            <Text {...pricingStyles.activePromotionText}>
                              {products.promoDescription}
                            </Text>
                          )}
                          {products.affiliateName && (
                            <Text {...pricingStyles.activePromotionText}>
                              {products.affiliateName}
                            </Text>
                          )}
                        </VStack>
                      </HStack>
                    </Button>
                  </Box>
                  {products.affiliateName && (
                    <Avatar file={products.affiliateLogo} style={pricingStyles.affiliateLogo} />
                  )}
                </Wrap>

                {showTermSelection() && (
                  <VStack spacing='5px'>
                    <Toggle
                      initialSelection={
                        activeProductType === PRODUCTBILLING.annual ? 'Yearly' : 'Monthly'
                      }
                      option1='Monthly'
                      option2='Yearly'
                      onSelection={(selection) => {
                        changeBillingHandler(
                          selection === 'Monthly' ? PRODUCTBILLING.monthly : PRODUCTBILLING.annual
                        );
                      }}
                    />
                    <Text {...pricingStyles.discountComment}>
                      Yearly payments save ~{yearlySavingsPts}%
                    </Text>
                  </VStack>
                )}

                <Flex {...pricingStyles.cardsBox}>
                  {isFamilyPlanAvailable() && (
                    <PaidPricingCard
                      key={activeProductType + LICENSE_STATUSES.family}
                      lecense={LICENSE_STATUSES.family}
                      isAnnualPlan={activeProductType === PRODUCTBILLING.annual}
                      heading='Group (up to 4) Bundle'
                      features={familyTierFeatures}
                      price={getDiscount(activeProduct[LICENSE_STATUSES.family], products.discount)}
                      renewalPrice={getAnnualRenewalPrice(LICENSE_STATUSES.family)}
                      monthlyPrice={getMonthlyCostCompare(LICENSE_STATUSES.family)}
                      promoCode={promoCode}
                      icon={FamilyIcon}
                    />
                  )}

                  {isIndividualPlanAvailable() && (
                    <PaidPricingCard
                      key={activeProductType + LICENSE_STATUSES.individual}
                      lecense={LICENSE_STATUSES.individual}
                      isAnnualPlan={activeProductType === PRODUCTBILLING.annual}
                      heading='Estate Plan'
                      isPopular
                      features={individualTierFeatures}
                      price={getDiscount(
                        activeProduct[LICENSE_STATUSES.individual],
                        products.discount
                      )}
                      renewalPrice={getAnnualRenewalPrice(LICENSE_STATUSES.individual)}
                      monthlyPrice={getMonthlyCostCompare(LICENSE_STATUSES.individual)}
                      promoCode={promoCode}
                      icon={IndividualIcon}
                    />
                  )}

                  {freeTierAvailable() && (
                    <FreePricingCard
                      planName='Agent (and Free Trial)'
                      key={activeProductType}
                      features={agentTierFeatures}
                    />
                  )}
                </Flex>
              </VStack>
            )}

            {multiplePlansAvailable() && (
              <Wrap padding='20px'>
                <Text {...pricingStyles.text}>Not sure which plan is right for you?</Text>
                <Link href='https://www.lved.com/prices.html' {...pricingStyles.link}>
                  Review our detailed plan comparisons.
                </Link>
              </Wrap>
            )}
            <SocialProof />
          </VStack>
        </Box>
      )}
    </>
  );
}

export default Pricing;
