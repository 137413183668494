import React, { ReactElement } from 'react';

import { Icon } from '@chakra-ui/react';

import { ICommonIconProps } from './interfaces';

function DokumentIcon({ width = '36px', height = '36px' }: ICommonIconProps): ReactElement {
  return (
    <Icon
      width={width}
      height={height}
      viewBox='0 0 38 37'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.9255 32.1695H23.0209V22.1165H33.0739V4.02119H4.9255V32.1695ZM4.9255 36.1907C3.81967 36.1907 2.87267 35.7973 2.08452 35.0105C1.2977 34.2223 0.904297 33.2753 0.904297 32.1695V4.02119C0.904297 2.91536 1.2977 1.96837 2.08452 1.18022C2.87267 0.393406 3.81967 0 4.9255 0H33.0739C34.1797 0 35.1267 0.393406 35.9149 1.18022C36.7017 1.96837 37.0951 2.91536 37.0951 4.02119V24.1271L25.0315 36.1907H4.9255ZM8.94669 22.1165V18.0954H18.9997V22.1165H8.94669ZM8.94669 14.0742V10.053H29.0527V14.0742H8.94669Z'
        fill='#8BCB4B'
      />
    </Icon>
  );
}

export default DokumentIcon;
