import React, { ReactElement } from 'react';
import InputMask from 'react-input-mask';
import { FormControl, FormLabel, FormErrorMessage, Input, InputGroup } from '@chakra-ui/react';

import { INotificationKeyInputProps } from './interfaces';

function NotificationKeyInput({
  label,
  id,
  errorMessage,
  mask,
  variant = 'outline',
  size = 'lg',
  isError = false,
  ...rest
}: INotificationKeyInputProps): ReactElement {
  return (
    <FormControl isInvalid={isError}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      <InputGroup size={size}>
        <Input
          id={id}
          as={InputMask}
          maskChar={null}
          mask={mask || '********-****-****-****-************'}
          variant={variant}
          {...rest}
        />
      </InputGroup>
      {isError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
}

export default NotificationKeyInput;
