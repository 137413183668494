import React from 'react';

import { Box, VStack } from '@chakra-ui/react';

import { PaymentStatus, StripeContainer } from '@modules/common-ui/components/payments';
import Background from '@assets/images/bgImage.png';

import { paymentCompletionStyles } from './styles';

function PaymentCompletion() {
  return (
    <Box backgroundImage={Background} {...paymentCompletionStyles.backgroundContainer}>
      <VStack {...paymentCompletionStyles.mainContainer}>
        <StripeContainer>
          <PaymentStatus />
        </StripeContainer>
      </VStack>
    </Box>
  );
}

export default PaymentCompletion;
