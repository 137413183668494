import React, { ReactElement, useState } from 'react';

import { Slide, Box } from '@chakra-ui/react';
import { CloseIcon, ArrowBackIcon } from '@chakra-ui/icons';

import { ConditionSeparator } from '@modules/common-ui/components/condition-separator';
import { TemplateName } from '@modules/common-ui/components/slide/digital-vault/TemplateName';
import { TemplateDetails } from '@modules/common-ui/components/slide/digital-vault/TemplateDetails';
import { IDigitalVaultSlideProps } from '@modules/common-ui/components/slide/digital-vault/interfaces';
import { TemplateNameType } from '@app/modules/core/aws/dynamo-db/digital-vault/interfaces';

import { digitalVaultSlideStyles } from '@modules/common-ui/components/slide/digital-vault/styles';

const DIRECTION = 'right';

function DigitalVaultSlide({ isOpen, section, onClose }: IDigitalVaultSlideProps): ReactElement {
  const [activeTemplate, setActiveTemplate] = useState<TemplateNameType | null>(null);
  const [processStep, setProcessStep] = useState<number>(0);

  const setFirstStep = (): void => setProcessStep(0);
  const setSecondStep = (): void => setProcessStep(1);

  const cleanTemplate = (): void => setActiveTemplate(null);
  const saveTemplate = (template: TemplateNameType): void => setActiveTemplate(template);

  const closeSlide = (): void => {
    onClose();
    cleanTemplate();
    setFirstStep();
  };

  return (
    <Slide direction={DIRECTION} in={isOpen} style={digitalVaultSlideStyles.slide}>
      <Box {...digitalVaultSlideStyles.mainContainer}>
        <ConditionSeparator
          condition={!processStep}
          defaultTarget={<TemplateDetails template={activeTemplate} closeSlide={closeSlide} />}
          target={
            <TemplateName
              section={section}
              activeTemplate={activeTemplate}
              onSubmitCallback={setSecondStep}
              saveTemplate={saveTemplate}
            />
          }
        />

        <Box {...digitalVaultSlideStyles.iconBox}>
          <ConditionSeparator
            condition={!!processStep}
            target={<ArrowBackIcon onClick={setFirstStep} {...digitalVaultSlideStyles.backIcon} />}
            defaultTarget={<CloseIcon onClick={closeSlide} />}
          />
        </Box>
      </Box>
    </Slide>
  );
}

export default DigitalVaultSlide;
