export enum AWS_LAMBDAS {
  linkUser = 'prod_linkUsernameToIdentityId',
  payments = 'prod_payments',
  dashboard = 'prod_getDashboardMetrics',
  exploreStoriesWithLink = 'prod_exploreStoriesWithLink',
  familyAction = 'prod_familyActions',
  reviewFinalMessage = 'prod_reviewFinalMessage',
  storageUsage = 'prod_getStorageUsage',
  manageFamilyPlan = 'prod_manageFamilyPlan',
  syncProfile = 'prod_syncCognitoContactInfo',
  getProfileThumbnail = 'prod_getProfileThumbnail',
  getAffiliateProfile = 'prod_getAffiliateProfile',
}

export enum AWS_LAMBDAS_OPERATIONS {
  dontRenewSubscription = 'dontRenewSubscription',
  createPaymentIntent = 'createPaymentIntent',
  getPayments = 'getPayments',
  getProductList = 'getProductList',
  joinFamilyPlan = 'joinFamilyPlan',
  selectFreePlan = 'selectFreePlan',
  getAccountHolderName = 'getAccountHolderName',
  registerEvent = 'registerEvent',
  getStoryList = 'getStoryList',
  getFinalMessage = 'getMessage',
  registerFinalMessagePlayed = 'messagePlayed',
  rejectFinalMessage = 'rejectMessage',
  getFamilyMemberSlotStatus = 'getFamilyMemberSlotStatus',
  inviteFamilyMember = 'inviteFamilyMember',
  beginEmailDomainChallenge = 'beginEmailDomainChallenge',
  acceptCorporatePlan = 'acceptCorporatePlan',
}

export enum AWS_LAMBDA_ERRORS {
  noData = 'No data returned',
  unknownFormat = 'Some problems with the data received: unknown format',
}
