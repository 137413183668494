import React, { ReactElement } from 'react';

import { VStack, Text, HStack, useDisclosure, Box, Heading, Wrap } from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';

import { useAuth } from '@modules/core/hooks';
import { Section } from '@modules/common-ui/components/section';
import { Avatar } from '@modules/common-ui/components/profile-basic-info/Avatar';
import { ConditionSeparator } from '@modules/common-ui/components/condition-separator';
import { EditUserProfileModal } from '@modules/common-ui/components/modal';
import { IAuthContext } from '@modules/core/contexts/auth/interfaces';

import { profileBasicInfoStyles } from '@modules/common-ui/components/profile-basic-info/styles';

function ProfileBasicInfo(): ReactElement {
  const { user, isAuthenticating }: IAuthContext = useAuth();
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();

  const getAddress = (): string | undefined => {
    if (!user?.address?.State) return undefined;

    return `${user.address.Street1 ?? ''}${user.address.Street2 ?? ''}, ${
      user.address.City ?? ''
    } ${user.address.State ?? ''} ${user.address.Zip ?? ''}`;
  };

  return (
    <Box {...profileBasicInfoStyles.container}>
      <EditUserProfileModal isOpen={isModalOpen} onClose={onModalClose} />

      <Section title='User Profile' isLoading={isAuthenticating}>
        <ConditionSeparator
          condition={!!user}
          target={
            <VStack {...profileBasicInfoStyles.contentBox}>
              <Wrap {...profileBasicInfoStyles.avatarIconBox}>
                <Avatar file={user?.thumbnail} />
                <Heading>{user?.fullName}</Heading>
                <EditIcon onClick={onModalOpen} {...profileBasicInfoStyles.editIcon} />
              </Wrap>

              <Box {...profileBasicInfoStyles.separator} />

              <Wrap {...profileBasicInfoStyles.userInfoContainer}>
                <VStack spacing='10' width='350px'>
                  <HStack {...profileBasicInfoStyles.userInfoSubContainer}>
                    <Text {...profileBasicInfoStyles.fieldName}>Email:</Text>

                    <Text {...profileBasicInfoStyles.greenText}>
                      {user?.contactInfo.Email || 'no info'}
                    </Text>
                  </HStack>

                  <HStack {...profileBasicInfoStyles.userInfoSubContainer}>
                    <Text {...profileBasicInfoStyles.fieldName}>Address:</Text>

                    <Text {...profileBasicInfoStyles.greenText}>{getAddress() || 'no info'}</Text>
                  </HStack>

                  <HStack {...profileBasicInfoStyles.userInfoSubContainer}>
                    <Text {...profileBasicInfoStyles.fieldName}>Cooling off period:</Text>

                    <Text {...profileBasicInfoStyles.greenText}>
                      {user?.coolOffPeriod || 'no info'}
                    </Text>
                  </HStack>

                  <HStack {...profileBasicInfoStyles.userInfoSubContainer}>
                    <Text {...profileBasicInfoStyles.fieldName}>Subscription status:</Text>

                    <Text {...profileBasicInfoStyles.greenText}>
                      {user?.licenseStatus || 'no info'}
                    </Text>
                  </HStack>
                </VStack>
                <VStack spacing='10' width='350px'>
                  <HStack {...profileBasicInfoStyles.userInfoSubContainer}>
                    <Text {...profileBasicInfoStyles.fieldName}>Birthdate:</Text>

                    <Text {...profileBasicInfoStyles.greenText}>
                      {user?.birthdate || 'no info'}
                    </Text>
                  </HStack>

                  <HStack {...profileBasicInfoStyles.userInfoSubContainer}>
                    <Text {...profileBasicInfoStyles.fieldName}>County of residence:</Text>

                    <Text {...profileBasicInfoStyles.greenText}>
                      {user?.residenceCounty || 'no info'}
                    </Text>
                  </HStack>

                  <HStack {...profileBasicInfoStyles.userInfoSubContainer}>
                    <Text {...profileBasicInfoStyles.fieldName}>Registered Account State:</Text>

                    <Text {...profileBasicInfoStyles.greenText}>
                      {user?.userAccountState || 'no info'}
                    </Text>
                  </HStack>
                </VStack>
              </Wrap>
            </VStack>
          }
        />
      </Section>
    </Box>
  );
}

export default ProfileBasicInfo;
