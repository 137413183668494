export const headerStyles = {
  headerContainer: {
    top: '0',
    zIndex: 10,
    backgroundColor: 'black',
    padding: '20px 20px',
    width: '100%',
    height: '70px',
    boxShadow: '0px 1px 3px #0000004B',
    opacity: '0.95',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  icon: {
    width: '100px',
    height: '58px',
  },

  signOutButton: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    color: '#0c0c0d',
    fontFamily: 'Lato',
    fontSize: '15px',
    fontStyle: 'bold',
    width: '120px',
    size: 'md',
    borderRadius: '25px',
    _hover: {
      backgroundColor: '#5F5F5F',
    },
  },
};
