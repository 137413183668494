import React, { ChangeEvent, ReactElement } from 'react';

import { VStack, Text, HStack } from '@chakra-ui/react';

import { IAddressInputProps } from './interfaces';
import TextInput from './TextInput';

function AddressInput({ values, setValue, helperText }: IAddressInputProps): ReactElement {
  return (
    <VStack>
      <Text>{helperText}</Text>

      <HStack>
        <TextInput
          size='md'
          label='Street1'
          value={values.Street1}
          key='Street1'
          id='Street1'
          name='Street1'
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setValue('address.Street1', e.target.value);
          }}
        />

        <TextInput
          size='md'
          label='Street2'
          value={values.Street2}
          key='Street2'
          id='Street2'
          name='Street2'
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setValue('address.Street2', e.target.value);
          }}
        />

        <TextInput
          size='md'
          label='City'
          value={values.City}
          key='City'
          id='City'
          name='City'
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setValue('address.City', e.target.value);
          }}
        />
      </HStack>

      <HStack>
        <TextInput
          size='md'
          label='State'
          value={values.State}
          key='State'
          id='State'
          name='State'
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setValue('address.State', e.target.value);
          }}
        />

        <TextInput
          size='md'
          label='Zip'
          value={values.Zip}
          key='Zip'
          id='Zip'
          name='Zip'
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setValue('address.Zip', e.target.value);
          }}
        />

        <TextInput
          size='md'
          label='Country'
          value={values.Country}
          key='Country'
          id='Country'
          name='Country'
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setValue('address.Country', e.target.value);
          }}
        />
      </HStack>
    </VStack>
  );
}

export default AddressInput;
