export enum REDIRECT_SEARCH_PARAMS {
  intentID = 'payment_intent',
  secret = 'payment_intent_client_secret',
}

export enum PAYMENT_STATUS {
  success = 'succeeded',
  processing = 'processing',
  canceled = 'canceled',

  requiresPaymentMethod = 'requires_payment_method',
  requiresAction = 'requires_action',
  requiresCapture = 'requires_capture',
  requiresConfirmation = 'requires_confirmation',
}

export enum PAYMENT_STATUS_MESSAGE {
  success = 'Success! Payment received.',
  processing = 'Payment processing. We"ll update you when payment is received.',
  loading = 'Please wait for the status...',
  requiresPaymentMethod = 'Payment failed. Please try another payment method.',
  unknown = 'Something went wrong.',
}
