import React, { ReactElement, useState } from 'react';

import { VStack, Box, Link, Spacer, Text, Button, Flex } from '@chakra-ui/react';
import { useFormik } from 'formik';

import { registerEvent } from '@modules/core/aws/lambdas/family-action';
import TextInput from '@modules/common-ui/components/text-field/TextInput';
import RadioTextInput from '@modules/common-ui/components/text-field/RadioTextInput';
import PhoneInput from '@modules/common-ui/components/text-field/PhoneInput';

import { IFamilyRegistrationProps } from './interfaces';
import validationSchema from './validation-schema';

import { familyRegistrationStyles } from './styles';

export function FamilyRegistration({
  accountHolderName,
  sub,
}: IFamilyRegistrationProps): ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [queryError, setQueryError] = useState<string | null>();
  const [executorInfo, setExecutorInfo] = useState<{
    executorName: string;
    executorMobile: string;
    executorEmail: string;
  }>();

  function formSubmitted(props: {
    email: string;
    phoneNumber: string;
    fullName: string;
    relation: string;
    lifeEvent: string;
    details: string;
  }): void {
    // console.log('For submission triggered');
    setIsLoading(true);
    registerEvent({
      accountHolderUserName: sub,
      fullName: props.fullName,
      mobile: props.phoneNumber,
      email: props.email,
      accountHolderStatus: props.lifeEvent,
      statusDetails: props.details,
      relationship: props.relation,
    })
      .then((result) => {
        setExecutorInfo({
          executorName: result.executorName as string,
          executorMobile: result.executorMobile as string,
          executorEmail: result.executorEmail as string,
        });
      })
      .catch((err) => {
        // console.log(`Error registering event: ${err.message}`);
        setQueryError(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    onSubmit: (props) => {
      formSubmitted(props);
    },
    validationSchema,
    initialValues: {
      email: '',
      phoneNumber: '',
      fullName: '',
      relation: '',
      lifeEvent: '',
      details: '',
    },
  });

  if (queryError) {
    return (
      <Flex>
        <Text {...familyRegistrationStyles.titleText}>
          Error with registration: {queryError}
          <br />
          Please try again later or contact our customer support team.
        </Text>
      </Flex>
    );
  }
  if (executorInfo) {
    return (
      <Flex w='100%' alignItems='center' justifyContent='center' direction='column'>
        <header className='App-header'>
          <Text {...familyRegistrationStyles.titleText}>
            Family&apos;s Life Event Registration for:
          </Text>
          <br />
          {accountHolderName}
        </header>
        <Text margin='20px'>
          We&apos;ve contacted {executorInfo.executorName} and shared your contact information with
          them. They should reach out to you soon. If you have an urgent matter to work through with
          them, you may try to reach out to them at the contact below:
        </Text>
        <Box borderWidth='2px' borderRadius='md' borderColor='brand.green.200'>
          <VStack alignItems='left' p='7px'>
            <Text>Account Agent: {executorInfo.executorName}</Text>
            <Text>Mobile number: {executorInfo.executorMobile}</Text>
            <Text>
              Email:
              <Link href={`mailto:${executorInfo.executorEmail}`}>
                {executorInfo.executorEmail}
              </Link>
            </Text>
          </VStack>
        </Box>
      </Flex>
    );
  }
  return (
    <Flex w='100%' alignItems='center' justifyContent='center' direction='column'>
      <header className='App-header'>
        <Text {...familyRegistrationStyles.titleText}>
          Family&apos;s Life Event Registration for:
        </Text>
        <br />
        <Text {...familyRegistrationStyles.titleText} align='center'>
          {accountHolderName}
        </Text>
      </header>

      <Spacer />

      <VStack margin='50px'>
        <Text>
          We&apos;re sorry to hear that {accountHolderName} may have been incapacitated or passed
          away.
          <br />
          Please provide the following details to notify their LVED account agent.
        </Text>
      </VStack>

      <form onSubmit={handleSubmit}>
        <VStack {...familyRegistrationStyles.contentBox} width='100%' spacing='5'>
          <Spacer />
          <TextInput
            id='fullName'
            name='fullName'
            type='text'
            label='Your name (first and last)'
            onChange={handleChange}
            value={values.fullName}
            isError={!!errors.fullName && touched.fullName}
            errorMessage={errors.fullName}
          />

          <TextInput
            id='email'
            name='email'
            type='email'
            label='Your email address'
            onChange={handleChange}
            value={values.email}
            isError={!!errors.email && touched.email}
            errorMessage={errors.email}
          />

          <PhoneInput
            id='phoneNumber'
            name='phoneNumber'
            label='Your mobile number (or best contact number)'
            onChange={handleChange}
            value={values.phoneNumber}
            isError={!!errors.phoneNumber && touched.phoneNumber}
            errorMessage={errors.phoneNumber}
          />

          <TextInput
            id='relation'
            name='relation'
            type='text'
            label={`Your relationship to ${accountHolderName}`}
            onChange={handleChange}
            value={values.relation}
            isError={!!errors.relation && touched.relation}
            errorMessage={errors.relation}
          />

          <RadioTextInput
            id='lifeEvent'
            name='lifeEvent'
            label={`What happened to ${accountHolderName}`}
            onChange={handleChange}
            value={values.lifeEvent}
            isError={!!errors.lifeEvent && touched.lifeEvent}
            errorMessage={errors.lifeEvent}
            options={['Incapacitated', 'Passed away']}
          />

          <TextInput
            id='details'
            name='details'
            type='text'
            label={`Additional helpful details you'd like to provide`}
            onChange={handleChange}
            value={values.details}
            isError={!!errors.details && touched.details}
            errorMessage={errors.details}
          />

          <Button isLoading={isLoading} type='submit' {...familyRegistrationStyles.submitButton}>
            Notify Account Agent
          </Button>
        </VStack>
      </form>
    </Flex>
  );
}
