export const articleStyles = {
  gridContainer: {
    templateAreas: `"latest score"
      "tasks score"`,
    gridTemplateRows: '30% 70%',
    gridTemplateColumns: '65% 35%',
    height: '700px',
    gap: '50px',
    color: 'blackAlpha.700',
    fontWeight: 'bold',
    width: '80%',
    margin: '80px auto',
  },

  gridItemBox: {
    backgroundColor: '#FEFEFE',
    borderRadius: '20px',
    padding: '45px',
    width: '100%',
    height: '100%',
  },

  spinnerBox: {
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },

  spinner: {
    thickness: '4px',
    speed: '0.65s',
    emptyColor: 'gray.200',
    color: 'brand.green.100',
    size: 'xl',
  },

  updatesContentBox: {
    spacing: '2',
    overflow: 'auto',
    width: '100%',
    height: '100%',
    alignItems: 'normal',
  },

  tasksContentBox: {
    height: '100%',
  },

  scoreContentBox: {
    spacing: '2',
  },

  widgetHeader: {
    size: 'md',
  },

  score: {
    size: '3xl',
    color: '#FDE000',
  },

  logo: {
    width: '65px',
    height: '36px',
    flexShrink: '0',
  },

  pageBackground: {
    width: '100%',
    background: 'linear-gradient(180deg, #D0F0E4 13.67%, #F3F7F8 37.59%, #F6F8FA 100%)',
    spacing: '30px',
  },

  articleCards: {
    spacing: '32px',
    justify: 'center',
  },

  errorText: {
    fontFamily: 'Lato',
    fontSize: '16px',
    color: 'brand.red.100',
  },
};
