import React, { ReactElement, useEffect } from 'react';
import { useNavigate, NavigateFunction } from 'react-router-dom';

import {
  VStack,
  Heading,
  Button,
  Text,
  Box,
  Spinner,
  HStack,
  Wrap,
  useDisclosure,
} from '@chakra-ui/react';

import { IAuthContext } from '@modules/core/contexts/auth/interfaces';
import { useAuth, useQuery } from '@modules/core/hooks';
import { formatDate, isExpired } from '@modules/core/date-formatter';
import { cancelSubscription } from '@app/modules/core/aws/lambdas/payments';
import { ICancelSubscriptionRes } from '@app/modules/core/aws/lambdas/payments/interfaces';
import { ConditionSeparator } from '@modules/common-ui/components/condition-separator';

import Modal from '@modules/common-ui/components/modal';
import PaymentsHistory from '@modules/common-ui/components/payments-history';

import { subscriptionStyles } from './styles';

import {
  LICENSE_RENEWAL,
  LICENSE_STATUSES,
  APP_ROUTES,
  ANCHORS,
} from '@app/modules/core/constants';
import { getAffiliateDetail } from '@app/modules/core/aws/lambdas/dashboard';
import {
  IGetAffiliateDetailsRequest,
  IGetAffiliateDetailsResult,
} from '@app/modules/core/aws/lambdas/dashboard/interfaces';
import { Avatar } from '@app/modules/common-ui/components/profile-basic-info/Avatar';

function SubscriptionManagement(): ReactElement {
  const { user, reloadUser }: IAuthContext = useAuth();
  const { isOpen: isModalOpen, onOpen: openModal, onClose: closeModal } = useDisclosure();
  const { submit, res, isLoading } = useQuery<void, ICancelSubscriptionRes>(cancelSubscription);

  const {
    submit: getAffiliateDetails,
    res: affiliateDetails,
    isLoading: isAffiliateLoading,
  } = useQuery<IGetAffiliateDetailsRequest, IGetAffiliateDetailsResult>(getAffiliateDetail);

  const navigateTo: NavigateFunction = useNavigate();
  const openPriciesPage = (): void => navigateTo(APP_ROUTES.pricing);
  const openProfilePage = (): void => navigateTo(APP_ROUTES.profile + ANCHORS.familyManagement);

  const isSubscriptionCanceled = (): boolean => {
    if (!user?.licenseRenewal) return true;
    return user.licenseRenewal === LICENSE_RENEWAL.never;
  };

  const isFamilyAnnual = (): boolean => {
    const renewal: LICENSE_RENEWAL | null = user?.licenseRenewal || null;
    const lecense: LICENSE_STATUSES | null = user?.licenseStatus || null;

    return renewal === LICENSE_RENEWAL.annual && lecense === LICENSE_STATUSES.family;
  };

  const isUpgradePlanAvaliable = () => {
    const renewal: LICENSE_RENEWAL | null = user?.licenseRenewal || null;
    const lecense: LICENSE_STATUSES | null = user?.licenseStatus || null;
    const expiresDate: string | null = user?.licenseExpires || null;

    if (!(renewal && lecense && expiresDate)) return true;
    if (renewal === LICENSE_RENEWAL.never || isExpired(expiresDate)) {
      return false;
    }

    return true;
  };

  const formatNextPayment = (date: string): string => {
    return formatDate(date, {
      format: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      },
    });
  };

  useEffect(() => {
    if (res && res.licenseRenewal === LICENSE_RENEWAL.never) {
      reloadUser();
    }
  }, [res]);

  useEffect(() => {
    if (user?.activeAffiliateId) {
      getAffiliateDetails({ affiliateId: user!.activeAffiliateId! });
    }
  }, [user]);

  /* useEffect(() => {
    console.log(affiliateDetails);
  }, [affiliateDetails]);
  */
  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title='Contact us'
        text='To contact us please write to this email: support@lved.com'
      />

      <VStack {...subscriptionStyles.mainContainer}>
        <Heading {...subscriptionStyles.pageHeading}>Subscription Overview</Heading>

        <Box {...subscriptionStyles.currentPlanBox} position='relative'>
          {!user ? (
            <Box position='absolute' {...subscriptionStyles.spinnerBox}>
              <Spinner {...subscriptionStyles.spinner} />
            </Box>
          ) : (
            <Wrap {...subscriptionStyles.currentPlanContentBox}>
              <VStack {...subscriptionStyles.currentPlanContentChild}>
                <Text {...subscriptionStyles.currentPlanTextHeader}>Current Plan</Text>

                <Text {...subscriptionStyles.currentPlanText}>{`${
                  !isSubscriptionCanceled() ? user.licenseRenewal : ''
                } ${user.licenseStatus}`}</Text>
              </VStack>

              <VStack {...subscriptionStyles.currentPlanContentChild}>
                <Text {...subscriptionStyles.currentPlanTextHeader}>
                  {isSubscriptionCanceled() ? 'Expires' : 'Next Payment'}
                </Text>

                <Text {...subscriptionStyles.currentPlanText}>
                  {user.licenseExpires ? formatNextPayment(user.licenseExpires) : 'None'}
                </Text>
              </VStack>

              {user.activeAffiliateId &&
                (isAffiliateLoading ? (
                  <Box position='absolute' {...subscriptionStyles.spinnerBox}>
                    <Spinner {...subscriptionStyles.spinner} />
                  </Box>
                ) : (
                  <HStack spacing='15px'>
                    <VStack>
                      <Text {...subscriptionStyles.currentPlanTextHeader}>Promotional Partner</Text>
                      <Text {...subscriptionStyles.currentPlanText}>
                        {affiliateDetails?.name ?? 'Unknown'}
                      </Text>
                    </VStack>
                    <Avatar
                      file={affiliateDetails?.thumbnail}
                      style={subscriptionStyles.affiliateLogo}
                    />
                  </HStack>
                ))}
            </Wrap>
          )}
        </Box>

        {!isFamilyAnnual() && (
          <Button
            onClick={openPriciesPage}
            isDisabled={!user}
            {...subscriptionStyles.upgradePlanButton}
          >
            {isUpgradePlanAvaliable() ? 'Upgrade Plan' : 'Renew'}
          </Button>
        )}

        <PaymentsHistory />

        <VStack {...subscriptionStyles.buttonsBox}>
          <ConditionSeparator
            condition={user?.licenseStatus === LICENSE_STATUSES.family}
            target={
              <Button onClick={openProfilePage} {...subscriptionStyles.additionalActionButton}>
                Manage Family Members
              </Button>
            }
          />

          <HStack {...subscriptionStyles.buttonsChildBox}>
            <Button
              isDisabled={isSubscriptionCanceled()}
              isLoading={isLoading}
              onClick={() => submit()}
              {...subscriptionStyles.cancelSubscriptionButton}
            >
              Cancel Subscription
            </Button>

            <Button
              onClick={openModal}
              {...subscriptionStyles.additionalActionButton}
              {...subscriptionStyles.contactUsButton}
            >
              Contact us
            </Button>
          </HStack>
        </VStack>
      </VStack>
    </>
  );
}

export default SubscriptionManagement;
