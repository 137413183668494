import React, { useState, useEffect, ReactElement } from 'react';

import { encryptionContext as EncryptionContext } from './encryption-context';
import { IEncryptionContextProviderProps, EncryptionKeyType, TimerID } from './interfaces';

const TIMEOUT: number = 1000 * 60 * 30;
const TIME_STEP: number = 1000;
const INTERVAL_STEP: number = 1000;

let keyTimeoutId: TimerID;
let keyIntervalId: TimerID;

function EncryptionContextProvider(props: IEncryptionContextProviderProps): ReactElement {
  const [encryptionKey, setEncryptionKey] = useState<EncryptionKeyType>(new ArrayBuffer(0));
  const [timeLeft, setTimeLeft] = useState<number>(0);

  const removeKey = (): void => setEncryptionKey(new ArrayBuffer(0));
  const reduceTime = (time: number): void => setTimeLeft((prevTime: number) => prevTime - time);
  const stopTimeout = (id: TimerID): void => clearTimeout(id);
  const stopInterval = (id: TimerID): void => clearInterval(id);
  const startInterval = (): TimerID => setInterval(reduceTime.bind(null, TIME_STEP), INTERVAL_STEP);

  const startTimeout = (): TimerID => {
    return setTimeout((): void => {
      removeKey();
      setTimeLeft(0);

      if (keyIntervalId) {
        stopInterval(keyIntervalId);
      }
    }, TIMEOUT);
  };

  const setKeyFromOutside = (key: EncryptionKeyType): void => {
    if (key) {
      setTimeLeft(TIMEOUT);
      setEncryptionKey(key);

      if (keyIntervalId) stopInterval(keyIntervalId);
      if (keyTimeoutId) stopTimeout(keyTimeoutId);

      keyIntervalId = startInterval();
      keyTimeoutId = startTimeout();
    }
  };

  useEffect(() => {
    return (): void => {
      if (keyIntervalId) stopInterval(keyIntervalId);
      if (keyTimeoutId) stopTimeout(keyTimeoutId);
    };
  }, []);

  return (
    <EncryptionContext.Provider
      value={{
        setEncryptionKey: setKeyFromOutside,
        isKeyExist: !!encryptionKey,
        encryptionKey,
        timeLeft,
      }}
    >
      {props.children}
    </EncryptionContext.Provider>
  );
}

export default EncryptionContextProvider;
