import React, { ReactElement } from 'react';

import { Icon } from '@chakra-ui/react';

import { ICommonIconProps } from './interfaces';

function UserIcon({ width = '152px', height = '152px' }: ICommonIconProps): ReactElement {
  return (
    <Icon
      width={width}
      height={height}
      viewBox='0 0 152 152'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1881_1804)'>
        <circle cx='76' cy='76' r='76' fill='#DFDFDF' fillOpacity='0.15' />
        <circle cx='76.0001' cy='47' r='19.3333' fill='#CFCFCF' />
        <ellipse opacity='0.5' cx='76.0001' cy='100.167' rx='33.8333' ry='19.3333' fill='#CFCFCF' />
      </g>
      <defs>
        <clipPath id='clip0_1881_1804'>
          <rect width='152' height='152' rx='76' fill='white' />
        </clipPath>
      </defs>
    </Icon>
  );
}

export default UserIcon;
