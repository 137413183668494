import React, { ReactElement } from 'react';

import { Icon } from '@chakra-ui/react';

import { ICommonIconProps } from './interfaces';

function TimerIcon({ width = '166px', height = '195px' }: ICommonIconProps): ReactElement {
  return (
    <Icon
      width={width}
      height={height}
      viewBox='0 0 166 195'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M125.622 29.0887L116.992 43.0203L129.856 50.9887L138.486 37.0572L125.622 29.0887Z'
        fill='#6BA1FF'
      />
      <path
        d='M125.651 29.0736L122.403 34.3162L135.267 42.2846L138.514 37.0421L125.651 29.0736Z'
        fill='black'
        fillOpacity='0.3'
      />
      <path
        d='M138.882 41.8555L121.066 30.8349C119.639 29.9784 119.182 28.094 120.096 26.6665L123.522 21.1276C124.378 19.7001 126.263 19.2433 127.69 20.1569L145.506 31.1775C146.933 32.034 147.39 33.9184 146.477 35.3459L143.05 40.8848C142.137 42.3123 140.31 42.7691 138.882 41.8555Z'
        fill='#8BC94A'
      />
      <path
        d='M40.5189 29.0218L27.6553 36.9902L36.2853 50.9218L49.1489 42.9533L40.5189 29.0218Z'
        fill='#6BA1FF'
      />
      <path
        d='M40.4694 29.0225L27.6057 36.991L30.8532 42.2335L43.7169 34.265L40.4694 29.0225Z'
        fill='black'
        fillOpacity='0.3'
      />
      <path
        d='M27.2482 41.8555L45.0639 30.8349C46.4915 29.9784 46.9483 28.094 46.0347 26.6665L42.6086 21.1276C41.752 19.7001 39.8677 19.2433 38.4402 20.1569L20.6245 31.1775C19.1969 32.034 18.7401 33.9184 19.6537 35.3459L23.0798 40.8848C23.9934 42.3123 25.8207 42.7691 27.2482 41.8555Z'
        fill='#8BC94A'
      />
      <path d='M94.8564 13.4189H71.3306V38.9434H94.8564V13.4189Z' fill='#6BA1FF' />
      <path d='M94.8564 13.4189H71.3306V22.9549H94.8564V13.4189Z' fill='black' fillOpacity='0.3' />
      <path
        d='M83.0364 194.945C129.016 194.945 166.29 157.671 166.29 111.691C166.29 65.7108 129.016 28.4366 83.0364 28.4366C37.0564 28.4366 -0.217773 65.7108 -0.217773 111.691C-0.217773 157.671 37.0564 194.945 83.0364 194.945Z'
        fill='#D3E9C0'
      />
      <path
        d='M83.0363 189.463C125.989 189.463 160.809 154.643 160.809 111.691C160.809 68.7383 125.989 33.9185 83.0363 33.9185C40.0838 33.9185 5.26392 68.7383 5.26392 111.691C5.26392 154.643 40.0838 189.463 83.0363 189.463Z'
        fill='#8BC94A'
      />
      <path
        d='M83.0544 184.22C123.168 184.22 155.687 151.701 155.687 111.587C155.687 71.4735 123.168 38.9548 83.0544 38.9548C42.9406 38.9548 10.4219 71.4735 10.4219 111.587C10.4219 151.701 42.9406 184.22 83.0544 184.22Z'
        fill='white'
      />
      <path
        d='M99.3103 19.5859H66.7624C64.1357 19.5859 62.0801 17.4731 62.0801 14.9036V4.79659C62.0801 2.16992 64.1928 0.114258 66.7624 0.114258H99.3103C101.937 0.114258 103.993 2.22702 103.993 4.79659V14.8465C104.05 17.4731 101.937 19.5859 99.3103 19.5859Z'
        fill='#8BC94A'
      />
    </Icon>
  );
}

export default TimerIcon;
