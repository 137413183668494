import React, { ReactElement, useEffect, useState } from 'react';
import { TOTPMFAProps } from './interfaces';
import { Box, Button, HStack, Image, Input, Spacer, Text, VStack } from '@chakra-ui/react';
import { TOTPMFAStyles, MFASharedStyles } from './styles';
import { IAuthContext } from '@app/modules/core/contexts/auth/interfaces';
import { useAuth } from '@app/modules/core/hooks';
import QRCode from 'react-qr-code';
import errorIcon from '@assets/images/error_filled.svg';
import successIcon from '@assets/images/check_circle.svg';

function TOTPMFASetup({ onClose, setMFAPreference }: TOTPMFAProps): ReactElement {
  const [verificationStage, setVerificationStage] = useState<0 | 1>(0);
  const [TOTPAuthCode, setTOTPAuthCode] = useState('');
  const [SWToken, setSWToken] = useState<null | string>();
  const [isValidatingCode, setIsValidatingCode] = useState(false);
  const { user, associateSWToken, verifySWToken }: IAuthContext = useAuth();
  const [errorStr, setErrorStr] = useState<string | null>();

  useEffect(() => {
    associateSWToken()
      .then((token) => {
        setSWToken(token);
      })
      .catch((error) => {
        setSWToken(null);
        setErrorStr(
          `Unable to get an Authenticator code at this time.  Please try again later. ${error.message}`
        );
      });
  }, []);

  function submitTOTPCode() {
    if (TOTPAuthCode.length !== 6) {
      setErrorStr('Code must be 6 digits');
    } else {
      setIsValidatingCode(true);
      verifySWToken(TOTPAuthCode, 'your authenticator app')
        .then(
          (success: boolean) => {
            if (success) {
              setErrorStr(null);
              setMFAPreference('TOTP').then((turnOnMFASuccess) => {
                if (turnOnMFASuccess) setVerificationStage(1);
                else
                  setErrorStr(
                    'Your code was correct, but there was an unknown error turning on MFA for this device'
                  );
              });
              setTOTPAuthCode('');
            }
          },
          (reason: any) => {
            setTOTPAuthCode('');
            setErrorStr(`Code was invalid: ${reason}`);
          }
        )
        .finally(() => {
          setIsValidatingCode(false);
        });
    }
  }

  return (
    <VStack {...TOTPMFAStyles.container}>
      {verificationStage === 0 && (
        <VStack {...TOTPMFAStyles.container}>
          <Text {...MFASharedStyles.heading}>
            Using an authenticator app like Google Authenticator, Microsoft Authenticator, Author or
            iPassword, scan this QR code. It will generate a 6-digit code for you to enter below.
          </Text>
          <VStack width='100%' gap='5px'>
            <Text>Scan the QR Code into your chosen auth app.</Text>
            <QRCode
              value={`otpauth://totp/${
                user?.fullName ?? 'Unknown'
              }?secret=${SWToken}&issuer=LVED.com Inc.&algorithm=SHA1&digits=6&period=30`}
            />
            <Box {...MFASharedStyles.divider} />
            <Text>Enter the 6-digit authentication code generated by your authentication app.</Text>
            <Input
              {...MFASharedStyles.authCodeInput}
              placeholder='- - - - - -'
              value={TOTPAuthCode}
              onChange={(e) => setTOTPAuthCode(e.target.value)}
            />
          </VStack>
        </VStack>
      )}
      {verificationStage === 1 && (
        <VStack {...TOTPMFAStyles.container}>
          <Text {...MFASharedStyles.heading}>
            Two-factor authentication with Authentication Apps
          </Text>
          <VStack width='100%' gap='25px'>
            <VStack gap='10px'>
              <Image src={successIcon} {...MFASharedStyles.successIcon} />
              <Text {...MFASharedStyles.successTitle}>Successfully enabled</Text>
            </VStack>
            <Text>
              Your authentication app is succsssfully set up. Codes will be requested from this app
              in the future
            </Text>
          </VStack>
        </VStack>
      )}
      {errorStr && (
        <HStack {...MFASharedStyles.textErrorContainer}>
          <Image {...MFASharedStyles.errorIcon} src={errorIcon} />
          <Text {...MFASharedStyles.textError}>{errorStr}</Text>
        </HStack>
      )}
      <HStack {...MFASharedStyles.footer}>
        <Spacer />
        <Button
          {...MFASharedStyles.cancelButton}
          onClick={() => {
            onClose();
          }}
        >
          {verificationStage === 1 ? 'Continue' : 'Cancel'}
        </Button>
        {verificationStage === 0 && (
          <Button
            {...MFASharedStyles.nextButton}
            isLoading={isValidatingCode}
            onClick={submitTOTPCode}
          >
            Verify code
          </Button>
        )}
      </HStack>
    </VStack>
  );
}

export default TOTPMFASetup;
