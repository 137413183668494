import { extendTheme } from '@chakra-ui/react';

import { sliderStyles } from '../modules/common-ui/components/slider/styles';

export default extendTheme({
  styles: {
    global: {
      '*': {
        boxSizing: 'border-box',
        padding: 0,
        margin: 0,
      },

      html: {
        scrollBehavior: 'smooth',
      },

      body: {
        backgroundColor: '#F9F8F6',
      },

      '.slick-slider': sliderStyles.slickSlider,
      '.slick-dots': sliderStyles.slickDots,
      '.slick-dots li': sliderStyles.slickDotsElem,
    },
  },

  colors: {
    brand: {
      floralWhite: '#F9F8F6',
      error: '#E53E3E',
      text: '#000000A3',
      blue: '#007AFF',
      red: {
        50: '#FF6D74',
        100: '#FF3D34',
        200: '#FF1010',
      },

      green: {
        50: '#60A4A5',
        100: '#207475',
        200: '#005455',
      },

      gray: {
        50: '#DFDFDF',
        100: '#CFCFCF',
      },
    },
  },
});
