import React, { ChangeEvent, ReactElement, useRef, useEffect } from 'react';

import {
  Input,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  FormErrorMessage,
} from '@chakra-ui/react';
import { AttachmentIcon } from '@chakra-ui/icons';

import { IFileUploadInputProps } from '@modules/common-ui/components/text-field/interfaces';
import { useFileReader } from '@modules/core/hooks';
import { encryptFile } from '@modules/core/crypto';

import { inputStyles } from '@modules/common-ui/components/text-field/styles';

import { FILE_TYPES } from '@modules/core/constants';

function FileUploadInput({
  label,
  id,
  name,
  placeholder,
  errorMessage,
  encryptionKey,
  variant = 'outline',
  resultType = 'binary',
  size = 'lg',
  acceptedFileTypes = FILE_TYPES.any,
  isError = false,
  required = false,
  isStorageEncrypted = false,
  style = {},
  setValue,
}: IFileUploadInputProps): ReactElement {
  const inputRef = useRef<HTMLInputElement>(null);
  const { file, fileContent, setFile } = useFileReader({
    format: isStorageEncrypted ? 'binary' : resultType,
  });

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files?.length) {
      setFile(event.target.files[0]);
    }
  };

  useEffect(() => {
    if (fileContent && setValue) {
      if (!(isStorageEncrypted && encryptionKey)) setValue(fileContent);
      else {
        encryptFile(fileContent, encryptionKey).then((fileData: string) => setValue(fileData));
      }
    }
  }, [fileContent]);

  return (
    <FormControl {...style} isInvalid={isError} isRequired={required}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}

      <InputGroup>
        <InputLeftElement {...inputStyles.attachmentIconBox}>
          <AttachmentIcon {...inputStyles.attachmentIcon} />
        </InputLeftElement>

        <Input
          name={name}
          onChange={onChangeHandler}
          type='file'
          accept={acceptedFileTypes}
          ref={inputRef}
          style={{ display: 'none' }}
        />

        <Input
          readOnly
          id={id}
          variant={variant}
          size={size}
          onClick={() => inputRef.current?.click()}
          placeholder={placeholder || 'Your file ...'}
          value={file?.name || ''}
        />
      </InputGroup>

      {isError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
}

export default FileUploadInput;
