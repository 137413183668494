export const promoCodeSlideStyles = {
  mainContainer: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    boxShadow: '0px -1px 60px rgba(81, 81, 81, 0.1)',
    background: 'white',
  },

  slide: {
    zIndex: 10,
  },

  contentBox: {
    width: { base: '95%', md: '600px' },
    padding: '40px 0px 10px 0px',
    spacing: '10',
    backgroundColor: 'white',
    margin: '0 auto',
  },

  submitButton: {
    width: '220px',
    border: '2px solid',

    color: 'white',
    backgroundColor: '#005F60',
    borderColor: '#006465',

    size: 'lg',

    _hover: {
      color: 'white',
      backgroundColor: '#307475',
      borderColor: '#307475',
    },
  },

  removePromocode: {
    width: '100%',
    color: 'black',
  },
};
