import { lambdaGeneralCaller } from '@modules/core/aws/lambdas/lambda-general-caller/lambdaGeneralCaller';
import {
  IUnAuthCredentials,
  IFinalMessageRequest,
  IFinalMessagePlayedResponse,
} from './interfaces';

import { AWS_LAMBDAS, AWS_LAMBDAS_OPERATIONS } from '@modules/core/constants';

export default async function recordMessagePlayed(request: IFinalMessageRequest): Promise<number> {
  const credentials: IUnAuthCredentials = {
    accessKeyId: process.env.REACT_APP_AWS_UNAUTHENTICATED_ACCESS_KEY_ID || 'NONE',
    secretAccessKey: process.env.REACT_APP_AWS_UNAUTHENTICATED_SECRET_ACCESS_KEY || 'NONE',
  };

  const messagePlayedResult = await lambdaGeneralCaller<IFinalMessagePlayedResponse>(credentials, {
    FunctionName: AWS_LAMBDAS.reviewFinalMessage,
    Payload: JSON.stringify({
      identityId: request.identityId,
      finalMessageId: request.finalMessageId,
      accessKey: request.accessKey,
      operation: AWS_LAMBDAS_OPERATIONS.registerFinalMessagePlayed,
    }),
  });

  return messagePlayedResult.viewCount;
}
