import React, { ReactElement } from 'react';

import { find } from 'lodash';
import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
  Collapse,
  Flex,
  Text,
  useDisclosure,
  ListItem,
  ListIcon,
  Box,
  UseDisclosureReturn,
} from '@chakra-ui/react';

import { useNavigation } from '@modules/core/hooks';
import { SideBarList } from '@modules/common-ui/components/side-bar/SideBarList';
import { ConditionSeparator } from '@modules/common-ui/components/condition-separator';
import { INavContext } from '@modules/core/contexts/navigation/interfaces';
import {
  ISideBarCollapseItemProps,
  SideBarItemsListType,
  SideBarItemType,
} from '@modules/common-ui/components/side-bar/interfaces';

import { sideBarStyles } from '@modules/common-ui/components/side-bar/styles';

export function SideBarCollapseItem({
  item,
  toggleNavMenuVisibility,
}: ISideBarCollapseItemProps): ReactElement {
  const { isOpen, onToggle }: UseDisclosureReturn = useDisclosure();
  const { activeRoute }: INavContext = useNavigation();

  const isChildActive = (items: SideBarItemsListType): boolean => {
    return !!find(items, (child: SideBarItemType) => {
      if (child.children) return isChildActive(child.children);
      return activeRoute?.includes(child.path);
    });
  };

  return (
    <>
      <ListItem
        onClick={onToggle}
        {...sideBarStyles.listItem}
        {...(isChildActive(item.children) && sideBarStyles.listItemActive)}
      >
        <Flex>
          <ConditionSeparator
            condition={!!item.icon}
            target={
              <Box {...sideBarStyles.listItemIconBox}>
                <ListIcon as={item.icon} />
              </Box>
            }
          />

          <Text as='span'>{item.title}</Text>
        </Flex>

        <ConditionSeparator
          condition={isOpen}
          target={<ChevronUpIcon boxSize={6} />}
          defaultTarget={<ChevronDownIcon boxSize={6} />}
        />
      </ListItem>

      <Collapse in={isOpen} animateOpacity>
        <SideBarList
          items={item.children}
          isNested
          toggleNavMenuVisibility={toggleNavMenuVisibility}
        />
      </Collapse>
    </>
  );
}
