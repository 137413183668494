import React, { ReactElement } from 'react';

import { Icon } from '@chakra-ui/react';

import { ICommonIconProps } from './interfaces';

function IndividualIcon({ width = '66', height = '66' }: ICommonIconProps): ReactElement {
  return (
    <Icon
      width={width}
      height={height}
      viewBox='0 0 66 66'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='66' height='66' rx='13' fill='currentColor' />
      <g clipPath='url(#clip0_1511_1715)'>
        <path
          d='M33 14.001C21.9555 14.001 13 22.9547 13 34.0001C13 45.0455 21.9546 53.9992 33 53.9992C44.0463 53.9992 53 45.0455 53 34.0001C53 22.9547 44.0463 14.001 33 14.001ZM33 19.9809C36.6546 19.9809 39.616 22.9433 39.616 26.5961C39.616 30.2498 36.6546 33.2112 33 33.2112C29.3472 33.2112 26.3858 30.2498 26.3858 26.5961C26.3858 22.9433 29.3472 19.9809 33 19.9809ZM32.9956 48.7704C29.3507 48.7704 26.0124 47.443 23.4375 45.2458C22.8102 44.7108 22.4483 43.9263 22.4483 43.1031C22.4483 39.3985 25.4466 36.4336 29.1522 36.4336H36.8496C40.556 36.4336 43.5429 39.3985 43.5429 43.1031C43.5429 43.9272 43.1827 44.7099 42.5546 45.2449C39.9806 47.443 36.6414 48.7704 32.9956 48.7704Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_1511_1715'>
          <rect width='40' height='40' fill='white' transform='translate(13 14)' />
        </clipPath>
      </defs>
    </Icon>
  );
}

export default IndividualIcon;
