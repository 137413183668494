import React from 'react';

import {
  Modal as ChakraUiModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';

import { ModalPropsType } from './interfaces';

import { modalBasicStyles } from './styles';

function Modal({
  isOpen,
  onClose,
  onSubmit,
  title,
  text,
  submitButtonText,
  children,
  isSubmitDisabled = false,
  size = 'xl',
}: ModalPropsType) {
  return (
    <ChakraUiModal isOpen={isOpen} onClose={onClose} size={size} isCentered>
      <ModalOverlay />

      <ModalContent margin='auto'>
        <ModalHeader>{title}</ModalHeader>

        <ModalCloseButton />

        <ModalBody>{text || children}</ModalBody>

        <ModalFooter>
          {Boolean(onSubmit) && (
            <Button isDisabled={isSubmitDisabled} onClick={onSubmit} {...modalBasicStyles.button}>
              {submitButtonText || 'Confirm'}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </ChakraUiModal>
  );
}

export default Modal;
