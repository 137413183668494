import { DynamoDB, AWSError } from 'aws-sdk';
import { Auth } from 'aws-amplify';

import { ICredentials, AnswersInputType } from './interfaces';

import { DYNAMO_DB_TABLES } from '@modules/core/constants';

export async function getAnswers(objectId: string): Promise<AnswersInputType | null> {
  const credentials: ICredentials = await Auth.currentCredentials();

  const dynamoClient: DynamoDB.DocumentClient = new DynamoDB.DocumentClient({
    region: process.env.REACT_APP_AWS_REGION || 'us-east-1',
    credentials: Auth.essentialCredentials(credentials),
  });

  const params = {
    TableName: DYNAMO_DB_TABLES.customerObjects,
    Key: { identityId: credentials.identityId, objectId },
  };

  if (!objectId) return null;

  return new Promise<AnswersInputType>((resolve, reject): void => {
    dynamoClient.get(params, (err: AWSError, data: DynamoDB.DocumentClient.GetItemOutput): void => {
      if (err) {
        return reject(new Error(err.message || JSON.stringify(err)));
      }

      return resolve(data.Item as AnswersInputType);
    });
  });
}
