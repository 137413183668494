import React, { ReactElement, useEffect } from 'react';

import {
  Box,
  Heading,
  VStack,
  Spinner,
  Grid,
  GridItem,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { useQuery } from '@modules/core/hooks';
import { sortByDateString } from '@modules/core/date-formatter';
import { getDashboardMetrics as getMetrics } from '@modules/core/aws/lambdas/dashboard';
import { getLatestUpdates } from '@modules/core/aws/dynamo-db/dashboard';
import {
  IDashboardMetricsRes,
  TasksListType,
  TaskType,
} from '@modules/core/aws/lambdas/dashboard/interfaces';
import {
  ILatestUpdates,
  LatestUpdatesListType,
} from '@modules/core/aws/dynamo-db/dashboard/interfaces';

import Task from '@modules/common-ui/components/task';
import Slider from '@modules/common-ui/components/slider';

import { dashboardStyles } from './styles';

const LATEST_UPDATES_LIMIT: number = 3;

function Dashboard(): ReactElement {
  const { isOpen: isLatestUpdatesOpen, onToggle: latestUpdatesToggle } = useDisclosure();

  const {
    submit: getMetricks,
    res: metricks,
    isLoading: isMetricksLoading,
  } = useQuery<void, IDashboardMetricsRes>(getMetrics);

  const {
    submit: getUpdates,
    res: updates,
    isLoading: isUpdatesLoading,
  } = useQuery<void, ILatestUpdates>(getLatestUpdates);

  const tasks: TasksListType = metricks?.tasks || [];
  const latestUpdatesItems: LatestUpdatesListType = updates?.Items || [];
  const readinessScore: number | undefined = metricks?.score;

  const filterLatestUpdates = (): LatestUpdatesListType => {
    const updatesArray: LatestUpdatesListType = sortByDateString(latestUpdatesItems);

    if (!isLatestUpdatesOpen && updatesArray.length > LATEST_UPDATES_LIMIT) {
      updatesArray.length = LATEST_UPDATES_LIMIT;
    }

    return updatesArray;
  };

  useEffect(() => {
    Promise.all([getMetricks(), getUpdates()]).catch(() => {});
  }, []);

  return (
    <Grid {...dashboardStyles.gridContainer}>
      <GridItem area='latest'>
        <Box {...dashboardStyles.gridItemBox} position='relative'>
          {isUpdatesLoading ? (
            <Box position='absolute' {...dashboardStyles.spinnerBox}>
              <Spinner {...dashboardStyles.spinner} />
            </Box>
          ) : (
            <VStack
              justify={latestUpdatesItems.length ? 'flex-start' : 'center'}
              {...dashboardStyles.updatesContentBox}
            >
              {!latestUpdatesItems.length && !!updates ? (
                <Heading>No updates</Heading>
              ) : (
                <>
                  {filterLatestUpdates().map(({ title, timestamp }, index) => (
                    <Text {...dashboardStyles.latestText} key={`${timestamp}`}>
                      {`${index + 1}. ${title}`}
                    </Text>
                  ))}

                  {latestUpdatesItems.length > LATEST_UPDATES_LIMIT && (
                    <Text as='u' cursor='pointer' onClick={latestUpdatesToggle}>
                      {isLatestUpdatesOpen ? 'Show less' : 'Show more'}
                    </Text>
                  )}
                </>
              )}
            </VStack>
          )}
        </Box>
      </GridItem>

      <GridItem area='tasks'>
        <Box {...dashboardStyles.gridItemBox} position='relative'>
          {isMetricksLoading ? (
            <Box position='absolute' {...dashboardStyles.spinnerBox}>
              <Spinner {...dashboardStyles.spinner} />
            </Box>
          ) : (
            <Box {...dashboardStyles.tasksContentBox}>
              <Heading textAlign='center' {...dashboardStyles.widgetHeader}>
                Tasks
              </Heading>

              {tasks.length && (
                <Slider>
                  {tasks.map((task: TaskType, index: number) => (
                    <Task key={String(index + task.interfaceReference)} task={task} />
                  ))}
                </Slider>
              )}
            </Box>
          )}
        </Box>
      </GridItem>

      <GridItem area='score'>
        <Box {...dashboardStyles.gridItemBox} position='relative'>
          {isMetricksLoading ? (
            <Box position='absolute' {...dashboardStyles.spinnerBox}>
              <Spinner {...dashboardStyles.spinner} />
            </Box>
          ) : (
            metricks && (
              <Box>
                <VStack {...dashboardStyles.scoreContentBox}>
                  <Heading textAlign='left' {...dashboardStyles.widgetHeader}>
                    Readiness Score
                  </Heading>

                  {!Number.isNaN(readinessScore) && (
                    <Heading as='h2' alignItems='center' noOfLines={1} {...dashboardStyles.score}>
                      {Math.round(readinessScore || 0)}%
                    </Heading>
                  )}
                </VStack>
              </Box>
            )
          )}
        </Box>
      </GridItem>
    </Grid>
  );
}

export default Dashboard;
