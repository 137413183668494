import { DynamoDB, AWSError } from 'aws-sdk';
import { Auth } from 'aws-amplify';

import { ICredentials, UserKeysResType } from './interfaces';

import { DYNAMO_DB_TABLES } from '@modules/core/constants';

export async function updateUserProfile(info: Record<string, any>): Promise<UserKeysResType> {
  const credentials: ICredentials = await Auth.currentCredentials();
  const obj: any = {};

  Object.entries(info).forEach(([key, value]) => {
    obj[`:${key}`] = value;
  });

  const clientDB: DynamoDB.DocumentClient = new DynamoDB.DocumentClient({
    region: process.env.REACT_APP_AWS_REGION || 'us-east-1',
    credentials: Auth.essentialCredentials(credentials),
  });

  const param = {
    TableName: DYNAMO_DB_TABLES.profiles,
    Key: { identityIdOrSub: credentials.identityId },
    UpdateExpression: `set ${Object.keys(info)
      .map((key) => `${key} = :${key}`)
      .join(',')}`,
    ExpressionAttributeValues: obj,
  };

  return new Promise<{}>((resolve, reject): void => {
    clientDB.update(
      param,
      (err: AWSError, data: DynamoDB.DocumentClient.UpdateItemOutput): void => {
        if (err) {
          return reject(new Error(err.message || JSON.stringify(err)));
        }

        return resolve(data);
      }
    );
  });
}
