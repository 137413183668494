import React, { ReactElement } from 'react';

import { Box, Button, VStack, Text } from '@chakra-ui/react';

import { pricingStyles } from './styles';

import { Avatar } from '@app/modules/common-ui/components/profile-basic-info/Avatar';
import {
  IEmailChallengeReq,
  ILicenseBlockApplicaitonRes,
  IProductsListRes,
} from '@app/modules/core/aws/lambdas/payments/interfaces';
import startEmailDomainChallenge from '@app/modules/core/aws/lambdas/payments/start-email-domain-challenge';
import { useQuery } from '@app/modules/core/hooks';
import { useFormik } from 'formik';
import validationSchema from './validation-schema';
import { TextInput } from '@app/modules/common-ui/components/text-field';
import Header from '@app/modules/common-ui/components/header';

export function EmailDomainChallenge({
  affiliateLogo,
  affiliateName,
  promoCode,
}: IProductsListRes): ReactElement {
  const { submit, res, isLoading, err } = useQuery<IEmailChallengeReq, ILicenseBlockApplicaitonRes>(
    startEmailDomainChallenge
  );
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    onSubmit: () => {
      submit({ ...values, promoCode: promoCode ?? 'BASE PRICE' });
    },
    validationSchema,
    initialValues: {
      email: '',
    },
  });

  return (
    <Box {...pricingStyles.backgroundContainer}>
      <VStack paddingTop='100px' paddingBottom='130px'>
        <Header />

        <VStack spacing='15px'>
          <Text {...pricingStyles.heading}>Employer Sponsored Plan</Text>
          <VStack>
            {affiliateName && <Avatar file={affiliateLogo} style={pricingStyles.affiliateLogo} />}
            <Box h={affiliateName ? '100px' : 'auto'}>
              <Text>{affiliateName}</Text>
            </Box>
          </VStack>

          <form onSubmit={handleSubmit}>
            <VStack spacing='30px'>
              <TextInput
                id='email'
                name='email'
                type='email'
                label={`Enter your ${affiliateName} Email Address`}
                onChange={handleChange}
                value={values.email}
                isError={!!errors.email && touched.email}
                errorMessage={errors.email}
              />

              <Button {...pricingStyles.button} isLoading={isLoading} type='submit'>
                Send validation link
              </Button>
            </VStack>
          </form>
          {err && <Text {...pricingStyles.errorText}>{err.message}</Text>}
          {res && (
            <Text>Please see your email and click the link provided to activate your account</Text>
          )}
        </VStack>
      </VStack>
    </Box>
  );
}
