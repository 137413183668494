import { DynamoDB, AWSError } from 'aws-sdk';
import { Auth } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';

import { ICredentials, ISaveAssetInputProps } from './interfaces';

import { DYNAMO_DB_TABLES } from '@modules/core/constants';

export async function saveAsset({ templateItem, assetData }: ISaveAssetInputProps): Promise<{}> {
  const credentials: ICredentials = await Auth.currentCredentials();
  const dynamoClient: DynamoDB.DocumentClient = new DynamoDB.DocumentClient({
    region: process.env.REACT_APP_AWS_REGION || 'us-east-1',
    credentials: Auth.essentialCredentials(credentials),
  });

  const params = {
    TableName: DYNAMO_DB_TABLES.customerObjects,
    Item: {
      objectId: uuidv4(),
      identityId: credentials.identityId,

      'Created Timestamp': new Date().toISOString(),
      'Last Update Timestamp': new Date().toISOString(),

      CanBeAgent: templateItem.CanBeAgent,
      CanBeBequestTarget: templateItem.CanBeBequestTarget,
      HierarchyCatagory: templateItem.HierarchyCatagory,
      HierarchySection: templateItem.HierarchySection,
      InstantiationLimit: templateItem.InstantiationLimit,
      ReleaseTiming: templateItem.ReleaseTiming,
      TemplateName: templateItem.TemplateName,
      Template: templateItem,
      ...assetData,
    },
  };

  return new Promise<{}>((resolve, reject): void => {
    dynamoClient.put(params, (err: AWSError, data: DynamoDB.DocumentClient.PutItemOutput): void => {
      if (err) {
        return reject(new Error(err.message || JSON.stringify(err)));
      }

      return resolve(data);
    });
  });
}
